import React from "react";

interface IArrow {
  colour: string;
}

const Arrow: React.FC<IArrow> = ({ colour }) => {
  return (
    <svg
      width="6"
      height="10"
      viewBox="0 0 6 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.24182 5.49828L1.32942 9.27213C1.04326 9.54785 0.580501 9.54785 0.294335 9.27213C0.00865903 8.99641 0.00865903 8.5496 0.294335 8.27388L3.68968 4.99916L0.294335 1.72396C0.00865903 1.44824 0.00865903 1.00143 0.294335 0.725712C0.580501 0.449991 1.04326 0.449991 1.32942 0.725712L5.24182 4.50003C5.5275 4.77575 5.5275 5.22256 5.24182 5.49828Z"
        fill={colour}
      />
    </svg>
  );
};

interface ICircle {
  colour: string;
  pct?: number;
  iHover?: boolean;
}

const Circle: React.FC<ICircle> = ({ colour, pct, iHover }) => {
  const r = 14;
  const circ = 2 * Math.PI * r;
  const strokePct = pct ? ((100 - pct) * circ) / 100 : circ;
  let widthLine;
  if (pct === 1) {
    widthLine = "0";
  } else if (pct === 100 || !pct) {
    widthLine = "1px";
  } else widthLine = "3px";
  if (iHover) widthLine = "3px";
  // console.log(widthLine);

  return (
    <circle
      r={r}
      cx={16}
      cy={16}
      fill="transparent"
      stroke={strokePct !== circ ? colour : ""} // remove colour as 0% sets full circumference
      strokeWidth={widthLine}
      strokeDasharray={circ}
      strokeDashoffset={pct ? strokePct : 1}
      strokeLinecap="round"
      style={
        pct === 99
          ? { transition: `all 3s ease-out` }
          : { transition: `all 1s ease-out` }
      }
    />
  );
};

interface ICircularIndicator {
  percentage: number;
  className?: string;
  isLeft: boolean;
  iHover: boolean;
}

const CircularIndicator: React.FC<ICircularIndicator> = ({
  percentage,
  className,
  isLeft,
  iHover,
}) => {
  const direction: string = isLeft
    ? "translate(13, 11) rotate(180 3 5)"
    : "translate(15, 11) rotate(0 3 5)";

  return (
    <svg className={className} width={32} height={32}>
      <g transform={`rotate(-90 ${"16 16"})`}>
        <Circle
          colour={!iHover ? "#DDDDDD" : "#333333"}
          pct={100}
          iHover={iHover}
        />
        <Circle colour="#333333" pct={percentage} />
      </g>
      <g transform={direction}>
        <Arrow colour={!iHover ? "#DDDDDD" : "#333333"} />
      </g>
    </svg>
  );
};

export default CircularIndicator;
