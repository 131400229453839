import React from "react";

import { useLanguage } from "@context";
import { InputSelect, InputText } from "@shared";
import { IDProduct } from "@types";

import styles from "./DataTemples.module.scss";

const quantity = ["1", "1000", "10000"];

interface IDataTemples {
  target: IDProduct;
  totalCost: number;
}

const DataTemples: React.FC<IDataTemples> = ({ target, totalCost }) => {
  const { text } = useLanguage();
  return (
    <div className={styles.data}>
      <h3>{target.title}</h3>
      <div className={styles.title}>{text[299]}</div>
      <form>
        <InputText
          label={text[300]}
          className={styles.description}
          placeholder={text[301]}
        />
        <InputSelect label={text[302]} className={styles.input_select}>
          {quantity.map((item) => (
            <option key={item}>{item}</option>
          ))}
        </InputSelect>
        <div className={styles.specify}>
          <span>{text[303]}</span>
          <div className={styles.as_input}>
            <div>10</div>
            <div>{text[304]}</div>
          </div>
        </div>
        <div className={styles.save}>
          <div>{totalCost}</div>
          <button type="button">{text[305]}</button>
        </div>
      </form>
    </div>
  );
};

export default DataTemples;
