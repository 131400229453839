import React from "react";

import { IIcon } from "@types";

const BrazilianIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg
      className={className}
      width="19"
      height="14"
      viewBox="0 0 19 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_758_5769"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="19"
        height="14"
      >
        <path
          d="M17.1905 0H1.80952C0.810151 0 0 0.810151 0 1.80952V11.7619C0 12.7613 0.810151 13.5714 1.80952 13.5714H17.1905C18.1898 13.5714 19 12.7613 19 11.7619V1.80952C19 0.810151 18.1898 0 17.1905 0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_758_5769)">
        <path
          d="M17.1905 0H1.80952C0.810151 0 0 0.810151 0 1.80952V11.7619C0 12.7613 0.810151 13.5714 1.80952 13.5714H17.1905C18.1898 13.5714 19 12.7613 19 11.7619V1.80952C19 0.810151 18.1898 0 17.1905 0Z"
          fill="#009C34"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.49995 1.80957L16.2857 6.78576L9.49995 11.762L2.71423 6.78576"
          fill="#F8E23D"
        />
        <path
          d="M9.50004 9.95247C11.2489 9.95247 12.6667 8.53471 12.6667 6.78581C12.6667 5.03691 11.2489 3.61914 9.50004 3.61914C7.75114 3.61914 6.33337 5.03691 6.33337 6.78581C6.33337 8.53471 7.75114 9.95247 9.50004 9.95247Z"
          fill="#002478"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.14282 8.14282V9.04758H9.04758V8.14282H8.14282ZM9.95235 8.14282V9.04758H10.8571V8.14282H9.95235Z"
          fill="#91A1C3"
        />
        <path
          d="M7.23804 5.42847C7.23804 5.42847 9.28642 5.79037 10.763 6.4219L12.6666 7.23799"
          stroke="#C9D0E0"
        />
        <path
          d="M17.1905 0.452393H1.80954C1.06001 0.452393 0.452393 1.06001 0.452393 1.80954V11.7619C0.452393 12.5114 1.06001 13.1191 1.80954 13.1191H17.1905C17.94 13.1191 18.5476 12.5114 18.5476 11.7619V1.80954C18.5476 1.06001 17.94 0.452393 17.1905 0.452393Z"
          stroke="black"
          strokeOpacity="0.1"
        />
      </g>
    </svg>
  );
};

export default BrazilianIcon;
