import {
  ErrorActionEnum,
  ErrorActionTypes,
  IErrorState,
} from "./errorContext.types";

export const errorReducer = (
  state: IErrorState,
  action: ErrorActionTypes,
): IErrorState => {
  switch (action.type) {
    case ErrorActionEnum.SET_ERROR:
      return { ...state, hasError: true, message: action.payload };
    case ErrorActionEnum.CLEAR_ERROR:
      return { ...state, hasError: false, message: null };
    default:
      return state;
  }
};
