import React from "react";

import { IIcon } from "@types";

const ImageIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.3339 2.00006C19.7232 2.00006 22.0001 4.37817 22.0001 7.91678V16.0833C22.0001 19.622 19.7232 22.0001 16.3329 22.0001H7.66624C4.27696 22.0001 2.00006 19.622 2.00006 16.0833V7.91678C2.00006 4.37817 4.27696 2.00006 7.66624 2.00006H16.3339ZM17.4367 12.5501C16.3647 11.8814 15.5371 12.8205 15.3139 13.1208C15.0987 13.4108 14.9137 13.7307 14.7186 14.0506C14.2419 14.8401 13.6959 15.7503 12.7507 16.2797C11.377 17.0403 10.3343 16.3396 9.58411 15.8298C9.30254 15.6399 9.02903 15.4603 8.75652 15.3406C8.08479 15.0506 7.48044 15.3809 6.58346 16.5202C6.11285 17.1156 5.64627 17.7059 5.17365 18.2942C4.89108 18.6461 4.95845 19.1889 5.33957 19.4242C5.94794 19.7988 6.69006 20.0001 7.52871 20.0001H15.9564C16.4321 20.0001 16.9087 19.935 17.3632 19.7864C18.3869 19.4521 19.1994 18.6863 19.6238 17.675C19.9818 16.8246 20.1557 15.7926 19.8209 14.934C19.7093 14.6492 19.5423 14.384 19.308 14.1507C18.6936 13.5408 18.1194 12.9712 17.4367 12.5501ZM8.49892 6.00006C7.12027 6.00006 6.00006 7.12179 6.00006 8.50006C6.00006 9.87833 7.12027 11.0001 8.49892 11.0001C9.87656 11.0001 10.9978 9.87833 10.9978 8.50006C10.9978 7.12179 9.87656 6.00006 8.49892 6.00006Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ImageIcon;
