import { Slider } from "antd";
import React from "react";

import { useLanguage } from "@context";

import styles from "./PriceRange.module.scss";

interface PriceRangeType {
  range: [number, number];
  onRange: (value: [number, number]) => void;
  setStartRange: (val: number) => void;
  setEndRange: (val: number) => void;
}

const PriceRange: React.FC<PriceRangeType> = ({
  range,
  onRange,
  setStartRange,
  setEndRange,
}) => {
  const { text } = useLanguage();
  const onChangeRange = (e: React.FormEvent<HTMLInputElement>): void => {
    if (e.currentTarget.name === "start")
      setStartRange(Number(e.currentTarget.value));
    else setEndRange(Number(e.currentTarget.value));
  };
  return (
    <div className={styles.range}>
      <h3>{text[281]}</h3>
      <p>{text[282]}</p>
      <Slider
        step={10}
        tooltipVisible={false}
        range
        value={range}
        onChange={onRange}
        style={{ color: "#51C9C2" }}
      />
      <div className={styles.input_block}>
        <div className={styles.block}>
          <label htmlFor="start">{text[283]}</label>
          <div className={styles.as_input}>
            <span>$</span>
            <input
              value={range[0] * 12}
              type="text"
              name="start"
              onChange={onChangeRange}
            />
          </div>
        </div>
        <div className={styles.line} />
        <div className={styles.block}>
          <label htmlFor="end">{text[284]}</label>
          <div className={styles.as_input}>
            <span>$</span>
            <input
              value={range[1] * 12}
              type="text"
              name="end"
              onChange={onChangeRange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PriceRange;
