export const expression = (str: string, pattern: RegExp): boolean => {
  const match = str.match(pattern);
  return Boolean(match && str === match[0]);
};

export const validateName = (fullname: string): boolean => {
  const pattern = /^\w+\s\w+$/gim;
  const rule = expression(fullname, pattern);
  return Boolean(rule);
};

export const validateMail = (mail: string): boolean => {
  const pattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(\\".+\\"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/i;
  const rule = expression(mail, pattern);
  return Boolean(rule);
};

export const validatePassword = (password: string): boolean => {
  return !!password;
};
