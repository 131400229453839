import React, { FC, RefObject } from "react";

import DownOutlineIcon from "@assets/icons/kit/svg/DownOutlineIcon.svg";

export interface SelectProps
  extends React.SelectHTMLAttributes<HTMLSelectElement> {
  pr?: number;
  ref?: RefObject<HTMLSelectElement>;
}

const Select: FC<SelectProps> = ({ pr = 12, children, ref, ...other }) => {
  return (
    <select
      ref={ref}
      {...other}
      style={{
        background: `url(${DownOutlineIcon}) no-repeat calc(100% - ${pr}px) 50% white`,
        appearance: "none",
      }}
    >
      {children}
    </select>
  );
};

export default Select;
