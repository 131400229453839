import React from "react";
import { useLocation } from "react-router-dom";

import NotificationItem from "@components/NotificationPageComponents/NotificationItem";
import { notification } from "@components/NotificationPageComponents/NotificationPageComponent.data";

import styles from "./NotificationList.module.scss";

const NotificationList: React.FC = () => {
  const { pathname } = useLocation();

  const currentPath: string = pathname.split("/").reverse()[0];

  const getList = (): JSX.Element[] => {
    return notification
      .filter((el) => el.type === currentPath)
      .map(({ id, title, isNew, description, date }) => (
        <NotificationItem
          key={id}
          isNew={isNew}
          date={date}
          description={description}
          title={title}
        />
      ));
  };

  const elements = getList();

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <p className={styles.info}>Notifications ({elements.length})</p>
        <ul className={styles.list}>{elements}</ul>
        <button type="button" className={styles.more}>
          Lead more notifications
        </button>
      </div>
    </div>
  );
};

export default React.memo(NotificationList);
