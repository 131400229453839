import React from "react";

import { ReactComponent as CalendarIcon } from "@assets/icons/event/details/calendarIcon.svg";
import { ReactComponent as CheckSquareIcon } from "@assets/icons/marketplace/checkSquareIcon.svg";
import { ReactComponent as RemoveIcon } from "@assets/icons/marketplace/removeIcon.svg";

import { Idata } from "../../MarketPlaceBasket.data";

import styles from "./BasketItem.module.scss";

const BasketItem: React.FC<Idata> = ({
  event,
  type,
  description,
  coast,
  positions,
  photo,
}) => {
  return (
    <li className={styles.item}>
      <div className={styles.content}>
        <div className={styles.photo}>
          <img alt="photoo" src={photo} className={styles.img} />
        </div>
        <div className={styles.wrapper}>
          <div className={styles.data}>
            <h3 className={styles.title}>
              <CalendarIcon className={styles.eventIcon} /> {event}
            </h3>
            <h4 className={styles.subtitle}>{type}</h4>
            <p className={styles.description}>{description}</p>
          </div>
          <div className={styles.meta}>
            <button type="button" className={styles.btn}>
              <RemoveIcon className={styles.btnIcon} />
              Remove
            </button>
            <div className={styles.price}>
              ${coast}
              <span className={styles.per}>/position</span>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.position}>
          <div className={styles.init}>Marketing positions:</div>
          <div className={styles.block}>
            <span className={styles.value}>{positions}</span>
            <CheckSquareIcon />
          </div>
        </div>
      </div>
    </li>
  );
};

export default BasketItem;
