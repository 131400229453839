import React from "react";

import { IIcon } from "@types";

const ChevronLeftIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.2075 18.6C17.0641 18.6 16.9207 18.5472 16.8075 18.4339L11.8868 13.5132C11.0868 12.7132 11.0868 11.4 11.8868 10.6L16.8075 5.67923C17.0264 5.46036 17.3887 5.46036 17.6075 5.67923C17.8264 5.89809 17.8264 6.26036 17.6075 6.47923L12.6868 11.4C12.3245 11.7622 12.3245 12.3509 12.6868 12.7132L17.6075 17.6339C17.8264 17.8528 17.8264 18.2151 17.6075 18.4339C17.4943 18.5396 17.3509 18.6 17.2075 18.6Z"
        fill="currentColor"
      />
      <path
        d="M11.3207 18.6C11.1774 18.6 11.034 18.5472 10.9207 18.4339L5.99999 13.5132C5.19999 12.7132 5.19999 11.4 5.99999 10.6L10.9207 5.67923C11.1396 5.46036 11.5019 5.46036 11.7207 5.67923C11.9396 5.89809 11.9396 6.26036 11.7207 6.47923L6.79999 11.4C6.43773 11.7622 6.43773 12.3509 6.79999 12.7132L11.7207 17.6339C11.9396 17.8528 11.9396 18.2151 11.7207 18.4339C11.6075 18.5396 11.4641 18.6 11.3207 18.6Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ChevronLeftIcon;
