import React from "react";
import { Link } from "react-router-dom";

import { ReactComponent as Arrow } from "@assets/icons/shared/arrow.svg";

import styles from "./PathHeader.module.scss";

interface IPathHeader {
  linkText: string;
}

const PathHeader: React.FC<IPathHeader> = ({ linkText }) => {
  return (
    <div className={styles.section}>
      <div className={styles.container}>
        <Link to="/" className={styles.link}>
          <Arrow className={styles.icon} /> {linkText}
        </Link>
        <div className={styles.path}>
          Homepage / Catalog / <span className={styles.active}>Checkout</span>
        </div>
      </div>
    </div>
  );
};

export default PathHeader;
