import React from "react";

const checkIcon = {
  position: "absolute",
  top: "-15px",
  left: "1px",
} as const;

const Check: React.FC = () => {
  return (
    <span style={checkIcon}>
      <svg
        width="12"
        height="9"
        viewBox="0 0 12 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 3.53846L5.11765 7L11 1"
          stroke="#51C9C2"
          strokeWidth="1.5"
        />
      </svg>
    </span>
  );
};

export default Check;
