import { useEffect, useMemo, useState } from "react";

import { DataApi } from "@api";
import { IMarketing, IProductMap, IResponseError } from "@types";

import { get, isEmpty } from "@lib/lodash";

interface IUseProductsOutput {
  products: IProductMap;
  data: IMarketing[];
  error: IResponseError;
  loading: boolean;
}

const productsOnline = [
  "Web Streaming",
  "Video production",
  "Events website",
  "Blog",
  "Social media marketing",
  "Podcasts",
  "Event structure",
];

const productsOffline = [
  "Video production",
  "Events website",
  "Blog",
  "Social media marketing",
  "Event structure",
  "Merchandising",
  "On the event",
  "Marketing materials",
];

const productsHybrid = [
  "Web Streaming",
  "Video production",
  "Events website",
  "Blog",
  "Social media marketing",
  "Podcasts",
  "Event structure",
];

const productsMap = {
  offline: productsOffline,
  online: productsOnline,
  hybrid: productsHybrid,
};

export const useProducts = (type: string): IUseProductsOutput => {
  const [products, setProducts] = useState<IProductMap>({} as IProductMap);

  const [data, setData] = useState<IMarketing[]>([]);
  const [error, setError] = useState<IResponseError>({} as IResponseError);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    if (isEmpty(data)) {
      DataApi.getProducts()
        .then((response) => setData(response))
        .catch((_error: IResponseError) => setError(_error));
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    setProducts(() => {
      // Get objects by type property 1
      const buffer = get(productsMap, type, []) as string[];
      const mask = buffer.map((item) => item.toLowerCase());

      if (!mask.length) return {} as IProductMap;

      // Filter array by the mask array of groups
      const filtered = data.filter((item) => {
        return mask.includes(item.Group.toLowerCase());
      });

      // Parse images from stringify json to array of urls
      const parsedImage = filtered.map((item) => {
        return {
          ...item,
          Images: item.Example.split(" "),
        };
      });

      // Recompose the groups from array to object with group key and array of products
      const result: [string, IMarketing[]][] = [];

      buffer.forEach((group) => {
        const values = parsedImage.filter(
          (item) => item.Group.toLowerCase() === group.toLowerCase(),
        );
        if (values.length) result.push([group, values]);
      });

      return Object.fromEntries(result);
    });
  }, [type, data]);

  const value = useMemo(() => products, [products, type, data]);

  return { products: value, data, error, loading };
};
