import React, { RefObject } from "react";
import { Link } from "react-router-dom";

import { RoutePaths } from "@helpers";

import styles from "./Sponsor.module.scss";

interface ISponsor {
  myRef?: RefObject<HTMLDivElement>;
}

const Sponsor: React.FC<ISponsor> = ({ myRef }) => {
  return (
    <section className={styles.section} ref={myRef}>
      <div className={styles.container}>
        <div className={styles.data}>
          <div className={styles.sponsor_block}>
            <h2 className={styles.title}>Brand</h2>
            <p className={styles.info}>
              Find out how we can help you make quicker, more targeted digital
              investments in sport
            </p>
            <Link to={RoutePaths.DEFAULT} className={styles.btn}>
              Marketplace
            </Link>
          </div>
          <div className={styles.line} />
          <div className={styles.sponsor_block}>
            <h2 className={styles.title}>Rights holder</h2>
            <p className={styles.info}>
              Find out how we can help you unlock more value from your media
              inventory
            </p>
            <Link to={RoutePaths.DEFAULT} className={styles.btn}>
              Get Started
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Sponsor;
