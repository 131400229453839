import { IDBPDatabase, openDB } from "idb";

export default class IndexApi {
  private database: string;

  private db: IDBPDatabase<unknown> | null = null;

  constructor(database: string) {
    this.database = database;
  }

  public async createObjectStore(tableNames: string[]): Promise<boolean> {
    try {
      this.db = await openDB(this.database, 1, {
        upgrade(db: IDBPDatabase) {
          tableNames.forEach((tableName) => {
            if (!db.objectStoreNames.contains(tableName)) {
              db.createObjectStore(tableName, {
                autoIncrement: true,
                keyPath: "id",
              });
            }
          });
        },
      });
    } catch (error) {
      return false;
    }
    return true;
  }

  public async getValue<T>(tableName: string, id: number): Promise<T> {
    if (this.db) {
      const tx = this.db.transaction(tableName, "readonly");
      const store = tx.objectStore(tableName);
      return (await store.get(id)) as unknown as T;
    }
    return {} as T;
  }

  public async getAllValue<T>(tableName: string): Promise<T[]> {
    if (this.db) {
      const tx = this.db.transaction(tableName, "readonly");
      const store = tx.objectStore(tableName);
      return (await store.getAll()) as T[];
    }
    return [];
  }

  public async putValue<T>(tableName: string, value: T): Promise<IDBValidKey> {
    if (this.db) {
      const tx = this.db.transaction(tableName, "readwrite");
      const store = tx.objectStore(tableName);
      const id = await store.put(value);
      return id;
    }
    return -1;
  }

  public async putBulkValue<T>(tableName: string, values: T[]): Promise<T[]> {
    if (this.db) {
      const tx = this.db.transaction(tableName, "readwrite");
      const store = tx.objectStore(tableName);
      for (let i = 0; i < values.length; i += 1) {
        store.put(values[i]).catch(null);
      }
      return this.getAllValue<T>(tableName);
    }
    return [];
  }

  public async deleteValue(tableName: string, id: number): Promise<number> {
    if (this.db) {
      const tx = this.db.transaction(tableName, "readwrite");
      const store = tx.objectStore(tableName);
      await store.delete(id);
      return id;
    }
    return -1;
  }

  public async clearTable(tableName: string): Promise<void> {
    if (this.db) {
      const tx = this.db.transaction(tableName, "readwrite");
      const store = tx.objectStore(tableName);
      await store.clear();
    }
  }
}
