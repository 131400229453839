import React, { useState } from "react";
import { Outlet } from "react-router-dom";

import { HeaderSidebar, Sidebar } from "@components";
import { useScroll } from "@context";

import CollapsedSideBar from "@components/CollapsedSideBar";

import styles from "./MainLayout.module.scss";

const MainLayout: React.FC = () => {
  const isDesktop = window.screen.width > 1050;
  const [refs, setRefs] = useState<number[]>([]);
  const { onScroll } = useScroll();
  const [isOpenSidebar, setIsOpenSidebar] = useState(isDesktop);
  const [isCollapse, setIsCollapse] = useState<boolean>(false);
  const pLeft = isCollapse ? 90 : 250;
  const onCollapse = (): void => {
    setIsCollapse((prev) => !prev);
  };

  const onSidebar = (): void => {
    if (!isDesktop) setIsOpenSidebar((prev) => !prev);
  };

  return (
    <div className={styles.container}>
      <HeaderSidebar
        onSidebar={onSidebar}
        isCollapse={isCollapse}
        onCollapse={onCollapse}
      />
      <div className={styles.wrapper}>
        {isOpenSidebar &&
          (!isCollapse ? (
            <Sidebar onSidebar={onSidebar} />
          ) : (
            <CollapsedSideBar onSidebar={onSidebar} />
          ))}
        <div
          className={styles.content}
          onScroll={onScroll(refs)}
          style={isDesktop ? { paddingLeft: pLeft } : {}}
        >
          <Outlet context={setRefs} />
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
