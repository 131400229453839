import React from "react";

import {
  EarthGlobeIcon,
  ImageIcon,
  IonIcon,
  PersonalIcon,
} from "@assets/icons/kit";

import {
  ProfileGeneralInformation,
  ProfilePersonalInformation,
  ProfilePortfolio,
  ProfileSocialMedias,
} from "./components";

export type SchemaType = {
  key: number;
  name: string;
  icon: React.FC;
  component: React.ReactElement;
};

export const ProfileSchema: SchemaType[] = [
  {
    key: 0,
    name: "Personal Information",
    icon: PersonalIcon,
    component: <ProfilePersonalInformation />,
  },
  {
    key: 1,
    name: "General information",
    icon: IonIcon,
    component: <ProfileGeneralInformation />,
  },
  // {
  //   key: 2,
  //   name: "Organisation’s documents",
  //   icon: PaperIcon,
  //   component: <ProfileDocumentaryInformation />,
  // },
  // {
  //   key: 3,
  //   name: "Team info",
  //   icon: ThreeUsersIcon,
  //   component: <ProfileTeamInfo />,
  // },
  {
    key: 2,
    name: "Social medias",
    icon: EarthGlobeIcon,
    component: <ProfileSocialMedias />,
  },
  {
    key: 3,
    name: "Portfolio and results",
    icon: ImageIcon,
    component: <ProfilePortfolio />,
  },
  // {
  //   key: 6,
  //   name: "Website generation",
  //   icon: WebsiteDesignIcon,
  // },
];
