import React from "react";

import { ICalendarCards } from "../CalendarBlock/CalendarBlock.data";

import styles from "./CalendarCard.module.scss";

const CalendarCard: React.FC<ICalendarCards> = ({
  coast,
  position,
  allPosition,
  colour,
  state,
  image,
  country,
  CountryIcon,
  dateEvent,
  eventName,
  inlineStyle,
}) => {
  return (
    <div className={styles.card} style={inlineStyle}>
      <div className={styles.header}>
        <div className={styles.state}>
          <div style={{ background: colour }} />{" "}
          <div style={{ color: colour }}>{state}</div>
        </div>
        <div className={styles.point} />
      </div>
      <img src={image} alt="event pic" />
      <div className={styles.country}>
        <CountryIcon />
        <div>{country}</div>
        <div>{dateEvent}</div>
      </div>
      <h2>{eventName}</h2>
      <div className={styles.position}>
        <h4>Position</h4>
        <div className={styles.range}>
          <span>{Math.round((position / allPosition) * 100)}%</span>
          <div className={styles.line}>
            <div style={{ width: `${(position / allPosition) * 100}%` }} />
          </div>
          <span>
            {position}/{allPosition}
          </span>
        </div>
        <div className={styles.footer}>
          <div className={styles.total}>
            <span>Total income: </span>
            <span>${coast}</span>
          </div>
          <button type="button">View listing details</button>
        </div>
      </div>
    </div>
  );
};

export default CalendarCard;
