import React from "react";

import { IIcon } from "@types";

const PaperIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.90442 16.39H14.7658C15.2076 16.39 15.5739 16.05 15.5739 15.64C15.5739 15.23 15.2076 14.9 14.7658 14.9H8.90442C8.46266 14.9 8.09632 15.23 8.09632 15.64C8.09632 16.05 8.46266 16.39 8.90442 16.39ZM12.5462 9.9H8.90442C8.46266 9.9 8.09632 10.24 8.09632 10.65C8.09632 11.06 8.46266 11.39 8.90442 11.39H12.5462C12.988 11.39 13.3543 11.06 13.3543 10.65C13.3543 10.24 12.988 9.9 12.5462 9.9ZM20.2355 9.02561C20.4888 9.02292 20.7646 9.02 21.0151 9.02C21.2845 9.02 21.5 9.22 21.5 9.47V17.51C21.5 19.99 19.3343 22 16.6622 22H8.08561C5.28422 22 3 19.89 3 17.29V6.51C3 4.03 5.17647 2 7.85935 2H13.613C13.8931 2 14.1086 2.21 14.1086 2.46V5.68C14.1086 7.51 15.7356 9.01 17.7073 9.02C18.1679 9.02 18.5739 9.02316 18.9293 9.02593C19.2057 9.02809 19.4514 9.03 19.6683 9.03C19.8217 9.03 20.0205 9.02789 20.2355 9.02561ZM20.5326 7.566C19.6469 7.569 18.6029 7.566 17.8519 7.559C16.6602 7.559 15.6786 6.648 15.6786 5.542V2.906C15.6786 2.475 16.2421 2.261 16.5643 2.572C17.1475 3.13479 17.9489 3.90841 18.7467 4.67847C19.5422 5.44639 20.3341 6.21077 20.9022 6.759C21.2168 7.062 20.9862 7.565 20.5326 7.566Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default PaperIcon;
