import React from "react";

import { IIcon } from "@types";

const EyeIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 6.18182C7.909 6.18182 4.391 8.86782 3 12.6818C4.391 16.4948 7.909 19.1808 12 19.1808C16.091 19.1808 19.609 16.4948 21 12.6818C19.609 8.86782 16.091 6.18182 12 6.18182ZM12 17.0148C9.709 17.0148 7.909 15.1078 7.909 12.6818C7.909 10.2548 9.709 8.34782 12 8.34782C14.291 8.34782 16.091 10.2548 16.091 12.6818C16.091 15.1078 14.291 17.0148 12 17.0148ZM12 10.0818C10.609 10.0818 9.545 11.2078 9.545 12.6818C9.545 14.1548 10.609 15.2818 12 15.2818C13.391 15.2818 14.455 14.1548 14.455 12.6818C14.455 11.2078 13.391 10.0818 12 10.0818Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default EyeIcon;
