import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useEvent } from "@context";
import { adapterProduct } from "@helpers";
import { useProducts } from "@hooks";
import { IDProduct } from "@types";

import { isEmpty } from "@lib/lodash";

import { Portal } from "../../PortalModal";

import CenterSide from "./CenterSide";
import Footer from "./Footer";
import LeftHeader from "./LeftHeader";
import LeftSide from "./LeftSide";
import RightSide from "./RightSide";
import styles from "./TemplesModal.module.scss";

interface TemplesModalProps {
  setIsOpen: (isOpen: boolean) => void;
  type: string;
}

const TemplesModal: React.FC<TemplesModalProps> = ({ setIsOpen, type }) => {
  const [target, setTarget] = useState<IDProduct>({} as IDProduct);
  const { products } = useProducts(type);
  const { rawProducts } = useEvent();
  const positions = useMemo(
    () => adapterProduct(products, rawProducts),
    [products, rawProducts],
  );

  const onTargetPosition = useCallback((item: IDProduct): void => {
    setTarget(() => item);
  }, []);

  const nextTargetPosition = useCallback(
    (item: IDProduct): void => {
      const pos = Object.entries<IDProduct[]>(positions).reduce(
        (acc, [, values]) => {
          acc.push(...values);
          return acc;
        },
        [] as IDProduct[],
      );

      for (let i = 0; i < pos.length; i += 1) {
        if (pos[i].mid === item.mid) {
          if (i + 1 < pos.length) {
            onTargetPosition(pos[i + 1]);
          } else {
            onTargetPosition(pos[0]);
          }
          break;
        }
      }
    },
    [positions],
  );

  useEffect(() => {
    if (!isEmpty(products)) {
      const obj = Object.entries(positions);
      setTarget(() => (obj[0][1] as IDProduct[])[0]);
    }
  }, [products]);

  const p = useMemo(
    () => ({
      len: rawProducts.length,
      cost: rawProducts.reduce((acc, el) => {
        return acc + el.price;
      }, 0),
    }),
    [rawProducts],
  );

  return (
    <Portal>
      <div className={styles.modal}>
        {!isEmpty(products) && (
          <div className={styles.content}>
            <LeftHeader hasNotifications />
            <LeftSide
              onTargetPosition={onTargetPosition}
              targetId={target.mid}
              products={positions}
            />
            <CenterSide target={target} />
            <RightSide target={target} onTargetPosition={nextTargetPosition} />
            <Footer
              totalCost={p.cost}
              selectedItem={p.len}
              setIsOpen={setIsOpen}
            />
          </div>
        )}
      </div>
    </Portal>
  );
};

export default TemplesModal;
