import React from "react";

import { IIcon } from "@types";

const ViewCarouselIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.9">
        <path
          d="M1.33325 3.99996H3.99992V11.3333H1.33325V3.99996ZM4.66659 12.6666H11.3333V2.66663H4.66659V12.6666ZM5.99992 3.99996H9.99992V11.3333H5.99992V3.99996ZM11.9999 3.99996H14.6666V11.3333H11.9999V3.99996Z"
          fill="#717D8A"
        />
      </g>
    </svg>
  );
};

export default ViewCarouselIcon;
