import React from "react";

import {
  bitcoinIcon,
  cubaIcon,
  dollarIcon,
  euroIcon,
  lariIcon,
  poundsIcon,
  rupeeIcon,
  yenIcon,
} from "@assets/icons/globalSet/currency";

import styles from "./SettingsCurrency.module.scss";

const currency = [
  {
    id: 0,
    name: "Euro",
    iconSrc: euroIcon,
  },
  {
    id: 1,
    name: "Yen",
    iconSrc: yenIcon,
  },
  {
    id: 2,
    name: "Dollar",
    iconSrc: dollarIcon,
  },
  {
    id: 3,
    name: "Pounds",
    iconSrc: poundsIcon,
  },
  {
    id: 4,
    name: "Rupee(INR)",
    iconSrc: rupeeIcon,
  },
  {
    id: 5,
    name: "Cuba",
    iconSrc: cubaIcon,
  },
  {
    id: 6,
    name: "Bitcoin",
    iconSrc: bitcoinIcon,
  },
  {
    id: 7,
    name: "Lari",
    iconSrc: lariIcon,
  },
];

const SettingsCurrency: React.FC = () => {
  return (
    <div className={styles.container}>
      <h4 className={styles.title}>Currency Preferency </h4>
      <div className={styles.currency_block}>
        {currency.map((item) => (
          <div className={styles.currency} key={item.id}>
            <span>{item.name}</span>
            <img src={item.iconSrc} alt={item.name} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SettingsCurrency;
