import { motion } from "framer-motion";
import React from "react";

import { CanadaIcon, EmojioneStar } from "@assets";
import { useLanguage } from "@context";
import { transformDate } from "@helpers";
import { IEvent } from "@types";

import {
  ArrowEventLandingLeft,
  OutlineLocationIcon,
  VectorEventLandingIcon,
} from "@assets/icons/event";
import { hybridIcon, singleEventIcon } from "@assets/icons/event/details";
import { IDateDict } from "@helpers/functions";

import styles from "./EventLandingHeader.module.scss";
import PhotoBlock from "./PhotoBlock/PhotoBlock";

const EventLandingHeader: React.FC<{ mEvent?: IEvent | null }> = ({
  mEvent,
}) => {
  const { text } = useLanguage();
  const { begin, end } = (transformDate(
    mEvent?.startTime,
    mEvent?.endTime,
    "full",
  ) as unknown as IDateDict) || { begin: null, end: null };

  return (
    <div className={styles.header}>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <ArrowEventLandingLeft className={styles.left_arrow} />
          <div className={styles.left_side}>
            <div className={styles.rate}>
              <EmojioneStar className={styles.star} />
              <span>4.5</span>
              <div className={styles.point} />
              <CanadaIcon className={styles.flag} />
              <span>{mEvent?.location.split(" ").pop()}</span>
            </div>
            <motion.h1
              initial={{ scale: 1.5 }}
              animate={{ scale: 1 }}
              transition={{ duration: 1, ease: "easeInOut" }}
            >
              {mEvent?.eventName}
            </motion.h1>
            <motion.div
              initial={{ opacity: 0, x: -300 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 1, ease: "easeInOut" }}
              className={styles.view}
            >
              <div className={styles.item}>
                <div>
                  <img src={hybridIcon} alt="hybrid" />
                </div>
                <span>{mEvent?.format}</span>
              </div>
              <div className={styles.item}>
                <div>
                  <img src={singleEventIcon} alt="single event" />
                </div>
                <span>{mEvent?.type}</span>
              </div>
            </motion.div>
            <div className={styles.link}>
              <a href="/">{text[237]}</a>
            </div>
          </div>
          <div className={styles.right_side}>
            <span className={styles.day}>
              {begin?.day === end?.day
                ? begin?.day
                : `${begin?.day} - ${end?.day}`}
            </span>
            <span className={styles.month}>
              {begin?.month} {begin?.year}
            </span>
            <div className={styles.label}>
              <OutlineLocationIcon />
              <span>{mEvent?.location}</span>
            </div>
            <VectorEventLandingIcon className={styles.vector} />
          </div>
        </div>
        <div className={styles.media}>
          <PhotoBlock photos={mEvent?.images || []} />
          <div className={styles.showPhoto}>
            <button type="button">
              {text[243]} {mEvent?.images.length} {text[244]}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventLandingHeader;
