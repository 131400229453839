import React, { FC } from "react";

import { Portal } from "../../PortalModal";

import { notifications } from "./Notification.data";
import styles from "./Notification.module.scss";

const Notification: FC = () => {
  return (
    <Portal>
      <div className={styles.wrapper}>
        <div className={styles.relative}>
          <div className={styles.influx} />
          {notifications.map((elem) => (
            <div className={styles.block} key={elem.id}>
              <div className={styles.description}>
                <div className={styles.marker} />
                <span>{elem.description}</span>
              </div>
              <div className={styles.time}>{elem.time}</div>
            </div>
          ))}
        </div>
      </div>
    </Portal>
  );
};

export default Notification;
