import { motion } from "framer-motion";
import React from "react";

import styles from "./SliderElement.module.scss";

export interface SliderElementProps {
  id: number;
  image: string;
  date: string;
  stars: number;
  price: number;
  title: string;
}

const SliderElement: React.FC<SliderElementProps> = ({
  id,
  date,
  title,
  price,
  stars,
  image,
}) => {
  return (
    <motion.div
      className={styles.slider_element}
      initial={{ y: -20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ delay: 0.2 }}
      key={id}
    >
      <img src={image} alt={`Sports events${stars}`} />
      <div className={styles.text}>
        <div className={styles.header}>
          <div>{date}</div>
          <div>
            {/* <EmojioneStar /> */}
            {/* <span>{stars}</span> */}
          </div>
        </div>
        <div className={styles.price}>
          <span>$ {price}</span>
          <span>/position</span>
        </div>
        <div className={styles.title}>{title}</div>
        <button type="button">Read more</button>
      </div>
    </motion.div>
  );
};

export default SliderElement;
