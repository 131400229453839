import { AxiosError } from "axios";

import { SessionApi } from "@api";
import { setApiPath } from "@helpers";
import { IMarketing, IText } from "@types";

export default class DataApi {
  static query = setApiPath("/api/v1/data");

  static async getLabels(language: string): Promise<IText> {
    return new Promise<IText>((resolve, reject) => {
      SessionApi.api
        .get(this.query(`/getlables/?language=${language}`))
        .then((response) => resolve(response.data as IText))
        .catch((error: AxiosError) => reject(error.response?.data));
    });
  }

  static async getProducts(): Promise<IMarketing[]> {
    return new Promise<IMarketing[]>((resolve, reject) => {
      SessionApi.api
        .get<IMarketing[]>(this.query("/getProducts"))
        .then((response) => resolve(response.data))
        .catch((error: AxiosError) => reject(error.response?.data));
    });
  }
}
