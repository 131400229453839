import React from "react";
import { useNavigate } from "react-router-dom";

import { useError, useEvent, useLanguage } from "@context";

import { ArrowLeftEventIcon } from "@assets/icons/event";

import styles from "./Footer.module.scss";

interface FooterProps {
  selectedItem: number;
  totalCost: number;
  setIsOpen: (isOpen: boolean) => void;
}

const Footer: React.FC<FooterProps> = ({
  selectedItem,
  totalCost,
  setIsOpen,
}) => {
  const { text } = useLanguage();
  const navigate = useNavigate();
  const { rawProducts, saveToMemory } = useEvent();
  const { setError, clearError } = useError();

  const handleSubmit = (): void => {
    if (rawProducts.length >= 5) {
      saveToMemory();
      clearError();
      navigate("/app/event-details");
    } else {
      setError("Must have at least five marketing positions");
    }
  };

  return (
    <div className={styles.footer}>
      <div className={styles.arrowLeft} onClick={() => setIsOpen(false)}>
        <ArrowLeftEventIcon />
        <span>{text[44]}</span>
      </div>
      <div className={styles.total}>
        <span>{text[142]?.toLowerCase()}: </span>
        <span>{selectedItem}</span>
      </div>
      <div className={styles.total}>
        <span>{text[68]?.toLowerCase()}: $</span>
        <span>{totalCost}</span>
      </div>
      <button
        type="button"
        onClick={handleSubmit}
        disabled={rawProducts.length < 5}
      >
        {text[39]}
      </button>
    </div>
  );
};

export default Footer;
