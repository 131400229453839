import React, { FC, PropsWithChildren } from "react";
import { GrClose } from "react-icons/gr";
import { Link } from "react-router-dom";

import styles from "./LoginLayout.module.scss";

const LoginLayout: FC<PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <div className={styles.container}>
      <div className={styles.background} />
      <div className={styles.form}>
        <Link to="/" className={styles.return}>
          <GrClose />
        </Link>

        <h4 className={styles.title}>Monetiseur</h4>
        {children}
      </div>
    </div>
  );
};

export default LoginLayout;
