import React from "react";

import { MediaInput } from "@shared";

import { useProfile } from "@pages/ProfilePage/context";

import { media } from "../../ProfilePage.data";

import styles from "./ProfileSocialMedias.module.scss";

const ProfileSocialMedias: React.FC = () => {
  const { handleSocialMediaChange, currentSocialMedias } = useProfile();

  const socialMedias = media.map(({ label, type, icon }) => {
    const socialMedia = currentSocialMedias.get(type);
    return (
      <MediaInput
        key={type}
        label={label}
        icon={icon}
        name={`${type}`}
        value={socialMedia?.link || ""}
        onChange={handleSocialMediaChange}
      />
    );
  });

  return (
    <div className={styles.social}>
      <h4 className={styles.title}>Media statistics</h4>
      <h6 className={styles.description}>
        Add at least 1 media channel of your organisation
      </h6>
      <div className={styles.wrapper}>{socialMedias}</div>
    </div>
  );
};

export default ProfileSocialMedias;
