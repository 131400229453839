import React, { useCallback, useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import { useAuth } from "@context";
import { RoutePaths, getStyleByValidation } from "@helpers";
import { useModal } from "@hooks";

import { Unlocked } from "@assets/icons/infoModal";
import InfoModal from "@components/InfoModal";
import InputField from "@components/LoginForm/components/InputField";
import { yupResolver } from "@hookform/resolvers/yup";
import { isEmpty } from "@lib/lodash";
import Spinner from "@shared/Spinner";

import styles from "./LoginForm.module.scss";

interface IMailRecovery {
  token: string | null;
}

interface IFields {
  Password: string;
  RepeatedPassword: string;
}

const schema = yup
  .object({
    Password: yup
      .string()
      .required("Password field is empty")
      .min(8, "The minimum password length must be more than 8 characters"),
    RepeatedPassword: yup
      .string()
      .required("Password field is empty")
      .min(8, "The minimum password length must be more than 8 characters")
      .oneOf([yup.ref("Password"), null], "Passwords must match"),
  })
  .required();

const PasswordRecovery: React.FC<IMailRecovery> = ({ token }) => {
  const { setPassword, error, loading } = useAuth();
  const { onOpen, onClose, Modal } = useModal(InfoModal, {});
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isValid },
  } = useForm<IFields>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<IFields> = useCallback((data) => {
    if (token) {
      setPassword(data.Password, token, () => {
        onOpen();
        setTimeout(() => {
          onClose();
          navigate(RoutePaths.LOGIN, { replace: true });
        }, 3000);
      });
    } else {
      setError("RepeatedPassword", {
        type: "manual",
        message: "Broken token, check for correctness of the link",
      });
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(error)) {
      switch (error?.status) {
        case 500:
          setError("RepeatedPassword", {
            type: "manual",
            message: "Broken token, check for correctness of the link",
          });
          setTimeout(() => {
            navigate(RoutePaths.LOGIN, { replace: true });
          }, 3000);
          break;
        default:
          setError("RepeatedPassword", {
            type: "manual",
            message: "Sorry, an error occurred, please try again.",
          });
      }
    }
  }, [error]);

  if (loading)
    return <Spinner title="Please wait, your data is being processed." />;

  return (
    // eslint-disable-next-line
    <form className={styles.content} onSubmit={handleSubmit(onSubmit)}>
      <InputField
        label="New password"
        type="password"
        placeholder="Enter your password"
        {...register("Password")}
        required
        style={getStyleByValidation(errors.Password, isValid)}
      />
      {errors.Password?.message && (
        <div className={styles.error}>{errors.Password?.message}</div>
      )}
      <InputField
        label="Repeat new password"
        type="password"
        placeholder="Enter your password"
        {...register("RepeatedPassword")}
        required
        style={getStyleByValidation(errors.RepeatedPassword, isValid)}
      />
      {errors.RepeatedPassword?.message && (
        <div className={styles.error}>{errors.RepeatedPassword?.message}</div>
      )}
      <button className={styles.button__sign_in} type="submit">
        Update password
      </button>
      <Modal
        text="Your password has been successfully changed"
        btnText="Cancel"
        Icon={<Unlocked />}
      />
    </form>
  );
};

export default React.memo(PasswordRecovery);
