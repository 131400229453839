export const SportKinds: string[] = [
  "Judo",
  "Running",
  "MMA",
  "Fencing",
  "Esports",
  "Outdoor sports",
  "Cycling",
  "Football",
  "Chess & checkers",
  "Snooker",
  "Water fun",
  "Autosport & Motosport",
  "Skating sports",
];

export const EventTypes: string[] = [
  "Show-match",
  "Festival",
  "Conference",
  "Masterclass",
  "Competition",
  "League",
];
