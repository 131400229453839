import { AxiosError } from "axios";

import { SessionApi } from "@api";
import { setApiPath } from "@helpers";
import { userMappers } from "@mappers";
import {
  APIUser,
  IEventUser,
  IOrganization,
  ISocialMedia,
  IUser,
} from "@types";

export default class ProfileApi {
  static query = setApiPath("/api/v1/Profile");

  static async getUser(): Promise<IUser> {
    return new Promise<IUser>((resolve, reject) => {
      SessionApi.api
        .get<APIUser>(this.query("/"))
        .then((response) => {
          resolve(userMappers.userMappingFromAPI(response.data));
        })
        .catch((error: AxiosError) => reject(error.response?.data));
    });
  }

  static async setProfile(user: Partial<IUser>): Promise<void> {
    if (!user?.UserID) return;

    await SessionApi.api.put<APIUser>(
      this.query(`/${user.UserID}`),
      userMappers.userMappingToAPI(user),
    );
  }

  static async addOrganization(organization: IOrganization): Promise<void> {
    await SessionApi.api.post(this.query("/addorganisation"), organization);
  }

  static async addSocialMedia(socialMedia: ISocialMedia): Promise<void> {
    await SessionApi.api.post(this.query("/addsocialmedia"), socialMedia);
  }

  static async addEvent({ id, ...event }: IEventUser): Promise<void> {
    await SessionApi.api.post(this.query("/addevent"), event);
  }
}
