import React, { CSSProperties, useEffect, useMemo, useState } from "react";
import { HiArrowNarrowLeft, HiArrowNarrowRight } from "react-icons/hi";

import { createArray } from "@helpers";

import { MarketingCardProps } from "@pages/ToolboxFirstPage/ToolboxFirstSlider.data";

import ToolboxFirstCard from "./ToolboxCard/ToolboxFirstCard";
import styles from "./ToolboxFirstSlider.module.scss";

interface ToolboxFirstSliderProps {
  title: string;
  style?: CSSProperties;
  data: MarketingCardProps[];
}

const ToolboxFirstSlider: React.FC<ToolboxFirstSliderProps> = ({
  title,
  style,
  data,
}) => {
  const isMobile = window.screen.width < 500;
  const visibleItems = isMobile ? 1 : 3;
  const [shiftIndex, setShiftIndex] = useState<number>(0);
  const [totalPagination2, setTotalPagination2] = useState<number[]>([
    shiftIndex - 1,
    shiftIndex,
    shiftIndex + 1,
  ]);
  const [slides, setSlides] = useState<MarketingCardProps[]>(
    data.slice(0, visibleItems),
  );

  useEffect(() => {
    setSlides(
      data.slice(
        shiftIndex * visibleItems,
        shiftIndex * visibleItems + visibleItems,
      ),
    );
  }, [shiftIndex, setShiftIndex, visibleItems]);

  const onNext = (): void => {
    if (shiftIndex * visibleItems < data.length - visibleItems)
      setShiftIndex((prev) => prev + 1);
  };

  const onPrev = (): void => {
    if (shiftIndex > 0) setShiftIndex((prev) => prev - 1);
  };

  const totalPagination1 = useMemo(
    () => createArray(Math.ceil(data.length / visibleItems)),
    [visibleItems, data],
  );

  useEffect(() => {
    setTotalPagination2([shiftIndex - 1, shiftIndex, shiftIndex + 1]);
  }, [shiftIndex]);

  return (
    <div className={styles.content} style={style}>
      <h3 className={styles.title}>{title}</h3>
      <div className={styles.card_block}>
        {slides.map((item, index) => (
          <ToolboxFirstCard
            key={item.position}
            title={item.title}
            subtitle={item.subtitle}
            image={item.image}
            description={item.description}
            index={index}
            type={item.type}
          />
        ))}
      </div>
      {totalPagination1.length > 1 && (
        <div className={styles.pagination}>
          <button
            onClick={onPrev}
            type="button"
            className={styles.arrow_prev}
            style={shiftIndex === 0 ? { color: "rgba(51, 51, 51, 0.7)" } : {}}
            disabled={shiftIndex === 0}
          >
            <HiArrowNarrowLeft />
            <span>Previous</span>
          </button>
          <div className={styles.number_pagin}>
            {totalPagination1.map((item, index) => (
              <button
                key={Math.random()}
                type="button"
                className={styles.pagination_button}
                onClick={() => setShiftIndex(index)}
                style={
                  index === shiftIndex
                    ? { borderTop: "1px solid #51C9C2", color: "#51C9C2" }
                    : {}
                }
              >
                {index + 1}
              </button>
            ))}
          </div>
          <div className={styles.mobile_number_pagin}>
            {totalPagination2.map((item) => {
              return item >= 0 && item < data.length ? (
                <button
                  key={Math.random()}
                  type="button"
                  className={styles.pagination_button}
                  onClick={() => setShiftIndex(item)}
                  style={
                    item === shiftIndex
                      ? { borderTop: "1px solid #51C9C2", color: "#51C9C2" }
                      : {}
                  }
                >
                  {item + 1}
                </button>
              ) : null;
            })}
          </div>
          <button
            type="button"
            onClick={onNext}
            className={styles.arrow_next}
            disabled={shiftIndex * visibleItems >= data.length - visibleItems}
            style={
              shiftIndex * visibleItems >= data.length - visibleItems
                ? { color: "rgba(51, 51, 51, 0.7)" }
                : {}
            }
          >
            <span>Next</span>
            <HiArrowNarrowRight />
          </button>
        </div>
      )}
    </div>
  );
};

export default ToolboxFirstSlider;
