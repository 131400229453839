import React, { useEffect } from "react";
import { useOutletContext } from "react-router-dom";

import { ErrorField } from "@components";
import { IStateHook } from "@types";

import ProfileWrapper from "@components/ProfileWrapper";
import SidebarAccount from "@components/Sidebar/SidebarAccount";
import { ProfileProvider, useProfile } from "@pages/ProfilePage/context";

import styles from "./ProfilePage.module.scss";
import { ProfileSchema, SchemaType } from "./ProfilePage.schema";
import { PageHeader, ProfileSidebar } from "./components";

const getComponentWrap = (
  schema: SchemaType,
  refs: React.RefObject<HTMLDivElement>[],
): React.ReactElement => {
  const isDesktop = window.screen.width > 1050;

  if (isDesktop) {
    return (
      <React.Fragment key={schema.name}>
        <div ref={refs[schema.key]} />
        {schema.component}
      </React.Fragment>
    );
  }

  return (
    <ProfileWrapper key={schema.name} title={schema.name} Icon={schema.icon}>
      {schema.component}
    </ProfileWrapper>
  );
};

const Profile: React.FC = () => {
  const setRefs = useOutletContext<IStateHook<number[]>>();
  const refs = new Array(ProfileSchema.length)
    .fill(0)
    .map(() => React.createRef<HTMLDivElement>());

  const { errorText, setErrorText } = useProfile();

  useEffect(() => {
    setRefs(refs.map((ref) => ref.current?.offsetTop || 0));
    return () => {
      setRefs([]);
    };
  });

  const handleSidebarClick = (key: number) => {
    return () => {
      refs[key].current?.scrollIntoView({ behavior: "smooth" });
    };
  };

  return (
    <>
      <ErrorField
        title={errorText}
        isError={!!errorText}
        onCleaning={() => setErrorText("")}
      />

      <div className={styles.container}>
        <PageHeader />

        <div className={styles.content}>
          <div className={styles.title}>Profile information</div>

          <div className={styles.account_wrapper}>
            <SidebarAccount />
          </div>

          <ProfileSidebar onClick={handleSidebarClick} />

          <div className={styles.wrapper}>
            {ProfileSchema.map((schema: SchemaType) =>
              getComponentWrap(schema, refs),
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const ProfilePage: React.FC = () => (
  <ProfileProvider>
    <Profile />
  </ProfileProvider>
);

export default ProfilePage;
