import classnames from "classnames";
import React, { useEffect, useRef } from "react";

import { useLanguage } from "@context";
import { useSingleImage } from "@hooks";
import { IUseImageSingleOutput } from "@types";

import { DeleteIcon, EditIcon, PhotoIcon } from "@assets/icons/kit";
import { isEmpty } from "@lib/lodash";

import styles from "./UploadImage.module.scss";

interface UploadImageProps {
  style?: React.CSSProperties;
  updateImages?: (logo: string, background: string) => void;
  defaultLogo?: string;
  defaultBackground?: string;
}

interface IImageContainer {
  imageData: IUseImageSingleOutput;
}

const ImageContainer: React.FC<IImageContainer> = ({ imageData }) => {
  const { image, onClick, onDelete } = imageData;
  return (
    <div className={styles.container}>
      <img src={image} alt="background" />
      <button type="button" className={styles.button__image} onClick={onClick}>
        <EditIcon />
      </button>
      <button
        type="button"
        className={classnames(
          styles.button__image,
          styles.button__image_bottom,
        )}
        onClick={onDelete}
      >
        <DeleteIcon />
      </button>
    </div>
  );
};

const UploadImage: React.FC<UploadImageProps> = ({
  style,
  updateImages,
  defaultLogo,
  defaultBackground,
}) => {
  const { text } = useLanguage();

  const logoRef = useRef<HTMLInputElement>(null);
  const backgroundRef = useRef<HTMLInputElement>(null);

  const logo = useSingleImage(logoRef, defaultLogo);
  const background = useSingleImage(backgroundRef, defaultBackground);

  useEffect(() => {
    if (updateImages) updateImages(logo.image, background.image);
  }, [logo.image, background.image]);

  return (
    <div className={styles.image} style={style}>
      {!isEmpty(background.image) && <ImageContainer imageData={background} />}
      <input
        type="file"
        ref={backgroundRef}
        className={styles.file}
        onChange={background.onChange}
      />
      {isEmpty(background.image) && (
        <button
          type="button"
          className={styles.upload}
          onClick={background.onClick}
        >
          <PhotoIcon />
          {text[145]}
        </button>
      )}

      <div
        className={classnames(styles.logo, {
          [styles.logo__set]: !isEmpty(logo.image),
        })}
      >
        {isEmpty(logo.image) ? text[153] : <img src={logo.image} alt="logo" />}
        <div>
          <input
            type="file"
            ref={logoRef}
            className={styles.file}
            onChange={logo.onChange}
          />
          <button
            type="button"
            className={styles.upload_logo}
            onClick={logo.onClick}
          >
            {isEmpty(logo.image) ? <PhotoIcon /> : <EditIcon />}
          </button>
        </div>
      </div>
    </div>
  );
};

export default UploadImage;
