import React from "react";
import DatePicker from "react-datepicker";

import { CalendarIcon } from "@assets/icons/eventPosition";

import styles from "./DatePickerBasic.module.scss";

interface IDatePickerBasic {
  value: Date | null;
  onChange: (date: Date | null) => void;
  placeholder?: string;
}

const DatePickerBasic: React.FC<IDatePickerBasic> = ({
  value,
  onChange,
  placeholder,
}) => {
  return (
    <div className={styles.label}>
      <DatePicker
        selected={value}
        onChange={(date) => onChange(date)}
        placeholderText={placeholder || ""}
        className={styles.dta}
      />
      <CalendarIcon className={styles.icon} />
    </div>
  );
};

export default DatePickerBasic;
