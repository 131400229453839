import { RefObject } from "react";

import { IUser } from "@types";

import { isEmpty } from "@lib/lodash";

/**
 * Make capital char.
 * @param {string} str - String for change char
 * @return {string} The string with capital char
 * @example
 * // return "Hello"
 * capitalChar("hello");
 */
export const capitalChar = (str: string | null | undefined): string => {
  if (!str) return "";
  const newStr = str.toLowerCase();
  return newStr.toLowerCase().charAt(0).toUpperCase() + newStr.slice(1);
};

/**
 * Create Array with numbers by length.
 * @param {number} length - Length of array
 * @return {number[]} Array with numbers
 * @example
 * // return [0, 1, 2]
 * createArray(3);
 */
export const createArray = (length: number): number[] => {
  return new Array(length).fill(0).map((val, i) => +val + i);
};

/**
 * Parse phone number to code of region and number.
 * @param {string} phone - Length of array
 * @return {[string, string]} Array wit alias and number
 * @example
 * // return ["+7", "1234567"]
 * getPhone("+71234567");
 */
export const getPhone = (phone: string): [string, string] => {
  if (!phone) return ["", ""];

  const keys = ["+7", "+372"];
  const code = keys.filter((key) => phone.startsWith(key));

  if (!isEmpty(code)) return [code[0], phone.split(code[0])[1]];
  return ["", ""];
};

/**
 * Set API path for services
 * @param {string} apiPath - absolute path
 * @return {(query: string) => string} Function to concat api url services
 */
export const setApiPath = (apiPath: string): ((query: string) => string) => {
  return (query: string): string => {
    const aliases = [...apiPath.split("/"), ...query.split("/")];
    const sorted = aliases.filter((alias) => !!alias);
    return String.prototype.concat("/", sorted.join("/"));
  };
};

export const updateItemInArray = <T>(
  array: T[],
  element: T,
  comparator: (a: T, b: T) => boolean,
): T[] => {
  const startIndex = array.findIndex((value) => comparator(value, element));
  if (startIndex === -1) return array;
  array.splice(startIndex, 1, element);
  return array;
};

export const setProgress = (user: IUser): number => {
  let count = 0;
  const arrayUser = Object.values(user);
  arrayUser.forEach((element) => {
    if (element !== null && element !== "") count += 1;
  });

  return Math.round((count / arrayUser.length) * 100);
};

/**
 * Get name of month by the number
 * @param {string} month - month number
 * @return {[string, string]} Array with two formats: [name, shortName]
 */
const monthMap = (month: string): [string, string] => {
  switch (month) {
    case "01":
      return ["January", "Jan"];
    case "02":
      return ["February", "Feb"];
    case "03":
      return ["March", "Mar"];
    case "04":
      return ["April", "Apr"];
    case "05":
      return ["May", "May"];
    case "06":
      return ["June", "June"];
    case "07":
      return ["July", "July"];
    case "08":
      return ["August", "Aug"];
    case "09":
      return ["September", "Sept"];
    case "10":
      return ["October", "Oct"];
    case "11":
      return ["November", "Nov"];
    case "12":
      return ["December", "Dec"];
    default:
      return ["January", "Jan"];
  }
};

type ModeType = "full" | "short";

interface IDateParams {
  day: number;
  month: string;
  year: number;
}

export interface IDateDict {
  begin: IDateParams;
  end: IDateParams;
}

/* Transform date to readable format
 *
 * Patterns
 * 1. (1 - 7 December 2021)
 * 2. (1 Jan - 7 Feb 2021)
 * 3. (1 Dec 2021 - 7 Jan 2022)
 * 4. (1 December 2021)
 *
 * @param {string} start - date of beginning
 * @param {string} end - date of ending
 * @param {ModeType} mode - mode of returning value
 * @return {string} Readable date for user flow
 */
export const transformDate = (
  start?: string,
  end?: string,
  mode: ModeType = "short",
): string | IDateDict => {
  if (!(start && end)) {
    return "";
  }

  // Separate date and time
  const [begin] = start.split("T");
  const [finish] = end.split("T");

  const [startYear, startMonth, startDay] = begin.split("-");
  const [endYear, endMonth, endDay] = finish.split("-");

  // Days
  const sd = startDay.startsWith("0") ? startDay.replace("0", "") : startDay;
  const ed = endDay.startsWith("0") ? endDay.replace("0", "") : endDay;

  // Months
  const sm = monthMap(startMonth);
  const em = monthMap(endMonth);

  // Flags
  const isSameDay = sd === ed;
  const isSameMonth = sm[0] === em[0];
  const isSameYear = startYear === endYear;

  if (mode === "short") {
    // Pattern #1
    if (!isSameDay && isSameMonth && isSameYear) {
      return `${sd} - ${ed} ${sm[0]} ${startYear}`;
    }

    // Pattern #2
    if (!isSameMonth && isSameYear) {
      return `${sd} ${sm[1]} - ${ed} ${em[1]} ${startYear}`;
    }

    // Pattern #3
    if (!isSameMonth && !isSameYear) {
      return `${sd} ${sm[1]} ${startYear} - ${ed} ${em[1]} ${endYear}`;
    }

    // Pattern #4
    if (isSameDay && isSameMonth && isSameYear) {
      return `${sd} ${sm[0]} ${startYear}`;
    }
  }

  if (mode === "full") {
    return {
      begin: {
        day: Number(sd),
        month: sm[0],
        year: Number(startYear),
      },
      end: {
        day: Number(ed),
        month: em[0],
        year: Number(endYear),
      },
    };
  }

  return "";
};
export const eventValidate = <T extends HTMLElement>(
  vName: boolean,
  setIsError: (flag: boolean) => void,
  validName: RefObject<T>,
): void => {
  if (vName) {
    setIsError(true);
    validName?.current?.setAttribute("style", "border: 1px solid #e53d3e");
  } else {
    validName?.current?.setAttribute("style", "");
  }
};

// ** startDate, endDate must be string, format: "year-month-day", example "2003-01-25" **

export const comparingDates = (startDate: string, endDate: string): boolean => {
  const start = startDate.split("-");
  const date1 =
    start.length === 3
      ? new Date(Number(start[0]), Number(start[1]) - 1, Number(start[2]))
      : null;
  const end = endDate.split("-");
  const date2 =
    end.length === 3
      ? new Date(Number(end[0]), Number(end[1]) - 1, Number(end[2]))
      : null;
  const nowDate = new Date();
  const relevance = date1 && date2 ? nowDate < date1 && nowDate < date2 : false;
  return date1 && date2 && relevance ? date2 >= date1 : false;
};

export const hasObjKey = <T>(obj: T, key: PropertyKey): key is keyof T => {
  return key in obj;
};
