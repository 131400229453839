import React, { useState } from "react";

import { isImageFile } from "@api";
import { fileToUrl } from "@helpers";
import { ChangeEventType, IUseImageOutput } from "@types";

export const useImage = (
  ref: React.RefObject<HTMLInputElement>,
  defaultImages?: string[],
): IUseImageOutput => {
  const [images, setImages] = useState<string[]>(defaultImages || []);
  const [error, setError] = useState<string | ArrayBuffer>("");

  const onClick = (): void => {
    ref?.current?.click();
  };

  const onDelete = (image: string): void => {
    setImages((prevState) => prevState.filter((value) => value !== image));
  };

  const onChange: ChangeEventType<HTMLInputElement> = (event) => {
    if (event?.currentTarget?.files) {
      const { files } = event.currentTarget;
      Array.from(files).forEach((file) => {
        if (isImageFile(file.type)) {
          fileToUrl(file)
            .then((response) =>
              setImages((prevState) => [...prevState, response]),
            )
            .catch((_error: string | ArrayBuffer) => setError(() => _error));
        }
      });
    }
  };

  return { images, setImages, error, onDelete, onClick, onChange };
};
