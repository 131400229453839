import React, { FC, useRef } from "react";

import { useAuth } from "@context";
import { capitalChar } from "@helpers";

import { AvatarDefault, EditIcon, PhotoIcon } from "@assets/icons/kit";

import styles from "./ProfileAccount.module.scss";

export interface ProfileAccountProps {
  logo: string | null;
  firstname: string;
  secondname: string;
  mail: string;
  registration: string;
}

const ProfileAccount: FC<ProfileAccountProps> = ({
  logo,
  firstname,
  secondname,
  mail,
  registration,
}) => {
  const ref = useRef<HTMLInputElement>(null);
  const { setUserLogo } = useAuth();
  const { onClick, onChange } = setUserLogo(ref);

  return (
    <div className={styles.account}>
      <div className={styles.icon}>
        <img
          src={logo || AvatarDefault}
          alt={firstname}
          className={styles.logo}
        />
        <div className={styles.change_icon}>
          <input ref={ref} type="file" onChange={onChange} />
          <span className={styles.button} onClick={onClick}>
            {logo ? <EditIcon /> : <PhotoIcon />}
          </span>
        </div>
      </div>
      <span className={styles.name}>
        {capitalChar(firstname)} {capitalChar(secondname)}
      </span>
      <span className={styles.mail}>{mail}</span>
      <span className={styles.registration}>Registration {registration}</span>
    </div>
  );
};

export default ProfileAccount;
