import React from "react";
import { Link } from "react-router-dom";

import { RoutePaths } from "@helpers";

import Champs from "./Champs";
import styles from "./Header.module.scss";

const Header: React.FC = () => {
  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.data}>
            <h1 className={styles.title}>
              Sports marketing{" "}
              <span className={styles.title__span}>marketplace</span>
            </h1>
            <p className={styles.description}>
              We connect brands with athletes and events to create digital
              marketing campaigns effortlessly.
            </p>
            <Link className={styles.btn} to={RoutePaths.DEFAULT}>
              Get started
            </Link>
          </div>
          <Champs />
        </div>
      </div>
    </section>
  );
};

export default Header;
