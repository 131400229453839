import { motion } from "framer-motion";
import React from "react";

import { useLanguage } from "@context";
import { IIcon } from "@types";

import styles from "./SliderElementSecond.module.scss";

interface SliderElementInterface {
  id: number;
  image: string;
  date: string;
  country: string;
  price: number;
  title: string;
  ImageCountry: React.FC<IIcon>;
  visibleItems: number;
}

const SliderElementSecond: React.FC<SliderElementInterface> = ({
  id,
  date,
  title,
  price,
  image,
  country,
  ImageCountry,
  visibleItems,
}) => {
  const { text } = useLanguage();
  const dynamicDelay =
    id % visibleItems ? (id % visibleItems) / 10 : visibleItems / 10;
  const transition = { delay: dynamicDelay, duration: 0.5, ease: "easeInOut" };

  return (
    <motion.div
      className={styles.card}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={transition}
    >
      <img src={image} alt="sport events" />
      <div className={styles.text}>
        <div className={styles.country}>
          <ImageCountry />
          <span>{country}</span>
        </div>
        <div className={styles.title}>{title}</div>
        <hr />
        <div className={styles.footer}>
          <span className={styles.date}>{date}</span>
          <div className={styles.price}>
            <span>$ {price}</span>
            <span>/{text[255]}</span>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default SliderElementSecond;
