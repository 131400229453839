import React, { useCallback, useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import { useAuth } from "@context";
import { RoutePaths, getStyleByValidation } from "@helpers";
import { useModal } from "@hooks";

import { Locked } from "@assets/icons/infoModal";
import InfoModal from "@components/InfoModal";
import { yupResolver } from "@hookform/resolvers/yup";
import { isEmpty } from "@lib/lodash";
import Spinner from "@shared/Spinner";

import styles from "./LoginForm.module.scss";
import InputField from "./components/InputField";

interface IFields {
  Email: string;
}

const schema = yup
  .object({
    Email: yup
      .string()
      .email("Incorrect email address")
      .required("Email field is empty"),
  })
  .required(
    "Fields are not filled or entered incorrectly. Correct or re-enter please.",
  );

const MailRecovery: React.FC = () => {
  const { resetPassword, error, loading } = useAuth();
  const { onOpen, onClose, Modal } = useModal(InfoModal, {});
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isValid },
  } = useForm<IFields>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate();

  const onBackClick = useCallback(() => {
    navigate(RoutePaths.LOGIN);
  }, []);

  const onSubmit: SubmitHandler<IFields> = useCallback((data) => {
    resetPassword(data.Email, () => {
      onOpen();
      setTimeout(() => {
        onClose();
        navigate(RoutePaths.LOGIN, { replace: true });
      }, 2000);
    });
  }, []);

  useEffect(() => {
    if (!isEmpty(error)) {
      switch (error?.status) {
        case 500:
          setError("Email", {
            type: "manual",
            message: "Invalid Email",
          });
          break;
        default:
          setError("Email", {
            type: "manual",
            message: "Sorry, an error occurred, please try again.",
          });
      }
    }
  }, [error]);

  if (loading)
    return <Spinner title="Please wait, your data is being processed." />;

  return (
    // eslint-disable-next-line
    <form className={styles.content} onSubmit={handleSubmit(onSubmit)}>
      <p>
        Enter the email address you used when you joined and we’ll send you
        instructions to reset your password.
      </p>
      <InputField
        label="Email"
        placeholder="Enter your email address"
        {...register("Email")}
        required
        style={getStyleByValidation(errors.Email, isValid)}
      />
      {errors.Email?.message && (
        <div className={styles.error}>{errors.Email?.message}</div>
      )}
      <button className={styles.button__sign_in} type="submit">
        Send reset instructions
      </button>
      <button type="button" className={styles.back} onClick={onBackClick}>
        <span>←</span>
        <span>Back</span>
      </button>
      <Modal
        text="A link to change your password has been sent to your email"
        btnText="Cancel"
        Icon={<Locked />}
      />
    </form>
  );
};

export default React.memo(MailRecovery);
