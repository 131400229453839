import React from "react";
import { NavLink } from "react-router-dom";

import styles from "./WhitePage.module.scss";

const WhitePage: React.FC = () => {
  return (
    <div className={styles.text}>
      <span>Page under construction - come back later</span>
      <NavLink to="/app/profile">⟵ return to Profile</NavLink>
    </div>
  );
};

export default WhitePage;
