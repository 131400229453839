import classnames from "classnames";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { mainMenu } from "@assets";
import { useAuth, useLanguage } from "@context";
import { setProgress } from "@helpers";

import {
  AvatarDefault,
  ChevronLeftIcon,
  NotificationIcon,
  NotificationTrueIcon,
} from "@assets/icons/kit";

import HeaderAccount from "../HeaderAccount";

import styles from "./HeaderSidebar.module.scss";
import Notification from "./Notification";

interface IHeaderSidebar {
  onSidebar: () => void;
  isCollapse: boolean;
  onCollapse: () => void;
}

const HeaderSidebar: React.FC<IHeaderSidebar> = ({
  onSidebar,
  onCollapse,
  isCollapse,
}) => {
  const hasNotifications = true;
  const [viewNotifications, setViewNotifications] = useState(false);
  const navigate = useNavigate();
  const { text } = useLanguage();
  const { user } = useAuth();
  const progress = setProgress(user);

  return (
    <div className={styles.container}>
      <img
        className={styles.burger}
        src={mainMenu}
        alt="burger mobil icon"
        onClick={onSidebar}
      />
      <div className={styles.logo}>
        {!isCollapse && (
          <h1
            className={styles.title}
            onClick={() => navigate("/app/profile", { replace: true })}
          >
            Monetiseur
          </h1>
        )}
        <button
          type="button"
          className={classnames(styles.arrows, { [styles.open]: isCollapse })}
          onClick={onCollapse}
        >
          <ChevronLeftIcon />
        </button>
      </div>
      <div className={styles.right_header}>
        <div className={styles.wrapper}>
          <div className={styles.content}>
            <div className={styles.links}>
              <Link className={styles.link} to="/marketplace">
                {text[126]}
              </Link>
              <Link className={styles.link} to="/app/policy">
                {text[135]}
              </Link>
              <Link className={styles.link} to="/app/contacts">
                {text[143]}
              </Link>
            </div>
            <button
              type="button"
              className={styles.notification}
              onClick={() => setViewNotifications((prev) => !prev)}
            >
              {!hasNotifications ? (
                <NotificationIcon />
              ) : (
                <NotificationTrueIcon />
              )}
            </button>
            {viewNotifications && <Notification />}
          </div>
          <div className={styles.account}>
            <HeaderAccount
              icon={user?.IconUrl || AvatarDefault}
              firstname={user?.FirstName}
              secondname={user?.SecondName}
              progress={progress}
              isProgress
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderSidebar;
