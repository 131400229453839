import { useState } from "react";

import { isImageFile } from "@api";
import { ChangeEventType, UseSingleImageHook } from "@types";

import { fileToUrl } from "@helpers/files";

export const useSingleImage: UseSingleImageHook = (ref, defaultImage) => {
  const [image, setImage] = useState<string>(defaultImage || "");
  const [error, setError] = useState<string | ArrayBuffer>("");

  const onClick = (): void => {
    ref?.current?.click();
  };

  const onDelete = (): void => {
    setImage(() => "");
  };

  const onChange: ChangeEventType<HTMLInputElement> = (event) => {
    if (event?.currentTarget?.files) {
      const file = event.currentTarget.files[0];
      if (isImageFile(file.type)) {
        fileToUrl(file)
          .then((response) => setImage(() => response))
          .catch((_error: string | ArrayBuffer) => setError(() => _error));
      }
    }
  };

  return { image, error, onDelete, onClick, onChange };
};
