// import { expression } from "./LoginValidation";

export const validateEventName = (fullname: string): boolean => {
  return Boolean(fullname.length > 1);
};

export const validateEventNumber = (fullname: number): boolean => {
  // const pattern = /(?<![-.])\b[0-9]+\b(?!\.[0-9])/gim;
  // const rule = expression(String(fullname), pattern);
  // TODO: Check if this validation was working before
  return !!fullname;
};

export const validateEventText = (fullname: string): boolean => {
  const text = fullname.split(" ").length;
  return text > 100;
};
