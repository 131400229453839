import React from "react";

import { AddIcon } from "@assets/icons/kit";
import { useProfile } from "@pages/ProfilePage/context";

import styles from "./PortfolioButton.module.scss";

export interface PortfolioButtonProps {
  setOpenEvent: (val: string) => void;
}

const PortfolioButton: React.FC<PortfolioButtonProps> = ({ setOpenEvent }) => {
  const { handleAddNewEvent } = useProfile();

  const createNewEvent = (): void => {
    const newEventId = handleAddNewEvent();
    setOpenEvent(newEventId);
  };

  return (
    <div className={styles.button} onClick={createNewEvent}>
      <AddIcon /> Add a new project
    </div>
  );
};

export default PortfolioButton;
