import React from "react";

import { useLanguage } from "@context";

import { DeleteIcon, EditIcon, PhotoIcon, UploadIcon } from "@assets/icons/kit";

import styles from "./UploadLogoFile.module.scss";

interface UploadLogoFileProps {
  isLogo: boolean;
  style?: React.CSSProperties | undefined;
  logoRef?: React.Ref<HTMLInputElement>;
  onClickLogo?: () => void;
  onClickFile: () => void;
  fileRef: React.Ref<HTMLInputElement>;
  onChangeLogo?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeFile: (event: React.ChangeEvent<HTMLInputElement>) => void;
  imagesFile: string[];
  onDeleteFile?: (str: string) => void;
  imagesLogo?: string[];
}

const UploadLogoFile: React.FC<UploadLogoFileProps> = ({
  isLogo,
  style,
  logoRef,
  fileRef,
  onClickLogo,
  onClickFile,
  onChangeLogo,
  onChangeFile,
  onDeleteFile,
  imagesFile,
  imagesLogo,
}) => {
  const { text } = useLanguage();

  const onDelete = (): void => {
    if (onDeleteFile) {
      imagesFile.forEach((el) => onDeleteFile(el));
    }
  };

  return (
    <div className={styles.upload} style={style}>
      <input
        type="file"
        ref={fileRef}
        onChange={onChangeFile}
        className={styles.file}
      />
      {imagesFile?.length ? (
        <>
          <img
            src={imagesFile[imagesFile.length - 1]}
            alt="logo"
            className={styles.pic_img}
          />
          <div className={styles.actions}>
            <button
              className={styles.actionBtn}
              onClick={onClickFile}
              type="button"
            >
              <EditIcon />
            </button>
            <button
              type="button"
              className={styles.actionBtn}
              {...(onDeleteFile && {
                onClick: onDelete,
              })}
            >
              <DeleteIcon />
            </button>
          </div>
        </>
      ) : (
        <button type="button" onClick={onClickFile}>
          <UploadIcon />
          <div>
            <span>{text[165]}</span> {text[197]}
          </div>
        </button>
      )}

      {isLogo && (
        <div className={styles.logo}>
          {text[153]}
          {!!imagesLogo?.length && (
            <img
              src={imagesLogo[imagesLogo.length - 1]}
              alt="logo"
              className={styles.logo_img}
            />
          )}
          <div onClick={onClickLogo}>
            <input
              type="file"
              ref={logoRef}
              onChange={onChangeLogo}
              className={styles.file}
            />
            <button type="button">
              <PhotoIcon />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadLogoFile;
