import React from "react";

import { IIcon } from "@types";

const ArrowLeftIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 11.5L7.28017 16L7.28017 12.4L23 12.4L23 10.6L7.28017 10.6L7.28017 7L2 11.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ArrowLeftIcon;
