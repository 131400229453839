import classnames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { Link, NavLink } from "react-router-dom";

import { useAuth } from "@context";

import { LogoutIcon, RocketIcon } from "@assets/icons/kit";

import { MenuItems } from "../Sidebar/Sidebar.data";

import styles from "./CollapsedSideBar.module.scss";

interface ICollapsedSideBar {
  onSidebar: () => void;
}

const CollapsedSideBar: React.FC<ICollapsedSideBar> = ({ onSidebar }) => {
  const isDesktop = window.screen.width < 1050;
  const motionX = isDesktop ? -250 : 0;
  const motionOpacity = isDesktop ? 0 : 1;
  const { logout } = useAuth();

  return (
    <AnimatePresence>
      <motion.div
        className={styles.sidebar}
        initial={{ x: motionX, opacity: motionOpacity }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: motionX, opacity: motionOpacity }}
        transition={{ duration: 0.85, delayChildren: 0.85, ease: "easeOut" }}
      >
        <div className={styles.sidebar_link}>
          {MenuItems.map((group) => (
            <div className={styles.group} key={group.title}>
              {/* {group.title && <h4 className={styles.title}> {group.title}</h4>} */}

              {group.links.map((link) => (
                <NavLink
                  to={link.path}
                  className={({ isActive }) =>
                    isActive
                      ? classnames(styles.menu_item, styles.active)
                      : styles.menu_item
                  }
                  key={link.name}
                  onClick={onSidebar}
                >
                  <link.icon />
                </NavLink>
              ))}
            </div>
          ))}
        </div>

        <div>
          <Link to="/app" className={styles.upgrade}>
            <div>
              <RocketIcon />
            </div>
          </Link>
          <button type="button" className={styles.logout} onClick={logout}>
            <LogoutIcon />
          </button>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default CollapsedSideBar;
