import React, { FC } from "react";

import { NLoginForm } from "@components/LoginForm";
import { LoginLayout } from "@components/layouts";

const Login: FC = () => {
  return (
    <LoginLayout>
      <NLoginForm />
    </LoginLayout>
  );
};

export default React.memo(Login);
