import React from "react";

import { useEvent } from "@context";
import { useInput } from "@hooks";
import { MediaInput } from "@shared";

import {
  FacebookIcon,
  InstagramIcon,
  MessengerIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from "@assets/icons/kit";

import styles from "./SocialMedia.module.scss";

interface ISocialMedia {
  disabled?: boolean;
}

const SocialMedia: React.FC<ISocialMedia> = ({ disabled }) => {
  const { socials } = useEvent();
  const facebook = useInput(socials?.facebook || "");
  const instagram = useInput(socials?.instagram || "");
  const telegram = useInput(socials?.telegram || "");
  const messenger = useInput(socials?.messenger || "");
  const twitter = useInput(socials?.twitter || "");
  const whatsapp = useInput(socials?.whatsapp || "");

  return (
    <div className={styles.content}>
      <h2 className={styles.title}>Social media events</h2>
      <div className={styles.wrapper_medias}>
        <div className={styles.medias__group}>
          <MediaInput
            label="Facebook"
            icon={FacebookIcon}
            placeholder="http://"
            disabled={disabled}
            {...facebook}
          />
          <MediaInput
            label="Instagram"
            icon={InstagramIcon}
            placeholder="http://"
            disabled={disabled}
            {...instagram}
          />
        </div>
        <div className={styles.medias__group}>
          <MediaInput
            label="Telegram"
            icon={TelegramIcon}
            placeholder="http://"
            disabled={disabled}
            {...telegram}
          />
          <MediaInput
            label="Whatsapp"
            icon={WhatsappIcon}
            placeholder="http://"
            disabled={disabled}
            {...whatsapp}
          />
        </div>
        <div className={styles.medias__group}>
          <MediaInput
            label="Twitter"
            icon={TwitterIcon}
            placeholder="http://"
            disabled={disabled}
            {...twitter}
          />
          <MediaInput
            label="Messenger"
            icon={MessengerIcon}
            placeholder="http://"
            disabled={disabled}
            {...messenger}
          />
        </div>
      </div>
    </div>
  );
};

export default SocialMedia;
