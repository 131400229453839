import React from "react";

import { IIcon } from "@types";

const HouseTropicsIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg
      className={className}
      width="57"
      height="38"
      viewBox="0 0 57 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.1399 9.12816L55.0083 14.7949C54.7241 12.465 53.5837 10.2615 51.7184 8.67606H57C55.2461 6.35351 52.4532 4.85119 49.3086 4.85119C49.0294 4.85119 48.7527 4.86307 48.4798 4.8862L55.6493 0.769895C52.9628 -0.369181 49.788 -0.281564 47.064 1.2827C45.7319 2.04733 44.6542 3.08127 43.8592 4.27517C43.0643 3.08127 41.9852 2.04733 40.6532 1.2827C37.9291 -0.281564 34.7557 -0.369181 32.0692 0.769895L39.2374 4.8862C38.9657 4.86307 38.689 4.85119 38.4111 4.85119C35.2652 4.85119 32.4723 6.35351 30.7184 8.67606H35.9987C34.1347 10.2615 32.9943 12.465 32.7102 14.7952L42.576 9.12971V35.45H36.1664V15.7551L29.547 19.5562L30.1651 14.4883C30.3018 13.3673 30.5954 12.2684 31.0372 11.2261H27.0342L0 17.948L0.621813 20.4218L5.40057 19.2335V35.45H0.272913V38H56.6769V35.45H45.1399V9.12816ZM31.0388 22.7006V35.45H23.3473V22.7006H31.0388ZM14.3739 27.8004C15.7899 27.8004 16.9378 26.6589 16.9378 25.2506C16.9378 23.8423 15.7899 22.7006 14.3739 22.7006C12.958 22.7006 11.8102 23.8423 11.8102 25.2506C11.8102 26.6589 12.958 27.8004 14.3739 27.8004Z"
        fill="#333333"
      />
    </svg>
  );
};

export default HouseTropicsIcon;
