import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { EventApi } from "@api";
import { IEvent } from "@types";

import { Plus } from "@assets/icons/eventPosition";
import {
  EventPositionFilter,
  EventPositionStatistics,
} from "@components/EventPositionComponents";
import EventPositionEventsBlock from "@components/EventPositionComponents/EventPositionEventsBlock";

import styles from "./EventPositionPage.module.scss";

const EventPositionPage: React.FC = () => {
  const { eventID } = useParams<"eventID">();
  const [event, setEvent] = useState<IEvent | null>(null);

  useEffect(() => {
    if (!event) {
      EventApi.getEventById(Number(eventID) || 1)
        .then((response) => setEvent(response))
        .catch(null);
    }
  }, []);
  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <h2 className={styles.title}>Event position</h2>
        <button type="button" className={styles.btn}>
          <Plus className={styles.icon} />
          Add new position
        </button>
      </div>
      <EventPositionFilter />
      <EventPositionStatistics />
      <EventPositionEventsBlock />
    </section>
  );
};

export default EventPositionPage;
