import React from "react";
import Slider from "react-slick";

import { champData } from "./Champ.data";
import styles from "./Champs.module.scss";

const Champs: React.FC = () => {
  const { example1, example2, example3 } = champData;
  const settings1 = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    speed: 2000,
    autoplaySpeed: 5000,
    cssEase: "linear",
    pauseOnHover: false,
    swipe: false,
    touchMove: false,
    adaptiveHeight: true,
  };
  const settings2 = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    rtl: true,
    autoplay: true,
    arrows: false,
    speed: 2000,
    autoplaySpeed: 4000,
    cssEase: "linear",
    pauseOnHover: false,
    swipe: false,
    touchMove: false,
    adaptiveHeight: true,
  };
  const settings3 = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    speed: 2000,
    autoplaySpeed: 4500,
    cssEase: "linear",
    pauseOnHover: false,
    swipe: false,
    touchMove: false,
    adaptiveHeight: true,
  };
  return (
    <div className={styles.list}>
      <div className={styles.height_block}>
        <ul className={styles.first}>
          <Slider {...settings1}>
            {example1.map(({ id, img, title, champName, duties }) => (
              <li key={id} className={styles.first__slide}>
                <img src={img} alt={title} className={styles.img1} />
                <p className={styles.first__name}>{champName}</p>
                <p className={styles.first__duties}>{duties}</p>
                <div className={styles.back} />
              </li>
            ))}
          </Slider>
        </ul>
      </div>

      <div className={styles.square_block}>
        <ul className={styles.second}>
          <Slider {...settings2}>
            {example2.map(({ id, img, title, champName, duties }) => (
              <li key={id} className={styles.second__slide}>
                <img src={img} alt={title} className={styles.img2} />
                <p className={styles.second__name}>{champName}</p>
                <p className={styles.second__duties}>{duties}</p>
                <div className={styles.back} />
              </li>
            ))}
          </Slider>
        </ul>

        <ul className={styles.third}>
          <Slider {...settings3}>
            {example3.map(({ id, img, title, champName, duties }) => (
              <li key={id} className={styles.third__slide}>
                <img src={img} alt={title} className={styles.img2} />
                <p className={styles.third__name}>{champName}</p>
                <p className={styles.third__duties}>{duties}</p>
                <div className={styles.back} />
              </li>
            ))}
          </Slider>
        </ul>
      </div>
    </div>
  );
};

export default Champs;
