import React, { CSSProperties, FC, useCallback } from "react";

import { useProducts } from "@hooks";
import { IMarketing } from "@types";

import ToolsCard from "@components/ToolboxSecondComponents/MarketingTools/ToolsCard";
import { ProductType } from "@context/EventCreate/EventContext.types";
import { isEmpty } from "@lib/lodash";

import { IToolsCard } from "./MarketingTools.data";
import styles from "./MarketingTools.module.scss";

interface IMarketingTools {
  style?: string;
  title?: string;
  _style?: CSSProperties;
  templates?: ProductType[];
}

const MarketingTools: FC<IMarketingTools> = ({
  style,
  title,
  _style,
  templates,
}) => {
  const { products } = useProducts("hybrid");

  const getCrossProduct = useCallback((): IToolsCard[] => {
    const result: IToolsCard[] = [];
    if (templates && templates.length && !isEmpty(products)) {
      templates.forEach((template) => {
        const p: IMarketing[] = [];
        Object.entries<IMarketing[]>(products).forEach((el) => {
          const [, value] = el;
          p.push(...value);
        });
        const { productID, quanity, price, description } = template;
        const product = p.find((el) => el.Id === productID);
        if (product) {
          const { Group, Images, Position } = product;
          const card: IToolsCard = {
            id: productID,
            type: Group,
            title:
              Position.length > 120
                ? Position.slice(0, 120).concat("...")
                : Position,
            description,
            image: (Images && Images[0]) || "",
            cost: price,
            available: quanity,
          };
          result.push(card);
        }
      });
    }
    return result;
  }, [templates, products]);

  return (
    <div className={style} style={_style}>
      {title && (
        <div className={styles.tools_title}>
          <h2>{title}</h2>
        </div>
      )}
      {getCrossProduct().map((item) => (
        /* eslint-disable */
        <ToolsCard key={item.id} {...item} />
      ))}
    </div>
  );
};

export default MarketingTools;
