import classnames from "classnames";
import React from "react";

import { useId } from "@hooks";
import { IIcon } from "@types";

import { omit } from "@lib/lodash";

import styles from "./Input.module.scss";

interface IMediaInput extends React.InputHTMLAttributes<HTMLElement> {
  icon: React.FC<IIcon>;
  label: string;
}

const IMediaInput: React.FC<IMediaInput> = ({ icon: Icon, label, ...rest }) => {
  const key = useId(`media-${label}`);
  return (
    <div className={classnames(styles.media__container, rest.className)}>
      <label htmlFor={key}>{label}</label>
      <div className={styles.wrapper}>
        <span className={styles.icon}>
          <Icon />
        </span>
        <input id={key} type="text" {...omit(rest, "type", "id")} />
      </div>
    </div>
  );
};

export default IMediaInput;
