import React from "react";

import { IIcon } from "@types";

const CheckIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg
      className={className}
      width="13"
      height="9"
      viewBox="0 0 13 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5364 0.263604C12.8879 0.615076 12.8879 1.18492 12.5364 1.5364L5.33641 8.7364C4.98494 9.08787 4.41509 9.08787 4.06362 8.7364L0.463616 5.1364C0.112144 4.78492 0.112144 4.21508 0.463616 3.8636C0.815088 3.51213 1.38494 3.51213 1.73641 3.8636L4.70001 6.82721L11.2636 0.263604C11.6151 -0.087868 12.1849 -0.087868 12.5364 0.263604Z"
        fill="white"
      />
    </svg>
  );
};

export default CheckIcon;
