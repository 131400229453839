import React from "react";

import { IIcon } from "@types";

const FAQIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12ZM12 17C11.4477 17 11 17.4477 11 18C11 18.5523 11.4477 19 12 19C12.5523 19 13 18.5523 13 18C13 17.4477 12.5523 17 12 17ZM14.17 12.17C13.67 12.68 13.31 13.14 13.13 13.86C12.9759 14.4764 12.485 15 11.8497 15H13C13 15.5523 12.5523 16 12 16C11.4477 16 11 15.5523 11 15V14.5C11 14.427 11.002 14.3545 11.006 14.2826C11.0269 13.9011 11.1022 13.5349 11.22 13.19C11.42 12.61 11.75 12.09 12.17 11.67L13.41 10.41C13.87 9.97 14.09 9.31 13.96 8.61C13.83 7.89 13.27 7.28 12.57 7.08C11.46 6.77 10.43 7.4 10.1 8.35C9.98 8.72 9.67 9 9.28 9H8.98C8.4 9 8 8.44 8.16 7.88C8.59 6.41 9.84 5.29 11.39 5.05C12.91 4.81 14.36 5.6 15.26 6.85C16.44 8.48 16.09 10.23 15.07 11.25L14.17 12.17Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default FAQIcon;
