import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import React from "react";

import { ReactComponent as Checked } from "@assets/icons/notification/checked.svg";
import { ReactComponent as Info } from "@assets/icons/notification/info.svg";

import styles from "./NotificationItem.module.scss";

interface INotificationItem {
  title: string;
  description: string;
  date: Date;
  isNew: boolean;
}

const NotificationItem: React.FC<INotificationItem> = ({
  title,
  description,
  date,
  isNew,
}) => {
  dayjs.extend(relativeTime);
  const dayFormat = dayjs(date).format("DD MMMM YYYY");
  const timeFormat = dayjs(date).format("hh:mm A");
  const timeAgo = dayjs(date).toNow();
  return (
    <li className={styles.item}>
      {isNew ? (
        <Info className={styles.icon} />
      ) : (
        <Checked className={styles.icon} />
      )}
      <div className={isNew ? styles.meta : `${styles.meta} ${styles.not}`}>
        <div className={styles.block}>
          <h3 className={styles.title}>{title}</h3>
          <p className={styles.date}>{timeAgo}</p>
          <div className={styles.desc_time}>{dayFormat}</div>
        </div>
        <div className={styles.line} />
        <div className={styles.data}>
          <p className={styles.description}>{description}</p>
          <p className={styles.time}>{timeFormat}</p>
        </div>
      </div>
    </li>
  );
};

export default React.memo(NotificationItem);
