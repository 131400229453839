import React, { CSSProperties, useState } from "react";

import { ValidateInputOptions } from "@types";

export type OutputType<T> = {
  value: T;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isValid?: boolean;
  customStyle?: CSSProperties;
  onBlur?: () => void;
  onFocus?: () => void;
};

export const useInput = <InputType>(
  initialValue: InputType,
): OutputType<InputType> => {
  const [value, setValue] = useState<InputType>(initialValue);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setValue(e.target.value as unknown as InputType);
  };

  return {
    value,
    onChange,
  };
};

export const useInputOne = (
  initialValue: number | string,
): OutputType<number | string> => {
  const [value, setValue] = useState<number | string>(initialValue);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setValue(e.target.value.length > 1 ? e.target.value[0] : e.target.value);
  };

  return {
    value,
    onChange,
  };
};

export const useCheckInput = <T>(
  name: T,
  initialCheck: boolean,
): (
  | boolean
  | { onBlur: () => void; onFocus?: undefined }
  | { onFocus: () => void; onBlur?: undefined }
)[] => {
  const [errName, setErrName] = useState<boolean>(initialCheck);

  const onBlurName = (): void => {
    if (!name) setErrName(true);
  };

  const onFocusName = (): void => {
    setErrName(false);
  };

  return [errName, { onBlur: onBlurName }, { onFocus: onFocusName }];
};

export const useValidateInput = <T>(
  initialState: T,
  callback: (arg: T) => boolean,
  options: Partial<ValidateInputOptions>,
  onError?: () => void,
): OutputType<T> => {
  const [isValid, setIsValid] = useState<boolean>(false);
  const [value, setValue] = useState<T>(initialState);
  const [customStyle, setCustomStyle] = useState<CSSProperties | undefined>(
    options?.defaultStyles,
  );
  const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setValue(() => event.target.value as unknown as T);
    setIsValid(() => callback(value));
  };

  const onBlur = (): void => {
    if (callback(value)) setCustomStyle(options.successStyles);
    else setCustomStyle(options.errorStyles);
    setIsValid(() => callback(value));
  };

  const onFocus = (): void => {
    setCustomStyle(options.defaultStyles);
    if (onError) onError();
  };

  return { value, isValid, customStyle, onChange, onBlur, onFocus };
};
