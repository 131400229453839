import React, { ReactNode } from "react";

import { PortalModalOverlay } from "@components/PortalModal";

import styles from "./InfoModal.module.scss";

interface IInfoModal {
  text: string;
  btnText: string;
  Icon: ReactNode;
  onClose?: () => void;
}

const InfoModal: React.FC<IInfoModal> = ({ text, btnText, Icon, onClose }) => {
  return (
    <PortalModalOverlay>
      <div className={styles.modal}>
        {Icon}
        <h2 className={styles.text}>{text}</h2>
        <button type="button" className={styles.btn} onClick={onClose}>
          {btnText}
        </button>
      </div>
    </PortalModalOverlay>
  );
};

export default React.memo(InfoModal);
