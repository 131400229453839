import React from "react";

import { CalendarBlock, CalendarProceed } from "@components";

import styles from "./EventsCalendar.module.scss";

const EventsCalendar: React.FC = () => {
  return (
    <div className={styles.event}>
      <CalendarProceed title="Events calendar" />
      <CalendarBlock />
    </div>
  );
};

export default EventsCalendar;
