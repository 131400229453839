import React from "react";

import { useLanguage } from "@context";

import { streamersInterface } from "../../MarketPlaceStreamers.data";

import styles from "./SliderElement.module.scss";

const SliderElement: React.FC<streamersInterface> = ({
  id,
  ImageCountry,
  country,
  from,
  title,
  date,
  price,
  image,
}) => {
  const { text } = useLanguage();
  return (
    <div className={styles.card} key={id}>
      <img src={image} alt="streamers" />
      <div className={styles.text}>
        <div className={styles.country}>
          <ImageCountry />
          <span>{country}</span>
        </div>
        <div className={styles.from}>{from}</div>
        <h2 className={styles.title}>{title}</h2>
        <div className={styles.footer}>
          <div className={styles.date}>{date}</div>
          <div className={styles.price}>
            <span>$ {price}</span>
            <span>/{text[255]}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SliderElement;
