import React, { useState } from "react";

import { Filter } from "@assets/icons/eventPosition";
import DatePickerBasic from "@components/DataPickerBasic";

import styles from "./EventPositionFilter.module.scss";

const EventPositionFilter: React.FC = () => {
  const [event, setEvent] = useState("");
  const [date, setDate] = useState<Date | null>(null);

  const handleChange = (e: React.FormEvent<HTMLSelectElement>): void => {
    const { value } = e.currentTarget;
    setEvent(value);
  };

  return (
    <div className={styles.container}>
      <label htmlFor="filterId" className={styles.label}>
        <Filter className={styles.icon} />
        <select
          id="filterId"
          name="event"
          value={event}
          className={styles.select}
          onChange={(e) => handleChange(e)}
        >
          <option className={styles.option} value="">
            Filter
          </option>
          <option className={styles.option} value="All">
            All
          </option>
          <option className={styles.option} value="Reporting">
            Reporting
          </option>
          <option className={styles.option} value="No reporting">
            No reporting
          </option>
          <option className={styles.option} value="On hold">
            On hold
          </option>
          <option className={styles.option} value="Completed">
            Completed
          </option>
          <option className={styles.option} value="Active">
            Active
          </option>
        </select>
      </label>

      <DatePickerBasic value={date} onChange={setDate} placeholder="Today" />
    </div>
  );
};
export default EventPositionFilter;
