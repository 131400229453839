import { Col, Row } from "antd";
import { motion } from "framer-motion";
import React from "react";
import { AiOutlineCheck } from "react-icons/all";
import { Link } from "react-router-dom";

import { useLanguage } from "@context";
import { MotionWrap } from "@hoc";

import ArrowBlock from "@assets/images/ArrowBlock";
import order from "@assets/images/landing3.png";
import list from "@assets/images/landing4.png";

import { Data } from "./LandingOperations.data";
import styles from "./LandingOperations.module.scss";

const LandingOperations: React.FC = () => {
  const { text } = useLanguage();
  return (
    <div className={styles.container}>
      <Row>
        <Col span={12}>
          <div className={styles.content}>
            <div>
              <h3 className={styles.title}>{text[226]}</h3>
              <p className={styles.description}>
                An enim nullam tempor sapien gravida donec enim ipsum porta
                justo integer at odio velna vitae auctor integer congue ||
              </p>
              <div className={styles.list}>
                <Row gutter={[8, 8]}>
                  {Data.map((item, index) => (
                    <Col span={12} key={item.title}>
                      <div className={styles.col}>
                        <motion.div
                          initial={{ opacity: 0 }}
                          whileInView={{ opacity: 1 }}
                          transition={{
                            duration: 0.5,
                            delay: index / 10,
                            ease: "easeIn",
                          }}
                        >
                          <AiOutlineCheck />
                        </motion.div>

                        <span className={styles.list_title}>{item.title}</span>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
              <Link to="/" className={styles.button}>
                {text[227]}
              </Link>
            </div>
          </div>
        </Col>
        <Col span={12}>
          <div className={styles.group}>
            <img src={order} alt="logo" />
            <img src={list} alt="logo" />
          </div>
        </Col>
      </Row>
      <div className={styles.line}>
        <ArrowBlock />
      </div>
    </div>
  );
};

export default MotionWrap(LandingOperations);
