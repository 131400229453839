import React, { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";

import Portal from "@components/PortalModal/Portal";

import styles from "./ImageViewing.module.scss";

interface IImageViewing {
  images: string[];
  onClose: () => void;
}

const ImageViewing: React.FC<IImageViewing> = ({ images, onClose }) => {
  const [shiftIndex, setShiftIndex] = useState<number>(0);
  const [onImg, setOnImg] = useState<string>(images[shiftIndex]);

  const onNext = (): void => {
    if (shiftIndex < images.length - 1) setShiftIndex((pre) => pre + 1);
  };

  useEffect(() => {
    setOnImg(images[shiftIndex]);
  }, [images, shiftIndex]);

  const onPrev = (): void => {
    if (shiftIndex > 0) setShiftIndex((pre) => pre - 1);
  };

  return (
    <Portal>
      <div className={styles.modal}>
        <div className={styles.content}>
          <img src={onImg} alt="gallery" />
          <div className={styles.close} onClick={onClose}>
            <IoMdClose />
          </div>
          <div className={styles.left} onClick={onPrev} />
          <div className={styles.right} onClick={onNext} />
        </div>
      </div>
    </Portal>
  );
};

export default ImageViewing;
