import React from "react";

import { InputSelect } from "@shared";

import styles from "./SettingsLanguage.module.scss";

const SettingsLanguage: React.FC = () => {
  return (
    <div className={styles.container}>
      <h4 className={styles.title}>Language</h4>
      <div className={styles.group}>
        <InputSelect label="Show in this language">
          <option defaultValue="eng">English (US)</option>
          <option defaultValue="deu">Deutsch (DE)</option>
        </InputSelect>
      </div>
    </div>
  );
};

export default SettingsLanguage;
