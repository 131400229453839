import React, { useEffect, useRef, useState } from "react";

type UseComponentVisibleType = {
  ref: React.RefObject<HTMLElement>;
  isComponentVisible: boolean;
  setIsComponentVisible: (
    value: ((prevState: boolean) => boolean) | boolean,
  ) => void;
};

export const useComponentVisible = (
  initialIsVisible: boolean,
): UseComponentVisibleType => {
  const [isComponentVisible, setIsComponentVisible] =
    useState(initialIsVisible);
  const ref = useRef<HTMLElement>(null);

  const handleClickOutside = (event: MouseEvent): void => {
    if (ref.current && !ref.current.contains(event.target as HTMLElement)) {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return { ref, isComponentVisible, setIsComponentVisible };
};
