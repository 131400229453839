import { motion } from "framer-motion";
import React from "react";

import { useLanguage } from "@context";
import { MotionWrap } from "@hoc";

import * as logos from "@assets/images/organizations";

import styles from "./LandingOrganizations.module.scss";

const LandingOrganizations: React.FC = () => {
  const { text } = useLanguage();
  return (
    <div className={styles.organizations}>
      <h3>{text[219]}</h3>
      <div className={styles.logos}>
        <motion.div
          whileInView={{ x: [-100, 0], opacity: [0, 1] }}
          transition={{ duration: 0.3, type: "tween", ease: "easeIn" }}
          className="wrapper"
        >
          <img src={logos.ejuLogo} alt="EJU" />
        </motion.div>
        <motion.div
          whileInView={{ x: [-100, 0], opacity: [0, 1] }}
          transition={{ duration: 0.3, type: "tween", ease: "easeIn" }}
          className="wrapper"
        >
          <img src={logos.fazeLogo} alt="faze" />
        </motion.div>

        <img src={logos.iesfLogo} alt="iesf" />
        <motion.div
          whileInView={{ x: [100, 0], opacity: [0, 1] }}
          transition={{ duration: 0.3, type: "tween", ease: "easeIn" }}
          className="wrapper"
        >
          <img src={logos.redbullLogo} alt="redbull" />
        </motion.div>
        <motion.div
          whileInView={{ x: [100, 0], opacity: [0, 1] }}
          transition={{ duration: 0.3, type: "tween", ease: "easeIn" }}
          className="wrapper"
        >
          <img src={logos.walmartLogo} alt="walmart" />
        </motion.div>
      </div>
    </div>
  );
};

export default MotionWrap(LandingOrganizations);
