import React from "react";

import { IIcon } from "@types";

const PhotoIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 16.2C10.2327 16.2 8.8 14.7673 8.8 13C8.8 11.2327 10.2327 9.8 12 9.8C13.7673 9.8 15.2 11.2327 15.2 13C15.2 14.7673 13.7673 16.2 12 16.2ZM20 5C21.1 5 22 5.9 22 7V19C22 20.1 21.1 21 20 21H4C2.9 21 2 20.1 2 19V7C2 5.9 2.9 5 4 5H7.17L8.4 3.65C8.78 3.24 9.32 3 9.88 3H14.12C14.68 3 15.22 3.24 15.59 3.65L16.83 5H20ZM12 18C14.76 18 17 15.76 17 13C17 10.24 14.76 8 12 8C9.24 8 7 10.24 7 13C7 15.76 9.24 18 12 18Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default PhotoIcon;
