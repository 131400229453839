import classNames from "classnames";
import React, { CSSProperties } from "react";

import { useLanguage } from "@context";
import { IDProduct } from "@types";

import { AvailableIcon, TotalCostIcon } from "@assets/icons/event";

import styles from "./TemplesCard.module.scss";

interface TemplesCardPlusProps {
  item: IDProduct;
  onClick: (item: IDProduct) => void;
  className?: string;
  style?: CSSProperties;
}

const TemplesCard: React.FC<TemplesCardPlusProps> = ({
  item,
  onClick,
  className,
  style,
}) => {
  const { text } = useLanguage();
  return (
    <div
      className={classNames(styles.card, className)}
      onClick={() => onClick(item)}
      style={style}
    >
      <div className={styles.header}>
        <span>{item.title}</span>
      </div>
      <div className={styles.content}>
        <button type="button" className={styles.left_block}>
          <img src={item.gallery[0]} alt={item.group} />
        </button>
        <div className={styles.right_block}>
          <button
            type="button"
            className={item.cost ? styles.active : undefined}
          >
            <TotalCostIcon />
            <span>{item.cost || text[68]}</span>
          </button>
          <button
            type="button"
            className={item.cost ? styles.active : undefined}
          >
            <AvailableIcon />
            <span>{item.quantity || text[125]}</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default TemplesCard;
