import classnames from "classnames";
import React, { FC } from "react";

import CheckIcon from "@assets/icons/CheckIcon";

import styles from "../LoginForm.module.scss";

interface ICheckBox {
  label: string;
  checked: boolean;

  onClick: () => void;
}

const CheckBox: FC<ICheckBox> = ({ label, checked, onClick }) => {
  return (
    <div
      className={classnames(styles.app__flex, styles.block_checkboxes)}
      onClick={onClick}
    >
      <div className={styles.checkbox}>{checked && <CheckIcon />}</div>
      <div className={styles.title}>{label}</div>
    </div>
  );
};

export default CheckBox;
