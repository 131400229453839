import React from "react";
import { Outlet } from "react-router-dom";

import { NotificationFilter } from "@components/NotificationPageComponents";

import styles from "./NotificationPage.module.scss";

const NotificationPage: React.FC = () => {
  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <h2 className={styles.title}>Notifications</h2>
      </div>
      <NotificationFilter />
      <Outlet />
    </section>
  );
};

export default NotificationPage;
