import React from "react";

import { useProfile } from "@pages/ProfilePage/context";

import styles from "./BlankInfo.module.scss";

const BlankInfo: React.FC = () => {
  const { handleAddNewEvent } = useProfile();

  return (
    <div className={styles.blank}>
      <div>
        <h5>There‘s nothing here, yet.</h5>
        <span>There are no information in your feed. </span>
        <span onClick={() => handleAddNewEvent()}>Add portfolio.</span>
      </div>
    </div>
  );
};

export default BlankInfo;
