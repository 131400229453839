import React from "react";

import { useLanguage } from "@context";
import { LandingBlock, MotionWrap } from "@hoc";

import LandingCard from "../LandingCard";
import LandingSlider from "../LandingSlider";

import { Cards } from "./LandingCompetitions.data";
import styles from "./LandingCompetitions.module.scss";

const LandingCompetitions: React.FC = () => {
  const { text } = useLanguage();
  return (
    <LandingBlock title={text[220]} topTitle={text[221]}>
      <div className={styles.container}>
        <LandingSlider visibleItems={3} data={Cards} Element={LandingCard} />
      </div>
    </LandingBlock>
  );
};

export default MotionWrap(LandingCompetitions);
