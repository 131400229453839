import { motion } from "framer-motion";
import React, { ReactElement } from "react";

const MotionWrap = <T extends object>(Component: React.FC<T>) =>
  function HOC(props: T): ReactElement<string> {
    return (
      <motion.div
        whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
        transition={{ duration: 0.5 }}
        className="wrapper"
      >
        <Component {...props} />
      </motion.div>
    );
  };

export default MotionWrap;
