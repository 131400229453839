import { AxiosError } from "axios";

import { SessionApi } from "@api";
import { setApiPath } from "@helpers";
import {
  IEvent,
  IResponse,
  IResponseCreateEvent,
  IResponseEvent,
  IResponseMarketplace,
} from "@types";

import {
  EventType,
  ProductType,
} from "@context/EventCreate/EventContext.types";

export default class EventApi {
  static query = setApiPath("/api/v1/events");

  static castToString<T extends object>(object: T): { [p: string]: string } {
    return Object.fromEntries(
      Object.entries(object).map((entry) => [
        entry[0],
        entry[1] as unknown as string,
      ]),
    );
  }

  static async addEvent(event: EventType): Promise<IResponseCreateEvent> {
    const {
      eventName,
      format,
      type,
      description,
      startTime,
      endTime,
      location,
      website,
      participantNumber,
      mediaReach,
    } = this.castToString(event);
    return new Promise<IResponseCreateEvent>((resolve, reject) => {
      const data = new URLSearchParams({
        eventName,
        format,
        type,
        description,
        startTime,
        endTime,
        location,
        website,
        participantNumber,
        mediaReach,
      });
      SessionApi.api
        .post<IResponseCreateEvent>(this.query("/addEvent"), data)
        .then((response) => resolve(response.data))
        .catch((error: AxiosError) => reject(error.response?.data));
    });
  }

  static async getAllEvents(): Promise<IResponseEvent> {
    return new Promise<IResponseEvent>((resolve, reject) => {
      SessionApi.api
        .get<IResponseEvent>(this.query("/getMyEvents"))
        .then((response) => resolve(response.data))
        .catch((error: AxiosError) => reject(error.response?.data));
    });
  }

  static async getEventById(id: number): Promise<IEvent> {
    return new Promise<IEvent>((resolve, reject) => {
      SessionApi.api
        .get<IEvent>(this.query(`/${id}`))
        .then((response) => resolve(response.data))
        .catch((error: AxiosError) => reject(error.response?.data));
    });
  }

  static async setEventLogo(id: number, image: FormData): Promise<IResponse> {
    return new Promise<IResponse>((resolve, reject) => {
      SessionApi.api
        // eslint-disable
        .post<IResponse>(this.query(`/${id}/setLogo`), image)
        .then((response) => resolve(response.data))
        .catch((error: AxiosError) => reject(error.response?.data));
    });
  }

  static async setEventBackground(
    id: number,
    image: FormData,
  ): Promise<IResponse> {
    return new Promise<IResponse>((resolve, reject) => {
      SessionApi.api
        .post<IResponse>(this.query(`/${id}/setBackground`), image)
        .then((response) => resolve(response.data))
        .catch((error: AxiosError) => reject(error.response?.data));
    });
  }

  static async setGalleryImage(
    id: number,
    image: FormData,
  ): Promise<IResponse> {
    return new Promise<IResponse>((resolve, reject) => {
      SessionApi.api
        .post<IResponse>(this.query(`/${id}/addImage`), image)
        .then((response) => resolve(response.data))
        .catch((error: AxiosError) => reject(error.response?.data));
    });
  }

  static async addProduct(
    eventId: number,
    product: ProductType,
  ): Promise<IResponse> {
    const {
      description,
      imgURL,
      price,
      productID,
      quanity,
      TEMPMS1,
      TEMPMS2,
      TEMPMS3,
      TEMPMS4,
    } = this.castToString(product);
    const data = new URLSearchParams({
      description,
      imgURL,
      price,
      productID,
      quanity,
      TEMPMS1,
      TEMPMS2,
      TEMPMS3,
      TEMPMS4,
    });
    return new Promise<IResponse>((resolve, reject) => {
      SessionApi.api
        .post<IResponse>(this.query(`/${eventId}/addProduct`), data)
        .then((response) => resolve(response.data))
        .catch((error: AxiosError) => reject(error.response?.data));
    });
  }

  static async getEvents(): Promise<IResponseMarketplace> {
    return new Promise<IResponseMarketplace>((resolve, reject) => {
      SessionApi.api
        .get<IResponseMarketplace>(this.query("/getAllEvents"))
        .then((response) => resolve(response.data))
        .catch((error: AxiosError) => reject(error.response?.data));
    });
  }
}
