import React, { useState } from "react";

import { useProfile } from "@pages/ProfilePage/context";

import styles from "./ProfilePortfolio.module.scss";
import BlankInfo from "./components/BlankInfo";
import PortfolioButton from "./components/PortfolioButton";
import PortfolioEvent from "./components/PortfolioEvent";

const ProfilePortfolio: React.FC = () => {
  const { currentEvents } = useProfile();
  const [openEvent, setOpenEvent] = useState<null | number | string>(null);

  const handleOpen = (id: number | string | null): void => {
    setOpenEvent(id);
  };

  return (
    <div className={styles.profile_portfolio}>
      <h3 className={styles.title}>Portfolio and results</h3>
      <p className={styles.subtitle}>Add the organisation events</p>
      <div>
        {!currentEvents.size && <BlankInfo />}

        {!!currentEvents.size && (
          <>
            {[...currentEvents.values()].map((event) => (
              <PortfolioEvent
                key={event.id}
                event={event}
                handleOpen={handleOpen}
                openEventId={openEvent}
              />
            ))}

            <PortfolioButton setOpenEvent={setOpenEvent} />
          </>
        )}
      </div>
    </div>
  );
};

export default ProfilePortfolio;
