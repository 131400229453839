import { AxiosError } from "axios";

import { SessionApi } from "@api";
import { setApiPath, urlToFormData } from "@helpers";
import { IResponse, IResponseDocuments } from "@types";

export default class FileApi {
  static query = setApiPath("/api/v1/File");

  static async uploadImage(imageUrl: string): Promise<IResponse> {
    return new Promise<IResponse>((resolve, reject) => {
      SessionApi.api
        .post<IResponse>(this.query("/UploadImg"), urlToFormData(imageUrl))
        .then((response) => resolve(response.data))
        .catch((error: AxiosError) => reject(error.response?.data));
    });
  }

  static async uploadDocument(
    name: string,
    file: FormData,
  ): Promise<IResponse> {
    return new Promise<IResponse>((resolve, reject) => {
      SessionApi.api
        .post<IResponse>(this.query(`/UploadFile?name=${name}`), file)
        .then((response) => resolve(response.data))
        .catch((error: AxiosError) => reject(error.response?.data));
    });
  }

  static async getDocument(documentID: number): Promise<IResponse> {
    return new Promise<IResponse>((resolve, reject) => {
      SessionApi.api
        .get<IResponse>(this.query(`/${documentID}`))
        .then((response) => resolve(response.data))
        .catch((error: AxiosError) => reject(error.response?.data));
    });
  }

  static async getMyDocuments(): Promise<IResponseDocuments> {
    return new Promise<IResponseDocuments>((resolve, reject) => {
      SessionApi.api
        .get<IResponseDocuments>(this.query("/getMyDocuments"))
        .then((response) => resolve(response.data))
        .catch((error: AxiosError) => reject(error.response?.data));
    });
  }

  static async sendImage(uri: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      if (uri.includes("base64")) {
        FileApi.uploadImage(uri)
          .then((response) => {
            resolve(response?.url || "");
          })
          .catch((error: AxiosError) => {
            resolve("");
            reject(error.response?.data);
          });
      } else {
        resolve(uri);
      }
    });
  }
}
