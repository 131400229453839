import { IDProduct, IMarketing, IPositionMap, IProductMap } from "@types";

import { ProductType } from "@context/EventCreate/EventContext.types";

export const adapterProduct = (
  markets: IProductMap,
  products: ProductType[],
): IPositionMap => {
  const newI: [string, IDProduct[]][] = [];
  Object.entries<IMarketing[]>(markets).forEach(([group, values]) => {
    const buffer = values.map<IDProduct>((market) => {
      const product = products.find((p) => p.productID === market.Id);
      return {
        mid: market.Id,
        group: market.Group,
        title: market.Position,
        gallery: market.Example.split(" "),
        image: product?.imgURL || null,
        description: product?.description || null,
        cost: product?.price || null,
        quantity: product?.quanity || null,
        specification1: market.Specification_1,
        specification2: market.Specification_2,
        specification3: market.Specification_3,
        specification4: market.Specification_4,
        specificationValue1: product?.TEMPMS1 || null,
        specificationValue2: product?.TEMPMS2 || null,
        specificationValue3: product?.TEMPMS3 || null,
        specificationValue4: product?.TEMPMS4 || null,
      };
    });
    newI.push([group, buffer]);
  });
  return Object.fromEntries(newI);
};
