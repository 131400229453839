import classnames from "classnames";
import React, { FC } from "react";
import { Link } from "react-router-dom";

import styles from "./PageHeader.module.scss";

export interface PageHeaderProps {
  title: string;
  buttonTitle: string;
  to: string;
  onClick: () => void;
  loading: boolean;
}

const PageHeaderWrap: FC<PageHeaderProps> = ({
  title,
  to,
  buttonTitle,
  onClick,
  loading = false,
}) => {
  return (
    <div className={styles.header}>
      <h2 className={styles.title}>{title}</h2>
      <Link
        to={to}
        className={classnames(styles.link, { [styles.disabled]: loading })}
        onClick={onClick}
      >
        {loading ? "Loading..." : buttonTitle}
      </Link>
    </div>
  );
};

export default PageHeaderWrap;
