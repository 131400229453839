import React from "react";

import { ErrorField, ToolboxFirstSlider, ToolboxTitle } from "@components";
import { useError } from "@context";

import styles from "./ToolboxFirstPage.module.scss";
import { templateAverage } from "./ToolboxFirstSlider.data";

const ToolboxFirstPage: React.FC = () => {
  const { hasError, message } = useError();
  return (
    <>
      <ErrorField title={message || "Some Error"} isError={hasError} />
      <div className={styles.wrapper}>
        <ToolboxTitle title="Marketing Toolbox" />
        <ToolboxFirstSlider
          title="Marketing templates for average events"
          data={templateAverage}
          style={{ marginTop: 24 }}
        />
      </div>
    </>
  );
};

export default ToolboxFirstPage;
