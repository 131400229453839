import { APIUser, IUser } from "@types";

const userMappingFromAPI = (userApiData: APIUser): IUser => {
  return {
    UserID: userApiData.id,
    UserType: userApiData.userType,
    RoleID: userApiData.roleID,
    FirstName: userApiData.firstName,
    SecondName: userApiData.secondName,
    E_mail: userApiData.e_mail,
    PhoneNumber: userApiData.phoneNumber,
    WebSite: userApiData.webSite,
    Login: userApiData.login,
    Registration: userApiData.registration,
    IconUrl: userApiData.iconUrl,
    DateOfBirth: userApiData.dateOfBirth,
    SportType: userApiData.sportType,
    Organization: {
      Id: userApiData?.organisation?.id,
      Name: userApiData?.organisation?.name,
      Email: userApiData?.organisation?.email,
      PhoneNumber: userApiData?.organisation?.phoneNumber,
      SportType: userApiData?.organisation?.sportType,
      Description: userApiData?.organisation?.description,
      WebSite: userApiData?.organisation?.webSite,
      Location: userApiData?.organisation?.location,
      DateEstablishment: userApiData?.organisation?.establishmentDate,
    },
    BackgroundUrl: userApiData.backgroundUrl,
    Notes: userApiData.notes,
    EmailConfirmed: userApiData.emailConfirmed,
    Location: userApiData.location,
    HomeAddress: userApiData.homeAdress,
    SocialMedias: userApiData.socialMedias,
    Portfolios: userApiData.portfolios,
    Events: userApiData.events,
  };
};

const userMappingToAPI = (userData: Partial<IUser>): Partial<APIUser> => {
  return {
    id: userData.UserID,
    userType: userData.UserType,
    roleID: userData.RoleID,
    firstName: userData.FirstName,
    secondName: userData.SecondName,
    e_mail: userData.E_mail,
    phoneNumber: userData.PhoneNumber,
    webSite: userData.WebSite,
    login: userData.Login,
    registration: userData.Registration,
    iconUrl: userData.IconUrl,
    dateOfBirth: userData.DateOfBirth,
    sportType: userData.SportType,
    organisation: {
      id: userData?.Organization?.Id,
      name: userData?.Organization?.Name,
      sportType: userData?.Organization?.SportType,
      description: userData?.Organization?.Description,
      phoneNumber: userData?.Organization?.PhoneNumber,
      webSite: userData?.Organization?.WebSite,
      email: userData?.Organization?.Email,
      location: userData?.Organization?.Location,
      establishmentDate: userData?.Organization?.DateEstablishment,
    },
    backgroundUrl: userData.BackgroundUrl,
    notes: userData.Notes,
    emailConfirmed: userData.EmailConfirmed,
    location: userData.Location,
    socialMedias: userData.SocialMedias,
    portfolios: userData.Portfolios,
    events: userData.Events,
    homeAdress: userData.HomeAddress,
  };
};

export default { userMappingFromAPI, userMappingToAPI };
