import classnames from "classnames";
import React from "react";

// import { useLanguage } from "@context";
import { Input } from "@shared";

import styles from "./SettingsPassword.module.scss";

const SettingsPassword: React.FC = () => {
  // const { text } = useLanguage();

  return (
    <div className={styles.container}>
      <h4 className={styles.title}>Password</h4>
      <div className={styles.subtitle}>
        Set a unique password to protect your personal account.
      </div>
      <div className={styles.group}>
        <Input
          label="Old Password"
          placeholder="*  *  *  *  *  *  *  *  *  *  *  *"
          className={classnames(styles.input__width, styles.input_grey)}
        />
        <Input
          className={styles.input_grey}
          label="New Password"
          placeholder="*  *  *  *  *  *  *  *  *  *  *  *"
        />
        <Input
          className={styles.input_grey}
          label="Re- enter password"
          placeholder="*  *  *  *  *  *  *  *  *  *  *  *"
        />
      </div>
      <div className={styles.footer}>
        <p>
          To ensure your account is well protected, use 8 or moere charecters
          with a miх jf letters, numbers & symbols.
        </p>
        <button type="button">Save Password</button>
      </div>
    </div>
  );
};

export default SettingsPassword;
