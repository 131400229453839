import React, { useState } from "react";

import { useLanguage } from "@context";
import { dateAdapter, getPhone } from "@helpers";
import { Input, InputPhone } from "@shared";

import DatePickerField from "@components/DatePickerField";
import GMapModal from "@components/GMap/GMapModal";

import { useProfile } from "../../context";

import styles from "./ProfilePersonalInformation.module.scss";

const ProfilePersonalInformation: React.FC = () => {
  const { text } = useLanguage();
  const {
    user,
    handleInputChange,
    handleChangeUserDate,
    handleChangeDate,
    validator,
  } = useProfile();
  const [isOpenMap, setIsOpenMap] = useState(false);
  const PhoneNumber = getPhone(user?.PhoneNumber || "+372");

  const handlePhoneChange = (alias: string, value: string): void => {
    handleChangeUserDate("PhoneNumber", [alias, value].join(""));
  };

  const handleAddressChange = (address: string): void => {
    handleChangeUserDate("HomeAddress", address);
  };

  return (
    <div className={styles.container}>
      <h4 className={styles.title}>{text[11]}</h4>

      <div className={styles.group}>
        <Input
          error={validator("FirstName", user?.FirstName)}
          label="First Name*"
          value={user?.FirstName ?? ""}
          name="FirstName"
          onChange={handleInputChange}
        />

        <Input
          label="Last Name*"
          error={validator("SecondName", user?.SecondName)}
          value={user?.SecondName ?? ""}
          name="SecondName"
          onChange={handleInputChange}
        />

        <Input
          label="Location*"
          onClick={() => setIsOpenMap(true)}
          placeholder="Choose the location"
          value={user?.HomeAddress ?? ""}
        />

        <DatePickerField
          error={validator("DateOfBirth", user?.DateOfBirth)}
          label="Date of Birth*"
          value={dateAdapter.toClient(user?.DateOfBirth) || null}
          name="DateOfBirth"
          onChange={handleChangeDate}
          customClassName={styles.dateOfBirth}
        />

        <Input label={text[109]} value={user?.E_mail || user?.Login} disabled />

        <InputPhone
          label={text[168]}
          inputValue={PhoneNumber[1]}
          selectValue={PhoneNumber[0]}
          onPhoneChange={handlePhoneChange}
        >
          <option defaultValue="" disabled>
            {" "}
          </option>
          <option value="+7">+7</option>
          <option value="+372">+372</option>
        </InputPhone>
      </div>

      {isOpenMap && (
        <GMapModal
          address={user?.HomeAddress || ""}
          onClose={() => setIsOpenMap(false)}
          setAddress={handleAddressChange}
        />
      )}
    </div>
  );
};

export default ProfilePersonalInformation;
