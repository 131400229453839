import React, { FC, useRef } from "react";

import { useAuth } from "@context";
import { capitalChar, setProgress } from "@helpers";

import { AvatarDefault, EditIcon, PhotoIcon } from "@assets/icons/kit";
import ProgressBar from "@components/ProgressBar";

import styles from "./SidebarAccount.module.scss";

const reverseDate = (data: string | null): string => {
  return data?.split("-").reverse().join("-") ?? "";
};

const SidebarAccount: FC = () => {
  const ref = useRef<HTMLInputElement>(null);
  const { setUserLogo, user } = useAuth();
  const { onClick, onChange } = setUserLogo(ref);

  const registration = user.Registration
    ? reverseDate(user?.Registration.split("T")[0])
    : "";
  const progress = setProgress(user);

  return (
    <div className={styles.content}>
      <div className={styles.icon}>
        <img
          src={user?.IconUrl || AvatarDefault}
          alt={user?.FirstName}
          className={styles.logo}
        />
        <div className={styles.change_icon}>
          <input ref={ref} type="file" onChange={onChange} />
          <span className={styles.button} onClick={onClick}>
            {user?.IconUrl ? <EditIcon /> : <PhotoIcon />}
          </span>
        </div>
      </div>
      <div className={styles.text}>
        <span className={styles.name}>
          {capitalChar(user?.FirstName)} {capitalChar(user?.SecondName)}
        </span>
        <span className={styles.mail}>{user?.Login || ""}</span>
        <span className={styles.registration}>Registration {registration}</span>
        <div className={styles.statistics}>
          <ProgressBar percents={progress} />
          <span className={styles.progress}>{progress}%</span>
        </div>
      </div>
    </div>
  );
};

export default SidebarAccount;
