import { motion } from "framer-motion";
import React from "react";

import { useLanguage } from "@context";
import { MotionWrap } from "@hoc";

import styles from "./ProcessFollowed.module.scss";

const ProcessFollowed: React.FC = () => {
  const { text } = useLanguage();
  const following = [
    { id: 1, title: text[260], desc: text[261] },
    { id: 2, title: text[262], desc: text[263] },
    { id: 3, title: text[264], desc: text[265] },
    { id: 4, title: text[266], desc: text[267] },
  ];

  return (
    <div className={styles.wrapper}>
      <h2>{text[268]}</h2>
      <div className={styles.follow}>
        {following.map((item, index) => (
          <motion.div
            key={item.id}
            className={styles.item}
            initial={{ opacity: 0, x: -300 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{
              duration: 0.5,
              delay: index / 2,
              ease: "easeIn",
            }}
          >
            <h4>{item.title}</h4>
            <p className={styles.desc}>{item.desc}</p>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default MotionWrap(ProcessFollowed);
