export interface IErrorState {
  message: string | null;
  hasError: boolean;
}

export enum ErrorActionEnum {
  SET_ERROR = "SET_ERROR",
  CLEAR_ERROR = "CLEAR_ERROR",
}

interface ISetErrorAction {
  type: ErrorActionEnum.SET_ERROR;
  payload: string;
}

interface IClearErrorAction {
  type: ErrorActionEnum.CLEAR_ERROR;
}

export type ErrorActionTypes = ISetErrorAction | IClearErrorAction;
