import React, { ReactElement, useCallback, useState } from "react";

interface IModalOption {
  extendOnOpen: () => void;
  extendOnClose: () => void;
}

interface IModalOutput<TProps> {
  onClose: () => void;
  onOpen: () => void;
  Modal: (props: Omit<TProps, "onClose">) => ReactElement<string>;
}

export const useModal = <TProps,>(
  Component: React.FC<TProps>,
  options: Partial<IModalOption>,
): IModalOutput<TProps> => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { extendOnOpen, extendOnClose } = options;

  const onOpen = useCallback(() => {
    setIsOpen(() => true);
    if (extendOnOpen) extendOnOpen();
  }, [extendOnOpen]);
  const onClose = useCallback(() => {
    setIsOpen(() => false);
    if (extendOnClose) extendOnClose();
  }, [extendOnClose]);

  const WrappedComponent = (
    props: Omit<TProps, "onClose">,
  ): ReactElement<string> => {
    return (
      <div>
        {isOpen ? (
          <Component {...(props as TProps)} onClose={onClose} />
        ) : undefined}
      </div>
    );
  };

  return { onOpen, onClose, Modal: WrappedComponent };
};
