import { Radio } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import React from "react";

import styles from "./AvailableLanguages.module.scss";

interface IAvailableLanguages {
  value: number;
  onChange: (event: RadioChangeEvent) => void;
  title: string;
  radioData: string[];
}

const AvailableLanguages: React.FC<IAvailableLanguages> = ({
  onChange,
  value,
  radioData,
  title,
}) => {
  return (
    <div className={styles.block}>
      <h3>{title}</h3>
      <Radio.Group className={styles.group} onChange={onChange} value={value}>
        <Radio value={1}>{radioData[0]}</Radio>
        <Radio value={2}>{radioData[1]}</Radio>
        <Radio value={3}>{radioData[2]}</Radio>
        <Radio value={4}>{radioData[3]}</Radio>
      </Radio.Group>
    </div>
  );
};

export default AvailableLanguages;
