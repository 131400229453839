import React from "react";

import { EstoniaIcon, KazakhstanIcon } from "@assets";
import { IIcon } from "@types";

import streamer1 from "@assets/images/marketplace/streamer1.png";
import streamer2 from "@assets/images/marketplace/streamer2.png";

export interface streamersInterface {
  id: number;
  image: string;
  date: string;
  country: string;
  price: number;
  title: string;
  ImageCountry: React.FC<IIcon>;
  from: string;
}

export const blockWidth =
  window.screen.width > 500 ? 525 : window.screen.width - 16;

export const streamers: streamersInterface[] = [
  {
    id: 1,
    image: streamer1,
    date: "02 Oct 2022",
    country: "Kazakhstan",
    ImageCountry: KazakhstanIcon,
    price: 650,
    from: "Stream from esportsman",
    title: "What is esports and who are esportsmen?",
  },
  {
    id: 2,
    image: streamer2,
    date: "10 - 12 Oct 2022",
    country: "Estonia",
    ImageCountry: EstoniaIcon,
    price: 869,
    from: "Stream from esportsman",
    title: "NFL - Playoffs",
  },
  {
    id: 3,
    image: streamer1,
    date: "02 Oct 2022",
    country: "Kazakhstan",
    ImageCountry: KazakhstanIcon,
    price: 650,
    from: "Stream from esportsman",
    title: "What is esports and who are esportsmen?",
  },
  {
    id: 4,
    image: streamer2,
    date: "10 - 12 Oct 2022",
    country: "Estonia",
    ImageCountry: EstoniaIcon,
    price: 869,
    from: "Stream from esportsman",
    title: "NFL - Playoffs",
  },
];
