import { SessionApi } from "@api";
import { IMedias } from "@types";

import {
  EventActionEnum,
  EventActionTypes,
  EventType,
  IEventState,
} from "./EventContext.types";

export const defaultEventState: IEventState = {
  event: SessionApi.get<EventType>("create-event:main"),
  gallery: [],
  rawImages: null,
  rawProducts: [],
  socials: SessionApi.get<IMedias>("create-event:socials"),
};

export const eventReducer = (
  state: IEventState,
  action: EventActionTypes,
): IEventState => {
  switch (action.type) {
    case EventActionEnum.CHANGE_EVENT_DATA:
      return { ...state, event: { ...state.event, ...action.payload } };
    case EventActionEnum.CHANGE_EVENT_IMAGES: {
      const buffer = {
        logo: action.payload.logo,
        background: action.payload.background,
      };
      return { ...state, rawImages: buffer };
    }
    case EventActionEnum.CHANGE_GALLEY_DATA:
      return { ...state, gallery: [...action.payload] };
    case EventActionEnum.ADD_PRODUCT:
      return { ...state, rawProducts: [...state.rawProducts, action.payload] };
    case EventActionEnum.UPDATE_PRODUCT: {
      const newProducts = state.rawProducts.filter(
        (product) => product.productID !== action.payload.productID,
      );
      return { ...state, rawProducts: [...newProducts, action.payload] };
    }
    case EventActionEnum.DELETE_PRODUCT:
      return {
        ...state,
        rawProducts: state.rawProducts.filter(
          (product) => product.productID !== action.payload,
        ),
      };
    case EventActionEnum.CHANGE_SOCIAL_MEDIAS:
      return { ...state, socials: { ...action.payload } };
    default:
      return state;
  }
};
