import React from "react";
import { Link } from "react-router-dom";

import { useLanguage } from "@context";
import { LandingBlock, MotionWrap } from "@hoc";

import styles from "./LandingPreFooter.module.scss";

const LandingPreFooter: React.FC = () => {
  const { text } = useLanguage();
  return (
    <LandingBlock title={text[230]} subTitle={text[231]}>
      <div className={styles.container}>
        <Link to="/" className="button solid" style={{ fontSize: "24px" }}>
          {text[182]}
        </Link>
        <div className={styles.footer}>
          <span className={styles.count}>3780</span>
          <span className={styles.text}>{text[232]}</span>
        </div>
      </div>
    </LandingBlock>
  );
};

export default MotionWrap(LandingPreFooter);
