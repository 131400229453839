import React from "react";

import { ReactComponent as Plus } from "@assets/icons/shared/plus.svg";
import BasketPrice from "@components/MarketPlaceBasket/BasketPrice";

import { data } from "../MarketPlaceBasket.data";

import styles from "./Basket.module.scss";
import BasketItem from "./BasketItem";

const Basket: React.FC = () => {
  const elements = data.map((item) => <BasketItem {...item} key={item.id} />);
  const total = data.reduce((previousValue, item) => {
    return previousValue + item.coast;
  }, 0);

  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <span className={styles.total}>{elements.length} item added</span>
          <ul className={styles.roster}>{elements}</ul>
          <button type="button" className={styles.btn}>
            <Plus className={styles.icon} />
            <span className={styles.btnText}>Add position</span>
          </button>
        </div>
        <BasketPrice items={elements.length} total={total} />
      </div>
    </section>
  );
};

export default Basket;
