import React from "react";

const ArrowButton: React.FC = () => {
  return (
    <svg viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="24" cy="24.6177" r="24" fill="currentColor" />
      <path
        d="M32.8795 19.8642C33.0157 19.0471 32.4636 18.2742 31.6465 18.138L18.3302 15.9187C17.513 15.7825 16.7402 16.3346 16.604 17.1517C16.4678 17.9689 17.0198 18.7417 17.837 18.8779L29.6737 20.8506L27.701 32.6874C27.5648 33.5045 28.1168 34.2774 28.934 34.4135C29.7511 34.5497 30.524 33.9977 30.6602 33.1805L32.8795 19.8642ZM16.8719 31.8383L32.2718 20.8382L30.528 18.397L15.1281 29.3971L16.8719 31.8383Z"
        fill="white"
      />
    </svg>
  );
};

export default ArrowButton;
