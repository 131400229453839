import React from "react";
import { IoMdClose } from "react-icons/io";

import styles from "./ErrorField.module.scss";

interface IErrorField {
  title: string;
  isError: boolean;
  onCleaning?: () => void;
}

const ErrorField: React.FC<IErrorField> = ({ title, isError, onCleaning }) => {
  if (!isError) {
    return null;
  }
  return (
    <div className={styles.error_field}>
      <div className={styles.title}>{title}</div>
      <div className={styles.subscribe} onClick={onCleaning}>
        <IoMdClose />
      </div>
    </div>
  );
};

export default ErrorField;
