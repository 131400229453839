import React from "react";
import { useNavigate } from "react-router-dom";

import { useEvent, useLanguage } from "@context";

import { CheckIcon } from "@assets/icons/event";

import styles from "./EventTitle.module.scss";

interface ToolboxTitleProps {
  title: string;
}

const EventTitle: React.FC<ToolboxTitleProps> = ({ title }) => {
  const { text } = useLanguage();
  const { validateEventData, sendEvent } = useEvent();
  const navigate = useNavigate();

  const handleSubmit = (): void => {
    if (validateEventData()) {
      sendEvent().catch(null);
      navigate("/app/profile", { replace: true });
    }
  };

  return (
    <>
      <div className={styles.content}>
        <div className={styles.title}>
          <span>{title}</span>
          <button type="button" onClick={handleSubmit}>
            Publish event
          </button>
        </div>
        <div className={styles.step}>
          <div className={styles.item}>
            <div className={styles.circle1}>
              <CheckIcon />
            </div>
            <div className={styles.text}>
              <h4>Event Form</h4>
              <p>Fill out the event form</p>
            </div>
          </div>

          <div className={styles.item}>
            <div className={styles.circle1}>
              <CheckIcon />
            </div>
            <div className={styles.text}>
              <h4>Event details</h4>
              <p>Define event position</p>
            </div>
          </div>

          <div className={styles.item}>
            <div className={styles.circle2}>
              <div />
            </div>
            <div className={styles.text}>
              <h4>Confirmation</h4>
              <p>Passage of the revue</p>
            </div>
          </div>
          <div className={styles.item_mobile1}>
            <CheckIcon />
          </div>
          <div className={styles.line_mobile} />
          <div className={styles.item_mobile1}>
            <CheckIcon />
          </div>
          <div className={styles.line_mobile} />
          <div className={styles.item_mobile2} />
        </div>
        <div className={styles.mobile_description}>
          <span>Event Form</span>
          <span>Event details</span>
          <span>Confirmation</span>
        </div>
      </div>
      <h3 className={styles.mobile_title}>{title}</h3>
      <div className={styles.proceed}>
        <button
          type="button"
          onClick={() => navigate("/app/toolbox-second", { replace: true })}
        >
          {text[39]}
        </button>
      </div>
    </>
  );
};

export default EventTitle;
