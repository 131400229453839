import React, { useEffect, useRef, useState } from "react";

import { useEvent, useLanguage } from "@context";
import { useImage } from "@hooks";

import { AddIcon, DeleteIcon, EyeIcon } from "@assets/icons/kit";

import ImageViewing from "../../ImageViewing";

import styles from "./EventGallery.module.scss";

interface IEventGallery {
  onCleaning: () => void;
}

const EventGallery: React.FC<IEventGallery> = ({ onCleaning }) => {
  const ref = useRef<HTMLInputElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const { changeEventGallery, gallery } = useEvent();
  const { images, setImages, onClick, onChange, onDelete } = useImage(
    ref,
    gallery,
  );
  const { text } = useLanguage();

  const onSelectImg = (): void => {
    setIsOpen(true);
  };

  const onClose = (): void => {
    setIsOpen(false);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => changeEventGallery(images), 1000);
    return () => clearTimeout(timeoutId);
  }, [images]);

  useEffect(() => {
    if (!images.length && gallery.length) {
      /* eslint-disable */
      setImages(() => gallery);
    }
  }, [gallery]);

  return (
    <div className={styles.gallery} onClick={onCleaning}>
      <div className={styles.title}>{text[132]}</div>
      <div className={styles.gallery_block}>
        {images.map((image) => (
          <div className={styles.img_wraper} key={image}>
            <img src={image} alt="gallery" />
            <div className={styles.hovered} onClick={onSelectImg}>
              <div onClick={() => onDelete(image)}>
                <DeleteIcon />
              </div>
              <div>
                <EyeIcon />
              </div>
            </div>
            {isOpen && <ImageViewing onClose={onClose} images={images} />}
          </div>
        ))}
        <div className={styles.gallery_upload} onClick={onClick}>
          <AddIcon />
          <span>{text[26]}</span>
        </div>
        <input type="file" ref={ref} multiple onChange={onChange} />
      </div>
    </div>
  );
};

export default EventGallery;
