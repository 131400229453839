import { motion } from "framer-motion";
import React, { useState } from "react";

import { EstoniaIcon, UsaIcon } from "@assets";
import { IIcon } from "@types";

import { Portal } from "../../PortalModal";

import styles from "./LanguageSelection.module.scss";

interface languagesType {
  country: string;
  Icon: React.FC<IIcon>;
  code: string;
}

const languages: languagesType[] = [
  // { country: "Belgium", Icon: BelgiumIcon, code: "NL" },
  // { country: "France", Icon: FranceIcon, code: "FR" },
  // { country: "Germany", Icon: GermanyIcon, code: "DE" },
  { country: "English", Icon: UsaIcon, code: "EN" },
  { country: "Estonia", Icon: EstoniaIcon, code: "ET" },
  // { country: "Brazilian", Icon: BrazilianIcon, code: "PT" },
  // { country: "Kazakhstan", Icon: KazakhstanIcon, code: "KK" },
];

interface ILang {
  country: string;
  Icon: React.FC<IIcon>;
  code: string;
  changeLang: (lang: string) => () => void;
}

const LangItem: React.FC<ILang> = ({ country, Icon, code, changeLang }) => {
  return (
    <motion.div className={styles.item} onClick={changeLang(code)}>
      <Icon />
      <div>{country}</div>
    </motion.div>
  );
};

interface ILanguageSelection {
  changeLang: (lang: string) => () => void;
}

const LanguageSelection: React.FC<ILanguageSelection> = ({ changeLang }) => {
  const [data] = useState<languagesType[]>(languages);
  // const [viewAll, setViewAll] = useState<boolean>(false);
  const container = {
    hidden: { opacity: 0 },
    visible: (i = 1) => ({
      opacity: 1,
      transition: { staggerChildren: 0.12, delayChildren: 0.04 * i },
    }),
  };

  const child = {
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        type: "spring",
        damping: 12,
        stiffness: 100,
      },
    },
    hidden: {
      opacity: 0,
      x: 20,
      transition: {
        type: "spring",
        damping: 12,
        stiffness: 100,
      },
    },
  };
  // useEffect(() => {
  //   if (viewAll) setData(languages);
  //   else setData(languages.slice(1, 5));
  // }, [viewAll]);
  return (
    <Portal>
      <motion.div
        className={styles.wrapper}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ ease: "easeIn", duration: 0.5 }}
      >
        <motion.div
          style={{ overflow: "hidden", display: "flex", paddingLeft: "22px" }}
          variants={container}
          initial="hidden"
          animate="visible"
        >
          {"Hello.".split("").map((word) => (
            <motion.span variants={child} className={styles.title} key={word}>
              {word}
            </motion.span>
          ))}
        </motion.div>
        <motion.div className={styles.block}>
          <LangItem
            changeLang={changeLang}
            code={data[0].code}
            country={data[0].country}
            Icon={data[0].Icon}
          />
          <div className={styles.line__short} />
          <LangItem
            changeLang={changeLang}
            code={data[1].code}
            country={data[1].country}
            Icon={data[1].Icon}
          />
        </motion.div>
        {/* <button type="button" onClick={() => setViewAll((prev) => !prev)}> */}
        {/*   <span>{viewAll ? "Hide optional" : "View all"}</span> */}
        {/*   <FaChevronRight /> */}
        {/* </button> */}
      </motion.div>
    </Portal>
  );
};

export default LanguageSelection;
