export const notification = [
  {
    id: 1,
    title: "Stuttgart, Germany  Tony Soap",
    description: "Shared your event on a social network",
    type: "general",
    isNew: false,
    date: new Date(2011, 0, 1, 0, 0),
  },
  {
    id: 2,
    title: "Stuttgart, Germany  Tony Soap",
    description: "Shared your event on a social network",
    type: "overview",
    isNew: false,
    date: new Date(2011, 0, 1, 0, 0),
  },
  {
    id: 3,
    title: "Stuttgart, Germany  Tony Soap",
    description: "Shared your event on a social network",
    type: "overview",
    isNew: true,
    date: new Date(2011, 0, 1, 0, 0),
  },
  {
    id: 4,
    title: "Stuttgart, Germany  Tony Soap",
    description: "Shared your event on a social network",
    type: "overview",
    isNew: true,
    date: new Date(2022, 8, 3, 12, 54),
  },
  {
    id: 5,
    title: "Stuttgart, Germany  Tony Soap",
    description: "Shared your event on a social network",
    type: "general",
    isNew: true,
    date: new Date(2022, 8, 28, 8, 2),
  },
  {
    id: 6,
    title: "Stuttgart, Germany  Tony Soap",
    description: "Shared your event on a social network",
    type: "general",
    isNew: true,
    date: new Date(2022, 8, 1, 15, 2),
  },
];
