import { Divider } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";

import { useAuth } from "@context";
import { RoutePaths, getStyleByValidation } from "@helpers";

import CheckBox from "@components/LoginForm/components/CheckBox";
import { yupResolver } from "@hookform/resolvers/yup";
import { isEmpty } from "@lib/lodash";
import GButton from "@shared/GoogleButton/GButton";
import Spinner from "@shared/Spinner";

import styles from "./LoginForm.module.scss";
import InputField from "./components/InputField";

const schema = yup
  .object({
    Email: yup
      .string()
      .email("Incorrect email address")
      .required("Email field is empty"),
    Password: yup
      .string()
      .required("Password field is empty")
      .min(8, "The minimum password length must be more than 8 characters"),
  })
  .required(
    "Fields are not filled or entered incorrectly. Correct or re-enter please.",
  );

interface IFields {
  Email: string;
  Password: string;
  remember: boolean;
  system: string;
}

const NLoginForm: React.FC = () => {
  const { signIn, hasUser, loading, error, user, googleAuth } = useAuth();
  const [remember, setRemember] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isValid },
    setValue,
    watch,
    trigger,
  } = useForm<IFields>({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      remember,
    },
  });

  const onSubmit: SubmitHandler<IFields> = useCallback((data) => {
    signIn(data.Email, data.Password);
  }, []);

  const onResetPassword = useCallback(() => {
    navigate(RoutePaths.RESET, { replace: false });
  }, []);

  useEffect(() => {
    if (hasUser()) navigate(RoutePaths.PROFILE, { replace: true });
  }, [user]);

  useEffect(() => {
    if (!isEmpty(error)) {
      switch (error?.detail) {
        case "invalid credentials":
          setError("system", {
            type: "manual",
            message:
              "The email or password entered is incorrect and does not match.",
          });
          break;
        default:
          setError("system", {
            type: "manual",
            message: "Sorry, an error occurred, please try again.",
          });
      }
    }
  }, [error]);

  useEffect(() => {
    const subscription = watch((_, { name }) => {
      if (name !== "system") trigger("system").catch(null);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  if (loading)
    return <Spinner title="Please wait, your data is being processed." />;

  return (
    <div>
      {/* eslint-disable-next-line */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputField
          label="Email"
          placeholder="Enter your email address"
          {...register("Email")}
          required
          style={getStyleByValidation(errors.Email, isValid)}
        />
        {errors.Email?.message && (
          <div className={styles.error}>{errors.Email?.message}</div>
        )}

        <InputField
          label="Password"
          type="password"
          placeholder="Enter your password"
          {...register("Password")}
          required
          resetPassword
          onReset={onResetPassword}
          style={getStyleByValidation(errors.Password, isValid)}
        />
        {errors.Password?.message && (
          <div className={styles.error}>{errors.Password?.message}</div>
        )}

        <CheckBox
          label="Remember this device"
          checked={remember}
          onClick={() => {
            setRemember((prevState) => {
              setValue("remember", !prevState);
              return !prevState;
            });
          }}
        />

        {errors.system?.message && (
          <div className={styles.error}>{errors.system?.message}</div>
        )}

        <button className={styles.button__sign_in} type="submit">
          Login
        </button>
      </form>
      <Divider>OR</Divider>
      <GButton onSuccess={googleAuth} />
      <div className={styles.footer}>
        <span>Don’t you have an account? </span>
        <Link className={styles.link} to={RoutePaths.REGISTRATION}>
          Sign up
        </Link>
      </div>
    </div>
  );
};

export default React.memo(NLoginForm);
