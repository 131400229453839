import React from "react";

import { IIcon } from "@types";

const EditIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.54 19.52C4.93 19.52 4.36 19.31 3.95 18.92C3.43 18.43 3.18 17.69 3.27 16.89L3.64 13.65C3.71 13.04 4.08 12.23 4.51 11.79L12.72 3.10002C14.77 0.930018 16.91 0.870019 19.08 2.92002C21.25 4.97002 21.31 7.11002 19.26 9.28002L11.05 17.97C10.63 18.42 9.85 18.84 9.24 18.94L6.02 19.49C5.85 19.5 5.7 19.52 5.54 19.52ZM15.93 2.91002C15.16 2.91002 14.49 3.39002 13.81 4.11002L5.6 12.81C5.4 13.02 5.17 13.52 5.13 13.81L4.76 17.05C4.72 17.38 4.8 17.65 4.98 17.82C5.16 17.99 5.43 18.05 5.76 18L8.98 17.45C9.27 17.4 9.75 17.14 9.95 16.93L18.16 8.24002C19.4 6.92002 19.85 5.70002 18.04 4.00002C17.24 3.23002 16.55 2.91002 15.93 2.91002Z"
        fill="currentColor"
      />
      <path
        d="M17.34 10.95C17.32 10.95 17.29 10.95 17.27 10.95C14.15 10.64 11.64 8.27002 11.16 5.17002C11.1 4.76002 11.38 4.38002 11.79 4.31002C12.2 4.25002 12.58 4.53002 12.65 4.94002C13.03 7.36002 14.99 9.22002 17.43 9.46002C17.84 9.50002 18.14 9.87002 18.1 10.28C18.05 10.66 17.72 10.95 17.34 10.95Z"
        fill="currentColor"
      />
      <path
        d="M21 22.75H3C2.59 22.75 2.25 22.41 2.25 22C2.25 21.59 2.59 21.25 3 21.25H21C21.41 21.25 21.75 21.59 21.75 22C21.75 22.41 21.41 22.75 21 22.75Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default EditIcon;
