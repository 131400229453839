import { AxiosError } from "axios";

import { SessionApi } from "@api";
import { setApiPath } from "@helpers";
import { IResponse, IUserEvent } from "@types";

export default class UserApi {
  static query = setApiPath("/api/v1/user");

  static async getUserById(id: string | number): Promise<IUserEvent> {
    return new Promise<IUserEvent>((resolve, reject) => {
      SessionApi.api
        .get<IUserEvent>(this.query(`/getUser?ID=${id}`))
        .then((response) => resolve(response.data))
        .catch((error: AxiosError) => reject(error.response?.data));
    });
  }

  static async uploadLogo(file: FormData): Promise<IResponse> {
    return new Promise<IResponse>((resolve, reject) => {
      SessionApi.api
        .post(this.query("/UploadIcon"), file)
        .then((response) => resolve(response.data as IResponse))
        .catch((error: AxiosError) => reject(error.response?.data));
    });
  }
}
