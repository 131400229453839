import React from "react";

import { BrazilianIcon, CanadaIcon, UsaIcon } from "@assets";
import { IIcon } from "@types";

import eSports1 from "@assets/images/marketplace/eSports1.png";
import eSports2 from "@assets/images/marketplace/eSports2.png";
import eSports3 from "@assets/images/marketplace/eSports3.png";
import eSports4 from "@assets/images/marketplace/eSports4.png";
import judo1 from "@assets/images/marketplace/judo1.png";
import judo2 from "@assets/images/marketplace/judo2.png";
import judo3 from "@assets/images/marketplace/judo3.png";
import judo4 from "@assets/images/marketplace/judo4.png";
import popularSport1 from "@assets/images/marketplace/popularSport1.png";
import popularSport2 from "@assets/images/marketplace/popularSport2.png";
import popularSport3 from "@assets/images/marketplace/popularSport3.png";
import popularSport4 from "@assets/images/marketplace/popularSport4.png";
import popularSport5 from "@assets/images/marketplace/popularSport5.png";
import popularSport6 from "@assets/images/marketplace/popularSport6.png";
import popularSport7 from "@assets/images/marketplace/popularSport7.png";
import popularSport8 from "@assets/images/marketplace/popularSport8.png";

export interface IEventCard {
  id: number;
  image: string;
  date: string;
  country: string;
  price: number;
  title: string;
  ImageCountry: React.FC<IIcon>;
  type?: string;
}

export const popularSportEvents: IEventCard[] = [
  {
    id: 1,
    image: popularSport1,
    date: "01 - 02 Oct 2022",
    country: "Canada",
    ImageCountry: CanadaIcon,
    price: 650,
    title: "IIHF Ice Hockey World Championship 2022",
  },
  {
    id: 2,
    image: popularSport2,
    date: "10 - 12 Oct 2022",
    country: "USA",
    ImageCountry: UsaIcon,
    price: 369,
    title: "NFL - Playoffs",
  },
  {
    id: 3,
    image: popularSport3,
    date: "01 - 02 Oct 2022",
    country: "Brazilian",
    ImageCountry: BrazilianIcon,
    price: 569,
    title: "Brazilian Jiu-Jitsu: Tatami Chess",
  },
  {
    id: 4,
    image: popularSport4,
    date: "01 - 02 Oct 2022",
    country: "Canada",
    ImageCountry: CanadaIcon,
    price: 650,
    title: "Federation of Equestrian Sports of the Republic of Kazakhstan ",
  },
  {
    id: 5,
    image: popularSport5,
    date: "01 - 02 Oct 2022",
    country: "Canada",
    ImageCountry: CanadaIcon,
    price: 789,
    title: "Synchronized swimming Olympics.",
  },
  {
    id: 6,
    image: popularSport6,
    date: "10 - 12 Oct 2022",
    country: "USA",
    ImageCountry: UsaIcon,
    price: 234,
    title: "NFL - Playoffs",
  },
  {
    id: 7,
    image: popularSport7,
    date: "25 Oct 2022",
    country: "Brazilian",
    ImageCountry: BrazilianIcon,
    price: 667,
    title: "On a mission to dominate every format possible",
  },
  {
    id: 8,
    image: popularSport8,
    date: "08 -12 Oct 2022",
    country: "Canada",
    ImageCountry: CanadaIcon,
    price: 320,
    title: "Fencing competitions with saber fights",
  },
  {
    id: 9,
    image: popularSport6,
    date: "10 - 12 Oct 2022",
    country: "USA",
    ImageCountry: UsaIcon,
    price: 234,
    title: "NFL - Playoffs",
  },
  {
    id: 10,
    image: popularSport6,
    date: "10 - 12 Oct 2022",
    country: "USA",
    ImageCountry: UsaIcon,
    price: 234,
    title: "NFL - Playoffs",
  },
  {
    id: 11,
    image: popularSport6,
    date: "10 - 12 Oct 2022",
    country: "USA",
    ImageCountry: UsaIcon,
    price: 234,
    title: "NFL - Playoffs",
  },
  {
    id: 12,
    image: popularSport6,
    date: "10 - 12 Oct 2022",
    country: "USA",
    ImageCountry: UsaIcon,
    price: 234,
    title: "NFL - Playoffs",
  },
  {
    id: 13,
    image: popularSport6,
    date: "10 - 12 Oct 2022",
    country: "USA",
    ImageCountry: UsaIcon,
    price: 234,
    title: "NFL - Playoffs",
  },
  {
    id: 14,
    image: popularSport6,
    date: "10 - 12 Oct 2022",
    country: "USA",
    ImageCountry: UsaIcon,
    price: 234,
    title: "NFL - Playoffs",
  },
  {
    id: 15,
    image: popularSport6,
    date: "10 - 12 Oct 2022",
    country: "USA",
    ImageCountry: UsaIcon,
    price: 234,
    title: "NFL - Playoffs",
  },
  {
    id: 16,
    image: popularSport6,
    date: "10 - 12 Oct 2022",
    country: "USA",
    ImageCountry: UsaIcon,
    price: 234,
    title: "NFL - Playoffs",
  },
];

export const allESportsEvents: IEventCard[] = [
  {
    id: 1,
    image: eSports1,
    date: "01 - 02 Oct 2022",
    country: "Canada",
    ImageCountry: CanadaIcon,
    price: 569,
    title: "The InternationalTl vs Worlds, Dota 2",
  },
  {
    id: 2,
    image: eSports2,
    date: "10 - 12 Oct 2022",
    country: "USA",
    ImageCountry: UsaIcon,
    price: 369,
    title: "Dot Esports Carzzy on MSI",
  },
  {
    id: 3,
    image: eSports3,
    date: "01 - 02 Oct 2022",
    country: "Brazilian",
    ImageCountry: BrazilianIcon,
    price: 569,
    title: "Intel Extreme Masters XV — World Championship",
  },
  {
    id: 4,
    image: eSports4,
    date: "01 - 02 Oct 2022",
    country: "Canada",
    ImageCountry: CanadaIcon,
    price: 479,
    title: "Intel Extreme Masters: Katowice.",
  },
  {
    id: 5,
    image: eSports2,
    date: "10 - 12 Oct 2022",
    country: "USA",
    ImageCountry: UsaIcon,
    price: 369,
    title: "Dot Esports Carzzy on MSI",
  },
  {
    id: 6,
    image: eSports2,
    date: "10 - 12 Oct 2022",
    country: "USA",
    ImageCountry: UsaIcon,
    price: 369,
    title: "Dot Esports Carzzy on MSI",
  },
  {
    id: 7,
    image: eSports2,
    date: "10 - 12 Oct 2022",
    country: "USA",
    ImageCountry: UsaIcon,
    price: 369,
    title: "Dot Esports Carzzy on MSI",
  },
  {
    id: 8,
    image: eSports2,
    date: "10 - 12 Oct 2022",
    country: "USA",
    ImageCountry: UsaIcon,
    price: 369,
    title: "Dot Esports Carzzy on MSI",
  },
];

export const allJudoSportsEvents: IEventCard[] = [
  {
    id: 1,
    image: judo1,
    date: "01 - 02 Oct 2022",
    country: "Canada",
    ImageCountry: CanadaIcon,
    price: 569,
    title: "Judo grand slam tournament",
  },
  {
    id: 2,
    image: judo2,
    date: "10 - 12 Oct 2022",
    country: "USA",
    ImageCountry: UsaIcon,
    price: 369,
    title: "First Grand Prix 2022",
  },
  {
    id: 3,
    image: judo3,
    date: "01 - 02 Oct 2022",
    country: "Brazilian",
    ImageCountry: BrazilianIcon,
    price: 569,
    title: "Grand slam judo",
  },
  {
    id: 4,
    image: judo4,
    date: "01 - 02 Oct 2022",
    country: "Canada",
    ImageCountry: CanadaIcon,
    price: 479,
    title: "Intel Extreme Masters: Katowice.",
  },
  {
    id: 5,
    image: judo2,
    date: "10 - 12 Oct 2022",
    country: "USA",
    ImageCountry: UsaIcon,
    price: 369,
    title: "First Grand Prix 2022",
  },
  {
    id: 6,
    image: judo2,
    date: "10 - 12 Oct 2022",
    country: "USA",
    ImageCountry: UsaIcon,
    price: 369,
    title: "First Grand Prix 2022",
  },
  {
    id: 7,
    image: judo2,
    date: "10 - 12 Oct 2022",
    country: "USA",
    ImageCountry: UsaIcon,
    price: 369,
    title: "First Grand Prix 2022",
  },
  {
    id: 8,
    image: judo2,
    date: "10 - 12 Oct 2022",
    country: "USA",
    ImageCountry: UsaIcon,
    price: 369,
    title: "First Grand Prix 2022",
  },
];
