import React, { FC } from "react";

import { GoogleLogin } from "@react-oauth/google";

interface IGoogleButton {
  onSuccess: (res: string) => void;
}

const GButton: FC<IGoogleButton> = ({ onSuccess }) => {
  return (
    <GoogleLogin
      onSuccess={(res) => onSuccess(res.credential || "")}
      auto_select={false}
      width="400px"
      locale="en-US"
    />
  );
};

export default React.memo(GButton);
