import React, { FC } from "react";
import { Link } from "react-router-dom";

import { useAuth } from "@context";

import styles from "./PageHeader.module.scss";

export interface PageHeaderProps {
  title: string;
  buttonTitle: string;
  to: string;
}

const PageHeader: FC<PageHeaderProps> = ({ title, to, buttonTitle }) => {
  const { setProfile, user } = useAuth();

  const handleSubmit = (): void | undefined => {
    setProfile(user);
  };

  return (
    <div className={styles.header}>
      <h2 className={styles.title}>{title}</h2>
      <Link to={to} className={styles.link} onClick={handleSubmit}>
        {buttonTitle}
      </Link>
    </div>
  );
};

export default PageHeader;
