import { v4 as uuidv4 } from "uuid";

export const fileToUrl = (file: File): Promise<string> => {
  const fReader = new FileReader();
  const formData = new FormData();
  const fileBuff = new Blob([file]);

  fReader.readAsDataURL(file);
  formData.append("formFile", fileBuff);

  return new Promise<string>((resolve, reject) => {
    fReader.onload = (event) => {
      resolve(event.target?.result as string);
    };
    fReader.onerror = (event): void => {
      reject(event.target?.result);
    };
  });
};

export const urlToFormData = (url: string): FormData => {
  if (!url.includes("base64"))
    throw new Error("Url is not valid for Data Uri as Base64");

  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  const byteString = atob(url.split(",")[1]);

  // separate out the mime component
  const mimeString = url.split(",")[0].split(":")[1].split(";")[0];

  let fileType = mimeString.split("/")[1];
  fileType = fileType === "jpeg" ? "jpg" : fileType;

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  const ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  const formData = new FormData();
  const blob = new Blob([ab], { type: mimeString });
  formData.append("formFile", blob, `${uuidv4()}.${fileType}`);
  return formData;
};
