import cn from "classnames";
import React, { HTMLProps } from "react";

import { useId } from "@hooks";

import styles from "../LoginForm.module.scss";

interface IInputField extends HTMLProps<HTMLInputElement> {
  label: string;
}

type ResetTypes =
  | {
      resetPassword?: false;
      onReset?: never;
    }
  | {
      resetPassword: true;
      onReset?: () => void;
    };

const InputField = React.forwardRef<HTMLInputElement, IInputField & ResetTypes>(
  ({ label, className, required, resetPassword, onReset, ...rest }, ref) => {
    const id = useId("login-");
    return (
      <div className={styles.input_wraper}>
        <label htmlFor={id}>
          <div className={styles.title_wraper}>
            <div className={styles.input_title}>
              <span>{label}</span>
              {required && <span>*</span>}
            </div>
            {resetPassword && <span onClick={onReset}>Forgot Password?</span>}
          </div>
          <input
            id={id}
            ref={ref}
            className={cn(styles.input_form, className)}
            required={required}
            {...rest}
          />
        </label>
      </div>
    );
  },
);

InputField.displayName = "InputField";

export default React.memo(InputField);
