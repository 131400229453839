export const urlToForm = async (url: string): Promise<FormData> => {
  const bytes = await fetch(url);
  const blob = await bytes.blob();
  const fileName = url.split("/").pop();

  const data = new FormData();
  data.append("formFile", blob, fileName);

  return data;
};
