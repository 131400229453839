import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "@context";
import { RoutePaths } from "@helpers";
import { useQueryParams } from "@hooks";

import { Registration } from "@components/LoginForm";
import { LoginLayout } from "@components/layouts";
import { isEmpty } from "@lib/lodash";
import Spinner from "@shared/Spinner";

const Message: FC<{ loading: boolean; message: string }> = ({
  loading,
  message,
}) => {
  if (loading)
    return <Spinner title="Please wait, your data is being processed." />;
  return (
    <span
      style={{ display: "flex", justifyContent: "center", textAlign: "center" }}
    >
      {message}
    </span>
  );
};

const RegistrationPage: FC = () => {
  const params = useQueryParams();
  const { finishRegistration, error, loading } = useAuth();
  const navigate = useNavigate();
  const [message, setMessage] = useState<string>("");

  useEffect(() => {
    if (params?.token) {
      setMessage(() => "Checking of correctness of the link");
      finishRegistration(params.token as string, () => {
        setMessage(() => "Successful registered");
        navigate(RoutePaths.LOGIN, { replace: true });
      });
    }
  }, [params]);

  useEffect(() => {
    if (params?.token && !isEmpty(error)) {
      setMessage(() => "Broken token, please check correctness of the link");
      setTimeout(() => {
        navigate(RoutePaths.REGISTRATION, { replace: true });
      }, 3000);
    }
  }, [error, params]);

  return (
    <LoginLayout>
      {params?.token ? (
        <Message message={message} loading={loading} />
      ) : (
        <Registration />
      )}
    </LoginLayout>
  );
};

export default React.memo(RegistrationPage);
