import React, { useState } from "react";

import { useLanguage } from "@context";
import { dateAdapter, getPhone } from "@helpers";
import { Input, InputPhone, InputSelect, InputText, SportKinds } from "@shared";

import DatePickerField from "@components/DatePickerField";
import GMapModal from "@components/GMap/GMapModal";
import UploadImage from "@components/UploadImage";
import { useProfile } from "@pages/ProfilePage/context";

import styles from "./ProfileGeneralInformation.module.scss";

const ProfileGeneralInformation: React.FC = () => {
  const { text } = useLanguage();
  const [isOpenMap, setIsOpenMap] = useState(false);
  const {
    user,
    handleInputChange,
    handleChangeUserDate,
    handleSelectChange,
    handleChangeDate,
    handleOrganizationImagesChange,
    validator,
  } = useProfile();

  const PhoneNumber = getPhone(user?.Organization?.PhoneNumber || "+372");

  const handlePhoneChange = (alias: string, value: string): void => {
    handleChangeUserDate("Organization.PhoneNumber", [alias, value].join(""));
  };

  const handleAddressChange = (address: string): void => {
    handleChangeUserDate("Organization.Location", address);
  };

  return (
    <div className={styles.container}>
      <h4 className={styles.title}>{text[20]}</h4>

      <UploadImage
        updateImages={handleOrganizationImagesChange}
        // defaultLogo={logos?.logo}
        // defaultBackground={logos?.background}
      />

      <div className={styles.subtitle}>
        **This background image will be used for the website design.
      </div>

      <h4 className={styles.title}>Organisation information</h4>

      <div className={styles.group}>
        <Input
          label={text[4]}
          value={user?.Organization?.Name ?? ""}
          name="Organization.Name"
          onChange={handleInputChange}
          error={validator("OrganizationName", user?.Organization?.Name)}
        />

        <InputSelect
          label="Kind of sports*"
          name="Organization.SportType"
          value={user?.Organization?.SportType ?? ""}
          onChange={handleSelectChange}
          error={validator(
            "OrganizationSportType",
            user?.Organization?.SportType,
          )}
        >
          <option value="" disabled selected hidden>
            Choose kind of sport
          </option>

          {SportKinds.map((opt) => (
            <option value={opt} key={opt}>
              {opt}
            </option>
          ))}
        </InputSelect>

        <Input
          label={text[13]}
          name="Organization.WebSite"
          value={user?.Organization?.WebSite ?? ""}
          onChange={handleSelectChange}
        />
        <DatePickerField
          label="Date of establishment"
          value={
            dateAdapter.toClient(user?.Organization?.DateEstablishment) || null
          }
          name="Organization.DateEstablishment"
          onChange={handleChangeDate}
          customClassName={styles.dateField}
        />

        <Input
          label="Official email"
          value={user?.Organization?.Email ?? ""}
          name="Organization.Email"
          onChange={handleInputChange}
          error={validator("Email", user?.Organization?.Email)}
        />

        <InputPhone
          label="Official phone number"
          inputValue={PhoneNumber[1]}
          selectValue={PhoneNumber[0]}
          onPhoneChange={handlePhoneChange}
        >
          <option defaultValue="" disabled>
            {" "}
          </option>
          <option value="+7">+7</option>
          <option value="+372">+372</option>
        </InputPhone>
      </div>

      <div className={styles.group}>
        <Input
          label="Location of the organisation*"
          onClick={() => setIsOpenMap(true)}
          placeholder="Choose the location of the organisation*"
          value={user?.Organization?.Location ?? ""}
          className={styles.fullWidth}
        />

        <InputText
          label="Description of the organisation*"
          placeholder="Add description about the organisation "
          value={user?.Organization?.Description ?? ""}
          name="Organization.Description"
          onChange={handleInputChange}
          className={styles.description}
          error={validator(
            "OrganizationDescription",
            user?.Organization?.Description,
          )}
        />
      </div>

      {isOpenMap && (
        <GMapModal
          address={user?.Organization?.Location || ""}
          onClose={() => setIsOpenMap(false)}
          setAddress={handleAddressChange}
        />
      )}
    </div>
  );
};

export default ProfileGeneralInformation;
