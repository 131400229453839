import React, { useEffect, useState } from "react";

import { EventApi } from "@api";
import { UsaIcon } from "@assets";
import {
  LandingNavbar,
  MarketPlaceFooter,
  MarketPlaceHeader,
  MarketPlaceStreamers,
  MarketplaceSliderSecond,
} from "@components";
import { useLanguage } from "@context";
import { transformDate } from "@helpers";
import { IResponseMarketplace } from "@types";

import judo4 from "@assets/images/marketplace/judo4.png";
import MarketplaceSlider from "@components/MarketPlaceComponents/MarketPlaceHeaderSliderFirst/MarketplaceSlider/MarketplaceSlider";
import SliderElementSecond from "@components/MarketPlaceComponents/MarketplaceSliderSecond/SliderElementSecond/SliderElementSecond";
import { Footer } from "@components/OrganizersLandingComponents";

import styles from "./MarketPlacePage.module.scss";
import { IEventCard, allESportsEvents } from "./Marketplace.data";

const MarketPlacePage: React.FC = () => {
  const blockWidth = window.screen.width - 16;
  const heightSlider = 383;
  const { text } = useLanguage();
  const isMobile: boolean = window.screen.width < 500;
  const visibleItems1 = 8;
  const visibleItems2 = 4;

  const [events, setEvents] = useState<IEventCard[]>([]);

  useEffect(() => {
    if (!events.length) {
      EventApi.getEvents()
        .then((response: IResponseMarketplace) => {
          setEvents(
            response.events.map((event) => {
              return {
                id: event.ID,
                date: transformDate(event.startTime, event.endTime),
                title: event.eventName,
                image: event.backgroundUrl || event.logoUrl || judo4,
                country: event.location?.split(" ").pop(),
                price: event.price,
                ImageCountry: UsaIcon,
                type: event.type,
              } as IEventCard;
            }),
          );
        })
        .catch(null);
    }
  }, []);

  return (
    <div className={styles.Landing}>
      <LandingNavbar />
      <MarketPlaceHeader cards={events.reverse()} />
      {isMobile ? (
        <div className={styles.wrapper_slider1}>
          <MarketplaceSlider
            title={text[273]}
            data={events.reverse()}
            Element={SliderElementSecond}
            blockWidth={blockWidth}
            heightSlider={heightSlider}
          />
        </div>
      ) : (
        <MarketplaceSliderSecond
          title={text[273]}
          visibleItems={visibleItems1}
          sportEvents={events.reverse()}
          Element={SliderElementSecond}
        />
      )}
      <MarketPlaceStreamers />
      {isMobile ? (
        <div className={styles.wrapper_slider2}>
          <MarketplaceSlider
            title={text[288]}
            data={allESportsEvents}
            Element={SliderElementSecond}
            blockWidth={blockWidth}
            heightSlider={heightSlider}
          />
        </div>
      ) : (
        <MarketplaceSliderSecond
          title={text[288]}
          visibleItems={visibleItems2}
          sportEvents={allESportsEvents}
          Element={SliderElementSecond}
        />
      )}
      {isMobile ? (
        <div className={styles.wrapper_slider2}>
          <MarketplaceSlider
            title={text[289]}
            data={events.filter((e) => e.type === "judo").reverse()}
            Element={SliderElementSecond}
            blockWidth={blockWidth}
            heightSlider={heightSlider}
          />
        </div>
      ) : (
        <MarketplaceSliderSecond
          title={text[289]}
          visibleItems={visibleItems2}
          sportEvents={events.filter((e) => e.type === "judo").reverse()}
          Element={SliderElementSecond}
        />
      )}

      <MarketPlaceFooter />
      <Footer style_={{ marginTop: 0 }} />
    </div>
  );
};

export default MarketPlacePage;
