import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";

import { ArrowRightIcon, CurveLeftIcon, VerifyIcon } from "@assets";
import { useLanguage } from "@context";

import { IEventCard } from "@pages/MarketPlacePage/Marketplace.data";

import ModalFilter from "../ModalFilter";

import {
  DynamicInterface,
  blockWidth,
  dynamicButton,
  dynamicButtonBlockWidth,
  staticBlockWidth,
  staticButton,
} from "./MarketPlaceHeader.data";
import styles from "./MarketPlaceHeader.module.scss";
import MarketplaceSlider from "./MarketplaceSlider/MarketplaceSlider";
import SliderElement from "./MarketplaceSlider/SliderElement/SliderElement";

interface IMPHeader {
  cards: IEventCard[];
}

const MarketPlaceHeader: React.FC<IMPHeader> = ({ cards }) => {
  const isMobil: boolean = window.screen.width < 500;
  const elementShift = isMobil ? null : document.getElementById("desktop");
  const { text } = useLanguage();
  const [staticData, setStaticData] = useState(staticButton);
  const [dynamicData, setDynamicData] =
    useState<DynamicInterface[]>(dynamicButton);

  const [shiftWidth, setShiftWidth] = useState<number>(0);
  const [showFilters, setShowFilters] = useState(false);

  const fullWidth = isMobil
    ? (dynamicData.length + staticData.length) * dynamicButtonBlockWidth
    : dynamicData.length * dynamicButtonBlockWidth;
  const screenWidthDynamic = window.screen.width - staticBlockWidth;

  const onScroll = (): void => {
    const component = document.getElementById("footer");
    component?.scrollIntoView();
  };

  const onStaticClick = (id: number) => {
    return () => {
      setStaticData((prev) =>
        prev.map((item) =>
          item.id === id
            ? { ...item, is_active: !item.is_active }
            : { ...item, is_active: false },
        ),
      );
      if (id === 3) setShowFilters(true);
    };
  };

  const onDynamicClick = (title: string) => {
    return () => {
      setDynamicData((prev): DynamicInterface[] =>
        prev.map((item) =>
          item.title === title ? { ...item, is_active: !item.is_active } : item,
        ),
      );
    };
  };

  const onNext = (): void => {
    if (fullWidth + shiftWidth > screenWidthDynamic)
      setShiftWidth((prev) => prev - dynamicButtonBlockWidth);
  };

  const onPrev = (): void => {
    if (shiftWidth < 0)
      setShiftWidth((prev: number) => prev + dynamicButtonBlockWidth);
  };

  useEffect(() => {
    if (elementShift) elementShift.style.left = `${shiftWidth}px`;
  }, [shiftWidth]);

  const onClose = (): void => setShowFilters(false);
  return (
    <>
      <div className={styles.header}>
        <h5 className={styles.verify}>
          <VerifyIcon />
          <span>{text[269]}</span>
        </h5>
        <motion.h1
          className={styles.title}
          initial={{ opacity: 0, scale: 1.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1, ease: "easeInOut" }}
        >
          <CurveLeftIcon className={styles.curve_left} />
          <ArrowRightIcon className={styles.arrow_right} />
          {text[270]}
        </motion.h1>
        <div className={styles.description}>{text[271]}</div>
        <button type="button" onClick={onScroll} className={styles.button}>
          {text[272]}
        </button>
        <div className={styles.sport_events}>
          <MarketplaceSlider
            title={text[273]}
            data={cards}
            blockWidth={blockWidth}
            Element={SliderElement}
            // heightSlider={heightSlider}
          />
        </div>
      </div>
      <div className={styles.button_block}>
        <div className={styles.hover_right} onMouseEnter={onNext} />
        <div className={styles.hover_left} onMouseEnter={onPrev} />
        <div className={styles.button_block_wrapper} id="mobil">
          <div className={styles.static}>
            {staticData.map((item) => (
              <button
                type="button"
                key={item.id}
                className={
                  item.is_active ? styles.active : styles.inactive_static
                }
                onClick={onStaticClick(item.id)}
              >
                {item.title}
              </button>
            ))}
          </div>
          <div className={styles.dynamic}>
            <div className={styles.shift_block} id="desktop">
              {dynamicData.map((item) => (
                <button
                  type="button"
                  key={item.title}
                  className={
                    item.is_active ? styles.active : styles.inactive_dynamic
                  }
                  onClick={onDynamicClick(item.title)}
                >
                  {item.title}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
      {showFilters && <ModalFilter onClose={onClose} />}
    </>
  );
};

export default MarketPlaceHeader;
