import classnames from "classnames";
import React, { useState } from "react";

import { useLanguage } from "@context";
import { MotionWrap } from "@hoc";

import ArrowButton from "@assets/images/ArrowButton";
import line from "@assets/images/line_work.svg";

import { Data } from "./LandingWork.data";
import styles from "./LandingWork.module.scss";

const LandingWork: React.FC = () => {
  const { text } = useLanguage();
  const [images, setImages] = useState<boolean[]>(Data.map(() => false));

  const onClick = (index: number): (() => void) => {
    return () => {
      const newSettings = Data.map((_, i) => i === index);
      setImages(() => [...newSettings]);
      setTimeout(() => {
        setImages(() => Data.map(() => false));
      }, 1500);
    };
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <div className={styles.headers}>
          <h3>{text[225]}</h3>
          <button type="button" className={styles.btn}>
            <ArrowButton />
          </button>
          <img src={line} alt="" className={styles.line} />
        </div>
        <p className="landing_subtitle" style={{ maxWidth: "384px" }}>
          {text[223]}
        </p>
      </div>
      <div className={styles.content}>
        {Data.map((row, i) => (
          <div className={styles.block} key={row.title}>
            <div>
              <h4 className={styles.blockTitle}>{row.title}</h4>
              <p className="landing_subtitle">{row.description}</p>
            </div>
            <button type="button" className={styles.btn} onClick={onClick(i)}>
              <ArrowButton />
            </button>
            <img
              src={row.image}
              alt={row.title}
              className={classnames(
                styles.fadeImage,
                images[i] ? styles.show : styles.hidden,
              )}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MotionWrap(LandingWork);
