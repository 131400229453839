import React from "react";

import { IIcon } from "@types";

const CanadaIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg
      className={className}
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_758_5725)">
        <path
          d="M18.6726 15.7239H0.327845C0.146937 15.7239 0.000244141 15.5772 0.000244141 15.3963V3.60324C0.000244141 3.42233 0.146937 3.27563 0.327845 3.27563H18.6726C18.8536 3.27563 19.0002 3.42233 19.0002 3.60324V15.3963C19.0002 15.5773 18.8535 15.7239 18.6726 15.7239Z"
          fill="#F5F5F5"
        />
        <path
          d="M14.7417 3.27588H4.25903V15.7242H14.7417V3.27588Z"
          fill="#F5F5F5"
        />
        <path
          d="M0.327845 3.27588C0.146937 3.27588 0.000244141 3.42253 0.000244141 3.60344V15.3965C0.000244141 15.5774 0.146937 15.7241 0.327845 15.7241H4.25887V3.27588H0.327845Z"
          fill="#FF4B55"
        />
        <path
          d="M18.673 3.27588H14.7419V15.7241H18.673C18.8539 15.7241 19.0006 15.5774 19.0006 15.3965V3.60344C19.0006 3.42253 18.8539 3.27588 18.673 3.27588Z"
          fill="#FF4B55"
        />
        <path
          d="M11.4884 10.9384L13.4323 9.82763L12.9863 9.60461C12.8612 9.54204 12.7896 9.40696 12.8081 9.26828L12.9532 8.18016L12.1302 8.41959C11.9484 8.47251 11.7598 8.36066 11.7189 8.17582L11.6401 7.81957L10.8673 8.72393C10.7602 8.84928 10.5561 8.75354 10.5841 8.591L10.9141 6.67215L10.4089 6.81035C10.2613 6.85072 10.1054 6.78356 10.0335 6.64855L9.50142 5.65139V5.6499L9.50105 5.65064L9.50064 5.6499V5.65139L8.96861 6.64855C8.89658 6.78352 8.74072 6.85069 8.59314 6.81035L8.08786 6.67215L8.41787 8.591C8.44581 8.75347 8.24171 8.84924 8.13458 8.72393L7.36185 7.81957L7.28303 8.17582C7.24213 8.3607 7.05347 8.47247 6.87167 8.41959L6.0487 8.18016L6.19383 9.26828C6.21235 9.40692 6.14073 9.54204 6.01563 9.60461L5.56958 9.82763L7.51348 10.9384C7.71483 11.0535 7.80308 11.2975 7.72185 11.5147L7.55567 11.9592L9.22132 11.817C9.3135 11.8091 9.39195 11.8833 9.38924 11.9758L9.33677 13.7587H9.66437L9.61186 11.9757C9.60915 11.8832 9.6876 11.809 9.77978 11.8169L11.4462 11.9592L11.28 11.5147C11.1989 11.2975 11.2871 11.0534 11.4884 10.9384Z"
          fill="#FF4B55"
        />
      </g>
      <defs>
        <clipPath id="clip0_758_5725">
          <rect width="19" height="19" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CanadaIcon;
