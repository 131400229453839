import React, { CSSProperties } from "react";

import { useInput } from "@hooks";
import { Input } from "@shared";

import { ReactComponent as Logo } from "@assets/icons/logo_footer.svg";
import { ReactComponent as Facebook } from "@assets/icons/social/facebook.svg";
import { ReactComponent as Insta } from "@assets/icons/social/insta.svg";
import { ReactComponent as Linkedin } from "@assets/icons/social/linkedin.svg";
import { ReactComponent as Twitter } from "@assets/icons/social/twitter.svg";

import styles from "./Footer.module.scss";

// declare global {
//   interface Window {
//     hbspt: any;
//   }
// }

interface IFooter {
  style_?: CSSProperties;
}

const Footer: React.FC<IFooter> = ({ style_ }) => {
  const email = useInput("");
  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.src = "//js-eu1.hsforms.net/forms/embed/v2.js";
  //   document.body.appendChild(script);
  //
  //   script.addEventListener("load", () => {
  //     if (window.hbspt) {
  //       // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
  //       window.hbspt.forms.create({
  //         formId: "34e0eadd-d9e4-42dc-9a43-c1162703eb1c",
  //         region: "eu1",
  //         portalId: "25904794",
  //         target: "#hubspotForm",
  //       });
  //     }
  //   });
  // }, []);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
  };
  return (
    <footer className={styles.footer} style={style_}>
      <div className={styles.main}>
        <div className={styles.mission}>
          <Logo width="208" height="15" />
          <div className={styles.desc}>
            Monetiseur&apos;s mission is help create great experiences, by
            bringing more money to sports.
            <div className={styles.arrow}>
              {" "}
              <span> Join us </span>
              <span className={styles.shift}>→</span>
            </div>
          </div>
          <ul className={styles.social_list}>
            <li className={styles.social_item}>
              <a
                target="_blank"
                className={styles.social_link}
                href="https://www.facebook.com/"
                rel="noreferrer"
              >
                <Facebook width="20" height="20" />
              </a>
            </li>
            <li className={styles.social_item}>
              <a
                target="_blank"
                className={styles.social_link}
                href="https://twitter.com/monetiseur_io"
                rel="noreferrer"
              >
                <Twitter width="20" height="20" />
              </a>
            </li>
            <li className={styles.social_item}>
              <a
                target="_blank"
                className={styles.social_link}
                href="https://www.linkedin.com/company/monetiseur"
                rel="noreferrer"
              >
                <Linkedin width="23" height="22" />
              </a>
            </li>
            <li className={styles.social_item}>
              <a
                target="_blank"
                className={styles.social_link}
                href="https://instagram.com/monetiseur.io?igshid=YmMyMTA2M2Y="
                rel="noreferrer"
              >
                <Insta width="20" height="20" />
              </a>
            </li>
          </ul>
        </div>
        <form className={styles.sign} id="hubspotForm" onSubmit={handleSubmit}>
          <h3>Don’t miss out. Sign up for our newsletter!</h3>
          <Input
            label=""
            placeholder="Email address"
            type="email"
            className={styles.input_email}
            required
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
            {...email}
          />
          <button type="submit" className={styles.subscibe}>
            Subscibe
          </button>
        </form>
      </div>
      <div className={styles.bottom_side}>
        <a href="/">Terms</a>
        <a href="/">Privacy</a>
        <a href="mailto: info@monetiseur.io">Contact Us</a>
      </div>
    </footer>
  );
};

export default Footer;
