import { IEvent, IMedias, IProduct } from "@types";

// State interfaces

export type EventType = Omit<
  IEvent,
  "ID" | "authorID" | "images" | "products" | "logoUrl" | "backgroundUrl"
>;
export type ProductType = Omit<IProduct, "ID" | "eventID">;

export interface IEventImage {
  logo: string;
  background: string;
}

export interface IEventState {
  event: EventType | null;
  gallery: string[];
  rawImages: IEventImage | null;
  rawProducts: ProductType[];
  socials: IMedias | null;
}

// Action interfaces

export enum EventActionEnum {
  CHANGE_EVENT_DATA = "CHANGE_EVENT_DATA",
  CHANGE_EVENT_IMAGES = "CHANGE_EVENT_IMAGES",
  CHANGE_GALLEY_DATA = "CHANGE_GALLEY_DATA",
  ADD_PRODUCT = "ADD_PRODUCT",
  UPDATE_PRODUCT = "UPDATE_PRODUCT",
  DELETE_PRODUCT = "DELETE_PRODUCT",
  CHANGE_SOCIAL_MEDIAS = "CHANGE_SOCIAL_MEDIAS",
}

export interface IChangeSocialMediasAction {
  type: EventActionEnum.CHANGE_SOCIAL_MEDIAS;
  payload: IMedias;
}

export interface IChangeEventDataAction {
  type: EventActionEnum.CHANGE_EVENT_DATA;
  payload: EventType;
}

export interface IChangeEventImagesAction {
  type: EventActionEnum.CHANGE_EVENT_IMAGES;
  payload: IEventImage;
}

export interface IChangeGalleryDataAction {
  type: EventActionEnum.CHANGE_GALLEY_DATA;
  payload: string[];
}

export interface IAddProductAction {
  type: EventActionEnum.ADD_PRODUCT;
  payload: ProductType;
}

export interface IUpdateProductAction {
  type: EventActionEnum.UPDATE_PRODUCT;
  payload: ProductType;
}

export interface IDeleteProductAction {
  type: EventActionEnum.DELETE_PRODUCT;
  payload: number;
}

export type EventActionTypes =
  | IChangeEventDataAction
  | IChangeEventImagesAction
  | IChangeGalleryDataAction
  | IAddProductAction
  | IUpdateProductAction
  | IDeleteProductAction
  | IChangeSocialMediasAction;
