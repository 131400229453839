import React from "react";
import { useNavigate } from "react-router-dom";

import { useEvent, useLanguage } from "@context";

import styles from "./EventProgress.module.scss";

interface IEventProgress {
  title: string;
  isError: boolean;
  // submitRef: RefObject<HTMLInputElement>;
}

const EventProgress: React.FC<IEventProgress> = ({ title, isError }) => {
  const navigate = useNavigate();
  const { text } = useLanguage();
  const { validateEventData, error, loading, saveToMemory } = useEvent();

  const handleSubmit = (): void => {
    const rootElement = document.getElementById("eventInformation");
    const event = new Event("submit", { bubbles: true });
    rootElement?.dispatchEvent(event);
    saveToMemory();
    // console.log(validateEventData());
    if (validateEventData() && !isError) {
      saveToMemory();
      if (!loading && !error) {
        navigate("/app/toolbox-first", { replace: true });
      }
    }
  };

  return (
    <>
      <div className={styles.mobile_title}>
        <h3>{title}</h3>
        <div className={styles.step}>
          <div className={styles.item_mobile1} />
          <div className={styles.line_mobile} />
          <div className={styles.item_mobile2} />
          <div className={styles.line_mobile} />
          <div className={styles.item_mobile3} />
        </div>
      </div>
      <div className={styles.title}>
        <span>{title}</span>
        <button type="button" onClick={handleSubmit}>
          {text[39]}
        </button>
      </div>
    </>
  );
};

export default EventProgress;
