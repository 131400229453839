import React from "react";

import { useLanguage } from "@context";

import streams from "@assets/images/marketplace/streamers.png";

import styles from "./MarketPlaceStreamers.module.scss";
import StreamersSlider from "./StreamersSlider/StreamersSlider";

const MarketPlaceStreamers: React.FC = () => {
  const { text } = useLanguage();
  return (
    <div className={styles.streamers}>
      <div className={styles.left_side}>
        <h1>{text[291]}</h1>
        <p>{text[292]}</p>
        <img src={streams} alt="Streams" />
      </div>
      <div className={styles.slider}>
        <StreamersSlider />
      </div>
    </div>
  );
};

export default MarketPlaceStreamers;
