import { Col, Row } from "antd";
import React from "react";

import { useLanguage } from "@context";
import { LandingBlock, MotionWrap } from "@hoc";

import LandingEventCard from "../LandingEventCard";

import { Events } from "./LandingEvents.data";
import styles from "./LandingEvents.module.scss";

const LandingEvents: React.FC = () => {
  const { text } = useLanguage();
  return (
    <LandingBlock title={text[228]} subTitle={text[229]}>
      <div className={styles.content}>
        <Row gutter={[16, 25]}>
          {Events.map((event) => (
            <Col key={event.id} span={event.isBig ? 16 : 8}>
              <div className={styles.col}>
                <LandingEventCard {...event} />
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </LandingBlock>
  );
};

export default MotionWrap(LandingEvents);
