import React from "react";

import Basket from "@components/MarketPlaceBasket/Basket";
import PathHeader from "@shared/PathHeader";

import styles from "./BasketPage.module.scss";

const BasketPage: React.FC = () => {
  return (
    <div className={styles.section}>
      <PathHeader linkText="Checkout" />
      <Basket />
    </div>
  );
};

export default BasketPage;
