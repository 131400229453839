import { motion } from "framer-motion";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import TemplesModal from "@components/EventPageComponents/TemplesModal";

import styles from "./ToolboxFirstCard.module.scss";

export interface ToolboxFirstCardProps {
  title: string;
  subtitle: string;
  image: string;
  description: string;
  index: number;
  type: string;
}

const ToolboxFirstCard: React.FC<ToolboxFirstCardProps> = ({
  image,
  title,
  description,
  subtitle,
  index,
  type,
}) => {
  const isMobil = window.screen.width < 500;
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const OnConstructor = (): void => {
    navigate("/app/constructor", { replace: true });
  };
  return (
    <>
      <motion.div
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ delay: index * 0.3, ease: "easeIn", duration: 0.5 }}
        className={styles.card}
        onClick={isMobil ? OnConstructor : () => setIsOpen(true)}
      >
        <h5>
          {title} - {subtitle}
        </h5>
        <figure>
          <img src={image} alt="sponsors" />
          <figcaption>{description}</figcaption>
        </figure>
      </motion.div>
      {isOpen && (
        <TemplesModal type={type} setIsOpen={(flag) => setIsOpen(flag)} />
      )}
    </>
  );
};

export default ToolboxFirstCard;
