import React, { useMemo } from "react";

import { useLanguage } from "@context";

import styles from "./NumberParticipants.module.scss";
import ParticipantsRange from "./ParticipantsRange/ParticipantsRange";

interface NumberParticipantsType {
  participants: number;
  sliderValueChanged: (val: number) => void;
}

const NumberParticipants: React.FC<NumberParticipantsType> = ({
  participants,
  sliderValueChanged,
}) => {
  const { text } = useLanguage();
  const rangeProps = useMemo(
    () => ({
      min: 0,
      max: 5,
      value: participants,
      step: 1,
      onChange: (val: number): void => sliderValueChanged(val),
    }),
    [participants],
  );
  return (
    <div className={styles.block}>
      <h3>{text[285]}</h3>
      <ParticipantsRange {...rangeProps} />
    </div>
  );
};

export default NumberParticipants;
