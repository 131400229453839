import classnames from "classnames";
import React, { FC } from "react";

import { useScroll } from "@context";

import { useProfile } from "@pages/ProfilePage/context";

import { ProfileSchema } from "../../ProfilePage.schema";
import ProfileAccount from "../ProfileAccount";

import styles from "./ProfileSidebar.module.scss";

export interface ProfileSidebarProps {
  onClick: (key: number) => () => void;
}

const reverseDate = (data: string | null): string => {
  return data?.split("-").reverse().join("-") ?? "";
};

const ProfileSidebar: FC<ProfileSidebarProps> = ({ onClick }) => {
  const { clientKey } = useScroll();
  const { user } = useProfile();

  return (
    <div className={styles.sidebar}>
      <ProfileAccount
        logo={user?.IconUrl}
        firstname={user?.FirstName}
        secondname={user?.SecondName}
        mail={user?.Login}
        registration={
          user.Registration ? reverseDate(user?.Registration.split("T")[0]) : ""
        }
      />
      {ProfileSchema.map((item) => (
        <div
          className={classnames(styles.item, {
            [styles.active]: clientKey === item.key,
          })}
          key={item.key}
          onClick={onClick(item.key)}
        >
          <span className={styles.icon}>
            <item.icon />
          </span>
          <span className={styles.name}>{item.name}</span>
        </div>
      ))}
    </div>
  );
};

export default ProfileSidebar;
