import React, { FC } from "react";

import { createArray } from "@helpers";

import styles from "./ProgressBar.module.scss";

export interface ProgressBarProps {
  percents: number;
  cells?: number;
}

const ProgressBar: FC<ProgressBarProps> = ({ percents, cells = 9 }) => {
  return (
    <div
      className={styles.bar}
      style={{
        background: `linear-gradient(90deg, #51C9C2 ${percents}%, #EBEAEA 0)`,
      }}
    >
      {createArray(cells * 2 - 1).map((cell) => (
        <span key={cell} />
      ))}
    </div>
  );
};

export default ProgressBar;
