import React from "react";

const ArrowBlock: React.FC = () => {
  return (
    <svg viewBox="0 0 401 353" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.913069 351.721C75.6838 305.027 199.185 195.343 95.0215 130.163C-35.1824 48.6877 -7.51015 275.79 153.244 261.276C313.998 246.762 424.735 44.6645 395.095 0.956144"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default ArrowBlock;
