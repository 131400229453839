import React from "react";

import { IIcon } from "@types";

const OutlineLocationIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg
      className={className}
      width="17"
      height="21"
      viewBox="0 0 17 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5 11.3125C10.0533 11.3125 11.3125 10.0533 11.3125 8.5C11.3125 6.9467 10.0533 5.6875 8.5 5.6875C6.9467 5.6875 5.6875 6.9467 5.6875 8.5C5.6875 10.0533 6.9467 11.3125 8.5 11.3125Z"
        stroke="#787E91"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 1C6.51088 1 4.60322 1.79018 3.1967 3.1967C1.79018 4.60322 1 6.51088 1 8.5C1 10.2737 1.37687 11.4344 2.40625 12.7188L8.5 19.75L14.5938 12.7188C15.6231 11.4344 16 10.2737 16 8.5C16 6.51088 15.2098 4.60322 13.8033 3.1967C12.3968 1.79018 10.4891 1 8.5 1V1Z"
        stroke="#787E91"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default OutlineLocationIcon;
