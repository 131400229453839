import React from "react";

import { IIcon } from "@types";

const RocketIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.3894 1.79449C22.3808 1.75303 22.3606 1.71487 22.3312 1.68449C22.3017 1.6541 22.2642 1.63275 22.223 1.62293C19.4775 0.951675 13.1339 3.34371 9.69705 6.77917C9.08405 7.38721 8.52522 8.04752 8.02689 8.75261C6.96705 8.65886 5.90721 8.73714 5.00393 9.13089C2.45533 10.2526 1.7133 13.1795 1.50659 14.4385C1.49486 14.5075 1.49928 14.5781 1.51951 14.6451C1.53973 14.712 1.5752 14.7733 1.62313 14.8242C1.67106 14.8751 1.73015 14.9141 1.79573 14.9383C1.86132 14.9625 1.93163 14.9711 2.00112 14.9635L6.09377 14.5121C6.09669 14.8207 6.11531 15.1289 6.14955 15.4356C6.17015 15.6485 6.26468 15.8476 6.41674 15.9981L8.00158 17.5792C8.15224 17.731 8.35118 17.8255 8.56408 17.8464C8.86903 17.8805 9.17552 17.8991 9.48236 17.9021L9.0333 21.9896C9.02581 22.0591 9.03451 22.1294 9.05873 22.1949C9.08295 22.2604 9.12202 22.3195 9.17288 22.3674C9.22374 22.4153 9.28501 22.4508 9.35188 22.471C9.41875 22.4913 9.4894 22.4958 9.5583 22.4842C10.815 22.2826 13.747 21.5406 14.8622 18.992C15.256 18.0887 15.3366 17.034 15.2456 15.9793C15.9524 15.481 16.6145 14.922 17.2242 14.3087C20.6719 10.8784 23.0503 4.67636 22.3894 1.79449ZM13.7845 10.2165C13.4697 9.9019 13.2553 9.50099 13.1684 9.0645C13.0814 8.628 13.1259 8.17553 13.2961 7.76431C13.4664 7.35309 13.7548 7.0016 14.1248 6.7543C14.4949 6.507 14.9299 6.375 15.375 6.375C15.8201 6.375 16.2552 6.507 16.6252 6.7543C16.9952 7.0016 17.2836 7.35309 17.4539 7.76431C17.6241 8.17553 17.6686 8.628 17.5817 9.0645C17.4947 9.50099 17.2803 9.9019 16.9655 10.2165C16.7567 10.4256 16.5088 10.5914 16.2359 10.7046C15.963 10.8177 15.6705 10.876 15.375 10.876C15.0796 10.876 14.787 10.8177 14.5141 10.7046C14.2412 10.5914 13.9933 10.4256 13.7845 10.2165Z"
        fill="currentColor"
      />
      <path
        d="M7.89375 18.7244C7.63687 18.9818 7.22484 19.0821 6.72891 19.1679C5.61469 19.3577 4.63078 18.3949 4.83094 17.2685C4.90734 16.8415 5.13328 16.2429 5.27391 16.1022C5.30464 16.0721 5.3251 16.033 5.33238 15.9906C5.33965 15.9482 5.33337 15.9045 5.31443 15.8659C5.29549 15.8272 5.26485 15.7955 5.22686 15.7752C5.18887 15.755 5.14547 15.7472 5.10281 15.753C4.47973 15.8293 3.90006 16.1117 3.45609 16.5555C2.35406 17.6585 2.25 21.7511 2.25 21.7511C2.25 21.7511 6.345 21.6471 7.44703 20.5441C7.89218 20.1005 8.17503 19.52 8.25 18.896C8.26734 18.7001 8.02875 18.5833 7.89375 18.7244Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default RocketIcon;
