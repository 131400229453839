import classnames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { IoIosArrowBack } from "react-icons/io";
import { NavLink } from "react-router-dom";

import { useAuth, useLanguage } from "@context";

import { LogoutIcon } from "@assets/icons/kit";
import SidebarAccount from "@components/Sidebar/SidebarAccount";

import { MenuItems } from "./Sidebar.data";
import styles from "./Sidebar.module.scss";

interface ISidebar {
  onSidebar: () => void;
}

const Sidebar: React.FC<ISidebar> = ({ onSidebar }) => {
  // const isMobil = window.screen.width < 500;
  // const motionX = isMobil ? -250 : 0;
  // const motionOpacity = isMobil ? 0 : 1;
  const { text } = useLanguage();
  const { logout } = useAuth();
  // const { removeFromMemory } = useEvent();

  // const handleLogout = (): void => {
  //   logout();
  //   removeFromMemory();
  // };

  return (
    <AnimatePresence>
      <motion.div
        className={styles.sidebar}
        // initial={{ x: motionX, opacity: motionOpacity }}
        // animate={{ x: 0, opacity: 1 }}
        // exit={{ x: motionX, opacity: motionOpacity }}
        // transition={{ duration: 0.85, delayChildren: 0.85, ease: "easeOut" }}
      >
        <div className={styles.header}>
          <div className={styles.arrow_title} onClick={onSidebar}>
            <IoIosArrowBack />
            <span>Profile Settings</span>
          </div>
          <button type="button">Done</button>
        </div>
        <div className={styles.account}>
          <SidebarAccount />
        </div>

        <div className={styles.sidebar_link}>
          {MenuItems.map((group) => (
            <div className={styles.group} key={group.title}>
              {group.title && <h4 className={styles.title}> {group.title}</h4>}

              {group.links.map((link) => (
                <NavLink
                  to={link.path}
                  className={({ isActive }) =>
                    isActive
                      ? classnames(styles.menu_item, styles.active)
                      : styles.menu_item
                  }
                  key={link.name}
                  onClick={onSidebar}
                >
                  <link.icon />
                  <span className={styles.menu_item_title}>{link.name}</span>
                </NavLink>
              ))}
            </div>
          ))}
        </div>

        <div>
          {/* <Link to="/app" className={styles.upgrade}> */}
          {/*   <RocketIcon /> */}
          {/*   {text[108]} */}
          {/* </Link> */}
          <button type="button" className={styles.logout} onClick={logout}>
            <LogoutIcon />
            {text[117]}
          </button>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default Sidebar;
