import React from "react";

import { IIcon } from "@types";

const ArrowLeftEventIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg
      className={className}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.24998 17.4173L9.54248 16.1248L5.34415 11.9173H20.1666V10.084H5.34415L9.55165 5.87648L8.24998 4.58398L1.83331 11.0007L8.24998 17.4173Z"
        fill="#333333"
      />
    </svg>
  );
};

export default ArrowLeftEventIcon;
