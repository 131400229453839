import React from "react";
import { useLocation } from "react-router-dom";

import { errorReducer } from "./errorContext.reducer";
import { ErrorActionEnum, IErrorState } from "./errorContext.types";

const defaultState: IErrorState = {
  hasError: false,
  message: null,
};

interface IErrorProvider extends IErrorState {
  setError: (message: string) => void;
  clearError: () => void;
}

const ErrorContext = React.createContext<IErrorProvider>({} as IErrorProvider);

export const ErrorProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  // Variables
  const location = useLocation();
  const [state, dispatch] = React.useReducer(errorReducer, defaultState);

  // Functions
  const setError = (message: string): void => {
    dispatch({
      type: ErrorActionEnum.SET_ERROR,
      payload: message,
    });
  };

  const clearError = (): void => {
    dispatch({
      type: ErrorActionEnum.CLEAR_ERROR,
    });
  };

  // Effects
  React.useEffect(() => {
    clearError();
  }, [location.pathname]);

  // Provider
  const value = React.useMemo(
    () => ({
      ...state,
      setError,
      clearError,
    }),
    [state],
  );

  return (
    <ErrorContext.Provider value={value}>{children}</ErrorContext.Provider>
  );
};

export const useError = (): IErrorProvider => React.useContext(ErrorContext);
