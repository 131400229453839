import {
  FacebookIcon,
  InstagramIcon,
  TiktokIcon,
  TwitchIcon,
  TwitterIcon,
  YoutubeIcon,
} from "@assets/icons/kit";

export const media = [
  { type: 1, icon: FacebookIcon, label: "Facebook" },
  { type: 2, icon: InstagramIcon, label: "Instagram" },
  { type: 3, icon: TwitterIcon, label: "Twitter" },
  { type: 4, icon: TiktokIcon, label: "TikTok" },
  { type: 5, icon: YoutubeIcon, label: "YouTube" },
  { type: 6, icon: TwitchIcon, label: "Twitch" },
];

export const mediaMap = new Map(media.map((m) => [m.type, m]));
