export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLE_MAP_API;
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

export enum RoutePaths {
  LANDING = "/",
  MARKETPLACE = "/marketplace",
  DEFAULT = "/default",
  MARKETPLACE_DETAILS = "/marketplace/:eventID",
  TEMP = "/basket",
  BASKET = "/marketplace/basket",
  // Auth paths
  LOGIN = "/auth/login",
  REGISTRATION = "/auth/register",
  RESET = "/auth/reset",
  // Monetiseur paths
  MONETISEUR = "/app",
  PROFILE = "/app/profile",
  EVENT = "/app/events",
  EVENT_POSITION = "/app/event-position",
  TOOLBOX_FIRST = "/app/toolbox-first",
  EVENT_DETAILS = "/app/event-details",
  EVENT_CALENDAR = "/app/event-calendar",
  CONSTRUCTOR = "/app/constructor",
  GLOBAL_SETTING = "/app/settings",
  NOTIFICATION = "/app/notification",
  PRICING = "/app/pricing",
  HELP = "/app/help",
}
