import sportImage1 from "@assets/images/marketplace/footbol.png";
import sportImage3 from "@assets/images/marketplace/karate.jpg";
import sportImage2 from "@assets/images/marketplace/tenis.png";

interface sportEventsInterface {
  id: number;
  image: string;
  date: string;
  stars: number;
  price: number;
  title: string;
  kindOfSport: string;
}

export const sportEvents: sportEventsInterface[] = [
  {
    id: 1,
    image: sportImage1,
    date: "01-02 Oct 2022",
    stars: 4.3,
    price: 650,
    title: "Dong'an Lake Sports Park Stadium",
    kindOfSport: "Football",
  },
  {
    id: 2,
    image: sportImage2,
    date: "21 Oct - 1 Nov 2022",
    stars: 5,
    price: 345,
    title: "School championship in futsal",
    kindOfSport: "Tennis",
  },
  {
    id: 3,
    image: sportImage3,
    date: "24.04.2022",
    stars: 4.3,
    price: 650,
    title: "Kyokushin Karate World Championship",
    kindOfSport: "Judo",
  },
  {
    id: 4,
    image: sportImage1,
    date: "01- 02 Oct 2022",
    stars: 4.3,
    price: 650,
    title: "Dong'an Lake Sports Park Stadium",
    kindOfSport: "Volleyball",
  },
  {
    id: 5,
    image: sportImage2,
    date: "24.04.2022",
    stars: 5,
    price: 345,
    title: "School championship in futsal",
    kindOfSport: "Сricket",
  },
  {
    id: 6,
    image: sportImage3,
    date: "24.04.2022",
    stars: 4.3,
    price: 650,
    title: "Kyokushin Karate World Championship",
    kindOfSport: "Karate",
  },
];

export const staticButton = [
  {
    id: 1,
    title: "See All",
    is_active: true,
  },
  {
    id: 2,
    title: "Dates",
    is_active: false,
  },
  {
    id: 3,
    title: "More filters",
    is_active: false,
  },
];

export interface DynamicInterface {
  title: string;
  is_active: boolean;
}

const dynamic = sportEvents.reduce(
  (accumulator: string[], current: sportEventsInterface) => {
    if (!accumulator.includes(current.kindOfSport))
      accumulator.push(current.kindOfSport);
    return accumulator;
  },
  [],
);

export const dynamicButton: DynamicInterface[] = dynamic.map((item) => {
  return { title: item, is_active: false };
});

export const blockWidth =
  window.screen.width > 500 ? 632 : window.screen.width - 16;
export const heightSlider = 400;
export const dynamicButtonBlockWidth = window.screen.width > 500 ? 195 : 112;
export const staticBlockWidth = window.screen.width > 500 ? 591 : 0;
