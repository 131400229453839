import React from "react";

import { BrazilianIcon, EstoniaIcon, UsaIcon } from "@assets/icons/marketplace";
import {
  judo1,
  judo2,
  popularSport1,
  popularSport2,
  popularSport4,
  popularSport5,
} from "@assets/images/marketplace";

export const menuItems = [
  {
    id: 0,
    title: "All",
    isSelect: true,
  },
  {
    id: 1,
    title: "Reporting",
    isSelect: false,
  },
  {
    id: 2,
    title: "No reporting",
    isSelect: false,
  },
  {
    id: 3,
    title: "On hold",
    isSelect: false,
  },
  {
    id: 4,
    title: "Completed",
    isSelect: false,
  },
  {
    id: 5,
    title: "Active",
    isSelect: false,
  },
];

export interface ICalendarCards {
  id: number;
  state: string;
  colour: string;
  image: string;
  CountryIcon: React.FC;
  country: string;
  dateEvent: string;
  eventName: string;
  position: number;
  allPosition: number;
  coast: number;
  inlineStyle?: React.CSSProperties;
}

export const Cards = [
  {
    id: 0,
    state: "Reporting",
    colour: "#51C9C2",
    image: judo1,
    CountryIcon: BrazilianIcon,
    country: "Brazilian",
    dateEvent: "04 - 07 Oct 2022",
    eventName: "Jiu-Jitsu: Tatami Chess",
    position: 21,
    allPosition: 21,
    coast: 900,
  },
  {
    id: 1,
    state: "Completed",
    colour: "#F0BB00",
    image: popularSport1,
    CountryIcon: UsaIcon,
    country: "Usa",
    dateEvent: "01 - 02 Oct 2022",
    eventName: "Dong’an Lake Sports Park Stadium",
    position: 10,
    allPosition: 30,
    coast: 900,
  },
  {
    id: 2,
    state: "Reporting",
    colour: "#51C9C2",
    image: popularSport2,
    CountryIcon: BrazilianIcon,
    country: "Brazilian",
    dateEvent: "04 - 07 Oct 2022",
    eventName: "Jiu-Jitsu: Tatami Chess",
    position: 21,
    allPosition: 21,
    coast: 900,
  },
  {
    id: 3,
    state: "Reporting",
    colour: "#51C9C2",
    image: judo2,
    CountryIcon: EstoniaIcon,
    country: "Estonia",
    dateEvent: "04 - 07 Oct 2022",
    eventName: "Jiu-Jitsu: Tatami Chess",
    position: 21,
    allPosition: 21,
    coast: 900,
  },
  {
    id: 4,
    state: "Completed",
    colour: "#F0BB00",
    image: popularSport4,
    CountryIcon: EstoniaIcon,
    country: "EstoniaI",
    dateEvent: "01 - 02 Oct 2022",
    eventName: "Dong’an Lake Sports Park Stadium",
    position: 10,
    allPosition: 30,
    coast: 900,
  },
  {
    id: 5,
    state: "Reporting",
    colour: "#51C9C2",
    image: popularSport5,
    CountryIcon: UsaIcon,
    country: "Usa",
    dateEvent: "04 - 07 Oct 2022",
    eventName: "Jiu-Jitsu: Tatami Chess",
    position: 21,
    allPosition: 21,
    coast: 900,
  },
];
