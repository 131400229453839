import image1 from "@assets/images/sport_image2.jpg";
import image2 from "@assets/images/sport_image3.jpg";
import logo1 from "@assets/images/sport_logo1.png";
import logo from "@assets/images/sport_logo2.png";
import logo2 from "@assets/images/sport_logo.png";
import image from "@assets/images/yankees.jpeg";

export const Cards = [
  {
    id: 1,
    image,
    logo,
    title: "New York Yankees (MLB)",
    date: "24.04.2022",
    city: "NEW YORK",
  },
  {
    id: 2,
    image: image1,
    logo: logo1,
    title: "New York Knicks (NBA)",
    date: "23.04.2022",
    city: "NEW YORK",
  },
  {
    id: 3,
    image: image2,
    logo: logo2,
    title: "Dallas cowboys (NFL)",
    date: "22.04.2022",
    city: "NEW YORK",
  },
  {
    id: 4,
    image,
    logo,
    title: "New York Yankees (MLB)",
    date: "21.04.2022",
    city: "NEW YORK",
  },
  {
    id: 5,
    image,
    logo,
    title: "New York Yankees (MLB)",
    date: "20.04.2022",
    city: "NEW YORK",
  },
];
