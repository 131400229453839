import React from "react";

import { useLanguage } from "@context";

// import CurveFooterIcon from "../../../assets/icons/marketplace/CurveFooterIcon";
import styles from "./MarketPlaceFooter.module.scss";

const MarketPlaceFooter: React.FC = () => {
  const { text } = useLanguage();
  return (
    <div className={styles.prev_footer}>
      {/* <button type="button">{text[293]}</button> */}
      <div className={styles.subscribe}>
        {/* <CurveFooterIcon /> */}
        <div className={styles.filter_img} />
        <h2>{text[294]}</h2>
        <div className={styles.description}>{text[295]}</div>
        <button type="button">{text[272]}</button>
      </div>
    </div>
  );
};

export default MarketPlaceFooter;
