import React, { ReactNode } from "react";

import styles from "./ActionMenu.module.scss";

export interface ActionsMenuProps {
  children: ReactNode;
}

const ActionMenu: React.FC<ActionsMenuProps> = ({ children }) => {
  return <div className={styles.wrap}>{children}</div>;
};

export default ActionMenu;
