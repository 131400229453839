import React, { createContext, useContext, useMemo, useState } from "react";

interface ScrollContextType {
  onScroll: (
    references: number[],
  ) => (event: React.UIEvent<HTMLElement>) => void;
  clientTopPosition: number;
  clientKey: number;
}

const ScrollContext = createContext<ScrollContextType>({} as ScrollContextType);

export const ScrollProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const [clientTopPosition, setClientTopPosition] = useState<number>(0);
  const [clientKey, setClientKey] = useState<number>(0);

  const onScroll = (
    references: number[],
  ): ((event: React.UIEvent<HTMLElement>) => void) => {
    return (event: React.UIEvent<HTMLElement>) => {
      const { scrollTop } = event.currentTarget;
      setClientTopPosition(scrollTop);
      references.forEach((reference, index) => {
        if (reference - 500 <= scrollTop) setClientKey(index);
        if (scrollTop === 0) setClientKey(0);
      });
    };
  };

  const value = useMemo(
    () => ({
      onScroll,
      clientTopPosition,
      clientKey,
    }),
    [clientTopPosition, clientKey],
  );

  return (
    <ScrollContext.Provider value={value}>{children}</ScrollContext.Provider>
  );
};

export default function useScroll(): ScrollContextType {
  return useContext(ScrollContext);
}
