import { RadioChangeEvent } from "antd/lib/radio";
import React, { useCallback, useState } from "react";

import { useLanguage } from "@context";

import { CloseOutlineIcon } from "@assets/icons/kit";

import Portal from "../../PortalModal/Portal";

import AvailableLanguages from "./AvailableLanguages";
import styles from "./ModalFilter.module.scss";
import NumberParticipants from "./NumberParticipants";
import PriceRange from "./PriceRange";

interface ModalFilterType {
  onClose: () => void;
}

const radioDataBasic = ["English", "French", "Deutsch", "Japanese"];
const radioDataAdditional = ["Australia", "Belgium", "Azerbaijan", "Brunei"];

const ModalFilter: React.FC<ModalFilterType> = ({ onClose }) => {
  const { text } = useLanguage();
  const [isShow, setShow] = useState(false);

  const [range, setRange] = useState<[number, number]>([10, 100]);
  const [radioBasic, setRadioBasic] = React.useState<number>(1);
  const [radioAdditional, setRadioAdditional] = React.useState<number>(1);

  const [participants, setParticipants] = useState<number>(1);

  const sliderValueChanged = useCallback((val) => {
    setParticipants(val as number);
  }, []);

  const setStartRange = useCallback((val: number) => {
    setRange((prev) => [val / 12, prev[1]]);
  }, []);

  const setEndRange = useCallback((val: number) => {
    setRange((prev) => [prev[0], val / 12]);
  }, []);

  const onRange: ((value: [number, number]) => void) | undefined = (value) => {
    setRange(value);
  };

  const onChangeBasic = (event: RadioChangeEvent): void => {
    setRadioBasic(event.target.value as number);
  };

  const onChangeAdditional = (event: RadioChangeEvent): void => {
    setRadioAdditional(event.target.value as number);
  };

  return (
    <Portal>
      <div className={styles.wrapper} onClick={onClose}>
        <div
          className={styles.modal}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <div className={styles.header}>
            <span>{text[274]}</span>
            <CloseOutlineIcon onClose={onClose} />
          </div>
          <form className={styles.content}>
            <PriceRange
              range={range}
              onRange={onRange}
              setStartRange={setStartRange}
              setEndRange={setEndRange}
            />
            <AvailableLanguages
              title={text[275]}
              radioData={radioDataBasic}
              value={radioBasic}
              onChange={onChangeBasic}
            />
            {isShow && (
              <>
                <div className={styles.line} />
                <AvailableLanguages
                  title={text[276]}
                  radioData={radioDataAdditional}
                  value={radioAdditional}
                  onChange={onChangeAdditional}
                />
                <div className={styles.see_more}>{text[277]}</div>
                <NumberParticipants
                  participants={participants}
                  sliderValueChanged={sliderValueChanged}
                />
              </>
            )}
          </form>
          <div className={styles.footer}>
            <span onClick={() => setShow((prev) => !prev)}>
              {!isShow ? text[278] : text[279]}
            </span>
            <button type="button">{text[280]}</button>
          </div>
        </div>
      </div>
    </Portal>
  );
};

export default ModalFilter;
