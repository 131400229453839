import React, { FC } from "react";

import { PageHeaderWrap } from "@components";

import { useProfile } from "@pages/ProfilePage/context";

const PageHeader: FC = () => {
  const { handleSave, loading } = useProfile();

  return (
    <PageHeaderWrap
      title="Profile information"
      to="/app/profile"
      buttonTitle="Save changes"
      onClick={handleSave}
      loading={loading}
    />
  );
};

export default PageHeader;
