import React, { useMemo, useState } from "react";

import { useEvent } from "@context";
import { adapterProduct } from "@helpers";
import { useProducts } from "@hooks";
import { IDProduct } from "@types";

import ControlTemples from "./ControlTemples/ControlTemples";
import DataTemples from "./DataTemples/DataTemples";
import MainTemples from "./MainTemples/MainTemples";
import ProceedTemples from "./ProceedTemples/ProceedTemples";
import styles from "./TemplesMobilView.module.scss";

const TemplesMobilView: React.FC = () => {
  const { products } = useProducts("hybrid");
  const { rawProducts } = useEvent();
  const positions = useMemo(
    () => adapterProduct(products, rawProducts),
    [products, rawProducts],
  );
  const [target, setTarget] = useState<IDProduct>({} as IDProduct);

  const p = useMemo(
    () => ({
      len: rawProducts.length,
      cost: rawProducts.reduce((acc, el) => {
        return acc + el.price;
      }, 0),
    }),
    [rawProducts],
  );

  const onTargetPosition = (item: IDProduct): void => {
    setTarget(item);
  };
  return (
    <div className={styles.temples}>
      <MainTemples target={target} />
      <ControlTemples
        onTargetPosition={onTargetPosition}
        targetId={target.mid || 1}
        products={positions}
      />
      <DataTemples target={target} totalCost={p.cost} />
      <ProceedTemples totalCost={p.cost} />
    </div>
  );
};

export default TemplesMobilView;
