import React, { CSSProperties, FC } from "react";

import styles from "./ProfileContainer.module.scss";

export interface ProfileContainerProps {
  ref?: React.LegacyRef<HTMLDivElement>;
  customClass?: string;
  customStyles?: CSSProperties;
}

const ProfileContainer: FC<ProfileContainerProps> = ({
  ref,
  children,
  customClass,
  customStyles,
}) => {
  return (
    <div
      ref={ref}
      className={`${styles.container} ${customClass || ""}`}
      style={customStyles}
    >
      {children}
    </div>
  );
};

export default ProfileContainer;
