import React from "react";
import ReactDOM from "react-dom";

const modalRoot = document.getElementById("modal-root") as HTMLElement;

type PortalProps = {
  children: React.ReactNode;
};

export default class Portal_ extends React.Component<PortalProps> {
  el: HTMLDivElement = document.createElement("div");

  componentDidMount(): void {
    modalRoot.appendChild(this.el);
  }

  componentWillUnmount(): void {
    modalRoot.removeChild(this.el);
  }

  render(): JSX.Element {
    const { children } = this.props;
    return ReactDOM.createPortal(children, this.el) as unknown as JSX.Element;
  }
}
