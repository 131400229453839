import React from "react";

import { IIcon } from "@types";

const TotalCostIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1179_17362)">
        <path
          d="M18.3334 4.31602L8.82502 13.8327L5.29169 10.2993L6.46669 9.12435L8.82502 11.4827L17.1584 3.14935L18.3334 4.31602ZM10 16.666C6.32502 16.666 3.33335 13.6743 3.33335 9.99935C3.33335 6.32435 6.32502 3.33268 10 3.33268C11.3084 3.33268 12.5334 3.71602 13.5667 4.37435L14.775 3.16602C13.4167 2.22435 11.775 1.66602 10 1.66602C5.40002 1.66602 1.66669 5.39935 1.66669 9.99935C1.66669 14.5993 5.40002 18.3327 10 18.3327C11.4417 18.3327 12.8 17.966 13.9834 17.316L12.7334 16.066C11.9 16.4494 10.975 16.666 10 16.666ZM15.8334 12.4993H13.3334V14.166H15.8334V16.666H17.5V14.166H20V12.4993H17.5V9.99935H15.8334V12.4993Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1179_17362">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TotalCostIcon;
