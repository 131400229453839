import React from "react";

import { useEvent } from "@context";
import { IEvent } from "@types";

import { HouseTropicsIcon } from "@assets/icons/event";
import { hybridIcon, singleEventIcon } from "@assets/icons/event/details";

import styles from "./PhotoGallery.module.scss";

interface IPhotoGallery {
  mEvent?: IEvent | null;
}

const PhotoGallery: React.FC<IPhotoGallery> = ({ mEvent }) => {
  const { event, gallery } = useEvent();
  return (
    <div className={styles.content}>
      <div className={styles.workcation}>
        <div className={styles.circle}>
          <HouseTropicsIcon />
        </div>
        <div className={styles.right_side}>
          <h2>Workcation</h2>
          <div className={styles.text}>
            <div>event title:</div>
            <h3>{mEvent?.eventName || event?.eventName}</h3>
          </div>

          <div className={styles.view}>
            <div className={styles.item}>
              <div>
                <img src={hybridIcon} alt="hybrid" />
              </div>
              <span>{mEvent?.format || event?.format}</span>
            </div>
            <div className={styles.item}>
              <div>
                <img src={singleEventIcon} alt="single event" />
              </div>
              <span>{mEvent?.type || event?.type}</span>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.view_mobil}>
        <div className={styles.item}>
          <div>
            <img src={hybridIcon} alt="hybrid" />
          </div>
          <span>{mEvent?.format || event?.format}</span>
        </div>
        <div className={styles.item}>
          <div>
            <img src={singleEventIcon} alt="single event" />
          </div>
          <span>{mEvent?.type || event?.type}</span>
        </div>
      </div>
      <div className={styles.photos}>
        <h2>Photo gallery of the event</h2>
        <div className={styles.wrapper}>
          <img
            src={mEvent?.images[0] || gallery[0]}
            className={styles.basic}
            alt="sport event"
          />
          {gallery.length > 4 && (
            <>
              <div className={styles.second}>
                {gallery.slice(1, 3).map((img) => (
                  <img src={img} key={img} alt="sport event" />
                ))}
              </div>
              <div className={styles.second}>
                {gallery.slice(3, 5).map((img) => (
                  <img src={img} key={img} alt="sport event" />
                ))}
              </div>
            </>
          )}
          {mEvent?.images && mEvent?.images.length > 4 && (
            <>
              <div className={styles.second}>
                {mEvent?.images.slice(1, 3).map((img) => (
                  <img src={img} key={img} alt="sport event" />
                ))}
              </div>
              <div className={styles.second}>
                {mEvent?.images.slice(3, 5).map((img) => (
                  <img src={img} key={img} alt="sport event" />
                ))}
              </div>
            </>
          )}
        </div>
      </div>
      <button type="button" className={styles.show_all}>
        show 10 photos
      </button>
    </div>
  );
};

export default PhotoGallery;
