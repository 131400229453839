import React, { FC } from "react";

import { SpinnerIcon } from "@assets";

import styles from "./index.module.scss";

interface ISpinnerProps {
  title: string;
}

const Spinner: FC<ISpinnerProps> = ({ title }) => {
  return (
    <div className={styles.spinner}>
      <h5>{title}</h5>
      <img src={SpinnerIcon} alt="spinner" />
    </div>
  );
};

export default React.memo(Spinner);
