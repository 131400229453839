import React from "react";

import { useLanguage } from "@context";
import { MotionWrap } from "@hoc";
import { IEvent } from "@types";

import {
  DribbbleEventIcon,
  FacebookEventIcon,
  GitHubEventIcon,
  HouseTropicsIcon,
  InstagramEventIcon,
  WorkcationIcon,
} from "@assets/icons/event";
import { ArrowLeftIcon, TwitterIcon } from "@assets/icons/kit";

import styles from "./EventsOrganizer.module.scss";

const EventsOrganizer: React.FC<{ mEvent?: IEvent | null }> = ({ mEvent }) => {
  const { text } = useLanguage();
  return (
    <div className={styles.organizer}>
      <div className={styles.wrapper}>
        <div className={styles.left}>
          <h4>{text[201]}:</h4>
          <div className={styles.workcation}>
            <div className={styles.ellipse}>
              <HouseTropicsIcon />
            </div>
            <WorkcationIcon className={styles.svg_label} />
          </div>
          <p>{text[246]}</p>
          <a href="/">
            <span>{text[247]}</span>
            <ArrowLeftIcon className={styles.arrow} />
          </a>
          <div className={styles.social}>
            <FacebookEventIcon />
            <InstagramEventIcon />
            <TwitterIcon className={styles.twitter} />
            <GitHubEventIcon />
            <DribbbleEventIcon />
          </div>
        </div>

        <div className={styles.right}>
          {/* <h2>{text[245]}</h2> */}
          <h2>{mEvent?.eventName}</h2>
          <div className={styles.text}>
            <div className={styles.truncate_text}>{mEvent?.description}</div>
          </div>
          <div className={styles.statistics}>
            <div>
              <span className={styles.name}>{text[248]}</span>
              <span className={styles.value}>2021</span>
            </div>
            <div>
              <span className={styles.name}>{text[249]}</span>
              <span className={styles.value}>5</span>
            </div>
            <div>
              <span className={styles.name}>{text[250]}</span>
              <span className={styles.value}>521</span>
            </div>
            <div>
              <span className={styles.name}>{text[251]}</span>
              <span className={styles.value}>$25M</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MotionWrap(EventsOrganizer);
