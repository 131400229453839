import React, { FC, useEffect, useState } from "react";

import { CloseOutlineIcon } from "@assets/icons/kit";
import { Portal } from "@components/PortalModal";

import AutoComplete from "./AutoComplete";
import GMap from "./GMap";
import styles from "./gmap.module.scss";

interface IGMapModal {
  onClose: () => void;
  setAddress: (_address: string) => void;
  address?: string;
}

const calcZoom = (address: string): number => {
  const sep = address.split(",");
  switch (sep.length) {
    case 0:
      return 10;
    case 1:
      return 3;
    case 2:
      return 10;
    case 3:
      return 10;
    case 4:
      return 15;
    default:
      return 18;
  }
};

const GMapModal: FC<IGMapModal> = ({ onClose, address, setAddress }) => {
  const [location, setLocation] = useState<string>(address || "");
  const [zoom, setZoom] = useState(10);

  const handleSubmit = (): void => {
    setAddress(location);
    onClose();
  };

  useEffect(() => {
    setZoom(calcZoom(location));
  }, [location]);

  return (
    <Portal>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.search}>
            <div className={styles.navbar}>
              <div className={styles.navbar__close}>
                <CloseOutlineIcon onClose={onClose} />
              </div>

              <button type="button" onClick={handleSubmit}>
                Save
              </button>
            </div>
            <AutoComplete onSelect={setLocation} />
          </div>
          <div className={styles.map}>
            <GMap address={location} zoom={zoom} />
          </div>
        </div>

        <div className={styles.backdrop} onClick={onClose} />
      </div>
    </Portal>
  );
};

export default GMapModal;
