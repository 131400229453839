import demo from "@assets/images/demo.png";

export const Data = [
  {
    title: "Register",
    description:
      "Are you an athlete, national federation or hosting a champonship? Whoever you are, we can help",
    image: demo,
  },
  {
    title: "Profile",
    description: "Create your unique CVI and stand out.",
    image: demo,
  },
  {
    title: "Toolbox",
    description:
      "Choose which media channels work for you, or just use a preset created by professionals.",
    image: demo,
  },
];
