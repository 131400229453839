import React, { createRef } from "react";

import { LandingNavbar } from "@components";

import {
  Footer,
  Header,
  Questions,
  Sponsor,
  StartWorking,
  SystemFunctionality,
} from "@components/OrganizersLandingComponents";

const OrganizersLanding: React.FC = () => {
  const refs = new Array(2).fill(0).map(() => createRef<HTMLDivElement>());
  const onClick = (key: number) => {
    return () => {
      refs[key].current?.scrollIntoView({ behavior: "smooth" });
    };
  };
  return (
    <div>
      <LandingNavbar onClick={onClick} />
      <Header />
      <StartWorking myRef={refs[1]} />
      <SystemFunctionality myRef={refs[0]} />
      {/* <Reviews /> */}
      <Questions />
      {/* <GetStarted /> */}
      <Sponsor />
      <Footer />
    </div>
  );
};

export default OrganizersLanding;
