import classnames from "classnames";
import React, { FC, useEffect, useRef } from "react";

import { useSingleImage } from "@hooks";
import { Input, InputSelect } from "@shared";

import { isEmpty } from "@lib/lodash";

import styles from "./DynamicInput.module.scss";

interface IDynamicInput extends React.InputHTMLAttributes<HTMLElement> {
  label: string | null;
  img?: string;
  setImage?: (image: string) => void;
  className?: string;
}

interface IDynamicSelect extends React.InputHTMLAttributes<HTMLElement> {
  label: string;
  options: string[];
}

const inputTypes = {
  number: /(nr|min|max|size|average|duration|budget)/gim,
  _image: /logo/gim,
  _select: /(section|online|offline|hybrid|,)/gim,
};

const getTypeOfInput = (label: string): string => {
  let result = "";
  Object.entries(inputTypes).forEach(([key, value]) => {
    const match = label.match(value);
    if (match?.length) result = key;
  });
  return result || "text";
};

const DynamicSelect: FC<IDynamicSelect> = ({ label, options, ...rest }) => {
  return (
    <InputSelect defaultValue="" label={label} {...rest}>
      <option defaultValue="" disabled>
        Choose the option
      </option>
      {options.map((item) => (
        <option key={item} value={item.trim()}>
          {item.trim()}
        </option>
      ))}
    </InputSelect>
  );
};

const DynamicInput: FC<IDynamicInput> = ({
  label,
  img,
  setImage,
  className,
  ...rest
}) => {
  const imageRef = useRef<HTMLInputElement>(null);
  const { image, error, onClick, onChange } = useSingleImage(imageRef, img);

  useEffect(() => {
    if (!isEmpty(image) && isEmpty(error)) {
      if (setImage) setImage(image);
    }
  }, [image]);

  if (!label) return null;

  const inputType = getTypeOfInput(label);

  if (inputType === "_select") {
    let name = "";
    let options = "";
    let delimiter = ",";

    if (label.includes(":")) {
      [name, options] = label.split(":");
    } else if (label.includes("/")) {
      name = "Format";
      options = label;
      delimiter = "/";
    } else {
      name = "Size Of Banner";
      options = label;
    }
    return <DynamicSelect label={name} options={options.split(delimiter)} />;
  }

  if (inputType === "_image") {
    return (
      <>
        <input
          ref={imageRef}
          type="file"
          className={styles.image_input}
          onChange={onChange}
        />
        <button type="button" className={styles.image_button} onClick={onClick}>
          {isEmpty(image) ? "Load image" : "Change image"}
        </button>
      </>
    );
  }

  return (
    <Input
      type={inputType.startsWith("_") ? "text" : inputType}
      label={label.replace(/nr/gim, "number")}
      className={classnames(className, styles.dynamic)}
      {...rest}
      onKeyPress={
        inputType === "number"
          ? (e) => !/[0-9]|,|:/.test(e.key) && e.preventDefault()
          : undefined
      }
    />
  );
};

export default DynamicInput;
