import React from "react";

import { EstoniaIcon } from "@assets";
import { useLanguage } from "@context";

import styles from "./PhotoBlock.module.scss";

interface PhotoBlockProps {
  photos: string[];
}

const PhotoBlock: React.FC<PhotoBlockProps> = ({ photos }) => {
  const { text } = useLanguage();
  return (
    <div className={styles.block}>
      <div className={styles.first}>
        <img src={photos[0]} alt="sport event" />
        {!photos[0].includes("img") && (
          <div className={styles.overlaid}>
            <div className={styles.play}>
              <div className={styles.triangle} />
            </div>
            <div className={styles.title}>
              <h3>{text[240]} 2022 (3:23)</h3>
              <div className={styles.country}>
                <EstoniaIcon />
                <span>{text[241]}</span>
              </div>
              <div className={styles.footer}>496 {text[242]}</div>
            </div>
          </div>
        )}
      </div>
      <div className={styles.second}>
        {photos[1] && <img src={photos[1]} alt="sport event" />}
      </div>
      <div className={styles.third}>
        <div>{photos[2] && <img src={photos[2]} alt="sport event" />}</div>
        <div>{photos[3] && <img src={photos[3]} alt="sport event" />}</div>
      </div>
      <div className={styles.fourth}>
        {photos[4] && <img src={photos[4]} alt="sport event" />}
        {photos.length > 5 && (
          <div className={styles.next}>
            <div className={styles.point}>
              <div />
              <div />
              <div />
              <div />
            </div>
            <span>
              {text[243]} {photos.length} {text[244]}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default PhotoBlock;
