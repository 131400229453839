import React from "react";
import { NavLink } from "react-router-dom";

import styles from "./NotificationFilter.module.scss";

const NotificationFilter: React.FC = () => {
  const getActiveLink = ({ isActive }: { isActive: boolean }): string => {
    return isActive ? `${styles.link} ${styles.active}` : styles.link;
  };
  return (
    <>
      <div className={styles.container}>
        <ul className={styles.list}>
          <li className={styles.item}>
            <NavLink to="/app/notification/overview" className={getActiveLink}>
              Overview
            </NavLink>
          </li>
          <li className={styles.item}>
            <NavLink to="/app/notification/general" className={getActiveLink}>
              General
            </NavLink>
          </li>
        </ul>
      </div>
      <div className={styles.line} />
    </>
  );
};

export default NotificationFilter;
