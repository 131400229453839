// import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import { AnimatePresence } from "framer-motion";
import React, { CSSProperties, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { useLanguage } from "@context";
import { useHover } from "@hooks";
import { IIcon } from "@types";

import CircularIndicator from "../../CircularIndicator";

import styles from "./MarketplaceSliderSecond.module.scss";

interface sportEventsInterface {
  id: number;
  image: string;
  date: string;
  country: string;
  price: number;
  title: string;
  ImageCountry: React.FC<IIcon>;
}

interface MarketplaceSliderType {
  title: string;
  visibleItems: number;
  style?: CSSProperties;
  sportEvents: sportEventsInterface[];
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  Element: React.FC<any>;
}

const MarketplaceSliderSecond: React.FC<MarketplaceSliderType> = ({
  title,
  visibleItems,
  sportEvents,
  style,
  Element,
}) => {
  const { text } = useLanguage();
  const [content, setContent] = useState<sportEventsInterface[]>([]);
  const [shiftIndex, setShiftIndex] = useState<number>(0);
  const prev = useHover();
  const next = useHover();

  useEffect((): void => {
    setContent(sportEvents.slice(0, visibleItems));
  }, [sportEvents, visibleItems]);

  useEffect(() => {
    setContent(
      sportEvents.slice(
        shiftIndex * visibleItems,
        shiftIndex * visibleItems + visibleItems,
      ),
    );
  }, [shiftIndex, setShiftIndex, visibleItems, sportEvents]);

  const onNext = (): void => {
    if (shiftIndex < (sportEvents.length - visibleItems) / visibleItems)
      setShiftIndex((pre) => pre + 1);
  };

  const onPrev = (): void => {
    if (shiftIndex > 0) setShiftIndex((pre) => pre - 1);
  };

  return (
    <div className={styles.sport} style={style}>
      <div className={styles.title}>
        <h3>{title}</h3>
        <div className={styles.button_block}>
          <span>
            {text[290]} ({sportEvents.length})
          </span>
          <button
            type="button"
            className={styles.arrow}
            onClick={onPrev}
            onMouseEnter={prev.onEnter}
            onMouseLeave={prev.onLeave}
          >
            <CircularIndicator
              className={styles.svg_cicl}
              percentage={0}
              isLeft
              iHover={prev.isHovered}
            />
          </button>
          <button
            type="button"
            className={styles.arrow}
            onClick={onNext}
            onMouseEnter={next.onEnter}
            onMouseLeave={next.onLeave}
          >
            <CircularIndicator
              className={styles.svg_cicl}
              percentage={0}
              isLeft={false}
              iHover={next.isHovered}
            />
          </button>
        </div>
      </div>
      <AnimatePresence>
        <div className={styles.content}>
          {content.map((item) => (
            <Link to={`/marketplace/${item.id}`} key={item.id}>
              <Element {...item} visibleItems={visibleItems} />
            </Link>
          ))}
        </div>
      </AnimatePresence>
    </div>
  );
};

export default MarketplaceSliderSecond;
