import { motion } from "framer-motion";
import React from "react";

import { IIcon } from "@types";

const transition1 = { duration: 1, ease: "easeInOut", delay: 0.5 };
const transition2 = { duration: 0.5, ease: "easeInOut", delay: 1 };

const ArrowEventLandingLeft: React.FC<IIcon> = ({ className }) => {
  return (
    <svg
      className={className}
      width="332"
      height="221"
      viewBox="0 0 332 221"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M320.565 136.492C319.234 137.041 317.736 136.828 317.222 136.017C313.222 129.712 309.739 123.322 306.324 117.057C303.875 112.565 301.462 108.138 298.918 103.852C292.76 93.476 285.662 83.5833 274.978 74.4994C255.854 58.2388 230.636 45.6677 198.795 41.4229C182.371 39.2334 162.291 39.8778 143.472 44.14C124.675 48.3976 107.34 56.2097 95.9687 68.2814C90.201 74.4045 86.0015 81.3423 83.4018 88.5208C92.4208 84.4527 102.252 81.1061 112.978 78.6948C138.386 72.983 154.989 82.6265 160.871 96.105C166.73 109.531 162.359 127.532 144.714 139.563C135.223 146.033 124.872 149.037 115.171 148.961C105.545 148.886 96.8467 145.784 90.3439 140.447C79.8781 131.859 74.847 119.227 75.0872 105.936C75.1467 102.646 75.5296 99.3031 76.2385 95.9594C53.0249 108.568 35.7731 126.435 22.6309 144.986C21.9606 145.932 20.3951 146.493 19.1342 146.239C17.8734 145.985 17.3947 145.012 18.065 144.066C32.0737 124.292 51.0727 104.723 77.413 91.3857C80.0133 82.776 84.7956 74.3132 91.8052 66.8716C104.257 53.6525 123.098 45.277 142.914 40.7887C162.71 36.3051 183.682 35.6454 200.835 37.9321C233.845 42.3327 259.773 55.3332 279.282 71.9209C290.288 81.2787 297.552 91.4324 303.778 101.922C306.43 106.39 308.863 110.86 311.309 115.351C314.666 121.517 318.046 127.725 322.045 134.029C322.56 134.84 321.897 135.943 320.565 136.492ZM81.9935 93.0035C80.9111 97.0585 80.3308 101.152 80.2579 105.183C80.0278 117.916 84.8656 129.84 94.6078 137.835C100.56 142.719 108.266 145.35 116.387 145.414C124.433 145.477 133.173 143.026 141.509 137.342C157.004 126.778 161.394 110.413 155.883 97.7844C150.395 85.2084 135.498 77.109 113.523 82.049C101.99 84.6416 91.5182 88.3862 81.9935 93.0035Z"
        fill="#333333"
        stroke="#F8F5E6"
        strokeWidth="3"
        strokeLinecap="round"
        initial={{ pathLength: 0, fillOpacity: 0 }}
        animate={{ pathLength: 1, fillOpacity: 1 }}
        transition={transition1}
      />
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M325.026 124.378C326.454 124.17 327.597 124.778 327.579 125.735C327.577 125.835 327.575 125.978 327.572 126.159C327.553 127.542 327.503 131.134 327.245 134.557C327.1 136.484 326.886 138.417 326.558 139.895C326.399 140.615 326.19 141.336 325.889 141.923C325.748 142.2 325.507 142.607 325.085 142.996C324.63 143.415 323.637 144.077 322.248 144.103C320.271 144.14 318.681 143.475 317.506 142.824C316.282 142.146 315.139 141.265 314.156 140.501L314.076 140.438C313.07 139.657 312.254 139.023 311.513 138.61C311.219 138.446 311.006 138.359 310.872 138.315C309.62 138.521 308.506 138.096 308.286 137.279C308.042 136.376 308.986 135.336 310.393 134.957C311.588 134.635 312.624 134.724 313.374 134.899C314.11 135.071 314.749 135.366 315.28 135.662C316.264 136.21 317.264 136.988 318.172 137.695C318.234 137.743 318.295 137.79 318.356 137.837C319.372 138.627 320.319 139.347 321.265 139.872C321.34 139.913 321.413 139.952 321.483 139.989C321.75 138.713 321.944 136.997 322.081 135.176C322.332 131.835 322.382 128.327 322.401 126.929C322.404 126.742 322.406 126.593 322.408 126.487C322.426 125.53 323.598 124.585 325.026 124.378Z"
        fill="#333333"
        stroke="#F8F5E6"
        strokeWidth="3"
        strokeLinecap="round"
        initial={{ fillOpacity: 0 }}
        animate={{ fillOpacity: 1 }}
        transition={transition2}
      />
    </svg>
  );
};

export default ArrowEventLandingLeft;
