import React, { useState } from "react";

import { ReactComponent as Cross } from "@assets/icons/organizersLanding/questions/cross.svg";
import { ReactComponent as Plus } from "@assets/icons/organizersLanding/questions/plus.svg";

import styles from "./Questions.module.scss";

const data = [
  {
    id: "01",
    title: "How much time does it take?",
    description:
      "You can create a simple event in under 10 minutes and upload it to the marketplace for sponsors to find.",
    isOpen: false,
  },
  {
    id: "02",
    title: "What is the Toolbox?",
    description:
      "Toolbox will help you to map out all your assets, which can be Monetised, just select a position, specify price and other details and you are ready to post it on the marketplace.",
    isOpen: false,
  },
  {
    id: "03",
    title: "How to get a brands attention?",
    description:
      "Brands choose athletes with a meaningful social media following. It always helps if you have a unique offering and authentic presence.",
    isOpen: false,
  },
  {
    id: "04",
    title: "I can’t find the right position for my idea",
    description:
      "You can always use a blank position and write out the details of your offering. You can always contact us to help set up a special campaign with brands.",
    isOpen: false,
  },
  {
    id: "05",
    title: "How do I get paid?",
    description:
      "When you have completed the campaign, we will transfer the money to your account. We are working on bringing out prepayment functionality for longer term partnerships.",
    isOpen: false,
  },
];

const data2 = [
  {
    id: "01",
    title: "How do I get started?",
    description:
      "You can browse the Marketplace and send and inquiry to us to initiate negotiations. We will soon launch automatic offers and invoicing, to make the process smoother.",
    isOpen: false,
  },
  {
    id: "02",
    title:
      "I want to work with an athlete, but they don’t offer any positions I am interested in.",
    description:
      "You can always send a counter offer and specify the details and the price which suits you best.",
    isOpen: false,
  },
  {
    id: "03",
    title: "How do I measure the results of campaigns?",
    description:
      "We will provide you with analytics and data, along with a campaign report from the rights holder, to give you an accurate insight to the results.",
    isOpen: false,
  },
  {
    id: "04",
    title: "Can I use Monetiseur for larger campaigns?",
    description:
      "You can contact us to run big custom campaigns, with multiple rights holders. You will be able to do it directly on Monetiseur in 2023.",
    isOpen: false,
  },
  {
    id: "05",
    title: "What if I don’t recieve the promised services?",
    description:
      "We will refund you in the case, where rights holders have not delivered on their promised services.",
    isOpen: false,
  },
];

const Questions: React.FC = () => {
  const [questions, setQuestions] = useState(data);
  const [questions2, setQuestions2] = useState(data2);

  const [list, setList] = useState(0);

  const onOpen = (id: string): (() => void) => {
    return () => {
      setQuestions((prev) => {
        return prev.map((item) =>
          item.id === id ? { ...item, isOpen: !item.isOpen } : item,
        );
      });
    };
  };
  const onOpen1 = (id: string): (() => void) => {
    return () => {
      setQuestions2((prev) => {
        return prev.map((item) =>
          item.id === id ? { ...item, isOpen: !item.isOpen } : item,
        );
      });
    };
  };
  return (
    <div className={styles.section}>
      <div className={styles.container}>
        <p className={styles.pretitle}>Contact us for more info</p>
        <div className={styles.meta}>
          <h2 className={styles.title}>Frequently asked questions</h2>
          <div className={styles.btn_block}>
            <button
              type="button"
              className={`${styles.btn} ${list === 0 ? styles.active : ""}`}
              onClick={() => setList(0)}
            >
              For rightsholders
            </button>
            <button
              type="button"
              className={`${styles.btn} ${list === 1 ? styles.active : ""}`}
              onClick={() => setList(1)}
            >
              For brands
            </button>
          </div>
        </div>
      </div>

      <div className={styles.wrapper}>
        <ul className={styles.list}>
          {list === 0 &&
            questions.map((item) => (
              <li
                className={`${styles.item} ${item.isOpen ? styles.active : ""}`}
                key={item.id}
                onClick={onOpen(item.id)}
              >
                <div className={styles.data}>
                  <span className={styles.number}>{item.id}</span>
                  <h3
                    className={`${styles.subtitle} ${
                      item.isOpen ? styles.active : ""
                    }`}
                  >
                    {item.title}
                  </h3>
                  {item.isOpen ? (
                    <Cross className={styles.icon} />
                  ) : (
                    <Plus className={styles.icon} />
                  )}
                </div>
                {item.isOpen && (
                  <p className={styles.description}>{item.description}</p>
                )}
              </li>
            ))}
          {list === 1 &&
            questions2.map((item) => (
              <li
                className={`${styles.item} ${item.isOpen ? styles.active : ""}`}
                key={item.id}
                onClick={onOpen1(item.id)}
              >
                <div className={styles.data}>
                  <span className={styles.number}>{item.id}</span>
                  <h3
                    className={`${styles.subtitle} ${
                      item.isOpen ? styles.active : ""
                    }`}
                  >
                    {item.title}
                  </h3>
                  {item.isOpen ? (
                    <Cross className={styles.icon} />
                  ) : (
                    <Plus className={styles.icon} />
                  )}
                </div>
                {item.isOpen && (
                  <p className={styles.description}>{item.description}</p>
                )}
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default Questions;
