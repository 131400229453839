import React from "react";

import { useLanguage } from "@context";
import { IDProduct, IPositionMap } from "@types";

import { isEmpty } from "@lib/lodash";

import TemplesCard from "../TemplesCard";

import styles from "./LeftSide.module.scss";

interface LeftSideProps {
  onTargetPosition: (item: IDProduct) => void;
  targetId: number;
  products: IPositionMap;
}

const LeftSide: React.FC<LeftSideProps> = ({
  onTargetPosition,
  targetId,
  products,
}) => {
  const { text } = useLanguage();

  return (
    <div className={styles.leftside}>
      <div className={styles.left_content}>
        <h3>{text[297]}</h3>
        {!isEmpty(products) &&
          Object.entries<IDProduct[]>(products).map(([group, value]) => (
            <div key={group}>
              <h3 className={styles.title}>{group}</h3>
              <div className={styles.streaming}>
                {value.map((item) => (
                  <TemplesCard
                    onClick={onTargetPosition}
                    key={item.mid}
                    item={item}
                    className={styles.marginTop}
                    style={
                      item.mid === targetId || item.cost
                        ? { borderColor: "#51c9c2" }
                        : {}
                    }
                  />
                ))}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default React.memo(LeftSide);
