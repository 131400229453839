import React, { RefObject, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { RoutePaths } from "@helpers";

import {
  athletes,
  brands,
  events,
} from "@assets/images/organizersLanding/systemFn";

import styles from "./SystemFunctionality.module.scss";

interface ISystemFunctionality {
  myRef?: RefObject<HTMLDivElement>;
}

const SystemFunctionality: React.FC<ISystemFunctionality> = ({ myRef }) => {
  const [state, setState] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setState((prevState) => {
        if (prevState === 2) return 0;
        return prevState + 1;
      });
    }, 10000);
    return () => clearInterval(interval);
  }, [state]);

  const mobileClick = (): void => {
    setState((prevState) => {
      if (prevState === 2) return 0;
      return prevState + 1;
    });
  };

  let btnTitle = "Athletes";
  switch (state) {
    case 0:
      btnTitle = "Athletes";
      break;
    case 1:
      btnTitle = "Events";
      break;
    case 2:
      btnTitle = "Brands";
      break;
    default:
  }

  return (
    <section className={styles.section} ref={myRef}>
      <div className={styles.container}>
        <h2 className={styles.title}>System functionality</h2>
        <p className={styles.pretitle}>Unlock the full power of Monetiseur</p>
        <p className={styles.info}>
          We bring together athletes and events with brands
        </p>
        <div className={styles.btn_block}>
          <button
            className={`${styles.btn} ${state === 0 ? styles.active : ""}`}
            type="button"
            onClick={() => setState(0)}
          >
            Brands
          </button>
          <button
            className={`${styles.btn} ${state === 1 ? styles.active : ""}`}
            type="button"
            onClick={() => setState(1)}
          >
            Athletes
          </button>
          <button
            className={`${styles.btn} ${state === 2 ? styles.active : ""}`}
            type="button"
            onClick={() => setState(2)}
          >
            Events
          </button>
        </div>
        <button
          type="button"
          className={styles.mobile_btn}
          onClick={mobileClick}
        >
          {btnTitle}
        </button>
        <div className={styles.wrapper}>
          {state === 0 && (
            <>
              <div className={styles.content}>
                <h3 className={styles.minititle}>Sports ads managers</h3>
                <p className={styles.minitext}>
                  Launch your sports-based marketing campaign in a few minutes
                  and accurately track the results of your campaign.
                </p>
                <ul className={styles.list}>
                  <li className={styles.item}>
                    Create marketing campaigns with athletes and events
                  </li>
                  <li className={styles.item}>
                    Manage and scale campaigns in one system
                  </li>
                  <li className={styles.item}>
                    Track campaign results and improve them
                  </li>
                </ul>
                <a
                  className={styles.bot_btn}
                  href="mailto:business@monetiseur.io"
                  target="_blank"
                  rel="noreferrer"
                >
                  Contact sales
                </a>
              </div>
              <div className={styles.img_wrapper}>
                <img
                  className={styles.img}
                  src={brands}
                  alt="Brand and Athletes"
                />
              </div>
            </>
          )}
          {state === 1 && (
            <>
              <div className={styles.content}>
                <h3 className={styles.minititle}>Realize your potential</h3>
                <p className={styles.minitext}>
                  Map your media value and partner with brands to reach your
                  goals with the help of Toolbox.
                </p>
                <ul className={styles.list}>
                  <li className={styles.item}>
                    Create your profile and assemble your inventory
                  </li>
                  <li className={styles.item}>
                    Quickly reach brands interested in working with you
                  </li>
                  <li className={styles.item}>
                    Manage campaigns and reporting with ease
                  </li>
                </ul>
                <Link className={styles.bot_btn} to={RoutePaths.DEFAULT}>
                  Get started
                </Link>
              </div>
              <div className={styles.img_wrapper}>
                <img
                  className={styles.img}
                  src={athletes}
                  alt="Brand and Athletes"
                />
              </div>
            </>
          )}
          {state === 2 && (
            <>
              <div className={styles.content}>
                <h3 className={styles.minititle}>Open new revenue streams</h3>
                <p className={styles.minitext}>
                  Launch your events with Monetiseur and find new partners for
                  your event. Monetiseur helps you to manage your inventory and
                  reach the right partners.
                </p>
                <ul className={styles.list}>
                  <li className={styles.item}>
                    Specify event details and media inventory
                  </li>
                  <li className={styles.item}>
                    Partner with brands best suited to your event
                  </li>
                  <li className={styles.item}>
                    Track results and compile a report with ease
                  </li>
                </ul>
                <Link className={styles.bot_btn} to={RoutePaths.DEFAULT}>
                  Get started
                </Link>
              </div>
              <div className={styles.img_wrapper}>
                <img
                  className={styles.img}
                  src={events}
                  alt="Brand and Athletes"
                />
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default SystemFunctionality;
