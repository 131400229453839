import React, { useState } from "react";

import {
  ErrorField,
  EventGallery,
  EventInformation,
  EventProgress,
} from "@components";
import { useLanguage } from "@context";

import styles from "./EventPage.module.scss";

const EventPage: React.FC = () => {
  const [isError, setIsError] = useState(false);
  const [errorText, setErrorText] = useState<string>(
    "Fields are not filled or entered incorrectly. Correct or re-enter please.",
  );

  const onTextImg = (text: string): void => {
    setErrorText((prev) => {
      return prev.concat(text);
    });
  };

  const onCleaning = (): void => {
    setIsError(false);
    setErrorText(
      "Fields are not filled or entered incorrectly. Correct or re-enter please.",
    );
  };
  const { text } = useLanguage();

  return (
    <>
      <ErrorField title={errorText} isError={isError} onCleaning={onCleaning} />
      <div className={styles.event}>
        <EventProgress title={text[34]} isError={isError} />
        <EventInformation
          setIsError={setIsError}
          setErrorText={setErrorText}
          errorText={errorText}
          onTextImg={onTextImg}
          onCleaning={onCleaning}
        />
        <EventGallery onCleaning={onCleaning} />
      </div>
    </>
  );
};

export default EventPage;
