import React from "react";

import { IIcon } from "@types";

const TwitchIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M2.49021 2.13529L0 4.2694V11.9977V19.7247H3H6V21.8647V24.0059L8.49841 21.8647L10.9954 19.7247H12.9977H15L19.5007 15.8676L24 12.0117V6.00527V1.90735e-06H14.4902H4.98178L2.49021 2.13529ZM22.0018 6.4246L22.0046 11.1332L19.9995 12.8515L17.9932 14.571H15.9977H14.0023L12.2597 16.0644C11.3016 16.8855 10.513 17.5578 10.5075 17.5578C10.5007 17.5578 10.4966 16.8855 10.4966 16.0644V14.571H8.24829H6V8.14056V1.7101L13.9995 1.71245L21.9977 1.71596L22.0018 6.4246Z"
          fill="#333333"
        />
        <path
          d="M11.5007 4.72262C11.4966 4.73082 11.4952 5.88924 11.4966 7.29715L11.5007 9.85645H12.4984H13.4962V7.28543V4.71442L12.5012 4.71091C11.7126 4.70857 11.5048 4.71091 11.5007 4.72262Z"
          fill="#333333"
        />
        <path
          d="M17.002 7.28544V9.8623H17.9997H18.9974V7.28544V4.70857H17.9997H17.002V7.28544Z"
          fill="#333333"
        />
      </g>
      <defs>
        <clipPath id="clip0_5162_5899">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TwitchIcon;
