import GoogleMapReact from "google-map-react";
import React, { FC, useEffect, useState } from "react";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";

import { ICoordinates } from "@types";

import { options } from "@components/GMap/gmap.config";
import { GOOGLE_MAP_KEY } from "@helpers/constants";

interface IMap {
  zoom: number;
  address: string;
}

const containerStyle = {
  width: "100%",
  height: "100%",
};

const GMap: FC<IMap> = ({ zoom, address }) => {
  const [dCenter, setDCenter] = useState<ICoordinates>({
    lat: 51,
    lng: -0.1,
  } as ICoordinates);

  useEffect(() => {
    if (address) {
      geocodeByAddress(address)
        .then((result) => getLatLng(result[0]))
        .then((coords) => {
          setDCenter(coords);
        })
        .catch(null);
    }
  }, [address]);

  return (
    <div style={containerStyle}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: String(GOOGLE_MAP_KEY),
          libraries: ["places"],
        }}
        center={dCenter}
        zoom={zoom}
        yesIWantToUseGoogleMapApiInternals
        options={options}
      />
    </div>
  );
};

export default React.memo(GMap);
