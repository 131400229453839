import React from "react";

import { IIcon } from "@types";

const YoutubeIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg
      className={className}
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.43957 4.4298C6.68157 4.51945 4.72512 4.66183 3.81282 4.8464C2.67375 5.07843 1.74035 5.90109 1.38176 6.98742C0.98098 8.18449 0.727855 11.8548 0.838597 14.7499C0.965159 17.9034 1.21301 19.4802 1.71399 20.2343C2.30461 21.1255 3.05871 21.5632 4.32434 21.7478C6.5761 22.0747 12.3136 22.2277 17.1124 22.0958C21.0148 21.9851 22.9396 21.8163 23.757 21.5105C24.1525 21.3628 24.7378 20.9357 25.0279 20.5823C25.629 19.8493 25.84 18.9792 26.0614 16.3741C26.1511 15.3194 26.1511 11.106 26.0614 10.046C25.9349 8.51671 25.782 7.56749 25.571 6.92413C25.281 6.04874 24.4267 5.22609 23.5197 4.95714C22.8288 4.7462 21.2784 4.588 18.879 4.48253C17.239 4.4087 11.2062 4.37179 9.43957 4.4298ZM14.2173 11.3802C15.9259 12.361 17.3603 13.189 17.4077 13.2153C17.4763 13.2523 16.7486 13.69 14.2859 15.1085C12.5193 16.121 11.048 16.9595 11.0216 16.97C10.9847 16.9859 10.9689 15.6886 10.9689 13.2628C10.9689 10.1198 10.9794 9.53976 11.0427 9.56085C11.0796 9.57667 12.5087 10.3941 14.2173 11.3802Z"
        fill="#333333"
      />
    </svg>
  );
};

export default YoutubeIcon;
