import React from "react";

const VerifyIcon: React.FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.5"
        d="M14.3333 3.26676C15.2533 2.48009 16.76 2.48009 17.6933 3.26676L19.8 5.08009C20.2 5.42676 20.9467 5.70676 21.48 5.70676H23.7467C25.16 5.70676 26.32 6.86676 26.32 8.28009V10.5468C26.32 11.0668 26.6 11.8268 26.9467 12.2268L28.76 14.3334C29.5467 15.2534 29.5467 16.7601 28.76 17.6934L26.9467 19.8001C26.6 20.2001 26.32 20.9468 26.32 21.4801V23.7468C26.32 25.1601 25.16 26.3201 23.7467 26.3201H21.48C20.96 26.3201 20.2 26.6001 19.8 26.9468L17.6933 28.7601C16.7733 29.5468 15.2667 29.5468 14.3333 28.7601L12.2267 26.9468C11.8267 26.6001 11.08 26.3201 10.5467 26.3201H8.24001C6.82667 26.3201 5.66667 25.1601 5.66667 23.7468V21.4668C5.66667 20.9468 5.38667 20.2001 5.05334 19.8001L3.25334 17.6801C2.48001 16.7601 2.48001 15.2668 3.25334 14.3468L5.05334 12.2268C5.38667 11.8268 5.66667 11.0801 5.66667 10.5601V8.26676C5.66667 6.85342 6.82667 5.69342 8.24001 5.69342H10.5467C11.0667 5.69342 11.8267 5.41342 12.2267 5.06676L14.3333 3.26676Z"
        fill="#51C9C2"
      />
      <path
        d="M14.3867 20.2267C14.12 20.2267 13.8667 20.12 13.68 19.9334L10.4533 16.7067C10.0667 16.32 10.0667 15.68 10.4533 15.2934C10.84 14.9067 11.48 14.9067 11.8667 15.2934L14.3867 17.8134L20.12 12.08C20.5067 11.6934 21.1467 11.6934 21.5333 12.08C21.92 12.4667 21.92 13.1067 21.5333 13.4934L15.0933 19.9334C14.9067 20.12 14.6533 20.2267 14.3867 20.2267Z"
        fill="white"
      />
    </svg>
  );
};

export default VerifyIcon;
