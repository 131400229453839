import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";

import { EventApi, FileApi, SessionApi } from "@api";
import { comparingDates, urlToForm } from "@helpers";
import { IMedias, IResponseError } from "@types";

import {
  validateEventNumber,
  validateEventText,
} from "@api/validation/EventValidation";
import { isEmpty } from "@lib/lodash";

import { defaultEventState, eventReducer } from "./EventContext.reducer";
import {
  EventActionEnum,
  EventType,
  IEventImage,
  ProductType,
} from "./EventContext.types";

interface IEventContext {
  // States
  event: EventType | null;
  gallery: string[];
  rawImages: IEventImage | null;
  rawProducts: ProductType[];
  socials: IMedias | null;
  error: IResponseError | null;
  loading: boolean;
  // Functions
  changeSocialMedias: (socials: IMedias) => void;
  changeEventData: (event: EventType) => void;
  validateEventData: () => boolean;
  changeEventImages: (logo: string, background: string) => void;
  changeEventGallery: (images: string[]) => void;
  addProduct: (product: ProductType) => void;
  changeProduct: (product: ProductType) => void;
  deleteProduct: (productID: number) => void;
  // Session
  saveToMemory: () => void;
  removeFromMemory: () => void;
  // Server
  sendEvent: () => Promise<void>;
}

const EventContext = createContext<IEventContext>({} as IEventContext);

export const EventProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [error] = useState<IResponseError | null>(null);
  const [loading] = useState(false);
  const [state, dispatch] = useReducer(eventReducer, defaultEventState);

  // Event data

  const changeSocialMedias = (socials: IMedias): void => {
    dispatch({
      type: EventActionEnum.CHANGE_SOCIAL_MEDIAS,
      payload: socials,
    });
  };

  const changeEventData = (event: EventType): void => {
    dispatch({
      type: EventActionEnum.CHANGE_EVENT_DATA,
      payload: event,
    });
  };

  const validateEventData = (): boolean => {
    if (!state.event || !state.rawImages || state.gallery.length < 5) {
      return false;
    }
    const relevanceDates = comparingDates(
      state.event.startTime,
      state.event.endTime,
    );
    return (
      !!state.event.description &&
      !validateEventText(state.event.description) &&
      !!state.event.eventName &&
      !!state.event.startTime &&
      !!state.event.endTime &&
      !!state.event.format &&
      !!state.event.type &&
      !!state.event.location &&
      !!state.event.participantNumber &&
      validateEventNumber(state.event.participantNumber) &&
      !!state.event.mediaReach &&
      validateEventNumber(state.event.mediaReach) &&
      !isEmpty(state.rawImages.logo) &&
      !isEmpty(state.rawImages.background) &&
      relevanceDates
    );
  };

  // Event logo and background

  const changeEventImages = (logo: string, background: string): void => {
    dispatch({
      type: EventActionEnum.CHANGE_EVENT_IMAGES,
      payload: { logo, background },
    });
  };

  // Event gallery

  const changeEventGallery = (images: string[]): void => {
    dispatch({
      type: EventActionEnum.CHANGE_GALLEY_DATA,
      payload: images,
    });
  };

  // Event products

  const addProduct = (product: ProductType): void => {
    dispatch({
      type: EventActionEnum.ADD_PRODUCT,
      payload: product,
    });
  };

  const changeProduct = (product: ProductType): void => {
    dispatch({
      type: EventActionEnum.UPDATE_PRODUCT,
      payload: product,
    });
  };

  const deleteProduct = (productID: number): void => {
    dispatch({
      type: EventActionEnum.DELETE_PRODUCT,
      payload: productID,
    });
  };

  // Session

  const removeGallery = (): void => {
    const len = SessionApi.get<number>("create-event:len");
    if (len) {
      for (let i = 0; i < len; i += 1) {
        SessionApi.remove(`create-event:gallery(${i})`);
      }
    }
    SessionApi.remove("create-event:len");
  };

  const removeProducts = (): void => {
    const len = SessionApi.get<number>("create-event:products:len");
    if (len) {
      for (let i = 0; i < len; i += 1) {
        SessionApi.remove(`create-event:products(${i})`);
      }
    }
    SessionApi.remove("create-event:products:len");
  };

  const saveToMemory = async (): Promise<void> => {
    // Rewrite data in session storage
    SessionApi.set("create-event:main", state.event);
    SessionApi.set("create-event:socials", state.socials);

    // Clear previous state.galley and set new
    removeGallery();
    const newGallery = [] as string[];
    for (let i = 0; i < state.gallery.length; i += 1) {
      /*eslint-disable*/
      const url = await FileApi.sendImage(state.gallery[i]);
      newGallery.push(url);
      SessionApi.set(`create-event:gallery(${i})`, url);
    }
    SessionApi.set("create-event:len", state.gallery.length);
    changeEventGallery(newGallery);

    // Clear previous state.rawImages and set new
    SessionApi.remove("create-event:logo");
    SessionApi.remove("create-event:background");
    if (state.rawImages?.logo && state.rawImages?.background) {
      const logo = await FileApi.sendImage(state.rawImages?.logo);
      const background = await FileApi.sendImage(state.rawImages?.background);
      SessionApi.set("create-event:logo", logo);
      SessionApi.set("create-event:background", background);
      changeEventImages(logo, background);
    }

    // Clear previous state.rawProducts and set new
    removeProducts();
    SessionApi.set("create-event:products:len", state.rawProducts.length);
    state.rawProducts.forEach((p, i) => {
      SessionApi.set(`create-event:products(${i})`, p);
    });
  };

  const removeFromMemory = (): void => {
    SessionApi.remove("create-event:main");
    SessionApi.remove("create-event:socials");
    removeGallery();
    SessionApi.remove("create-event:logo");
    SessionApi.remove("create-event:background");
    removeProducts();
  };

  // Server

  const sendEvent = async (): Promise<void> => {
    if (
      state.event &&
      state.rawImages &&
      state.gallery &&
      state.rawProducts &&
      validateEventData()
    ) {
      const res = await EventApi.addEvent(state.event);
      const { eventID } = res;

      const logoData = await urlToForm(state.rawImages.logo);
      const backgroundData = await urlToForm(state.rawImages.background);

      await EventApi.setEventLogo(eventID, logoData);
      await EventApi.setEventBackground(eventID, backgroundData);

      state.gallery.map(await urlToForm).forEach(async (image) => {
        const img = await image;
        await EventApi.setGalleryImage(eventID, img);
      });

      state.rawProducts.forEach(async (product) => {
        await EventApi.addProduct(eventID, product);
      });

      removeFromMemory();
    }
  };

  useEffect(() => {
    if (!state.gallery.length) {
      const newGallery: string[] = [];
      const len = SessionApi.get<number>("create-event:len");
      if (len) {
        for (let i = 0; i < len; i += 1) {
          const img = SessionApi.get<string>(`create-event:gallery(${i})`);
          if (img) {
            newGallery.push(img);
          }
        }
      }
      if (newGallery.length) changeEventGallery(newGallery);
    }
  }, [state.gallery]);

  useEffect(() => {
    if (
      !state.rawImages ||
      !state.rawImages.logo ||
      !state.rawImages.background
    ) {
      const logo = SessionApi.get<string>("create-event:logo");
      const background = SessionApi.get<string>("create-event:background");
      if (logo && background) {
        changeEventImages(logo, background);
      }
    }
  }, [state.rawImages]);

  useEffect(() => {
    if (!state.rawProducts.length) {
      const newProducts: ProductType[] = [];
      const len = SessionApi.get<number>("create-event:products:len");
      if (len) {
        for (let i = 0; i < len; i += 1) {
          const img = SessionApi.get<ProductType>(
            `create-event:products(${i})`,
          );
          if (img) {
            newProducts.push(img);
          }
        }
      }
      if (newProducts.length) {
        newProducts.forEach((p) => addProduct(p));
      }
    }
  }, [state.rawProducts]);

  const value = useMemo(
    () => ({
      ...state,
      error,
      loading,
      changeSocialMedias,
      changeEventData,
      validateEventData,
      changeEventImages,
      changeEventGallery,
      addProduct,
      changeProduct,
      deleteProduct,
      saveToMemory,
      removeFromMemory,
      sendEvent,
    }),
    [state, error, loading],
  );
  return (
    <EventContext.Provider value={value}>{children}</EventContext.Provider>
  );
};

export default function useEvent(): IEventContext {
  return useContext(EventContext);
}
