import React, { useEffect, useState } from "react";

import { IDProduct } from "@types";

import { web1 } from "@assets/images/Temples/WebStreaming";

import styles from "./MainTemples.module.scss";

interface targetType {
  img: string;
  isActive: boolean;
}

interface IMainTemples {
  target: IDProduct;
}

const MainTemples: React.FC<IMainTemples> = ({ target }) => {
  const [targetImg, setTargetImg] = useState<targetType[]>([
    { img: web1, isActive: true },
  ]);
  const [viewImg, setViewImg] = useState<string>();

  useEffect(() => {
    const tempTarget = target.gallery.map((item, index) => {
      return index === 0
        ? { img: item, isActive: true }
        : { img: item, isActive: false };
    });
    setTargetImg(tempTarget);
  }, [target]);

  useEffect(() => {
    const tempView = targetImg.find((item) => item.isActive)?.img;
    setViewImg(tempView);
  }, [targetImg, setTargetImg]);

  const onActive = (src: string) => {
    return () =>
      setTargetImg((pre) =>
        pre.map((item) =>
          item.img === src
            ? { ...item, isActive: true }
            : { ...item, isActive: false },
        ),
      );
  };
  return (
    <div className={styles.main}>
      <div className={styles.title}>
        <h2>Event group: {target.group}</h2>
        <h3>{target.title}</h3>
      </div>
      <div className={styles.img_field}>
        <div className={styles.img_block}>
          <img alt="positions" src={viewImg} />
        </div>

        <div className={styles.all_img}>
          {targetImg.map((item) => (
            <button
              type="button"
              key={item.img}
              className={item.isActive ? styles.active : ""}
              onClick={onActive(item.img)}
            >
              <img src={item.img} alt="positions" />
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MainTemples;
