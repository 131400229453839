import React from "react";
import { Link } from "react-router-dom";

import { useLanguage } from "@context";
import { MotionWrap } from "@hoc";

import line from "@assets/images/line.svg";
import video from "@assets/images/video.png";

import styles from "./LandingWhatYouCanDo.module.scss";

const LandingWhatYouCanDo: React.FC = () => {
  const { text } = useLanguage();
  return (
    <div className={styles.container}>
      <img src={line} alt="" className={styles.line} />
      <div className={styles.headers}>
        <h3 className="landing_title">{text[222]}</h3>
        <p className="landing_subtitle">{text[223]}</p>
      </div>
      <Link to="/" className="button solid">
        {text[224]}
      </Link>
      <img className={styles.video} src={video} alt="asd" />
    </div>
  );
};

export default MotionWrap(LandingWhatYouCanDo);
