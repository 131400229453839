import React from "react";

import { IIcon } from "@types";

const PricingIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.531 11.622L11.631 3.522C11.955 3.198 12.405 3 12.9 3H19.2C20.19 3 21 3.81 21 4.8V11.1C21 11.595 20.802 12.045 20.469 12.378L12.369 20.478C12.045 20.802 11.595 21 11.1 21C10.605 21 10.155 20.802 9.831 20.469L3.531 14.169C3.198 13.845 3 13.395 3 12.9C3 12.405 3.207 11.946 3.531 11.622ZM17.85 7.5C18.597 7.5 19.2 6.897 19.2 6.15C19.2 5.403 18.597 4.8 17.85 4.8C17.103 4.8 16.5 5.403 16.5 6.15C16.5 6.897 17.103 7.5 17.85 7.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default PricingIcon;
