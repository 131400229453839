import React from "react";

import { useLanguage } from "@context";

import { BurgerMenuIcon } from "@assets/icons/event";

// import { NotificationIcon, NotificationTrueIcon } from "@assets/icons/kit";
import styles from "./LeftHeader.module.scss";

interface LeftHeaderProps {
  hasNotifications?: boolean;
}

const LeftHeader: React.FC<LeftHeaderProps> = () => {
  const { text } = useLanguage();
  return (
    <div className={styles.left_header}>
      <div className={styles.burger}>
        <BurgerMenuIcon />
        <span>{text[296]}</span>
      </div>
      {/* <button type="button" className={styles.notification}> */}
      {/*   {!hasNotifications ? <NotificationIcon /> : <NotificationTrueIcon />} */}
      {/* </button> */}
    </div>
  );
};

export default LeftHeader;
