import { motion } from "framer-motion";
import React from "react";

import { IIcon } from "@types";

const transition = { duration: 0.5, ease: "easeInOut", delay: 1.5 };

const VectorEventLandingIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg
      className={className}
      width="287"
      height="333"
      viewBox="0 0 287 333"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        d="M1.25892 20.5962C53.9954 80.9616 170.328 175.958 213.769 73.0193C268.07 -55.6538 68.2037 2.04785 104.493 144.257C140.782 286.466 338.229 356.633 373.177 323.7"
        stroke="#313131"
        strokeWidth="1.5"
        initial={{ pathLength: 0, fillOpacity: 0 }}
        animate={{ pathLength: 1, fillOpacity: 1 }}
        transition={transition}
      />
    </svg>
  );
};

export default VectorEventLandingIcon;
