import React from "react";

import { IDProduct, IPositionMap } from "@types";

import TemplesCard from "@components/EventPageComponents/TemplesModal/TemplesCard";
import { isEmpty } from "@lib/lodash";

import styles from "./ControlTemples.module.scss";

interface IControlTemples {
  onTargetPosition: (item: IDProduct) => void;
  targetId: number;
  products: IPositionMap;
}

const ControlTemples: React.FC<IControlTemples> = ({
  onTargetPosition,
  targetId,
  products,
}) => {
  return (
    <div className={styles.control}>
      <div className={styles.slider}>
        {!isEmpty(products) &&
          Object.entries<IDProduct[]>(products).map(([group, value]) => (
            <div className={styles.position} key={group}>
              <h3>{group}</h3>
              <div className={styles.block_cards}>
                {value.map((item) => (
                  <TemplesCard
                    onClick={onTargetPosition}
                    key={item.mid}
                    item={item}
                    style={
                      item.mid === targetId ? { borderColor: "#51c9c2" } : {}
                    }
                  />
                ))}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ControlTemples;
