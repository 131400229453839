import React from "react";
import { useNavigate } from "react-router-dom";

import { useError, useEvent, useLanguage } from "@context";

import { CheckIcon } from "@assets/icons/event";

import styles from "./ToolboxTitle.module.scss";

interface ToolboxTitleProps {
  title: string;
}

const ToolboxTitle: React.FC<ToolboxTitleProps> = ({ title }) => {
  const { text } = useLanguage();
  const navigate = useNavigate();
  const { rawProducts, saveToMemory } = useEvent();
  const { setError, clearError } = useError();

  const handleSubmit = (): void => {
    if (rawProducts.length >= 5) {
      clearError();
      saveToMemory();
      navigate("/app/event-details");
    } else {
      setError("Must have at least five marketing positions");
    }
  };

  return (
    <>
      <h3 className={styles.mobile_title}>{title}</h3>
      <div className={styles.proceed}>
        <button type="button" onClick={handleSubmit}>
          {text[39]}
        </button>
      </div>
      <div className={styles.content}>
        <div className={styles.title}>
          <span>{title}</span>
          <button type="button" onClick={handleSubmit}>
            {text[39]}
          </button>
        </div>
        <div className={styles.step}>
          <div className={styles.item}>
            <div className={styles.circle1}>
              <CheckIcon />
            </div>
            <div className={styles.text}>
              <h4>Event Form</h4>
              <p>Fill out the event form</p>
            </div>
          </div>

          <div className={styles.item}>
            <div className={styles.circle2}>
              <div />
            </div>
            <div className={styles.text}>
              <h4>Event details</h4>
              <p>Define event positions</p>
            </div>
          </div>

          <div className={styles.item}>
            <div className={styles.circle2}>
              <span>03</span>
            </div>
            <div className={styles.text}>
              <h4>Confirmation</h4>
              <p>Passage of the revue</p>
            </div>
          </div>

          <div className={styles.item_mobile1}>
            <CheckIcon />
          </div>
          <div className={styles.line_mobile} />
          <div className={styles.item_mobile2} />
          <div className={styles.line_mobile} />
          <div className={styles.item_mobile3} />
        </div>
        <div className={styles.mobile_description}>
          <span>Event Form</span>
          <span>Event details</span>
          <span>Confirmation</span>
        </div>
      </div>
    </>
  );
};

export default ToolboxTitle;
