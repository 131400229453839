import {
  merch11,
  merch12,
  merch21,
  merch22,
  streaming1,
  streaming2,
} from "@assets/images/events";

export interface SponsorPositionType {
  id: number;
  image: string;
  title: string;
  description: string;
  numberPosition: number;
  coast: number;
}

export const blockWidth = window.screen.width > 500 ? 517 : 360;
export const heightSlider = window.screen.width > 500 ? 316 : 252;

export const sponsorPosition1: SponsorPositionType[] = [
  {
    id: 1,
    image: merch11,
    title: "Man’t- shirt",
    description:
      "A beautiful townhouse in the heart of Miami’s down district. With 3 bedrooms and a heated pool.",
    numberPosition: 24,
    coast: 569,
  },
  {
    id: 2,
    image: merch21,
    title: "Baseball caps",
    description:
      "A beautiful townhouse in the heart of Miami’s down district. With 3 bedrooms and a heated pool.",
    numberPosition: 2,
    coast: 769,
  },
  {
    id: 3,
    image: merch12,
    title: "Shirt",
    description:
      "A beautiful townhouse in the heart of Miami’s down district. With 3 bedrooms and a heated pool.",
    numberPosition: 33,
    coast: 556,
  },
  {
    id: 4,
    image: merch22,
    title: "Woman baseball caps",
    description:
      "A beautiful townhouse in the heart of Miami’s down district. With 3 bedrooms and a heated pool.",
    numberPosition: 58,
    coast: 369,
  },
];

export const sponsorPosition2: SponsorPositionType[] = [
  {
    id: 1,
    image: streaming1,
    title: "Special guest in the stream",
    description:
      "A beautiful townhouse in the heart of Miami’s down district. With 3 bedrooms and a heated pool.",
    numberPosition: 24,
    coast: 569,
  },
  {
    id: 2,
    image: streaming2,
    title: "Sponsor’s logo on general overlay",
    description:
      "A beautiful townhouse in the heart of Miami’s down district. With 3 bedrooms and a heated pool.",
    numberPosition: 2,
    coast: 769,
  },
  {
    id: 3,
    image: streaming1,
    title: "Special guest in the stream",
    description:
      "A beautiful townhouse in the heart of Miami’s down district. With 3 bedrooms and a heated pool.",
    numberPosition: 33,
    coast: 556,
  },
  {
    id: 4,
    image: streaming2,
    title: "Sponsor’s logo on general overlay",
    description:
      "A beautiful townhouse in the heart of Miami’s down district. With 3 bedrooms and a heated pool.",
    numberPosition: 58,
    coast: 369,
  },
];
