import React, { useEffect, useState } from "react";

import { useLanguage } from "@context";

import styles from "./ParticipantsRange.module.scss";

export interface RangePropsType {
  min: number;
  max: number;
  value: number;
  step: number;
  onChange: (val: number) => void;
}

const ParticipantsRange: React.FC<RangePropsType> = ({
  value,
  onChange,
  ...rangeProps
}) => {
  const { text } = useLanguage();
  const [rangeVal, setRangeVal] = useState<number>(0);
  const [mouseState, setMouseState] = useState<string | null>(null);

  useEffect(() => {
    setRangeVal(value);
  }, [value]);

  const changeCallback = (e: React.FormEvent<HTMLInputElement>): void => {
    setRangeVal(Number(e.currentTarget.value));
  };

  useEffect(() => {
    if (mouseState === "up") {
      onChange(rangeVal);
    }
  }, [mouseState]);

  return (
    <div className={styles.block}>
      <input
        className={styles.custom_range}
        type="range"
        value={rangeVal}
        {...rangeProps}
        onChange={changeCallback}
        onMouseDown={() => setMouseState("down")}
        onMouseUp={() => setMouseState("up")}
      />
      <div className={styles.track_bg} />
      <div className={styles.track} style={{ width: `${rangeVal * 20}%` }} />
      <div className={styles.point0}>
        <span>{text[286]}</span>
      </div>
      <div className={styles.point1}>
        <span>1</span>
      </div>
      <div className={styles.point2}>
        <span>2</span>
      </div>
      <div className={styles.point3}>
        <span>3</span>
      </div>
      <div className={styles.point4}>
        <span>4</span>
      </div>
      <div className={styles.point5}>
        <span>{text[287]} 4.5</span>
      </div>
    </div>
  );
};

export default ParticipantsRange;
