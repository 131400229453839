import classnames from "classnames";
import React, { FC } from "react";

import { useScroll } from "@context";

import { MenuItems } from "./SettingsSidebar.data";
import styles from "./SettingsSidebar.module.scss";

export interface ISettingsSidebar {
  onClick: (key: number) => () => void;
}

// const reverseDate = (data: string): string => {
//   return data.split("-").reverse().join("-");
// };

const SettingsSidebar: FC<ISettingsSidebar> = ({ onClick }) => {
  const { clientKey } = useScroll();
  // const { user } = useAuth();

  return (
    <div className={styles.sidebar}>
      {MenuItems.map((item) => (
        <div
          className={classnames(styles.item, {
            [styles.active]: clientKey === item.key,
          })}
          key={item.key}
          onClick={onClick(item.key)}
        >
          <span className={styles.icon}>
            <item.icon />
          </span>
          <span className={styles.name}>{item.name}</span>
        </div>
      ))}
    </div>
  );
};

export default SettingsSidebar;
