import React from "react";

import { IIcon } from "@types";

const SettingIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4375 3C10.8162 3 10.3125 3.50368 10.3125 4.125L10.1774 5.07038C10.0565 5.91684 9.0728 6.32431 8.38875 5.81128L7.62478 5.23829C7.18544 4.79895 6.47313 4.79895 6.03379 5.23829L5.23829 6.03379C4.79895 6.47313 4.79895 7.18544 5.23829 7.62478L5.81127 8.38875C6.32431 9.0728 5.91684 10.0565 5.07037 10.1774L4.125 10.3125C3.50368 10.3125 3 10.8162 3 11.4375V12.5625C3 13.1838 3.50368 13.6875 4.125 13.6875L5.07038 13.8226C5.91685 13.9435 6.32432 14.9272 5.81128 15.6112L5.23829 16.3752C4.79895 16.8146 4.79895 17.5269 5.23829 17.9662L6.03379 18.7617C6.47313 19.2011 7.18544 19.2011 7.62478 18.7617L8.38875 18.1887C9.0728 17.6757 10.0565 18.0832 10.1774 18.9296L10.3125 19.875C10.3125 20.4963 10.8162 21 11.4375 21H12.5625C13.1838 21 13.6875 20.4963 13.6875 19.875L13.8226 18.9296C13.9435 18.0832 14.9272 17.6757 15.6112 18.1887L16.3752 18.7617C16.8146 19.2011 17.5269 19.2011 17.9662 18.7617L18.7617 17.9662C19.201 17.5269 19.201 16.8146 18.7617 16.3752L18.1887 15.6112C17.6757 14.9272 18.0832 13.9435 18.9296 13.8226L19.875 13.6875C20.4963 13.6875 21 13.1838 21 12.5625V11.4375C21 10.8162 20.4963 10.3125 19.875 10.3125L18.9296 10.1774C18.0832 10.0565 17.6757 9.0728 18.1887 8.38875L18.7617 7.62478C19.201 7.18544 19.201 6.47313 18.7617 6.03379L17.9662 5.23829C17.5269 4.79895 16.8146 4.79895 16.3752 5.23829L15.6112 5.81128C14.9272 6.32431 13.9435 5.91684 13.8226 5.07038L13.6875 4.125C13.6875 3.50368 13.1838 3 12.5625 3H11.4375ZM12 14.25C13.2426 14.25 14.25 13.2426 14.25 12C14.25 10.7574 13.2426 9.75 12 9.75C10.7574 9.75 9.75 10.7574 9.75 12C9.75 13.2426 10.7574 14.25 12 14.25Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default SettingIcon;
