import React from "react";
import { useNavigate } from "react-router-dom";

import styles from "./ProceedTemples.module.scss";

interface IProceedTemples {
  totalCost: number;
  // setIsOpen: (isOpen: boolean) => void;
}

const ProceedTemples: React.FC<IProceedTemples> = ({ totalCost }) => {
  const navigate = useNavigate();
  const OnReturn = (): void => {
    navigate("/app/toolbox-first", { replace: true });
  };
  return (
    <div className={styles.proceed}>
      <div className={styles.bar}>
        <div className={styles.items}>
          <span>Items: </span>
          <span>3</span>
        </div>
        <div className={styles.items}>
          <span>total cost: </span>
          <span>${totalCost}</span>
        </div>
      </div>
      <div className={styles.button}>
        <button type="button" onClick={OnReturn}>
          Proceed
        </button>
      </div>
    </div>
  );
};

export default ProceedTemples;
