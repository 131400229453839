import React, { useEffect, useRef, useState } from "react";

import { validateEventName } from "@api";
import { useEvent, useLanguage } from "@context";
import { comparingDates, eventValidate } from "@helpers";
import { useInput } from "@hooks";
import {
  EventTypes,
  Input,
  InputDate,
  InputSelect,
  InputText,
  MediaInput,
} from "@shared";
import { IMedias, IStateHook } from "@types";

import {
  validateEventNumber,
  validateEventText,
} from "@api/validation/EventValidation";
import {
  FacebookIcon,
  InstagramIcon,
  LocationIcon,
  MessengerIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from "@assets/icons/kit";
import GMapModal from "@components/GMap/GMapModal";
import { EventType } from "@context/EventCreate/EventContext.types";

import UploadImage from "../../UploadImage";

import styles from "./EventInformation.module.scss";

interface IEventInformation {
  setIsError: (flag: boolean) => void;
  setErrorText: (text: string) => void;
  errorText: string;
  onTextImg: (txt: string) => void;
  onCleaning: () => void;
}

const EventInformation: React.FC<IEventInformation> = ({
  setIsError,
  setErrorText,
  errorText,
  onTextImg,
  onCleaning,
}) => {
  const isMobile = window.screen.width < 1050;
  const topUpload = isMobile ? 13 : 22;
  const { text } = useLanguage();
  const {
    event,
    socials,
    changeSocialMedias,
    changeEventData,
    changeEventImages,
    rawImages,
  } = useEvent();
  const [logos, setLogos] = useState(rawImages);
  const { gallery } = useEvent();

  const [updateView, setUpdateView] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const name = useInput(event?.eventName || "");
  const description = useInput(event?.description || "");
  const website = useInput(event?.website || "");
  const format = useInput(event?.format || "");
  const eventType = useInput(event?.type || "");
  const participantNumber = useInput(event?.participantNumber || "");
  const mediaReach = useInput(event?.mediaReach || "");
  const [location, setLocation] = useState<string>(event?.location || "");
  const [isErrorLocation, setErrorLocation] = useState(false);
  const [startDate, setStartDate] = useState<string>(event?.startTime || "");
  const [endDate, setEndDate] = useState<string>(event?.endTime || "");
  const [isRelevanceDates, setRelevanceDates] = useState(false);

  const facebook = useInput(socials?.facebook || "");
  const instagram = useInput(socials?.instagram || "");
  const telegram = useInput(socials?.telegram || "");
  const messenger = useInput(socials?.messenger || "");
  const twitter = useInput(socials?.twitter || "");
  const whatsapp = useInput(socials?.whatsapp || "");

  const validName = useRef<HTMLInputElement>(null);
  const validDescription = useRef<HTMLTextAreaElement>(null);
  const validStartDate = useRef<HTMLInputElement>(null);
  const validEndDate = useRef<HTMLInputElement>(null);
  const validFormat = useRef<HTMLDivElement>(null);
  const validEventType = useRef<HTMLDivElement>(null);
  const validParticipantNumber = useRef<HTMLInputElement>(null);
  const validMediaReach = useRef<HTMLInputElement>(null);

  const handleChangeDate = (
    callback: IStateHook<string>,
  ): ((e: React.ChangeEvent<HTMLInputElement>) => void) => {
    return (e) => callback(() => e.target.value);
  };

  useEffect(() => {
    if (rawImages) {
      setLogos(() => rawImages);
      if ((rawImages.logo || rawImages.background) && updateView <= 10) {
        setUpdateView((prevState) => prevState + 1);
      }
    }
  }, [rawImages]);

  useEffect(() => {
    const newEvent: EventType = {
      eventName: name.value,
      description: description.value,
      location,
      format: format.value,
      type: eventType.value,
      website: website.value,
      participantNumber: +participantNumber.value,
      mediaReach: +mediaReach.value,
      startTime: startDate,
      endTime: endDate,
    };
    const newSocial: IMedias = {
      facebook: facebook.value,
      twitter: twitter.value,
      instagram: instagram.value,
      telegram: telegram.value,
      messenger: messenger.value,
      whatsapp: whatsapp.value,
    };
    const timeoutId = setTimeout(() => {
      changeEventData(newEvent);
      changeSocialMedias(newSocial);
    }, 1000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [
    mediaReach.value,
    participantNumber.value,
    website.value,
    eventType.value,
    format.value,
    location,
    description.value,
    name.value,
    startDate,
    endDate,
    facebook.value,
    twitter.value,
    instagram.value,
    telegram.value,
    messenger.value,
    whatsapp.value,
  ]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    const vName = !validateEventName(name.value);
    const vParticipantNumber = !validateEventNumber(
      participantNumber.value as number,
    );
    const vMediaReach = !validateEventNumber(mediaReach.value as number);
    const vDescription =
      validateEventText(description.value) || !description.value;
    const vFormat = !event?.format;
    const vType = !event?.type;
    const vStartTime = !event?.startTime;
    const vEndTime = !event?.endTime;
    const vLogo = !logos?.logo;
    const vBackground = !logos?.background;
    const vGallery = !(gallery.length >= 1);
    const relevanceDates = comparingDates(
      event?.startTime ? event?.startTime : "",
      event?.startTime ? event?.endTime : "",
    );

    eventValidate<HTMLInputElement>(vName, setIsError, validName);
    eventValidate<HTMLInputElement>(
      vParticipantNumber,
      setIsError,
      validParticipantNumber,
    );
    eventValidate<HTMLInputElement>(vMediaReach, setIsError, validMediaReach);
    eventValidate<HTMLTextAreaElement>(
      vDescription,
      setIsError,
      validDescription,
    );
    eventValidate<HTMLDivElement>(vFormat, setIsError, validFormat);
    eventValidate<HTMLDivElement>(vType, setIsError, validEventType);
    eventValidate<HTMLInputElement>(vStartTime, setIsError, validStartDate);
    eventValidate<HTMLInputElement>(vEndTime, setIsError, validEndDate);

    if (vLogo) {
      setIsError(true);
      onTextImg(" No photo for logo.");
    }
    if (vBackground) {
      setIsError(true);
      onTextImg(" No photo for background.");
    }
    if (vGallery) {
      setIsError(true);
      onTextImg(" Gallery must have 1 or more photos");
    }
    if (!location) {
      setIsError(true);
      setErrorLocation(true);
    }
    if (!relevanceDates) {
      setIsError(true);
      setRelevanceDates(true);
    }
    if (
      !vName &&
      !vParticipantNumber &&
      !vMediaReach &&
      !vDescription &&
      !vFormat &&
      !vType &&
      !vStartTime &&
      !vEndTime &&
      !vLogo &&
      !vBackground &&
      !vGallery &&
      !!location &&
      relevanceDates
    ) {
      setIsError(false);
      setErrorText(errorText);
      setErrorLocation(false);
      setRelevanceDates(false);
    }
  };

  useEffect(() => {
    window.onkeydown = () => {
      setIsError(false);
      setErrorLocation(false);
      setRelevanceDates(false);
    };
  });

  return (
    <form id="eventInformation" onSubmit={handleSubmit} onClick={onCleaning}>
      <div className={styles.basic}>
        <h2 className={styles.title}>{text[53]}</h2>
        <UploadImage
          key={updateView}
          style={{ width: "100%", marginTop: topUpload }}
          updateImages={changeEventImages}
          defaultLogo={logos?.logo}
          defaultBackground={logos?.background}
        />
        <div className={styles.upload_footer}>{text[62]}</div>

        <Input
          label={text[71]}
          className={styles.events_name}
          required
          inputRef={validName}
          {...name}
        />

        <div className={styles.block}>
          <InputSelect
            label="Event format*"
            {...format}
            selectRef={validFormat}
          >
            <option value="" disabled selected hidden>
              Choose the event format
            </option>
            <option value="offline">offline</option>
            <option value="online">online</option>
            <option value="hybrid">hybrid</option>
          </InputSelect>

          <InputSelect
            label="Event type*"
            {...eventType}
            selectRef={validEventType}
          >
            <option value="" disabled selected hidden>
              Choose the event type
            </option>
            {EventTypes.map((opt) => (
              <option value={opt} key={opt}>
                {opt}
              </option>
            ))}
          </InputSelect>
        </div>

        <div className={styles.location_seats}>
          <div
            onClick={() => {
              setIsOpen(true);
              setErrorLocation(false);
            }}
          >
            {text[40]}
            <div
              className={styles.as_input}
              style={isErrorLocation ? { borderColor: "#e53d3e" } : {}}
            >
              {!location ? (
                <>
                  <LocationIcon />
                  <span>{text[89]}</span>
                </>
              ) : (
                <div>{location}</div>
              )}
            </div>
          </div>
          <div
            className={styles.date_block}
            style={isRelevanceDates ? { border: "1px solid #e53d3e" } : {}}
            onClick={() => setRelevanceDates(false)}
          >
            <InputDate
              label="Start date*"
              value={startDate}
              onChange={handleChangeDate(setStartDate)}
              selectRef={validStartDate}
            />
            <InputDate
              label="End date*"
              value={endDate}
              onChange={handleChangeDate(setEndDate)}
              selectRef={validEndDate}
            />
          </div>
        </div>
      </div>

      <div className={styles.additional}>
        <h2 className={styles.title}>{text[105]}</h2>

        <InputText
          label={text[114]}
          className={styles.description}
          {...description}
          required
          textRef={validDescription}
        />
        <Input
          label={text[13]}
          className={styles.site}
          placeholder="https://www.google.com"
          {...website}
        />
        <div className={styles.block}>
          <Input
            label="Estimated number of participants*"
            {...participantNumber}
            required
            inputRef={validParticipantNumber}
          />
          <Input
            label="Estimated media reach*"
            {...mediaReach}
            required
            inputRef={validMediaReach}
          />
        </div>
      </div>

      <div className={styles.additional}>
        <h2 className={styles.title}>Social media*</h2>
        <p className={styles.subtitle}>Add social media events.</p>
        <div className={styles.wrapper_medias}>
          <div className={styles.medias__group}>
            <MediaInput
              label="Facebook"
              icon={FacebookIcon}
              placeholder="http://"
              {...facebook}
            />
            <MediaInput
              label="Instagram"
              icon={InstagramIcon}
              placeholder="http://"
              {...instagram}
            />
          </div>
          <div className={styles.medias__group}>
            <MediaInput
              label="Telegram"
              icon={TelegramIcon}
              placeholder="http://"
              {...telegram}
            />
            <MediaInput
              label="Whatsapp"
              icon={WhatsappIcon}
              placeholder="http://"
              {...whatsapp}
            />
          </div>
          <div className={styles.medias__group}>
            <MediaInput
              label="Twitter"
              icon={TwitterIcon}
              placeholder="http://"
              {...twitter}
            />
            <MediaInput
              label="Messenger"
              icon={MessengerIcon}
              placeholder="http://"
              {...messenger}
            />
          </div>
        </div>
      </div>
      {isOpen && (
        <GMapModal
          address={location}
          onClose={() => setIsOpen(false)}
          setAddress={(_address) => setLocation(_address)}
        />
      )}
    </form>
  );
};

export default EventInformation;
