import { motion } from "framer-motion";
import React, { RefObject } from "react";

import {
  line,
  number1,
  number2,
  number3,
} from "@assets/images/organizersLanding/startWorking";

import styles from "./StartWorking.module.scss";

interface IStartWorking {
  myRef?: RefObject<HTMLDivElement>;
}

const StartWorking: React.FC<IStartWorking> = ({ myRef }) => {
  return (
    <section className={styles.section} ref={myRef}>
      <div className={styles.container}>
        <p className={styles.pretitle}>For rights holders</p>
        <h2 className={styles.title}>
          Start working with Monetiseur right now
        </h2>
        <p className={styles.info}>
          Finding the right marketing partner and negotiating the details can
          take weeks. With Monetiseur you can get it done in a few minutes.
        </p>
      </div>
      <div className={styles.block}>
        <img src={line} alt="diagonal line" className={styles.img} />
        <div className={styles.data_first}>
          <motion.div
            whileInView={{ rotate: 360 }}
            style={{ originX: "32px", originY: "32px" }}
            transition={{
              duration: 1,
            }}
          >
            <div className={styles.data_first__out}>
              <div className={styles.in} />
            </div>
          </motion.div>

          <h3 className={styles.data_first__subtitle}>
            Register on Monetiseur
          </h3>
          <p className={styles.data_first__subinfo}>
            Fill out your profile to get started
          </p>
          <img src={number1} alt="number" className={styles.number1} />
        </div>

        <div className={styles.data_second}>
          <motion.div
            whileInView={{ rotate: 360 }}
            style={{ originX: "32px", originY: "32px" }}
            transition={{
              duration: 1,
            }}
          >
            <div className={styles.data_second__out}>
              <div className={styles.in} />
            </div>
          </motion.div>

          <h3 className={styles.data_second__subtitle}>
            Manage your inventory
          </h3>
          <p className={styles.data_second__subinfo}>
            Toolbox helps you to professionally manage your marketing inventory
            to attract more brands
          </p>
          <img src={number2} alt="number" className={styles.number2} />

          <img src={line} alt="diagonal line" className={styles.line_img2} />
        </div>
        <div className={styles.data_third}>
          <motion.div
            whileInView={{ rotate: 360 }}
            style={{ originX: "32px", originY: "32px" }}
            transition={{
              duration: 1,
            }}
          >
            <div className={styles.data_third__out}>
              <div className={styles.in} />
            </div>
          </motion.div>

          <h3 className={styles.data_third__subtitle}>Find partners</h3>
          <p className={styles.data_third__subinfo}>
            Publish your proposal on the Marketplace and quickly reach the right
            partners
          </p>
          <img src={number3} alt="number" className={styles.number3} />
          <img src={line} alt="diagonal line" className={styles.line_img3} />
        </div>
      </div>
    </section>
  );
};

export default StartWorking;
