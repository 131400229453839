import { motion } from "framer-motion";
import React from "react";

import { IIcon } from "@types";

const transition1 = { duration: 1, ease: "easeInOut", delay: 0.5 };
const transition2 = { duration: 0.5, ease: "easeInOut", delay: 1.5 };

const ArrowRightIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg
      className={className}
      width="353"
      height="379"
      viewBox="0 0 353 379"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M161.475 363.738C160.498 362.657 160.582 360.988 161.663 360.011C170.063 352.416 178.66 345.311 187.089 338.345C193.132 333.35 199.089 328.427 204.824 323.446C218.71 311.388 231.77 298.619 243.152 282.574C263.528 253.853 277.755 220.831 279.147 185.492C279.866 167.265 276.011 146.795 267.184 129.612C258.368 112.447 244.7 98.7427 225.834 93.5513C216.264 90.918 205.763 90.3562 195.153 91.585C202.27 98.7133 208.477 107.078 213.482 116.882C225.337 140.106 214.023 162.595 195.675 176.063C177.399 189.478 151.106 194.787 131.381 183.067C120.772 176.764 114.978 167.672 113.667 157.572C112.367 147.548 115.516 136.833 122.169 127.172C132.878 111.624 150.144 99.5014 169.119 92.4846C173.817 90.7472 178.636 89.3168 183.505 88.2238C162.142 71.0456 134.157 62.9247 105.799 59.4389C104.352 59.2611 103.324 57.9444 103.502 56.498C103.679 55.0516 104.996 54.0233 106.442 54.2011C136.67 57.9168 167.336 66.9192 190.195 86.9412C202.845 84.9307 215.604 85.2629 227.234 88.4632C247.893 94.1481 262.584 109.106 271.879 127.201C281.163 145.276 285.171 166.663 284.42 185.7C282.977 222.336 268.242 256.329 247.457 285.628C235.73 302.157 222.323 315.24 208.284 327.431C202.305 332.623 196.292 337.59 190.249 342.581C181.953 349.433 173.601 356.332 165.202 363.925C164.121 364.903 162.453 364.819 161.475 363.738ZM188.559 92.5755C182.622 93.6701 176.704 95.306 170.949 97.4342C152.77 104.157 136.484 115.693 126.515 130.166C120.425 139.008 117.804 148.437 118.901 156.893C119.988 165.271 124.759 172.994 134.077 178.531C151.398 188.823 175.361 184.428 192.552 171.809C209.671 159.243 219.035 139.367 208.781 119.281C203.4 108.74 196.532 99.9278 188.559 92.5755Z"
        fill="#333333"
        stroke="#FBF9F0"
        strokeWidth="3"
        strokeLinecap="round"
        initial={{ pathLength: 0, fillOpacity: 0 }}
        animate={{ pathLength: 1, fillOpacity: 1 }}
        transition={transition1}
      />
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M179.392 361.74C179.896 363.107 179.197 364.625 177.83 365.13C177.688 365.182 177.483 365.258 177.225 365.355C175.252 366.091 170.125 368.002 165.209 369.607C162.442 370.51 159.656 371.344 157.502 371.813C156.453 372.041 155.395 372.219 154.514 372.228C154.099 372.232 153.483 372.205 152.868 371.98C152.204 371.738 151.116 371.07 150.876 369.643C150.534 367.614 151.249 365.601 152.004 364.028C152.791 362.387 153.88 360.72 154.825 359.283L154.902 359.166C155.869 357.696 156.653 356.503 157.133 355.51C157.323 355.115 157.417 354.846 157.459 354.683C156.983 353.498 157.426 352.11 158.557 351.435C159.809 350.689 161.429 351.098 162.175 352.35C162.809 353.413 162.834 354.536 162.693 355.41C162.556 356.268 162.229 357.091 161.885 357.803C161.248 359.123 160.286 360.586 159.411 361.914C159.352 362.004 159.293 362.094 159.235 362.182C158.258 363.668 157.37 365.043 156.761 366.311C156.713 366.411 156.668 366.508 156.626 366.602C158.483 366.181 160.957 365.443 163.572 364.59C168.37 363.024 173.377 361.157 175.372 360.413C175.638 360.314 175.851 360.235 176.002 360.179C177.369 359.674 178.887 360.373 179.392 361.74Z"
        fill="#333333"
        stroke="#FBF9F0"
        strokeWidth="3"
        strokeLinecap="round"
        initial={{ fillOpacity: 0 }}
        animate={{ fillOpacity: 1 }}
        transition={transition2}
      />
    </svg>
  );
};

export default ArrowRightIcon;
