import React, { ReactNode } from "react";

import Portal from "../Portal";

import styles from "./PortalModalOverlay.module.scss";

interface IPortalModalOverlay {
  children?: ReactNode;
}

const PortalModalOverlay: React.FC<
  React.PropsWithChildren<IPortalModalOverlay>
> = ({ children }) => {
  return (
    <Portal>
      <div className={styles.overlay}>{children}</div>
    </Portal>
  );
};

export default PortalModalOverlay;
