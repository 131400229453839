import axios, { AxiosError } from "axios";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation } from "react-router-dom";

import { useAuth } from "@context";
import { dateAdapter } from "@helpers";
import {
  IEventUser,
  IResponseError,
  ISocialMedia,
  IUser,
  IUserProfileValidationMap,
  IValidationItem,
} from "@types";

// import { FileApi } from "@api";
import { userProfileValidationMap } from "@validation/userProfileValidationMap";

import { IProfileContext, OrganizationImages } from "./ProfileContext.types";

const ProfileContext = createContext<IProfileContext>({} as IProfileContext);

export const ProfileProvider: React.FC = ({ children }) => {
  const {
    user: userData,
    setProfile,
    setOrganization,
    setSocialMedia,
    getUser,
    setEvent,
  } = useAuth();
  const location = useLocation();

  const [user, setUserData] = useState<IUser>(userData ?? {});
  const [currentSocialMedias, setCurrentSocialMedia] = useState<
    Map<number, ISocialMedia>
  >(new Map());
  const [currentEvents, setCurrentEvents] = useState<
    Map<number | string, IEventUser>
  >(new Map());
  const [validate, setValidate] = useState(false);
  const [organizationImages, setOrganizationImages] =
    useState<OrganizationImages>({
      logo: "",
      background: "",
      isChanged: false,
    });
  const [error, setError] = useState<IResponseError>({} as IResponseError);
  const [errorText, setErrorText] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const handleChangeUserDate = (type: string, value: string): void => {
    setUserData((prev: IUser) => {
      const updatedValue: Partial<IUser> = {};

      if (type.includes(".")) {
        const [objKeyMain, objectKey] = type.split(".");
        updatedValue[objKeyMain as keyof IUser] = {
          // TODO: Should be removed
          // @ts-ignore
          ...prev[objKeyMain as keyof IUser],
          [objectKey]: value,
        };
      } else {
        // TODO: Should be removed
        // @ts-ignore
        updatedValue[type] = value;
      }

      return { ...prev, ...updatedValue };
    });
  };

  const handleInputChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>): void => {
    handleChangeUserDate(target.name, target.value);
  };

  const handleSelectChange = ({
    target,
  }: React.ChangeEvent<HTMLSelectElement>): void => {
    handleChangeUserDate(target.name, target.value);
  };

  const handleChangeDate = (type: string, date: Date | null): void => {
    if (!date) return;
    handleChangeUserDate(type, dateAdapter.toServer(date.toISOString()));
  };

  const handleSocialMediaChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>): void => {
    const mapSocialMedia = new Map(currentSocialMedias);
    const updSocial = currentSocialMedias.get(+target.name);
    mapSocialMedia.set(
      +target.name,
      updSocial
        ? { ...updSocial, link: target.value }
        : { id: 0, type: +target.name, link: target.value, connected: true },
    );

    setCurrentSocialMedia(mapSocialMedia);
  };

  const handleEventChange = (
    eventId: string | number,
    fieldName: string,
    value: string,
  ): void => {
    const events = new Map(currentEvents);
    const currentEvent = currentEvents.get(eventId);
    if (currentEvent)
      events.set(eventId, { ...currentEvent, [fieldName]: value });

    setCurrentEvents(events);
  };

  const handleAddNewEvent = (): string => {
    const events = new Map(currentEvents);
    const portfolioId = `new_${currentEvents.size + 1}`;
    events.set(portfolioId, {
      id: portfolioId,
      name: "",
      startDate: "",
      endDate: "",
      startTime: "",
      location: "",
      website: "",
      description: "",
      sportType: "",
    });
    setCurrentEvents(events);
    return portfolioId;
  };

  const validator = (
    key: keyof IUserProfileValidationMap,
    value: any,
    passValidate = false,
  ): string => {
    if (
      (validate || passValidate) &&
      userProfileValidationMap[key].isError(value)
    ) {
      return userProfileValidationMap[key]?.helperText;
    }
    return "";
  };

  const checkValidation = (
    arr: IEventUser[],
    validationRules: Record<keyof IEventUser, IValidationItem>,
  ): boolean => {
    let result = true;
    arr?.some((el) => {
      (Object.keys(validationRules) as Array<keyof IEventUser>).forEach(
        (item) => {
          if (validationRules[item].isError(el[item])) {
            result = !validationRules[item].isError(el[item]);
            return validationRules[item].isError(el[item]);
          }
          return null;
        },
      );
    });
    return result;
  };

  const { EventSportType, EventDescription } = userProfileValidationMap;

  const eventValidationRules = {
    sportType: EventSportType,
    description: EventDescription,
  };

  const handleSave = async (): Promise<void> => {
    setValidate(true);

    if (
      checkValidation(
        [...currentEvents].map((el) => el[1]),
        eventValidationRules as Record<keyof IEventUser, IValidationItem>,
      ) &&
      !validator("FirstName", user?.FirstName, true) &&
      !validator("SecondName", user?.SecondName, true) &&
      !validator("DateOfBirth", user?.DateOfBirth, true) &&
      !validator("OrganizationSportType", user?.Organization?.Name, true) &&
      !validator("Email", user?.Organization?.Email, true) &&
      !validator("OrganizationName", user?.Organization?.SportType, true) &&
      !validator(
        "OrganizationDescription",
        user?.Organization?.Description,
        true,
      )
    ) {
      setValidate(false);
      setLoading(true);
      const temp = { ...user };
      const { Organization, ...personalInformation } = temp;
      const updatedOrganization = Organization?.Id ? { ...Organization } : null;

      /** Divide updated and new social media */
      const updatedSocialMedias: ISocialMedia[] = [];
      const newSocialMedias: ISocialMedia[] = [];
      [...currentSocialMedias.values()].forEach((media: ISocialMedia) => {
        if (media.id) {
          updatedSocialMedias.push(media);
        } else {
          newSocialMedias.push(media);
        }
      });

      /** Divide updated and new events */
      const updatedEvents: IEventUser[] = [];
      const newEvents: IEventUser[] = [];
      [...currentEvents.values()].forEach((event: IEventUser) => {
        if (`${event.id}`.includes("new")) {
          newEvents.push(event);
        } else {
          updatedEvents.push(event);
        }
      });

      try {
        /** Update Profile */
        await setProfile({
          ...personalInformation,
          Organization: updatedOrganization,
          SocialMedias: updatedSocialMedias,
          Events: updatedEvents,
        });

        const promises = [];

        /** Create Organization */
        if (!Organization?.Id && Organization) {
          promises.push(setOrganization(Organization));
        }

        /** Create Social Media */
        if (newSocialMedias?.length) {
          newSocialMedias.forEach((media: ISocialMedia) => {
            promises.push(setSocialMedia(media));
          });
        }

        /** Create Events */
        if (newEvents?.length) {
          newEvents.forEach((event: IEventUser) => {
            promises.push(setEvent(event));
          });
        }

        if (organizationImages.isChanged) {
          // TODO: Need integrate
          // promises.push(setImage);
        }

        await Promise.all(promises);

        /** Get updated user */
        await getUser();
      } catch (err) {
        if (axios.isAxiosError(err)) {
          const serverError = err as AxiosError<string>;
          if (serverError && serverError.response) {
            setErrorText(serverError.response.data);
          }
        } else {
          setErrorText("Something went wrong! Please try later...");
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const handleOrganizationImagesChange = (
    logo: string,
    background: string,
  ): void => {
    setOrganizationImages({ logo, background, isChanged: true });
  };

  useEffect(() => {
    if (error) setError({} as IResponseError);
  }, [location.pathname]);

  useEffect(() => {
    if (userData) {
      /** Set current user data */
      setUserData(userData);

      /** Set current social media */
      if (userData.SocialMedias?.length) {
        setCurrentSocialMedia(
          new Map(userData.SocialMedias.map((s) => [s.type, s])),
        );
      }

      /** Set current events */
      if (userData.Events?.length) {
        setCurrentEvents(new Map(userData.Events.map((s) => [s.id, s])));
      }
    }
  }, [userData]);

  const value = useMemo(
    () => ({
      user,
      currentSocialMedias,
      currentEvents,
      error,
      loading,
      validate,
      errorText,
      setErrorText,
      handleInputChange,
      handleChangeUserDate,
      handleSelectChange,
      handleChangeDate,
      handleSocialMediaChange,
      handleSave,
      validator,
      handleEventChange,
      handleAddNewEvent,
      handleOrganizationImagesChange,
    }),
    [
      user,
      currentSocialMedias,
      currentEvents,
      error,
      loading,
      validate,
      errorText,
    ],
  );

  return (
    <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>
  );
};

export const useProfile = (): IProfileContext => {
  return useContext<IProfileContext>(ProfileContext);
};
