import React from "react";

import { IIcon } from "@types";

const MessengerIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2C6.4775 2 2 6.145 2 11.2592C2.0123 12.6637 2.35318 14.0459 2.99538 15.2952C3.63757 16.5444 4.56324 17.626 5.69833 18.4533L5.72417 18.4708V22L9.13083 20.1317C9.99 20.3775 10.9767 20.5183 11.9967 20.5183H12C17.5225 20.5183 22 16.3725 22 11.26C22 6.1475 17.5225 2 12 2ZM12.9925 14.4692L10.4467 11.75L5.4775 14.4667L10.9433 8.66667L13.5525 11.3825L18.46 8.66667L12.9925 14.4692Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default MessengerIcon;
