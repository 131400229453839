import React, { useEffect, useState } from "react";

import { useLanguage } from "@context";
import { MotionWrap } from "@hoc";
import { useProducts } from "@hooks";
import { IEvent } from "@types";

import EventLandingShiftSlider from "@components/EventLandingComponents/EventLandingShiftSlider";
import {
  blockWidth,
  heightSlider,
} from "@pages/EventLandingPage/EventLandingPage.data";

import SponsorPositionCard from "../SponsorPositionCard";

import styles from "./PositionsForSponsor.module.scss";

interface ICard {
  id: number;
  image: string;
  title: string;
  description: string;
  numberPosition: number;
  coast: number;
}

const PositionsForSponsor: React.FC<{ mEvent?: IEvent | null }> = ({
  mEvent,
}) => {
  const { text } = useLanguage();
  const { data, loading } = useProducts("hybrid");
  const [marketingCard, setMarketingCard] = useState<ICard[]>([]);
  const [sponsorCard, setSponsorCard] = useState<ICard[]>([]);

  useEffect(() => {
    if (
      !marketingCard.length &&
      !sponsorCard.length &&
      data.length &&
      mEvent?.products
    ) {
      const marketingCardB = [] as ICard[];
      const sponsorCardB = [] as ICard[];

      mEvent.products.forEach((el) => {
        const product = data.find((obj) => obj.Id === el.productID);
        if (product) {
          const frame = {
            id: el.ID,
            title: product.Position,
            description: el.description,
            numberPosition: el.quanity,
            image: product.Example.split(" ")[0],
            coast: el.price,
          } as ICard;

          if (product.Group === "Merchandising") {
            marketingCardB.push(frame);
          } else {
            sponsorCardB.push(frame);
          }
        }
      });

      setMarketingCard(() => marketingCardB);
      setSponsorCard(() => sponsorCardB);
    }
  }, [data, loading, mEvent]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h2>{text[252]}</h2>
        <p>{text[253]}</p>
      </div>
      <div className={styles.content}>
        {!!marketingCard.length && (
          <EventLandingShiftSlider
            title="Marketing positions"
            data={marketingCard}
            blockWidth={blockWidth}
            Element={SponsorPositionCard}
            heightSlider={heightSlider}
          />
        )}
        {!!sponsorCard.length && (
          <EventLandingShiftSlider
            title="Sponsorship packages"
            data={sponsorCard}
            blockWidth={blockWidth}
            Element={SponsorPositionCard}
            heightSlider={heightSlider}
            indent={67}
          />
        )}
      </div>
    </div>
  );
};

export default MotionWrap(PositionsForSponsor);
