import React from "react";

import { IIcon } from "@types";

const WorkcationIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg
      className={className}
      width="126"
      height="18"
      viewBox="0 0 126 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.6416 17.0267H8.3596L11.4618 6.08581L14.5878 17.0267H18.3058L22.9473 0.449707H19.5135L16.3402 12.7403L12.859 0.449707H10.0646L6.6071 12.7403L3.4338 0.449707H0L4.6416 17.0267Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.9258 17.3583C32.407 17.3583 35.2014 14.635 35.2014 11.1064C35.2014 7.57789 32.407 4.85449 28.9258 4.85449C25.4446 4.85449 22.6739 7.57789 22.6739 11.1064C22.6739 14.635 25.4446 17.3583 28.9258 17.3583ZM28.9258 14.3745C27.126 14.3745 25.7288 13.0246 25.7288 11.1064C25.7288 9.18819 27.126 7.83839 28.9258 7.83839C30.7493 7.83839 32.1465 9.18819 32.1465 11.1064C32.1465 13.0246 30.7493 14.3745 28.9258 14.3745Z"
        fill="#333333"
      />
      <path
        d="M40.4054 5.1859V7.2226C41.0211 5.6122 42.513 4.9491 44.005 4.9491V8.3593C42.418 8.1698 40.4054 8.8803 40.4054 11.3668V17.0267H37.3505V5.1859H40.4054Z"
        fill="#333333"
      />
      <path
        d="M56.709 17.0267L51.807 11.0353L56.567 5.18591H52.92L48.847 10.3959V0.449707H45.792V17.0267H48.847V11.651L53.157 17.0267H56.709Z"
        fill="#333333"
      />
      <path
        d="M63.871 17.3585C66.192 17.3585 68.205 16.127 69.223 14.2799L66.571 12.7643C66.097 13.7352 65.079 14.3272 63.847 14.3272C62.024 14.3272 60.674 12.9774 60.674 11.1066C60.674 9.21201 62.024 7.86221 63.847 7.86221C65.055 7.86221 66.073 8.47791 66.547 9.44891L69.176 7.90961C68.205 6.08611 66.192 4.85461 63.871 4.85461C60.271 4.85461 57.619 7.57801 57.619 11.1066C57.619 14.6351 60.271 17.3585 63.871 17.3585Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M79.653 6.58341V5.18621H82.708V17.0269H79.653V15.6297C78.8 16.6954 77.521 17.3585 75.793 17.3585C72.643 17.3585 70.038 14.6351 70.038 11.1066C70.038 7.57801 72.643 4.85461 75.793 4.85461C77.521 4.85461 78.8 5.51771 79.653 6.58341ZM73.093 11.1066C73.093 13.0958 74.49 14.4456 76.361 14.4456C78.256 14.4456 79.653 13.0958 79.653 11.1066C79.653 9.11731 78.256 7.76751 76.361 7.76751C74.49 7.76751 73.093 9.11731 73.093 11.1066Z"
        fill="#333333"
      />
      <path
        d="M92.251 8.12275V5.18625H89.575V1.87085L86.52 2.79445V5.18625H84.46V8.12275H86.52V13.0485C86.52 16.2455 87.964 17.5007 92.251 17.027V14.2562C90.498 14.3509 89.575 14.3273 89.575 13.0485V8.12275H92.251Z"
        fill="#333333"
      />
      <path
        d="M97.815 1.8946C97.815 2.9129 96.962 3.7654 95.92 3.7654C94.902 3.7654 94.049 2.9129 94.049 1.8946C94.049 0.8763 94.902 0 95.92 0C96.962 0 97.815 0.8763 97.815 1.8946Z"
        fill="#333333"
      />
      <path d="M97.46 17.027H94.405V5.1863H97.46V17.027Z" fill="#333333" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M105.892 17.3585C109.373 17.3585 112.167 14.6351 112.167 11.1066C112.167 7.57801 109.373 4.85461 105.892 4.85461C102.411 4.85461 99.64 7.57801 99.64 11.1066C99.64 14.6351 102.411 17.3585 105.892 17.3585ZM105.892 14.3746C104.092 14.3746 102.695 13.0248 102.695 11.1066C102.695 9.18841 104.092 7.83851 105.892 7.83851C107.715 7.83851 109.112 9.18841 109.112 11.1066C109.112 13.0248 107.715 14.3746 105.892 14.3746Z"
        fill="#333333"
      />
      <path
        d="M117.371 6.51241C118.082 5.44671 119.313 4.85461 120.9 4.85461C123.41 4.85461 125.376 6.60711 125.376 9.75671V17.0269H122.321V10.1356C122.321 8.52531 121.35 7.69641 119.976 7.69641C118.484 7.69641 117.371 8.57261 117.371 10.6329V17.0269H114.316V5.18621H117.371V6.51241Z"
        fill="#333333"
      />
    </svg>
  );
};

export default WorkcationIcon;
