import React from "react";

import { IIcon } from "@types";

const WhatsappIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      width="24"
      height="24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.004 22L3.356 17.032C2.46514 15.5049 1.99709 13.768 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C10.2328 22.0029 8.49665 21.5352 6.97 20.645L2.004 22ZM8.391 7.308C8.26186 7.31602 8.13567 7.35003 8.02 7.408C7.91152 7.46943 7.81249 7.54622 7.726 7.636C7.606 7.749 7.538 7.847 7.465 7.942C7.09541 8.423 6.89661 9.01342 6.9 9.62C6.902 10.11 7.03 10.587 7.23 11.033C7.639 11.935 8.312 12.89 9.201 13.775C9.415 13.988 9.624 14.202 9.849 14.401C10.9524 15.3725 12.2673 16.073 13.689 16.447L14.258 16.534C14.443 16.544 14.628 16.53 14.814 16.521C15.1052 16.506 15.3896 16.4271 15.647 16.29C15.778 16.2225 15.9058 16.1491 16.03 16.07C16.03 16.07 16.073 16.042 16.155 15.98C16.29 15.88 16.373 15.809 16.485 15.692C16.568 15.606 16.64 15.505 16.695 15.39C16.773 15.227 16.851 14.916 16.883 14.657C16.907 14.459 16.9 14.351 16.897 14.284C16.893 14.177 16.804 14.066 16.707 14.019L16.125 13.758C16.125 13.758 15.255 13.379 14.724 13.137C14.668 13.1126 14.608 13.0987 14.547 13.096C14.4786 13.089 14.4094 13.0967 14.3442 13.1186C14.2791 13.1405 14.2193 13.1761 14.169 13.223V13.221C14.164 13.221 14.097 13.278 13.374 14.154C13.3325 14.2098 13.2753 14.2519 13.2098 14.2751C13.1443 14.2982 13.0733 14.3013 13.006 14.284C12.9408 14.2666 12.877 14.2445 12.815 14.218C12.691 14.166 12.648 14.146 12.563 14.109L12.558 14.107C11.9859 13.8572 11.4562 13.5198 10.988 13.107C10.862 12.997 10.745 12.877 10.625 12.761C10.2316 12.3842 9.88871 11.958 9.605 11.493L9.546 11.398C9.50362 11.3342 9.46936 11.2653 9.444 11.193C9.406 11.046 9.505 10.928 9.505 10.928C9.505 10.928 9.748 10.662 9.861 10.518C9.95509 10.3983 10.0429 10.2738 10.124 10.145C10.242 9.955 10.279 9.76 10.217 9.609C9.937 8.925 9.647 8.244 9.349 7.568C9.29 7.434 9.115 7.338 8.956 7.319C8.902 7.313 8.848 7.307 8.794 7.303C8.6597 7.29633 8.52513 7.29766 8.391 7.307V7.308Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default WhatsappIcon;
