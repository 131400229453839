import React from "react";
import Slider from "react-slick";

import { Cards } from "@components/EventPageComponents/CalendarBlock/CalendarBlock.data";
import CalendarCard from "@components/EventPageComponents/CalendarCard";

import styles from "./EventPositionEventsBlock.module.scss";

const EventPositionEventsBlock: React.FC = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 1,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          centerMode: true,
        },
      },
    ],
  };
  return (
    <div className={styles.block}>
      <div className={styles.container}>
        <Slider {...settings}>
          {Cards.map((item) => (
            <CalendarCard
              {...item}
              key={item.id}
              inlineStyle={{ marginRight: "20px" }}
            />
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default EventPositionEventsBlock;
