import React, { useEffect, useState } from "react";

import { filterIcon } from "@assets";

import CalendarCard from "../CalendarCard";

import { Cards, menuItems } from "./CalendarBlock.data";
import styles from "./CalendarBlock.module.scss";

const CalendarBlock: React.FC = () => {
  const [menu, setMenu] = useState(menuItems);
  const [select, setSelect] = useState(menu[0].title);
  const [arrayCards, setArrayCards] = useState(Cards);

  const onSelect = (id: number): (() => void) => {
    return () => {
      setMenu((prev) => {
        return prev.map((item) =>
          item.id === id
            ? { ...item, isSelect: true }
            : { ...item, isSelect: false },
        );
      });
      setSelect(menu[id].title);
    };
  };

  useEffect(() => {
    setArrayCards(() => {
      return select === "All"
        ? Cards
        : Cards.filter((item) => item.state === select);
    });
  }, [select]);

  return (
    <div className={styles.calendar_block}>
      <div className={styles.title}>
        <div>Events calendar</div>
        <div>
          <span>Default</span>
          <img src={filterIcon} alt="filter" />
        </div>
      </div>

      <div className={styles.filter}>
        <div className={styles.left_side}>
          {menu.map((item) => (
            <span
              key={item.id}
              className={item.isSelect ? styles.active : ""}
              onClick={onSelect(item.id)}
            >
              {item.title}
            </span>
          ))}
        </div>
        <div className={styles.right_side}>
          <span>Default</span>
          <img src={filterIcon} alt="filter" />
        </div>
      </div>
      <div className={styles.block}>
        <div className={styles.shift_block}>
          {arrayCards.map((item) => (
            <CalendarCard {...item} key={item.id} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CalendarBlock;
