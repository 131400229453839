import { useState } from "react";

type OutputType = {
  isHovered: boolean;
  onEnter: () => void;
  onLeave: () => void;
};

export const useHover = (): OutputType => {
  const [isHovered, setIsHovered] = useState(false);
  const onEnter = (): void => setIsHovered(true);
  const onLeave = (): void => setIsHovered(false);

  return { isHovered, onEnter, onLeave };
};
