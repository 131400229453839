import classnames from "classnames";
import { motion } from "framer-motion";
import React from "react";

import styles from "./LandingBlock.module.scss";

export interface LandingBlockProps {
  topTitle?: string;
  title: string;
  subTitle?: string;
}

const LandingBlock: React.FC<LandingBlockProps> = ({
  topTitle,
  title,
  subTitle,
  children,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.header_container}>
        <div className={styles.header_wrapper}>
          {topTitle && (
            <motion.h5
              initial={{ y: 30, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.2 }}
              className={styles.toptitle}
            >
              {topTitle}
            </motion.h5>
          )}
          <motion.h3
            initial={{ y: 30, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.4 }}
            className={classnames(styles.title, { [styles.bottom]: !subTitle })}
          >
            {title}
          </motion.h3>
          {subTitle && (
            <motion.p
              initial={{ y: 30, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.6 }}
              className={styles.subtitle}
            >
              {subTitle}
            </motion.p>
          )}
        </div>
      </div>
      <motion.div
        initial={{ x: 100, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ delay: 1, duration: 2 }}
        className={styles.content}
      >
        {children}
      </motion.div>
    </div>
  );
};

export default LandingBlock;
