import React from "react";
import { HashRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import {
  AuthProvider,
  ErrorProvider,
  EventProvider,
  LanguageProvider,
  ScrollProvider,
} from "@context";
import { GOOGLE_CLIENT_ID } from "@helpers";
import Navigation from "@routes";

import { GoogleOAuthProvider } from "@react-oauth/google";

const App: React.FC = () => {
  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID || ""}>
      <HashRouter>
        <ErrorProvider>
          <AuthProvider>
            <LanguageProvider>
              <ScrollProvider>
                <EventProvider>
                  <Navigation />
                  <ToastContainer />
                </EventProvider>
              </ScrollProvider>
            </LanguageProvider>
          </AuthProvider>
        </ErrorProvider>
      </HashRouter>
    </GoogleOAuthProvider>
  );
};

export default App;
