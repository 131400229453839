import React from "react";

import { useLanguage } from "@context";
import { MotionWrap } from "@hoc";

import styles from "./HelpProject.module.scss";

const HelpProject: React.FC = () => {
  const { text } = useLanguage();
  return (
    <div className={styles.wrapper}>
      <div className={styles.filter_img} />
      <div className={styles.content}>
        <h2>{text[257]}</h2>
        <p>{text[258]}</p>
        <button type="button">{text[259]}</button>
      </div>
    </div>
  );
};

export default MotionWrap(HelpProject);
