export const userProfileValidationMap = {
  FirstName: {
    helperText: "Field is required",
    isError: (value: string | undefined): boolean => !value?.length,
  },
  SecondName: {
    helperText: "Field is required",
    isError: (value: string | undefined): boolean => !value?.length,
  },
  Email: {
    helperText: "Invalid Email",
    isError: (value: string | undefined): boolean =>
      !value
        ?.toLowerCase()
        ?.match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        ),
  },
  EventEndDate: {
    helperText: "The end date must be more than the start date",
    isError: (value: { start: Date; end: Date }): boolean =>
      !(value.end > value.start),
  },
  HomeAddress: {
    helperText: "Field is required",
    isError: (value: string | undefined): boolean => !value?.length,
  },
  DateOfBirth: {
    helperText: "Field is required",
    isError: (value: string | undefined): boolean => !value?.length,
  },
  OrganizationSportType: {
    helperText: "Field is required",
    isError: (value: string | undefined): boolean => !value,
  },
  OrganizationName: {
    helperText: "Field is required",
    isError: (value: string | undefined): boolean => !value?.length,
  },
  OrganizationLocation: {
    helperText: "Field is required",
    isError: (value: string | undefined): boolean => !value?.length,
  },
  OrganizationDescription: {
    helperText: "Field is required",
    isError: (value: string | undefined): boolean => !value?.length,
  },
  EventSportType: {
    helperText: "Field is required",
    isError: (value: string | undefined): boolean => !value,
  },
  EventDescription: {
    helperText: "Field is required",
    isError: (value: string | undefined): boolean => !value?.length,
  },
};
