import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { useHover } from "@hooks";
import { IDataId } from "@types";

import CircularIndicator from "../../../CircularIndicator";

import styles from "./MarketplaceSlider.module.scss";

interface MarketplaceSliderProps {
  data: IDataId[];
  blockWidth: number;
  title: string;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  Element: React.FC<any>;
  className?: string;
  heightSlider?: number;
  indent?: number;
}

const MarketplaceSlider: React.FC<MarketplaceSliderProps> = ({
  data,
  title,
  blockWidth,
  Element,
  className,
  heightSlider,
}) => {
  const fullWidth = data.length * blockWidth - 35;
  const screenWidth = window.screen.width;

  const [shiftWidth, setShiftWidth] = useState<number>(0);
  const [seconds, setSeconds] = useState<number>(1);
  const [percentage, setPercentage] = useState<number>(1);
  let shiftsNumber =
    fullWidth - screenWidth > 0
      ? Math.ceil((fullWidth - screenWidth) / blockWidth)
      : 0;

  if (screenWidth < 500) shiftsNumber = 0;

  const [count, setCount] = useState(shiftsNumber);

  const prev = useHover();
  const next = useHover();

  useEffect(() => {
    if (count > 0) {
      setTimeout(() => {
        setPercentage(99);
        setTimeout(() => {
          setShiftWidth((pre) => pre - blockWidth);

          setCount((pre) => pre - 1);
        }, 3000);
      }, 3000);
    }
    setPercentage(1);
  }, [count]);

  const onNext = (): void => {
    setSeconds(1);
    if (fullWidth + shiftWidth > screenWidth)
      setShiftWidth((pre) => pre - blockWidth);
  };

  const onPrev = (): void => {
    setSeconds(1);
    if (shiftWidth < 0) setShiftWidth((pre) => pre + blockWidth);
  };
  return (
    <div className={styles.slider} style={{ height: heightSlider }}>
      <div className={styles.title}>
        <h3 className={className}>{title}</h3>
        <div className={styles.button}>
          <button
            type="button"
            className={styles.arrow}
            onClick={onPrev}
            onMouseEnter={prev.onEnter}
            onMouseLeave={prev.onLeave}
          >
            <CircularIndicator
              className={styles.svg_cicl}
              percentage={0}
              isLeft
              iHover={prev.isHovered}
            />
          </button>
          <button
            type="button"
            className={styles.arrow}
            onClick={onNext}
            onMouseEnter={next.onEnter}
            onMouseLeave={next.onLeave}
          >
            <CircularIndicator
              className={styles.svg_cicl}
              percentage={percentage}
              isLeft={false}
              iHover={next.isHovered}
            />
          </button>
        </div>
      </div>
      <div
        className={styles.slider_container}
        style={{ left: shiftWidth, transition: `all ${seconds}s ease-out` }}
      >
        {data.map((item) => (
          <Link to={`/marketplace/${item.id}`} key={item.id}>
            <Element {...item} />
          </Link>
        ))}
      </div>
    </div>
  );
};

export default MarketplaceSlider;
