import React from "react";

import { ReactComponent as Facebook } from "@assets/icons/social/facebook.svg";
import { ReactComponent as Insta } from "@assets/icons/social/insta.svg";
import { ReactComponent as Linkedin } from "@assets/icons/social/linkedin.svg";
import { ReactComponent as Twitter } from "@assets/icons/social/twitter.svg";
import { h1, h2, h3, logo, p } from "@assets/images/zaglushka";
import { ReactComponent as Icon } from "@assets/images/zaglushka/icon.svg";

import styles from "./Default.module.scss";

const Default: React.FC = () => {
  return (
    <section className={styles.section}>
      <img className={styles.title1} src={logo} alt="title" />
      <img className={styles.title2} src={h1} alt="title" />
      <img className={styles.title3} src={h2} alt="title" />
      <img className={styles.title4} src={h3} alt="title" />
      <p className={styles.paragraph}>
        <Icon width="40" height="40" /> <img src={p} alt="title" />
      </p>
      <ul className={styles.social_list}>
        <li className={styles.social_item}>
          <a
            target="_blank"
            className={styles.social_link}
            href="https://facebook.com/"
            rel="noreferrer"
          >
            <Facebook width="20" height="20" />
          </a>
        </li>
        <li className={styles.social_item}>
          <a
            target="_blank"
            className={styles.social_link}
            href="https://twitter.com/monetiseur_io"
            rel="noreferrer"
          >
            <Twitter width="20" height="20" />
          </a>
        </li>
        <li className={styles.social_item}>
          <a
            target="_blank"
            className={styles.social_link}
            href="https://www.linkedin.com/company/monetiseur"
            rel="noreferrer"
          >
            <Linkedin width="23" height="22" />
          </a>
        </li>
        <li className={styles.social_item}>
          <a
            target="_blank"
            className={styles.social_link}
            href="https://instagram.com/monetiseur.io?igshid=YmMyMTA2M2Y="
            rel="noreferrer"
          >
            <Insta width="20" height="20" />
          </a>
        </li>
      </ul>
    </section>
  );
};

export default Default;
