import React from "react";
import Slider from "react-slick";

import { coverage, payment, planned, under } from "@assets/icons/eventPosition";

import styles from "./EventPositionStatistics.module.scss";
import EventPositionStatisticsItem from "./EventPositionStatisticsItem";

const EventPositionStatistics: React.FC = () => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 4,
    swipeToSlide: true,
    initialSlide: 0,
    // variableWidth: true,
    responsive: [
      {
        breakpoint: 1439,
        settings: {
          slidesToShow: 3,
          variableWidth: true,

          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className={styles.block}>
      <div className={styles.container}>
        {/* <div className={styles.list}> */}
        <Slider {...settings}>
          <EventPositionStatisticsItem
            img={planned}
            stats="178+"
            text="Planned positions"
          />
          <EventPositionStatisticsItem
            img={under}
            stats="20+"
            text="Under consideration"
          />
          <EventPositionStatisticsItem
            img={coverage}
            stats="17800+"
            text="Coverage"
          />
          <EventPositionStatisticsItem
            img={payment}
            stats="$17780"
            text="Payment"
          />
        </Slider>
        {/* </div> */}
      </div>
    </div>
  );
};

export default EventPositionStatistics;
