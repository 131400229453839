import React from "react";

import { IIcon } from "@types";

const UsaIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg
      className={className}
      width="19"
      height="14"
      viewBox="0 0 19 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_758_5768"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="19"
        height="14"
      >
        <path
          d="M17.1905 0H1.80952C0.810151 0 0 0.810151 0 1.80952V11.7619C0 12.7613 0.810151 13.5714 1.80952 13.5714H17.1905C18.1898 13.5714 19 12.7613 19 11.7619V1.80952C19 0.810151 18.1898 0 17.1905 0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_758_5768)">
        <path
          d="M17.1905 0H1.80952C0.810151 0 0 0.810151 0 1.80952V11.7619C0 12.7613 0.810151 13.5714 1.80952 13.5714H17.1905C18.1898 13.5714 19 12.7613 19 11.7619V1.80952C19 0.810151 18.1898 0 17.1905 0Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.33333 0H19V0.904762H6.33333V0ZM6.33333 1.80952H19V2.71429H6.33333V1.80952ZM6.33333 3.61905H19V4.52381H6.33333V3.61905ZM6.33333 5.42857H19V6.33333H6.33333V5.42857ZM0 7.2381H19V8.14286H0V7.2381ZM0 9.04762H19V9.95238H0V9.04762ZM0 10.8571H19V11.7619H0V10.8571Z"
          fill="#C1012B"
        />
        <path d="M19 12.6667H0V13.5714H19V12.6667Z" fill="#C1012B" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0H6.33333V6.33333H0V0Z"
          fill="#00256A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.1124 4.43057L1.74802 5.14805L2.00859 3.62805L0.904785 2.55409L2.43021 2.33243L3.1124 0.949951L3.79459 2.33152L5.32002 2.55319L4.21621 3.62986L4.47679 5.14805"
          fill="white"
        />
        <path
          d="M17.1905 0.452393H1.80954C1.06001 0.452393 0.452393 1.06001 0.452393 1.80954V11.7619C0.452393 12.5114 1.06001 13.1191 1.80954 13.1191H17.1905C17.94 13.1191 18.5476 12.5114 18.5476 11.7619V1.80954C18.5476 1.06001 17.94 0.452393 17.1905 0.452393Z"
          stroke="black"
          strokeOpacity="0.1"
        />
      </g>
    </svg>
  );
};

export default UsaIcon;
