import React from "react";

import {
  CurrencyIcon,
  LanguageIcon,
  PersonalIcon,
  SecurityIcon,
  UpdateKYCIcon,
} from "@assets/icons/globalSet";

interface IMenuItems {
  key: number;
  name: string;
  icon: React.FC;
}

export const MenuItems: IMenuItems[] = [
  {
    key: 0,
    name: "Personal Information",
    icon: PersonalIcon,
  },
  {
    key: 1,
    name: "Language",
    icon: LanguageIcon,
  },
  {
    key: 2,
    name: "Password & Security",
    icon: SecurityIcon,
  },
  {
    key: 3,
    name: "Currency Preferency",
    icon: CurrencyIcon,
  },
  {
    key: 4,
    name: "KYC Update",
    icon: UpdateKYCIcon,
  },
];
