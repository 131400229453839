import React, { useEffect, useState } from "react";

import { useLanguage } from "@context";
import { IDProduct } from "@types";

import { web1 } from "@assets/images/Temples/WebStreaming";
import { isEmpty } from "@lib/lodash";

import styles from "./CenterSide.module.scss";

interface targetType {
  img: string;
  isActive: boolean;
}

interface CenterSideProps {
  target: IDProduct;
}

const CenterSide: React.FC<CenterSideProps> = ({ target }) => {
  const { text } = useLanguage();
  const [targetImg, setTargetImg] = useState<targetType[]>([
    { img: web1, isActive: true },
  ]);
  const [viewImg, setViewImg] = useState<string>();

  useEffect(() => {
    if (!isEmpty(target)) {
      const tempTarget = target.gallery.map((item, index) => {
        return { img: item, isActive: index === 0 };
      });
      setTargetImg(tempTarget);
    }
  }, [target]);

  useEffect(() => {
    const tempView = targetImg.find((item) => item.isActive)?.img;
    setViewImg(tempView);
  }, [targetImg, setTargetImg]);

  const onActive = (src: string) => {
    return () =>
      setTargetImg((pre) =>
        pre.map((item) =>
          item.img === src
            ? { ...item, isActive: true }
            : { ...item, isActive: false },
        ),
      );
  };

  if (isEmpty(target)) return null;

  return (
    <div className={styles.center}>
      <div className={styles.title}>
        <h2>
          {text[298]}: <span>{target.group.toLowerCase()}</span>
        </h2>
        <h3>{target.title}</h3>
      </div>
      <figure className={styles.img_block}>
        <img alt="positions" src={viewImg} />
      </figure>
      <div className={styles.all_img}>
        {targetImg.map((item) => (
          <button
            type="button"
            key={item.img}
            className={item.isActive ? styles.active : ""}
            onClick={onActive(item.img)}
          >
            <img src={item.img} alt="positions" />
          </button>
        ))}
      </div>
    </div>
  );
};

export default CenterSide;
