import { erik, kaido, petrov } from "@assets/images/organizersLanding/champ";

export const champData = {
  example1: [
    {
      id: 1,
      img: kaido,
      title: "Looking for a young adrenaline junkie? We got you covered!",
      champName: "Kaido Külaots",
      duties: "Chess Grandmaster",
    },
    {
      id: 2,
      img: kaido,
      title: "Looking for a young adrenaline junkie? We got you covered!",
      champName: "Kaido Külaots",
      duties: "Chess Grandmaster",
    },
    {
      id: 3,
      img: kaido,
      title: "Looking for a young adrenaline junkie? We got you covered!",
      champName: "Kaido Külaots",
      duties: "Chess Grandmaster",
    },
  ],
  example2: [
    {
      id: 4,
      img: petrov,
      title: "Introducing",
      champName: "Andres Petrov",
      duties: "European champion in snooker",
    },
    {
      id: 5,
      img: petrov,
      title: "Introducing",
      champName: "Andres Petrov",
      duties: "European champion in snooker",
    },
    {
      id: 6,
      img: petrov,
      title: "Introducing",
      champName: "Andres Petrov",
      duties: "European champion in snooker",
    },
  ],
  example3: [
    {
      id: 7,
      img: erik,
      title: "Launching a new summer drink?",
      champName: "Tristen Erik Kivi",
      duties: "World champion in windsurfing",
    },
    {
      id: 8,
      img: erik,
      title: "Launching a new summer drink?",
      champName: "Tristen Erik Kivi",
      duties: "World champion in windsurfing",
    },
    {
      id: 9,
      img: erik,
      title: "Launching a new summer drink?",
      champName: "Tristen Erik Kivi",
      duties: "World champion in windsurfing",
    },
  ],
};
