import React, { useState } from "react";

import styles from "./SettingsKYCUpdate.module.scss";

const data = [
  {
    id: 0,
    type: "Authentication app",
    name: "Google auth app",
    isEnable: false,
  },
  {
    id: 1,
    type: "Another e-mail",
    name: "E-mail to send verification link",
    isEnable: false,
  },
  {
    id: 2,
    type: "SMS Recovery",
    name: "Your phone number or something",
    isEnable: false,
  },
];

const SettingsKycUpdate: React.FC = () => {
  const [typeKYS, setTypeKYS] = useState(data);

  const onEnable = (key: number) => {
    return () => {
      setTypeKYS((prev) => {
        return prev.map((item) =>
          item.id === key ? { ...item, isEnable: !item.isEnable } : { ...item },
        );
      });
    };
  };
  return (
    <div className={styles.container}>
      <h4 className={styles.title}>KYC Update</h4>
      <div className={styles.block}>
        {typeKYS.map((item) => (
          <div className={styles.item} key={item.id}>
            <div className={styles.text}>
              <h4>{item.type}</h4>
              <div>{item.name}</div>
            </div>
            <button type="button" onClick={onEnable(item.id)}>
              {item.isEnable ? "Enable" : "Disable"}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SettingsKycUpdate;
