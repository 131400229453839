import React from "react";

import { IIcon } from "@types";

const AvailableIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.7167 7.94232L8.82502 13.834L5.29169 10.3007L6.46669 9.12565L8.82502 11.484L13.5417 6.76732L14.7167 7.94232ZM3.33335 10.0007C3.33335 8.05898 4.18335 6.31732 5.51669 5.10065L7.50002 7.08398V2.08398H2.50002L4.33335 3.91732C2.70002 5.43398 1.66669 7.59232 1.66669 10.0007C1.66669 14.3257 4.95835 17.8757 9.16669 18.2923V16.609C5.88335 16.2007 3.33335 13.3923 3.33335 10.0007ZM18.3334 10.0007C18.3334 5.67565 15.0417 2.12565 10.8334 1.70898V3.39232C14.1167 3.80065 16.6667 6.60898 16.6667 10.0007C16.6667 11.9423 15.8167 13.684 14.4834 14.9007L12.5 12.9173V17.9173H17.5L15.6667 16.084C17.3 14.5673 18.3334 12.409 18.3334 10.0007Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default AvailableIcon;
