import classnames from "classnames";
import React, { RefObject, useState } from "react";

import { AddIcon } from "@assets/icons/kit";
import { useId } from "@hooks/useId";

import Date from "./Date";
import styles from "./Input.module.scss";
import Select from "./Select";

export interface InputProps extends React.InputHTMLAttributes<HTMLElement> {
  label: string;
  pr?: number;
  selectValue?: string;
  inputValue?: string;
  onPhoneChange?: (alias: string, value: string) => void;
  placeholder?: string;
  inputType?: string;
  required?: boolean;
  error?: string;
  pattern?: string;
  inputRef?: RefObject<HTMLInputElement>;
  textRef?: RefObject<HTMLTextAreaElement>;
  selectRef?: RefObject<HTMLDivElement>;
}

const Input: React.FC<InputProps> = (props) => {
  const key = useId("input");
  const {
    label,
    className,
    placeholder,
    inputType,
    required,
    pattern,
    error,
    inputRef,
    ...other
  } = props;

  const handleError = (e: { target: { value: string } }): void => {
    if (required && !e.target.value) {
      inputRef?.current?.setAttribute("style", "border:1px solid #e53d3e");
    } else {
      inputRef?.current?.setAttribute("style", "border:1px solid #F5F5F5");
    }
  };

  return (
    <div
      className={classnames(
        styles.container,
        className,
        !!error && styles.inputError,
      )}
    >
      <label htmlFor={key}>{label}</label>
      <input
        id={key}
        ref={inputRef}
        type={inputType && "text"}
        {...other}
        placeholder={placeholder}
        required={required}
        pattern={pattern}
        onBlur={(e) => handleError(e)}
      />
      {!!error && <div className={styles.errorText}>{error}</div>}
    </div>
  );
};

export const InputSelect = (props: InputProps): JSX.Element => {
  const key = useId("select");
  const { label, className, children, selectRef, error, ...other } = props;
  return (
    <div
      className={classnames(
        styles.container,
        className,
        !!error && styles.inputError,
      )}
      ref={selectRef}
    >
      <label htmlFor={key}>{label}</label>
      <Select id={key} {...other}>
        {children}
      </Select>
      {!!error && <div className={styles.errorText}>{error}</div>}
    </div>
  );
};

export const InputDate = (props: InputProps): JSX.Element => {
  const key = useId("date");
  const { label, className, selectRef, ...other } = props;
  return (
    <div className={classnames(styles.container, className)} ref={selectRef}>
      <label htmlFor={key}>{label}</label>
      <Date id={key} {...other} />
    </div>
  );
};

export const InputPhone = (props: InputProps): JSX.Element => {
  const key = useId("phone");
  const {
    label,
    className,
    pr,
    selectValue,
    inputValue,
    children,
    onPhoneChange,
    ...other
  } = props;
  const [alias, setAlias] = useState(selectValue || "");
  const [number, setNumber] = useState(inputValue || "");

  const onInput = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setNumber(event.target.value);
    if (onPhoneChange) {
      onPhoneChange(alias, event.target.value);
    }
  };

  const onSelect = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    setAlias(event.target.value);
    if (onPhoneChange) {
      onPhoneChange(event.target.value, number);
    }
  };

  return (
    <div className={classnames(styles.container, className)}>
      <label htmlFor={key}>{label}</label>
      <div className={styles.phone}>
        <Select id={key} pr={pr} {...other} value={alias} onChange={onSelect}>
          {children}
        </Select>
        <input
          id={key}
          type="text"
          {...other}
          value={number}
          onChange={onInput}
        />
      </div>
    </div>
  );
};

export const InputText: React.FC<InputProps> = (props) => {
  const key = useId("text");
  const { label, className, onChange, textRef, required, error, ...other } =
    props;
  const [words, setWords] = useState(0);

  const onChangeText = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ): void => {
    const wordsCount = event.target.value.split(" ").length;
    if (wordsCount <= 100) {
      if (onChange) onChange(event);
      setWords(event.target.value.split(" ").length);
    }
  };

  const handleError = (e: { target: { value: string } }): void => {
    if (required && !e.target.value) {
      textRef?.current?.setAttribute("style", "border:1px solid #e53d3e");
    } else {
      textRef?.current?.setAttribute("style", "border:1px solid #F5F5F5");
    }
  };

  return (
    <div
      className={classnames(
        styles.container,
        className,
        !!error && styles.inputError,
      )}
    >
      <label htmlFor={key}>{label}</label>
      <textarea
        id="notes"
        {...other}
        onChange={onChangeText}
        required={required}
        ref={textRef}
        onBlur={(e) => handleError(e)}
      />
      <div className={styles.textAreaFooter}>
        {!!error && <div className={styles.errorText}>{error}</div>}
        <span className={styles.text}>{words} / 100</span>
      </div>
    </div>
  );
};

export interface InputFileProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  fileRef?: React.Ref<HTMLInputElement>;
  uploadFile?: () => void;
  title: string;
}

export const InputFile: React.FC<InputFileProps> = ({
  label,
  className,
  fileRef,
  uploadFile,
  title,
  ...other
}) => {
  const key = useId("file");

  return (
    <div
      className={classnames(styles.input_file, className)}
      onClick={uploadFile}
    >
      <label htmlFor={key}>{label}</label>
      <input
        type="file"
        id={key}
        ref={fileRef}
        {...other}
        className={styles.file}
      />
      <div className={styles.as_file}>
        <AddIcon className={styles.add_icon} /> {title}
      </div>
    </div>
  );
};

export default Input;
