import React from "react";

import { LocationIconSvg } from "@assets";
import { useAuth, useEvent } from "@context";
import { transformDate } from "@helpers";
import { IEvent, IUserEvent } from "@types";

import {
  backsIcon,
  calendarIcon,
  headsetIcon,
  phoneIcon,
  userIcon,
  usersIcon,
} from "@assets/icons/event/details";
import GMap from "@components/GMap/GMap";

import styles from "./MainInformation.module.scss";

interface IMainInformation {
  mEvent?: IEvent | null;
  mUser?: IUserEvent | null;
}

const MainInformation: React.FC<IMainInformation> = ({ mEvent, mUser }) => {
  const { event, rawProducts } = useEvent();
  const { user } = useAuth();

  return (
    <div className={styles.content}>
      <div className={styles.data_event}>
        <article className={styles.description}>
          <h2>Description of the event</h2>
          {event?.description && <p>{event.description}</p>}
          {mEvent?.description && <p>{mEvent.description}</p>}
        </article>
        {/* <article className={styles.additional}> */}
        {/*   <h2>Additional information about the organization</h2> */}
        {/*   <p> */}
        {/*     We sponsor sports and create opportunities for physical education, */}
        {/*     support the new generation, and highly value culture and artistic */}
        {/*     heritage. */}
        {/*   </p> */}
        {/* </article> */}
        <article className={styles.location}>
          <h2>Property Location</h2>
          <div className={styles.input_location}>
            <div className={styles.icon}>
              <img src={LocationIconSvg} alt="location" />
            </div>
            <span>{mEvent?.location || event?.location}</span>
          </div>
          <div className={styles.map}>
            <GMap
              address={mEvent?.location || event?.location || ""}
              zoom={17}
            />
          </div>
        </article>
      </div>
      <div className={styles.details}>
        <h2>Details</h2>
        <div className={styles.item_block}>
          <div className={styles.item}>
            <img src={backsIcon} alt="icon" />
            <div className={styles.text}>
              <h3>Total cost of the event</h3>
              <div>{rawProducts.reduce((acc, p) => acc + p.price, 0)}</div>
            </div>
          </div>
          <div className={styles.item}>
            <img src={phoneIcon} alt="icon" />
            <div className={styles.text}>
              <h3>Contact details</h3>
              <div>{mUser?.PhoneNumber || user.PhoneNumber}</div>
            </div>
          </div>
          <div className={styles.item}>
            <img src={userIcon} alt="icon" />
            <div className={styles.text}>
              <h3>Managing agent</h3>
              <div>
                {mUser?.FirstName || user.FirstName}{" "}
                {mUser?.SecondName || user.SecondName}
              </div>
            </div>
          </div>
          <div className={styles.item}>
            <img src={calendarIcon} alt="icon" />
            <div className={styles.text}>
              <h3>Event date</h3>
              <div>
                {event && transformDate(event.startTime, event.endTime)}
              </div>
              <div>
                {mEvent && transformDate(mEvent.startTime, mEvent.endTime)}
              </div>
            </div>
          </div>
          <div className={styles.item}>
            <img src={calendarIcon} alt="icon" />
            <div className={styles.text}>
              <h3>Founded</h3>
              <div>2022</div>
            </div>
          </div>
          <div className={styles.item}>
            <img src={usersIcon} alt="icon" />
            <div className={styles.text}>
              <h3>Estimated number of participants</h3>
              <div>{mEvent?.participantNumber || event?.participantNumber}</div>
            </div>
          </div>
          <div className={styles.item}>
            <img src={headsetIcon} alt="icon" />
            <div className={styles.text}>
              <h3>Estimated number of participants</h3>
              <div>{mEvent?.mediaReach || event?.mediaReach}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainInformation;
