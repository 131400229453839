import React from "react";

import { useLanguage } from "@context";

import { ViewCarouselIcon } from "@assets/icons/event";
import { SponsorPositionType } from "@pages/EventLandingPage/EventLandingPage.data";

import styles from "./SponsorPositionCard.module.scss";

const SponsorPositionCard: React.FC<SponsorPositionType> = ({
  id,
  title,
  description,
  numberPosition,
  coast,
  image,
}) => {
  const { text } = useLanguage();
  return (
    <div className={styles.position} key={id}>
      <div className={styles.images}>
        <img src={image} alt="position" />
      </div>
      <div className={styles.text}>
        <div className={styles.header}>
          <h3>{title}</h3>
          <p>{description}</p>
        </div>
        <div className={styles.number_pos}>
          <ViewCarouselIcon />
          <span>
            {numberPosition} {text[255]}
          </span>
        </div>
        <div className={styles.footer}>
          <div className={styles.coast}>
            <span>$ {coast}</span>
            <span>/{text[255]}</span>
          </div>
          <button type="button">{text[256]}</button>
        </div>
      </div>
    </div>
  );
};

export default SponsorPositionCard;
