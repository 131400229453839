import React, { useRef, useState } from "react";

import { dateAdapter } from "@helpers";
import { useImage } from "@hooks";
import { Input, InputSelect, InputText, SportKinds } from "@shared";
import { IEventUser } from "@types";

import { PricingIcon, SettingIcon } from "@assets/icons/kit";
import DatePickerField from "@components/DatePickerField";
import GMapModal from "@components/GMap/GMapModal";
import { UploadLogoFile } from "@components/UploadLogoFile";
import { useComponentVisible } from "@hooks/useComponentVisible";
import { useProfile } from "@pages/ProfilePage/context";

import ProfileContainer from "../../../ProfileContainer";
import ActionMenu from "../ActionMenu";

import styles from "./PortfolioEvent.module.scss";

export interface PortfolioCreateProps {
  event: IEventUser;
  openEventId?: number | string | null;
  handleOpen: (val: number | string | null) => void;
}

const PortfolioEvent: React.FC<PortfolioCreateProps> = ({
  event,
  openEventId,
  handleOpen,
}) => {
  const { handleEventChange, validator, validate } = useProfile();
  const [isOpenMap, setIsOpenMap] = useState(false);
  const fileRef = useRef<HTMLInputElement>(null);
  const file = useImage(fileRef);

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const eventHasError = validate && (!event.sportType || !event.description);

  const handleShowMenu = (): void => {
    setIsComponentVisible(!isComponentVisible);
  };

  const handleEditEvent = (id: number | string | null): void => {
    handleOpen(id);
  };

  const handleEventEdit = (): void => {
    setIsComponentVisible(false);
    handleEditEvent(event.id);
  };

  const handleEventClose = (): void => {
    setIsComponentVisible(false);
    handleOpen(null);
  };

  const handleChangeCurrentEvent = (fieldName: string, value: string): void => {
    handleEventChange(event.id, fieldName, value);
  };

  const handleInputChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>): void => {
    handleChangeCurrentEvent(target.name, target.value);
  };

  const handleChangeDate = (type: string, date: Date | null): void => {
    if (!date) return;
    handleChangeCurrentEvent(type, dateAdapter.toServer(date.toISOString()));
  };

  const handleAddressChange = (address: string): void => {
    handleChangeCurrentEvent("location", address);
  };

  const handleSelectChange = ({
    target,
  }: React.ChangeEvent<HTMLSelectElement>): void => {
    handleChangeCurrentEvent(target.name, target.value);
  };

  const isEventOpen = event.id === openEventId;

  const isNewEvent = typeof event.id === "string" && event.id.includes("new");

  const stylesForContainer = {
    ...(event.id === openEventId && { maxHeight: 900 }),
    ...(eventHasError &&
      !(event.id === openEventId) && { border: "1px solid #e53d3e" }),
  };

  return (
    <div className={styles.eventWrap}>
      <ActionMenu>
        <button
          type="button"
          onClick={handleShowMenu}
          className={styles.actionButton}
        >
          <span>...</span>
        </button>

        {isComponentVisible && (
          <ul
            ref={ref as React.LegacyRef<HTMLUListElement>}
            className={styles.menu}
          >
            <li className={styles.menuItem} onClick={handleEventEdit}>
              <span className={styles.menuIcon}>
                <PricingIcon />
              </span>
              <span>Edit</span>
            </li>
            {isEventOpen && (
              <li className={styles.menuItem} onClick={handleEventClose}>
                <span className={styles.menuIcon}>
                  <SettingIcon />
                </span>
                <span>Close</span>
              </li>
            )}
          </ul>
        )}
      </ActionMenu>
      <ProfileContainer
        customClass={styles.eventInner}
        customStyles={stylesForContainer}
      >
        {!isEventOpen && (
          <h3 className={styles.title}>
            {event?.name
              ? `${event.name} ${isNewEvent ? "(new)" : ""}`
              : "New Project"}
          </h3>
        )}

        <div className={styles.content}>
          <Input
            label="Full name of the event"
            value={event.name ?? ""}
            name="name"
            onChange={handleInputChange}
          />

          <InputSelect
            label="Kind of sports*"
            name="sportType"
            value={event.sportType ?? ""}
            onChange={handleSelectChange}
            error={validator("EventSportType", event.sportType)}
          >
            <option value="" disabled selected hidden>
              Choose kind of sport
            </option>

            {SportKinds.map((opt) => (
              <option value={opt} key={opt}>
                {opt}
              </option>
            ))}
          </InputSelect>

          <DatePickerField
            label="Start Date"
            value={dateAdapter.toClient(event.startTime) || null}
            name="startTime"
            onChange={handleChangeDate}
            customClassName={styles.dateField}
          />
          <DatePickerField
            label="End Date"
            value={
              dateAdapter.toClient(event.endDate) ||
              dateAdapter.toClient(event.startDate) ||
              null
            }
            name="endDate"
            onChange={handleChangeDate}
            error={validator("EventEndDate", {
              start: dateAdapter.toClient(event.startDate),
              end: dateAdapter.toClient(event.endDate),
            })}
            customClassName={styles.dateField}
          />

          <Input
            label="Location"
            onClick={() => setIsOpenMap(true)}
            placeholder="Choose the location"
            value={event.location ?? ""}
          />

          <Input
            label="Website"
            value={event.website ?? ""}
            name="website"
            onChange={handleInputChange}
            className={styles.input}
          />
        </div>

        <InputText
          label="Event description*"
          value={event.description ?? ""}
          name="description"
          error={validator("EventDescription", event.description)}
          onChange={handleInputChange}
          className={styles.textarea}
        />

        <h6 className={styles.gallery}>Photo gallery of event</h6>

        <UploadLogoFile
          isLogo={false}
          fileRef={fileRef}
          onDeleteFile={file.onDelete}
          onClickFile={file.onClick}
          onChangeFile={file.onChange}
          imagesFile={file.images}
        />
      </ProfileContainer>

      {isOpenMap && (
        <GMapModal
          address={event.location || ""}
          onClose={() => setIsOpenMap(false)}
          setAddress={handleAddressChange}
        />
      )}
    </div>
  );
};

export default PortfolioEvent;
