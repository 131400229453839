export const dateAdapter = {
  /** Transform date from server to client
   *
   *  @param {string} date - form from server is "yyyy-mm-ddThh:mm:ss"
   *  @return {Date} - date form for client is "yyyy-mm-dd"
   */
  toClient(date: string | undefined): Date | null {
    if (!date) return null;
    let [ymd] = date.split("T");
    if (ymd.includes("/")) {
      const [month, day, year] = date.split("/");
      ymd = `${year}-${
        month.length === 1 ? String.prototype.concat("0", month) : month
      }-${day}`;
    }
    return new Date(ymd);
  },
  /** Transfer date from client to server
   *
   *  @param {string} date - form from client is "yyyy-mm-dd"
   *  @return {string} - date form for server is "yyyy-mm-ddThh:mm:ss"
   */
  toServer(date: string): string {
    if (!date) return "";
    const timeStamp = date.split("T")[0];
    if (timeStamp.includes("-")) {
      return String.prototype.concat(date.slice(0, -1), "000+00:00");
    }
    const [month, day, year] = timeStamp.split("/");
    return `${year}-${month}-${day}T00:00:00`;
  },
};
