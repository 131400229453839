import * as img from "@assets/images/events";

export const Events = [
  {
    id: 1,
    title: "International sports competitions",
    date: " 1 JUNE 2022",
    views: 343,
    image: img.event1,
    isBig: false,
    color: "#EEE9CF",
  },
  {
    id: 2,
    title: "International sports competitions",
    date: " 1 JUNE 2022",
    views: 343,
    image: img.event2,
    isBig: true,
    color: "#F7F8F9",
  },
  {
    id: 3,
    title: "International sports competitions",
    date: " 1 JUNE 2022",
    views: 343,
    image: img.event3,
    isBig: false,
    color: "#FFFFFF",
  },
  {
    id: 4,
    image: img.event4,
  },
  {
    id: 5,
    title: "International sports competitions",
    date: " 1 JUNE 2022",
    views: 343,
    image: img.event3,
    isBig: false,
    color: "#FFDC8E",
  },
];
