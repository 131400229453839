import { AxiosResponse } from "axios";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { AuthApi, ProfileApi, SessionApi, UserApi } from "@api";
import { RoutePaths } from "@helpers";
import {
  APIUser,
  IEventUser,
  IOrganization,
  IResponseError,
  ISocialMedia,
  IUser,
} from "@types";

import { googleLogout } from "@react-oauth/google";

interface AuthContextType {
  user: IUser;
  loading: boolean;
  error?: IResponseError;
  signIn: (email: string, password: string) => void;
  signUp: (
    name: string,
    email: string,
    password: string,
    onSuccess?: () => void,
  ) => void;
  logout: () => void;
  hasUser: () => boolean;
  getUser: () => Promise<void>;
  setUserLogo: (ref: React.RefObject<HTMLInputElement>) => {
    onClick: () => void;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  };
  setProfile: (_user: Partial<IUser>) => Promise<AxiosResponse<APIUser> | void>;
  setOrganization: (organization: IOrganization) => void;
  setSocialMedia: (socialMedia: ISocialMedia) => void;
  setEvent: (event: IEventUser) => void;
  resetPassword: (email: string, success?: () => void) => void;
  setPassword: (password: string, token: string, success?: () => void) => void;
  finishRegistration: (token: string, success?: () => void) => void;
  googleAuth: (token: string) => void;
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export const AuthProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const [user, setUser] = useState<IUser>({} as IUser);
  const [error, setError] = useState<IResponseError>();
  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();

  const logout = (): void => {
    setLoading(true);
    const isGoogleAuth = SessionApi.get<boolean>("x-google-auth");
    if (isGoogleAuth) {
      googleLogout();
      SessionApi.remove("x-google-auth");
    }
    // Remove token
    SessionApi.removeToken();
    SessionApi.remove("x-session-token");
    // Remove User data
    SessionApi.remove("x-session-user");
    setUser({} as IUser);
    // Navigate to Landing page
    navigate(RoutePaths.LANDING, { replace: true });
    setLoading(false);
  };

  const getUser = async (): Promise<void> => {
    const token = SessionApi.get<string>("x-session-token");
    if (token) {
      await ProfileApi.getUser()
        .then((response) => {
          setUser(() => response);
          SessionApi.set("x-session-user", response);
        })
        .catch((_error: IResponseError) => {
          setError(_error);
          logout();
        });
    }
  };

  const hasUser = (): boolean => {
    const token = SessionApi.get<string>("x-session-token");
    return !!token;
  };

  const signIn = (email: string, password: string): void => {
    AuthApi.signIn(email, password)
      .then((response) => {
        setLoading(true);
        // Set token
        SessionApi.set("x-session-token", response.token as string);
        SessionApi.setToken(response.token as string);
        // Get User data
        ProfileApi.getUser()
          .then((_response) => {
            SessionApi.set("x-session-user", _response);
            setUser(() => _response);
            // Navigate to profile page
            navigate(RoutePaths.PROFILE, { replace: true });
          })
          .catch((_error: IResponseError) => setError(_error));
      })
      .catch((_error: IResponseError) => {
        setLoading(true);
        setError(_error);
      })
      .finally(() => setLoading(false));
  };

  const signUp = (
    name: string,
    email: string,
    password: string,
    onSuccess?: () => void,
  ): void => {
    AuthApi.signUp(name, email, password)
      .then(() => {
        setLoading(true);
        if (onSuccess) onSuccess();
      })
      .catch((_error: IResponseError) => {
        setLoading(true);
        setError(_error);
      })
      .finally(() => setLoading(false));
  };

  const setUserLogo = (
    ref: React.RefObject<HTMLInputElement>,
  ): {
    onClick: () => void;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  } => {
    const onClick = (): void => {
      ref?.current?.click();
    };

    const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const formData = new FormData();
      if (event?.currentTarget?.files) {
        const { files } = event.currentTarget;
        const fileBuff = new Blob([files[0]]);
        formData.append("formFile", fileBuff);
        UserApi.uploadLogo(formData)
          .then((response) =>
            setUser((_user) => ({
              ..._user,
              IconUrl: response?.url as string,
            })),
          )
          .catch((_error: IResponseError) => setError(_error));
      }
    };

    return { onClick, onChange };
  };

  const setProfile = async (
    _user: Partial<IUser>,
  ): Promise<AxiosResponse<APIUser> | void> => {
    return ProfileApi.setProfile(_user);
  };

  const setOrganization = async (
    organization: IOrganization,
  ): Promise<void> => {
    await ProfileApi.addOrganization(organization).catch(
      (_error: IResponseError) => setError(_error),
    );
  };

  const setEvent = async (event: IEventUser): Promise<void> => {
    await ProfileApi.addEvent(event).catch((_error: IResponseError) =>
      setError(_error),
    );
  };

  const setSocialMedia = async (socialMedia: ISocialMedia): Promise<void> => {
    await ProfileApi.addSocialMedia(socialMedia).catch(
      (_error: IResponseError) => setError(_error),
    );
  };

  const resetPassword = (email: string, success?: () => void): void => {
    setLoading(() => true);
    AuthApi.resetPassword(email)
      .then(() => success && success())
      .catch((_error: IResponseError) => setError(_error))
      .finally(() => setLoading(() => false));
  };

  const finishRegistration = (token: string, success?: () => void): void => {
    setLoading(() => true);
    AuthApi.finishRegistration(token)
      .then(() => success && success())
      .catch((_error: IResponseError) => setError(_error))
      .finally(() => setLoading(() => false));
  };

  const googleAuth = (token: string): void => {
    setLoading(() => true);
    AuthApi.googleAuth(token)
      .then((response) => {
        // Set token
        SessionApi.set("x-session-token", response.token as string);
        SessionApi.set("x-google-auth", true);
        SessionApi.setToken(response.token as string);
        // Get User data
        ProfileApi.getUser()
          .then((_response) => {
            SessionApi.set("x-session-user", _response);
            setUser(() => _response);
            // Navigate to profile page
            navigate(RoutePaths.PROFILE, { replace: true });
          })
          .catch((_error: IResponseError) => setError(_error));
      })
      .catch((_error: IResponseError) => {
        setError(_error);
      })
      .finally(() => setLoading(false));
  };

  const setPassword = (
    password: string,
    token: string,
    success?: () => void,
  ): void => {
    setLoading(() => true);
    AuthApi.setPassword(password, token)
      .then(() => success && success())
      .catch((_error: IResponseError) => setError(_error))
      .finally(() => setLoading(() => false));
  };

  useEffect(() => {
    if (error) setError({} as IResponseError);
  }, [location.pathname]);

  useEffect(() => {
    // Set base url to request like https://localhost:3000
    SessionApi.setBaseUrl();
    // Set token
    const token = SessionApi.get<string>("x-session-token");
    if (token) SessionApi.setToken(token);
    // Set User
    if (token) getUser();
  }, []);

  const value = useMemo(
    () => ({
      user,
      loading,
      error,
      signIn,
      signUp,
      logout,
      hasUser,
      getUser,
      setUserLogo,
      setProfile,
      resetPassword,
      setPassword,
      finishRegistration,
      googleAuth,
      setOrganization,
      setSocialMedia,
      setEvent,
    }),
    [user, loading, error],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default function useAuth(): AuthContextType {
  return useContext(AuthContext);
}
