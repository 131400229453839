/* eslint-disable */
export const omit = <T extends object = {}>(
  obj: T,
  ...keys: string[]
): Partial<T> => {
  return (keys as any).reduce((a: Partial<T>, e: keyof T) => {
    const { [e]: omitted, ...rest } = a;
    return rest;
  }, obj);
};

export const isEmpty = <T>(args: T | T[]): boolean => {
  if (args === null) {
    return true;
  }
  if (typeof args === "object") {
    return Object.keys(args).length === 0;
  }
  if (Array.isArray(args)) {
    return args.length === 0;
  }
  return !args;
};

export function get<T, D = keyof T>(
  obj: T,
  key: string,
  defaultValue?: D,
): D | undefined {
  if (obj === null) {
    return defaultValue;
  }
  if (isEmpty(obj)) {
    return defaultValue;
  }
  let result: D | undefined = undefined;
  Object.entries(obj as object).forEach(([k, v]) => {
    if (k === key) {
      result = v;
    }
  });
  return result ?? defaultValue;
}
