import { CSSProperties } from "react";
import { FieldError } from "react-hook-form";

import { ValidateInputOptions } from "@types";

const options: ValidateInputOptions = {
  errorStyles: { borderColor: "#E53D3E" },
  successStyles: { borderColor: "#51c9c2" },
  defaultStyles: { borderColor: "#DFDFDF" },
};

export const getStyleByValidation = (
  error: FieldError | undefined,
  isValid: boolean,
): CSSProperties => {
  if (isValid) {
    return options.successStyles;
  }
  if (error) {
    return options.errorStyles;
  }
  return options.defaultStyles;
};
