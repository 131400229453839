import React, { FC } from "react";

import CalendarIcon from "@assets/icons/kit/svg/CalendarIcon.svg";

export interface DateProps extends React.InputHTMLAttributes<HTMLInputElement> {
  pr?: number;
}

const Date: FC<DateProps> = (props) => {
  const { pr = 12, ...other } = props;
  return (
    <input
      type="date"
      required
      style={{
        background: `url(${CalendarIcon}) no-repeat calc(100% - ${pr}px) 50%`,
      }}
      {...other}
    />
  );
};

export default Date;
