import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { EventApi } from "@api";
import {
  EventLandingHeader,
  EventsOrganizer,
  HelpProject,
  LandingNavbar,
  PositionsForSponsor,
  ProcessFollowed,
} from "@components";
import { IEvent } from "@types";

import { Footer } from "@components/OrganizersLandingComponents";

import styles from "./EventLandingPage.module.scss";

const EventLandingPage: React.FC = () => {
  const { eventID } = useParams<"eventID">();
  const [event, setEvent] = useState<IEvent | null>(null);

  useEffect(() => {
    if (!event) {
      EventApi.getEventById(Number(eventID) || 1)
        .then((response) => setEvent(response))
        .catch(null);
    }
  }, []);

  return (
    <div className={styles.Landing}>
      <LandingNavbar />
      <EventLandingHeader mEvent={event} />
      <EventsOrganizer mEvent={event} />
      <PositionsForSponsor mEvent={event} />
      <HelpProject />
      <ProcessFollowed />
      {/* <MarketplaceSliderSecond */}
      {/*   title={text[233]} */}
      {/*   visibleItems={window.screen.width > 500 ? 4 : 1} */}
      {/*   sportEvents={allESportsEvents} */}
      {/*   style={{ marginTop: window.screen.width > 500 ? 194 : 118 }} */}
      {/*   Element={SliderElementSecond} */}
      {/* /> */}
      <Footer />
    </div>
  );
};

export default EventLandingPage;
