import cn from "classnames";
import React, { FC, useState } from "react";
import PlacesAutocomplete from "react-places-autocomplete";

import { SearchIcon, location } from "@assets/icons/kit";

import styles from "./gmap.module.scss";

interface IAutoComplete {
  onSelect: (pos: string) => void;
}

const AutoComplete: FC<IAutoComplete> = ({ onSelect }) => {
  const [address, setAddress] = useState<string>("");

  const handleSelect = (_address: string): void => {
    setAddress(_address);
    onSelect(_address);
  };

  return (
    <PlacesAutocomplete
      value={address}
      onChange={setAddress}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <div className={styles.autocomplete}>
            <SearchIcon />
            <input
              {...getInputProps({
                placeholder: "Search Places ...",
                className: styles.autocomplete__input,
              })}
            />
          </div>
          <div
            className={cn(styles.variances, {
              [styles.variances__none]: !suggestions.length,
            })}
          >
            {loading && (
              <div className={styles.variances__loading}>Loading...</div>
            )}
            {suggestions.map((suggestion) => {
              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    className: cn(styles.variances__item, {
                      [styles.active]: suggestion.active,
                    }),
                  })}
                  key={suggestion.id}
                >
                  <img alt="location" src={location} />
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default AutoComplete;
