import classnames from "classnames";
import React, { FC } from "react";
import { AiOutlineClockCircle, AiOutlineEye } from "react-icons/all";

import styles from "./LandingEventCard.module.scss";

export interface LandingEventCardProps {
  title?: string;
  image: string;
  date?: string;
  views?: number;
  isBig?: boolean;
  color?: string;
}

const LandingEventCard: FC<LandingEventCardProps> = ({
  title,
  date,
  views,
  image,
  color,
  isBig = false,
}) => {
  return (
    <div className={classnames(styles.card, { [styles.big]: isBig })}>
      <img src={image} alt={title} />
      {title && (
        <div className={styles.content} style={{ backgroundColor: color }}>
          <h4 className={styles.title}>{title}</h4>
          <div className={styles.footer}>
            <div className={styles.date}>
              <AiOutlineClockCircle /> {date}
            </div>
            <div className={styles.view}>
              <AiOutlineEye /> {views}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LandingEventCard;
