import React from "react";

import {
  EventTitle,
  MainInformation,
  MarketingTools,
  PhotoGallery,
} from "@components";
import { useEvent } from "@context";

import SocialMedia from "../../components/EventDetailsComponents/SocialMedia";

import styles from "./EventDetails.module.scss";

const EventDetails: React.FC = () => {
  const { rawProducts } = useEvent();
  return (
    <div className={styles.wrapper}>
      <EventTitle title="Basic information of the event" />
      <PhotoGallery />
      <MainInformation />
      <SocialMedia disabled />
      <MarketingTools
        style={styles.tools}
        title={`Marketing positions (${rawProducts.length})`}
        templates={rawProducts}
      />
    </div>
  );
};

export default EventDetails;
