import React, { useEffect, useState } from "react";

import { FileApi } from "@api";
import { useEvent, useLanguage } from "@context";
import { useInput } from "@hooks";
import { Input, InputText } from "@shared";
import { IDProduct } from "@types";

import { isEmpty } from "@lib/lodash";

import DynamicInput from "./DynamicInput";
import styles from "./RightSide.module.scss";

interface RightSideProps {
  target: IDProduct;
  onTargetPosition: (item: IDProduct) => void;
}

const defaultValue = (value: string): React.ChangeEvent<HTMLInputElement> =>
  ({
    target: {
      value,
    },
  } as React.ChangeEvent<HTMLInputElement>);

const RightSide: React.FC<RightSideProps> = ({ target, onTargetPosition }) => {
  const { text } = useLanguage();
  const { addProduct, deleteProduct } = useEvent();

  const cost = useInput<number>(target.cost || 0);
  const quantity = useInput<number>(target.quantity || 0);
  const description = useInput<string>(target.description || "");
  const [image, setImage] = useState<string>(target.image || "");

  const specification1 = useInput<string | number>(
    target.specificationValue1 || "",
  );
  const specification2 = useInput<string | number>(
    target.specificationValue2 || "",
  );
  const specification3 = useInput<string | number>(
    target.specificationValue3 || "",
  );
  const specification4 = useInput<string | number>(
    target.specificationValue4 || "",
  );

  useEffect(() => {
    cost.onChange(defaultValue(target.cost ? String(target.cost) : "0"));
    quantity.onChange(
      defaultValue(target.quantity ? String(target.quantity) : "0"),
    );
    description.onChange(defaultValue(target.description || ""));
    specification1.onChange(
      defaultValue(
        target.specificationValue1 ? String(target.specificationValue1) : "",
      ),
    );
    specification3.onChange(
      defaultValue(
        target.specificationValue2 ? String(target.specificationValue2) : "",
      ),
    );
    specification4.onChange(
      defaultValue(
        target.specificationValue3 ? String(target.specificationValue3) : "",
      ),
    );
    specification2.onChange(
      defaultValue(
        target.specificationValue4 ? String(target.specificationValue4) : "",
      ),
    );
    setImage("");
  }, [target]);

  if (isEmpty(target)) return null;

  const handleSave = (): void => {
    if (cost.value > 0 && quantity.value > 0) {
      let url = "none";
      if (!isEmpty(image)) {
        FileApi.uploadImage(image)
          .then((response) => {
            url = response?.url || "none";
          })
          .catch(() => {
            url = "none";
          });
      }

      addProduct({
        productID: target.mid,
        description: description.value || "none",
        price: +cost.value || 100,
        quanity: +quantity.value || 1,
        imgURL: url,
        TEMPMS1: String(specification1.value) || "none",
        TEMPMS2: String(specification2.value) || "none",
        TEMPMS3: String(specification3.value) || "none",
        TEMPMS4: String(specification4.value) || "none",
      });
    } else {
      deleteProduct(target.mid);
    }

    onTargetPosition(target);
  };

  return (
    <div className={styles.right_side}>
      <div>
        <h3>{target.title}</h3>
        <div className={styles.title}>{text[299]}</div>
        <form>
          <InputText
            label={text[300]}
            className={styles.description}
            placeholder={text[301]}
            {...description}
          />
          <Input
            type="number"
            {...quantity}
            label={text[302]}
            className={styles.default}
            onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
          />
          <DynamicInput
            label={target.specification1}
            {...specification1}
            className={styles.font}
          />
          <DynamicInput
            label={target.specification2}
            {...specification2}
            className={styles.font}
          />
          <DynamicInput
            label={target.specification3}
            {...specification3}
            setImage={(img: string) => setImage(img)}
            className={styles.font}
          />
          <DynamicInput
            label={target.specification4}
            {...specification4}
            setImage={(img: string) => setImage(img)}
          />
          <div className={styles.specify}>
            <span>{text[303]}</span>
            <div className={styles.as_input}>
              <input
                type="number"
                onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                {...cost}
              />
              <div>{text[304]}</div>
            </div>
          </div>
          <div className={styles.save}>
            <div>{cost.value}</div>
            <button type="button" onClick={handleSave}>
              {text[305]}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RightSide;
