import { Divider } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";

import { useAuth } from "@context";
import { RoutePaths, getStyleByValidation } from "@helpers";
import { useModal } from "@hooks";

import { Mail } from "@assets/icons/infoModal";
import InfoModal from "@components/InfoModal";
import CheckBox from "@components/LoginForm/components/CheckBox";
import { yupResolver } from "@hookform/resolvers/yup";
import { isEmpty } from "@lib/lodash";
import GButton from "@shared/GoogleButton/GButton";
import Spinner from "@shared/Spinner";

import styles from "./LoginForm.module.scss";
import InputField from "./components/InputField";

const schema = yup
  .object({
    Name: yup
      .string()
      .matches(/^\w+\s\w+$/gim, "Need first and second name")
      .required("Name field is empty"),
    Email: yup
      .string()
      .email("Incorrect email address")
      .required("Email field is empty"),
    Password: yup
      .string()
      .required("Password field is empty")
      .min(8, "The minimum password length must be more than 8 characters"),
    policy: yup
      .boolean()
      .oneOf(
        [true],
        "To register, you must agree to our Terms and Privacy Policy",
      )
      .required("To register, you must agree to our Terms and Privacy Policy"),
  })
  .required(
    "Fields are not filled or entered incorrectly. Correct or re-enter please.",
  );

interface IFields {
  Name: string;
  Email: string;
  Password: string;
  remember: boolean;
  policy: boolean;
  system: string;
}

const Registration: React.FC = () => {
  const { signUp, loading, error, googleAuth } = useAuth();
  const { onOpen, onClose, Modal } = useModal(InfoModal, {});
  const [remember, setRemember] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isValid },
    setValue,
    getValues,
    watch,
    trigger,
  } = useForm<IFields>({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      remember,
      policy: privacy,
    },
  });

  const onSubmit: SubmitHandler<IFields> = useCallback((data) => {
    signUp(data.Name, data.Email, data.Password, () => {
      onOpen();
      setTimeout(() => {
        onClose();
        navigate(RoutePaths.LOGIN, { replace: true });
      }, 3000);
    });
  }, []);

  useEffect(() => {
    if (!isEmpty(error)) {
      switch (error?.detail) {
        case "username already exist":
          setError("system", {
            type: "manual",
            message: `${
              getValues().Email
            } is already a Monetiseur account. Choose a different email address.`,
          });
          break;
        case "invalid credentials":
          setError("system", {
            type: "manual",
            message:
              "The email or password entered is incorrect and does not match.",
          });
          break;
        default:
          setError("system", {
            type: "manual",
            message: "Sorry, an error occurred, please try again.",
          });
      }
    }
  }, [error]);

  useEffect(() => {
    const subscription = watch((_, { name }) => {
      if (name !== "system") trigger("system").catch(null);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  if (loading)
    return <Spinner title="Please wait, your data is being processed." />;

  return (
    <div>
      {/* eslint-disable-next-line */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputField
          label="Name"
          placeholder="Enter your name"
          {...register("Name")}
          required
          style={getStyleByValidation(errors.Name, isValid)}
        />
        {errors.Name?.message && (
          <div className={styles.error}>{errors.Name?.message}</div>
        )}
        <InputField
          label="Email"
          placeholder="Enter your email address"
          {...register("Email")}
          required
          style={getStyleByValidation(errors.Email, isValid)}
        />
        {errors.Email?.message && (
          <div className={styles.error}>{errors.Email?.message}</div>
        )}

        <InputField
          label="Password"
          type="password"
          placeholder="Enter your password"
          {...register("Password")}
          required
          style={getStyleByValidation(errors.Password, isValid)}
        />
        {errors.Password?.message && (
          <div className={styles.error}>{errors.Password?.message}</div>
        )}

        <div className={styles.title_wraper_check}>
          <CheckBox
            label="Remember this device"
            checked={remember}
            onClick={() => {
              setRemember((prevState) => {
                setValue("remember", !prevState);
                return !prevState;
              });
            }}
          />
          <CheckBox
            label="By signing up, you agree to our Terms & Privacy Policy."
            checked={privacy}
            onClick={() =>
              setPrivacy((prevState) => {
                setValue("policy", !prevState);
                return !prevState;
              })
            }
          />
          {errors.policy?.message && (
            <div className={styles.error}>{errors.policy?.message}</div>
          )}
        </div>

        {errors.system?.message && (
          <div className={styles.error}>{errors.system?.message}</div>
        )}

        <button className={styles.button__sign_in} type="submit">
          Sign up
        </button>
      </form>
      <Divider>OR</Divider>
      <GButton onSuccess={googleAuth} />
      <div className={styles.footer}>
        <span>Do you have an account? </span>
        <Link className={styles.link} to={RoutePaths.LOGIN}>
          Sign in
        </Link>
      </div>
      <Modal
        text="Account verification link sent to email"
        btnText="Cancel"
        Icon={<Mail />}
      />
    </div>
  );
};

export default React.memo(Registration);
