import { expression } from "./LoginValidation";

export const isImageFile = (type: string): boolean => {
  const pattern = /^image\/\w+$/gim;
  const rule = expression(type, pattern);
  return Boolean(rule);
};

export const isApplicationFile = (type: string): boolean => {
  const pattern = /^application\/\w+$/gim;
  const rule = expression(type, pattern);
  return Boolean(rule);
};
