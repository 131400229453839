import React from "react";
import { useNavigate } from "react-router-dom";

import { useEvent, useLanguage } from "@context";

import { isEmpty } from "@lib/lodash";

import styles from "./CalendarProceed.module.scss";

interface EventProgressProps {
  title: string;
}

const CalendarProceed: React.FC<EventProgressProps> = ({ title }) => {
  const navigate = useNavigate();
  const { text } = useLanguage();
  const { validateEventData, error, loading } = useEvent();

  const handleSubmit = (): void => {
    if (validateEventData() && isEmpty(error)) {
      // createEvent();
      if (!loading && isEmpty(error)) {
        navigate("/app/toolbox-first", { replace: true });
      }
    }
  };

  return (
    <div className={styles.title}>
      <span>{title}</span>
      <button type="button" onClick={handleSubmit}>
        {text[39]}
      </button>
    </div>
  );
};

export default CalendarProceed;
