import { hoodieFront } from "@assets/images/Temples/Merchandising";
import { web6 } from "@assets/images/Temples/WebStreaming";

export interface Idata {
  id: number;
  event: string;
  type: string;
  description: string;
  coast: number;
  positions: string;
  photo: string;
}

export const data: Idata[] = [
  {
    id: 0,
    event: "Brazilian Jiu-Jitsu: Tatami Chess",
    type: "Polo",
    description:
      "A beautiful townhouse in the heart of Miami’s down town district. With 3 bedrooms and a heated pool.",
    coast: 169,
    positions: "Merchandise",
    photo: hoodieFront,
  },
  {
    id: 1,
    event: "Brazilian Jiu-Jitsu: Tatami Chess",
    type: "Polo",
    description:
      "A beautiful townhouse in the heart of Miami’s down town district. With 3 bedrooms and a heated pool.",
    coast: 169,
    positions: "Merchandise",
    photo: web6,
  },
  {
    id: 2,
    event: "Brazilian Jiu-Jitsu: Tatami Chess",
    type: "Polo",
    description:
      "A beautiful townhouse in the heart of Miami’s down town district. With 3 bedrooms and a heated pool.",
    coast: 169,
    positions: "Merchandise",
    photo: hoodieFront,
  },
  {
    id: 3,
    event: "Brazilian Jiu-Jitsu: Tatami Chess",
    type: "Polo",
    description:
      "A beautiful townhouse in the heart of Miami’s down town district. With 3 bedrooms and a heated pool.",
    coast: 169,
    positions: "Merchandise",
    photo: web6,
  },
];
