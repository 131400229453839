import React, { useEffect, useState } from "react";

import { IDataId } from "@types";

import CircularIndicator from "../../CircularIndicator";

import styles from "./EventLandingShiftSlider.module.scss";

interface IEventLandingShiftSlider {
  data: IDataId[];
  blockWidth: number;
  title: string;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  Element: React.FC<any>;
  className?: string;
  heightSlider?: number;
  indent?: number;
}

const EventLandingShiftSlider: React.FC<IEventLandingShiftSlider> = ({
  data,
  title,
  blockWidth,
  Element,
  heightSlider,
}) => {
  const fullWidth = data.length * blockWidth - 25;
  const screenWidth = window.screen.width;

  const [shiftWidth, setShiftWidth] = useState<number>(0);
  const [seconds, setSeconds] = useState<number>(1);
  const [percentage, setPercentage] = useState<number>(1);
  const shiftsNumber =
    fullWidth - screenWidth > 0
      ? Math.ceil((fullWidth - screenWidth) / blockWidth)
      : 0;

  useEffect(() => {
    const move = 99 / shiftsNumber;
    const value = (-shiftWidth / blockWidth) * move;
    setPercentage(value !== 0 ? value : 1);
  }, [shiftWidth, setShiftWidth]);

  // useEffect(() => {
  //   setSeconds(4);
  //   setTimeout(() => {
  //     setShiftWidth(-shiftsNumber * blockWidth);
  //   }, 4000);
  // }, []);

  const onNext = (): void => {
    setSeconds(1);
    if (fullWidth + shiftWidth > screenWidth)
      setShiftWidth((prev) => prev - blockWidth);
  };

  const onPrev = (): void => {
    setSeconds(1);
    if (shiftWidth < 0) setShiftWidth((prev) => prev + blockWidth);
  };
  return (
    <div className={styles.slider} style={{ height: heightSlider }}>
      <div className={styles.title}>
        <h3>{title}</h3>
        <div className={styles.button}>
          <button type="button" className={styles.arrow} onClick={onPrev}>
            <CircularIndicator
              className={styles.svg_cicl}
              percentage={0}
              isLeft
              iHover={false}
            />
          </button>
          <button type="button" className={styles.arrow} onClick={onNext}>
            <CircularIndicator
              className={styles.svg_cicl}
              percentage={percentage}
              isLeft={false}
              iHover={false}
            />
          </button>
        </div>
      </div>
      <div
        className={styles.slider_container}
        style={{ left: shiftWidth, transition: `all ${seconds}s ease-out` }}
      >
        {data.map((item) => (
          <Element key={item.id} {...item} />
        ))}
      </div>
    </div>
  );
};

export default EventLandingShiftSlider;
