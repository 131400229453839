import {
  averageEvents1,
  averageEvents2,
  averageEvents3,
} from "@assets/images/events";

export interface MarketingCardProps {
  title: string;
  subtitle: string;
  image: string;
  description: string;
  position: number;
  total: number;
  type: string;
}

export const templateAverage: MarketingCardProps[] = [
  {
    title: "Live Streaming",
    subtitle: "online",
    position: 24,
    total: 900,
    image: averageEvents1,
    description: "Sponsors logo in overlays, transitions and so on...",
    type: "online",
  },
  {
    title: "Merchandise",
    subtitle: "offline",
    position: 14,
    total: 560,
    image: averageEvents2,
    description: "Logo of the sponsor on the event’s clothes and attributes...",
    type: "offline",
  },
  {
    title: "Event’s social media",
    subtitle: "online & offline",
    position: 4,
    total: 220,
    image: averageEvents3,
    description: "Logo of the sponsor on the event’s clothes and attributes...",
    type: "hybrid",
  },
];
