import React from "react";

import { IIcon } from "@types";

const EmojioneStar: React.FC<IIcon> = ({ className }) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5 6.3H9.775L8 0.75L6.225 6.3H0.5L5.125 9.725L3.375 15.25L8 11.825L12.625 15.25L10.85 9.7L15.5 6.3Z"
        fill="#FFCE31"
      />
    </svg>
  );
};

export default EmojioneStar;
