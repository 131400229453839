import { motion } from "framer-motion";
import React from "react";
import { Link, NavLink } from "react-router-dom";

import { RoutePaths } from "@helpers";

import { ReactComponent as Facebook } from "@assets/icons/social/facebook.svg";
import { ReactComponent as Insta } from "@assets/icons/social/insta.svg";
import { ReactComponent as Linkedin } from "@assets/icons/social/linkedin.svg";
import { ReactComponent as Twitter } from "@assets/icons/social/twitter.svg";
import { Portal } from "@components/PortalModal";

import styles from "./LandingMenu.module.scss";

interface ILandingMenu {
  onClose: (e: React.MouseEvent<HTMLDivElement>) => void | MouseEvent;
  onClick?: (key: number) => () => void;
}

const LandingMenu: React.FC<ILandingMenu> = ({ onClose, onClick }) => {
  return (
    <Portal>
      <motion.div
        className={styles.layout}
        initial={{ opacity: 0 }}
        exit={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        onClick={(e) => onClose(e)}
      >
        <div className={styles.modal}>
          <div className={styles.inner} />
          <nav className={styles.nav}>
            <NavLink
              className={({ isActive }) =>
                isActive ? `${styles.active} ${styles.link}` : styles.link
              }
              to="/"
            >
              Home
            </NavLink>
            <button
              type="button"
              className={styles.link}
              onClick={onClick ? onClick(0) : onClick}
            >
              Brand
            </button>
            <button
              type="button"
              className={styles.link}
              onClick={onClick ? onClick(1) : onClick}
            >
              Rights holder
            </button>
            <Link className={styles.link} to={RoutePaths.DEFAULT}>
              Contacts
            </Link>
          </nav>
          <ul className={styles.social_list}>
            <li className={styles.social_item}>
              <a
                target="_blank"
                className={styles.social_link}
                href="https://www.facebook.com/"
                rel="noreferrer"
              >
                <Facebook width="20" height="20" />
              </a>
            </li>
            <li className={styles.social_item}>
              <a
                target="_blank"
                className={styles.social_link}
                href="https://twitter.com/monetiseur_io"
                rel="noreferrer"
              >
                <Twitter width="20" height="20" />
              </a>
            </li>
            <li className={styles.social_item}>
              <a
                target="_blank"
                className={styles.social_link}
                href="https://www.linkedin.com/company/monetiseur"
                rel="noreferrer"
              >
                <Linkedin width="23" height="22" />
              </a>
            </li>
            <li className={styles.social_item}>
              <a
                target="_blank"
                className={styles.social_link}
                href="https://instagram.com/monetiseur.io?igshid=YmMyMTA2M2Y="
                rel="noreferrer"
              >
                <Insta width="20" height="20" />
              </a>
            </li>
          </ul>
          <div className={styles.line} />
          <ul className={styles.info_list}>
            <li className={styles.info_item}>
              <Link className={styles.info_link} to={RoutePaths.DEFAULT}>
                Privacy Police
              </Link>
            </li>
            <li className={styles.info_item}>
              <Link className={styles.info_link} to={RoutePaths.DEFAULT}>
                Terms & Conditions
              </Link>
            </li>
          </ul>
        </div>
      </motion.div>
    </Portal>
  );
};
export default LandingMenu;
