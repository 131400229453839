import React from "react";

import { IIcon } from "@types";

const DeleteIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.8333 5.33333H17.25C17.0566 4.39284 16.5449 3.54779 15.8011 2.9406C15.0573 2.33341 14.1269 2.00121 13.1667 2L11.5 2C10.5398 2.00121 9.60945 2.33341 8.86564 2.9406C8.12183 3.54779 7.6101 4.39284 7.41668 5.33333H4.83335C4.61233 5.33333 4.40037 5.42113 4.24409 5.57741C4.08781 5.73369 4.00002 5.94565 4.00002 6.16667C4.00002 6.38768 4.08781 6.59964 4.24409 6.75592C4.40037 6.9122 4.61233 7 4.83335 7H5.66668V17.8333C5.66801 18.938 6.10742 19.997 6.88853 20.7782C7.66965 21.5593 8.72869 21.9987 9.83335 22H14.8333C15.938 21.9987 16.9971 21.5593 17.7782 20.7782C18.5593 19.997 18.9987 18.938 19 17.8333V7H19.8333C20.0544 7 20.2663 6.9122 20.4226 6.75592C20.5789 6.59964 20.6667 6.38768 20.6667 6.16667C20.6667 5.94565 20.5789 5.73369 20.4226 5.57741C20.2663 5.42113 20.0544 5.33333 19.8333 5.33333ZM11.5 3.66667H13.1667C13.6836 3.6673 14.1876 3.82781 14.6097 4.1262C15.0318 4.42459 15.3512 4.84624 15.5242 5.33333H9.14252C9.3155 4.84624 9.63493 4.42459 10.057 4.1262C10.4791 3.82781 10.9831 3.6673 11.5 3.66667ZM17.3333 17.8333C17.3333 18.4964 17.07 19.1323 16.6011 19.6011C16.1323 20.0699 15.4964 20.3333 14.8333 20.3333H9.83335C9.17031 20.3333 8.53442 20.0699 8.06558 19.6011C7.59674 19.1323 7.33335 18.4964 7.33335 17.8333V7H17.3333V17.8333Z"
        fill="currentColor"
      />
      <path
        d="M10.6665 17C10.8876 17 11.0995 16.9122 11.2558 16.7559C11.4121 16.5996 11.4999 16.3876 11.4999 16.1666V11.1666C11.4999 10.9456 11.4121 10.7337 11.2558 10.5774C11.0995 10.4211 10.8876 10.3333 10.6665 10.3333C10.4455 10.3333 10.2335 10.4211 10.0772 10.5774C9.92095 10.7337 9.83315 10.9456 9.83315 11.1666V16.1666C9.83315 16.3876 9.92095 16.5996 10.0772 16.7559C10.2335 16.9122 10.4455 17 10.6665 17Z"
        fill="currentColor"
      />
      <path
        d="M13.9998 17C14.2208 17 14.4328 16.9122 14.5891 16.7559C14.7454 16.5996 14.8332 16.3876 14.8332 16.1666V11.1666C14.8332 10.9456 14.7454 10.7337 14.5891 10.5774C14.4328 10.4211 14.2208 10.3333 13.9998 10.3333C13.7787 10.3333 13.5668 10.4211 13.4105 10.5774C13.2542 10.7337 13.1664 10.9456 13.1664 11.1666V16.1666C13.1664 16.3876 13.2542 16.5996 13.4105 16.7559C13.5668 16.9122 13.7787 17 13.9998 17Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default DeleteIcon;
