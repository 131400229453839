import classnames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { AiOutlineClose } from "react-icons/all";
import { Link, useNavigate } from "react-router-dom";

import { useAuth, useLanguage } from "@context";
import { RoutePaths } from "@helpers";

import { AvatarDefault, DownOutlineIcon, WorldIcon } from "@assets/icons/kit";
import { ReactComponent as Logo } from "@assets/icons/logo.svg";
import { ReactComponent as Humburher } from "@assets/icons/menu-hamburger.svg";

import HeaderAccount from "../../HeaderAccount";
import LanguageSelection from "../LanguageSelection";

import LandingMenu from "./LandingMenu";
import styles from "./LandingNavbar.module.scss";

interface ILandingNavbar {
  onClick?: (key: number) => () => void;
}

const LandingNavbar: React.FC<ILandingNavbar> = ({ onClick }) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { text } = useLanguage();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [lang, setLang] = useState<string>("EN");
  const [isOpenLang, setIsOpenLang] = useState<boolean>(false);
  const prevScrollY = useRef(0);

  const onOpen = (): void => {
    setIsOpenLang(false);
    setIsOpen((prev) => !prev);
  };
  const onClose = (e: React.MouseEvent<HTMLDivElement>): void => {
    if (e.target !== e.currentTarget) return;
    setIsOpen(false);
  };

  const onProfile = (): void => {
    navigate("/app/profile", { replace: true });
  };

  const variationDiv = {
    init: {
      y: -10,
      opacity: 0,
    },
    anim: {
      y: 0,
      opacity: 1,
    },
  };
  const transitionDiv = (delay: number): { delay: number } => ({
    delay,
  });

  const changeLang = (langCode: string): (() => void) => {
    return () => {
      setLang(langCode);
      setIsOpenLang(false);
    };
  };

  useEffect(() => {
    const handleScroll = (): void => {
      const currentScrollY = window.scrollY;
      if (prevScrollY.current < currentScrollY && isOpen) {
        setIsOpen(false);
        setIsOpenLang(false);
      }
      prevScrollY.current = currentScrollY;
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, [isOpen]);

  return (
    <div className={styles.navbar}>
      <AnimatePresence>
        {isOpen && <LandingMenu onClose={onClose} onClick={onClick} />}
      </AnimatePresence>
      <div className={styles.leftMenu}>
        <motion.div
          className={!isOpen ? styles.menu : styles.open_menu}
          onClick={onOpen}
          variants={variationDiv}
          initial="init"
          animate="anim"
          transition={transitionDiv(0)}
        >
          {!isOpen ? <Humburher width="28" height="28" /> : <AiOutlineClose />}
          {text[200]}
        </motion.div>

        {/* {!isOpen && ( */}
        {/*  <motion.div */}
        {/*    variants={variationDiv} */}
        {/*    initial="init" */}
        {/*    animate="anim" */}
        {/*    transition={transitionDiv(0.4)} */}
        {/*    className={styles.hidden_el} */}
        {/*  > */}
        {/*    <Link to="/marketplace" className={styles.link}> */}
        {/*      {text[202]} */}
        {/*    </Link> */}
        {/*  </motion.div> */}
        {/* )} */}
      </div>
      <div className={styles.main_title}>
        <Link to="/" className={styles.logo_link}>
          <Logo width="333" height="33" />
        </Link>
      </div>
      <div className={styles.rightMenu}>
        {user?.UserID ? (
          <div className={styles.account} onClick={onProfile}>
            <HeaderAccount
              icon={user?.IconUrl || AvatarDefault}
              firstname={user?.FirstName}
              secondname={user?.SecondName}
              progress={35}
              isProgress={false}
            />
          </div>
        ) : (
          <div className={styles.login_reg}>
            <motion.div
              variants={variationDiv}
              initial="init"
              animate="anim"
              transition={transitionDiv(0.4)}
              className={styles.hidden_el}
            >
              <Link
                to={RoutePaths.REGISTRATION}
                className={classnames(styles.button)}
              >
                Sign Up
              </Link>
            </motion.div>
            <motion.div
              variants={variationDiv}
              initial="init"
              animate="anim"
              transition={transitionDiv(0.2)}
              className={styles.hidden_el}
            >
              <Link
                to={RoutePaths.LOGIN}
                className={classnames(styles.button, styles.solid)}
              >
                {text[204]}
              </Link>
            </motion.div>
          </div>
        )}

        <motion.div
          variants={variationDiv}
          initial="init"
          animate="anim"
          transition={transitionDiv(0)}
          className={styles.hidden_el}
        >
          <button
            type="button"
            className={styles.language}
            style={isOpenLang ? { color: "#1598FF" } : {}}
            onClick={() => setIsOpenLang((prev) => !prev)}
          >
            <WorldIcon className={styles.world} />
            <span>{lang}</span>
            <div>
              <DownOutlineIcon />
            </div>
          </button>
        </motion.div>
        <motion.div
          variants={variationDiv}
          initial="init"
          animate="anim"
          transition={transitionDiv(0.2)}
          className={styles.basket}
        >
          <Link to="/" className={styles.logo_link}>
            <Logo width="163" height="13" />
          </Link>
          {/* <Link to={RoutePaths.MARKETPLACE}> */}
          {/*  <img src={ShoppingIcon} alt="shop" /> */}
          {/* </Link> */}
          {/* <div className={styles.notification}>3</div> */}
        </motion.div>
        {isOpenLang && <LanguageSelection changeLang={changeLang} />}
      </div>
    </div>
  );
};

export default LandingNavbar;
