export const Data = [
  {
    title: "Lorem Ipsum1",
  },
  {
    title: "Lorem Ipsum2",
  },
  {
    title: "Lorem Ipsum3",
  },
  {
    title: "Lorem Ipsum4",
  },
  {
    title: "Lorem Ipsum5",
  },
  {
    title: "Lorem Ipsum6",
  },
];
