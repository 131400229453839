import {
  CustomerListIcon,
  DocumentIcon,
  FAQIcon,
  NotificationIcon,
  PricingIcon,
  SettingIcon,
} from "@assets/icons/kit";

export const MenuItems = [
  {
    title: "",
    links: [
      {
        path: "/app/profile",
        name: "Profile",
        icon: CustomerListIcon,
      },
      {
        path: "/app/events",
        name: "Events",
        icon: DocumentIcon,
      },
    ],
  },
  {
    title: "Other Information",
    links: [
      {
        path: "/app/notification",
        name: "Notification",
        icon: NotificationIcon,
      },
      {
        path: "/app/pricing",
        name: "Pricing",
        icon: PricingIcon,
      },
      {
        path: "/app/help",
        name: "Help",
        icon: FAQIcon,
      },
    ],
  },
  {
    title: "Settings",
    links: [
      // {
      //   path: "/app/personal_settings",
      //   name: "Personal Settings",
      //   icon: SettingPersonalIcon,
      // },
      {
        path: "/app/settings",
        name: "Global Settings",
        icon: SettingIcon,
      },
    ],
  },
];
