import React, { useState } from "react";

import { useAuth, useLanguage } from "@context";
import { getPhone } from "@helpers";
import { useInput } from "@hooks";
import { Input, InputPhone } from "@shared";

import styles from "./SettingsPersonalInformation.module.scss";

const SettingsPersonalInformation: React.FC = () => {
  const { text } = useLanguage();
  const { user } = useAuth();

  const FirstName = useInput(user?.FirstName);
  const SecondName = useInput(user?.SecondName);
  const Email = useInput(user?.E_mail || user?.Login);
  const [PhoneNumber, setPhoneNumber] = useState(
    getPhone(user?.PhoneNumber || "+372"),
  );

  const onPhoneChange = (alias: string, value: string): void => {
    setPhoneNumber(() => [alias, value]);
  };

  // useEffect(() => {
  //   const newUser = {
  //     FirstName: FirstName.value,
  //     SecondName: SecondName.value,
  //     E_mail: Email.value,
  //     PhoneNumber: PhoneNumber.join(""),
  //   };
  // }, [FirstName.value, SecondName.value, Email.value, PhoneNumber]);

  return (
    <div className={styles.container}>
      <h4 className={styles.title}>{text[11]}</h4>
      <div className={styles.group}>
        <Input label="First Name*" {...FirstName} />
        <Input label="Last Name*" {...SecondName} />
        <div>
          <div />
          <Input label={text[109]} {...Email} disabled />
        </div>
        <InputPhone
          label={text[168]}
          inputValue={PhoneNumber[1]}
          selectValue={PhoneNumber[0]}
          onPhoneChange={onPhoneChange}
        >
          <option defaultValue="" disabled>
            {" "}
          </option>
          <option value="+7">+7</option>
          <option value="+372">+372</option>
        </InputPhone>
      </div>
    </div>
  );
};

export default SettingsPersonalInformation;
