import React from "react";

import styles from "./EventPositionStatisticsItem.module.scss";

interface IEventPositionStatisticsItem {
  img: string;
  stats: string;
  text: string;
}

const EventPositionStatisticsItem: React.FC<IEventPositionStatisticsItem> = ({
  img,
  stats,
  text,
}) => {
  return (
    <div className={styles.item}>
      <div className={styles.data}>
        <img src={img} alt={text} className={styles.img} />
        <div className={styles.meta}>
          <p className={styles.stats}>{stats}</p>
          <p className={styles.text}>{text}</p>
        </div>
      </div>
    </div>
  );
};

export default EventPositionStatisticsItem;
