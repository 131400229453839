export interface NotificationProps {
  id: number;
  description: string;
  time: string;
}

export const notifications: NotificationProps[] = [
  {
    id: 1,
    description: "Your MEMO organization has been approved",
    time: "01:00 AM",
  },
  {
    id: 2,
    description: "Your MEMO organization has been approved",
    time: "01:00 AM",
  },
];
