import React from "react";

import { IIcon } from "@types";

const KazakhstanIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg
      className={className}
      width="19"
      height="14"
      viewBox="0 0 19 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_758_5939)">
        <mask
          id="mask0_758_5939"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="19"
          height="14"
        >
          <path
            d="M17.1905 0H1.80952C0.810151 0 0 0.810151 0 1.80952V11.7619C0 12.7613 0.810151 13.5714 1.80952 13.5714H17.1905C18.1898 13.5714 19 12.7613 19 11.7619V1.80952C19 0.810151 18.1898 0 17.1905 0Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_758_5939)">
          <path
            d="M17.1905 0H1.80952C0.810151 0 0 0.810151 0 1.80952V11.7619C0 12.7613 0.810151 13.5714 1.80952 13.5714H17.1905C18.1898 13.5714 19 12.7613 19 11.7619V1.80952C19 0.810151 18.1898 0 17.1905 0Z"
            fill="#00AFCC"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.80957 2.25743C1.80957 2.01043 2.01948 1.80957 2.26195 1.80957C2.51167 1.80957 2.71433 2.005 2.71433 2.25743V4.076C2.71433 4.323 2.50443 4.52386 2.26195 4.52386C2.01224 4.52386 1.80957 4.32843 1.80957 4.076V2.25743ZM2.71433 4.06695C2.71433 3.81995 2.92424 3.61909 3.16671 3.61909C3.41643 3.61909 3.61909 3.81452 3.61909 4.06695V5.88552C3.61909 6.13252 3.40919 6.33338 3.16671 6.33338C2.917 6.33338 2.71433 6.13795 2.71433 5.88552V4.06695ZM2.71433 7.686C2.71433 7.439 2.92424 7.23814 3.16671 7.23814C3.41643 7.23814 3.61909 7.43357 3.61909 7.686V9.50457C3.61909 9.75157 3.40919 9.95243 3.16671 9.95243C2.917 9.95243 2.71433 9.757 2.71433 9.50457V7.686ZM1.80957 9.49552C1.80957 9.24852 2.01948 9.04767 2.26195 9.04767C2.51167 9.04767 2.71433 9.24309 2.71433 9.49552V11.3141C2.71433 11.5611 2.50443 11.762 2.26195 11.762C2.01224 11.762 1.80957 11.5665 1.80957 11.3141V9.49552ZM1.80957 5.881C1.80957 5.63128 2.01948 5.42862 2.26195 5.42862C2.51167 5.42862 2.71433 5.63852 2.71433 5.881C2.71433 6.13071 2.50443 6.33338 2.26195 6.33338C2.01224 6.33338 1.80957 6.12348 1.80957 5.881ZM2.71433 2.26195C2.71433 2.01224 2.92424 1.80957 3.16671 1.80957C3.41643 1.80957 3.61909 2.01948 3.61909 2.26195C3.61909 2.51167 3.40919 2.71433 3.16671 2.71433C2.917 2.71433 2.71433 2.50443 2.71433 2.26195ZM1.80957 6.78576C1.80957 6.53605 2.01948 6.33338 2.26195 6.33338C2.51167 6.33338 2.71433 6.54328 2.71433 6.78576C2.71433 7.03548 2.50443 7.23814 2.26195 7.23814C2.01224 7.23814 1.80957 7.02824 1.80957 6.78576ZM2.71433 11.3096C2.71433 11.0599 2.92424 10.8572 3.16671 10.8572C3.41643 10.8572 3.61909 11.0671 3.61909 11.3096C3.61909 11.5593 3.40919 11.762 3.16671 11.762C2.917 11.762 2.71433 11.552 2.71433 11.3096ZM1.80957 7.69052C1.80957 7.44081 2.01948 7.23814 2.26195 7.23814C2.51167 7.23814 2.71433 7.44805 2.71433 7.69052C2.71433 7.94024 2.50443 8.1429 2.26195 8.1429C2.01224 8.1429 1.80957 7.933 1.80957 7.69052Z"
            fill="#DAC21D"
          />
          <path
            d="M10.4047 8.14283C11.6539 8.14283 12.6666 7.13014 12.6666 5.88092C12.6666 4.63171 11.6539 3.61902 10.4047 3.61902C9.15551 3.61902 8.14282 4.63171 8.14282 5.88092C8.14282 7.13014 9.15551 8.14283 10.4047 8.14283Z"
            fill="#FFC600"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.23804 6.33337L8.1428 8.1429L10.4047 9.04766L12.6666 8.1429L13.5714 6.33337V9.04766L10.4047 9.95242L7.23804 9.04766V6.33337Z"
            fill="#FFC600"
          />
          <path
            d="M17.1905 0.452393H1.80954C1.06001 0.452393 0.452393 1.06001 0.452393 1.80954V11.7619C0.452393 12.5114 1.06001 13.1191 1.80954 13.1191H17.1905C17.94 13.1191 18.5476 12.5114 18.5476 11.7619V1.80954C18.5476 1.06001 17.94 0.452393 17.1905 0.452393Z"
            stroke="black"
            strokeOpacity="0.1"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_758_5939">
          <rect width="19" height="13.5714" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default KazakhstanIcon;
