export const LanguageData = {
  "1": "Your account has been created!",
  "2": "Registration 04 - 04 -2022",
  "3": "City",
  "4": "Full name of the organisation*",
  "5": "Date of confirmation",
  "6": "(+372) 66 00 00 00",
  "7": "Disable social network",
  "8": "0",
  "9": "Position: ",
  "10": "Edit account",
  "11": "Personal Information",
  "12": "ZIP Code",
  "13": "Web site",
  "14": "Team and Board members",
  "15": "Remove team member",
  "16": "Disable your organization s social network?",
  "17": "Number of seats per event",
  "18": "Total income: $400",
  "19": "Monetiseur",
  "20": "General information",
  "21": "N",
  "22": "https://barra.ee",
  "23": "Here you can manage all documents which will be shown to sponsors.",
  "24": "Are you sure you want to delete the selected command read?",
  "25": "Disconnected",
  "26": "Upload",
  "27": "Event’s social media",
  "28": "Profile",
  "29": "Organisation’s documents",
  "30": "0",
  "31": "barra@gmail.com",
  "32": "There‘s nothing here,  yet.",
  "33": "Cancel",
  "34": "Creating a new event",
  "35": "Marketing Toolboox",
  "36": "Mention of the sponsors in posts,  stories and so on...",
  "37": "Events",
  "38": "Team info",
  "39": "Proceed",
  "40": "Location",
  "41": "There are no members in your feed. Add team members.",
  "42": "New member",
  "43": "you have done the work 25%",
  "44": "Marketing groups",
  "45": "Position:10",
  "46": "Other Information",
  "47": "Social medias",
  "48": "judo",
  "49": "Notes",
  "50": "Team and Board members",
  "51": "Full name of the member",
  "52": "Total income: $300",
  "53": "Basic information",
  "54": "Streaming",
  "55": "Notification",
  "56": "Portfolio",
  "57": "kiruku 6",
  "58": "Add notes about organization",
  "59": "Nikolay Nosov",
  "60": "Nikita Pavlov",
  "61": "$150",
  "62": "**Upload a photo or video of the event to be used for publication.",
  "63": "Sponsors logo in overlays,  transitions and so on...",
  "64": "Pricing",
  "65": "Website generetion",
  "66": "Estonia",
  "67": "Taekwondo",
  "68": "Total cost",
  "69": "Sports discipline",
  "70": "Telegram",
  "71": "Full name off the event*",
  "72": "Position: 24",
  "73": "Representative",
  "74": "$350",
  "75": "Help",
  "76": "First Name",
  "77": "Your MEMO organization has been approved",
  "78": "jodo",
  "79": "Add a new member",
  "80": "Theme of event",
  "81": "Total income: $900",
  "82": "Giveaways",
  "83": "Personal Settings",
  "84": "Nika",
  "85": "0, 0416666666666667",
  "86": "527 969 99 00",
  "87": "Edit",
  "88": "Position",
  "89": "Select on the map",
  "90": "8",
  "91": "7",
  "92": "6",
  "93": "5",
  "94": "4",
  "95": "3",
  "96": "2",
  "97": "1",
  "98": "Giveaway something from sponsors products between your sub...",
  "99": "Global Settings",
  "100": "Kind of sport",
  "101": "372",
  "102": "0 / 100",
  "103": "Delete",
  "104": "Add social medias",
  "105": "Additional information",
  "106": "You added for position sponsors: 24",
  "107": "Position: 3",
  "108": "Upgrade Now",
  "109": "Email Address",
  "110": "527 965 26 36",
  "111": "Vika",
  "112": "Position: trainer",
  "113": "Connect",
  "114": "Event description*",
  "115": "Merchandise",
  "116": "Total income: ",
  "117": "Logout",
  "118": "Personal Address",
  "119": "Tallinn",
  "120":
    "Your subscription is not active. Subscribe and get access to the full platform.",
  "121": "nikolay.nosov@gmail.com",
  "122": "Add your organization’s social medias.",
  "123": "Add a description to an event",
  "124": "Man s T-shirt",
  "125": "Available",
  "126": "Marketplace",
  "127": "Address 1",
  "128": "31316",
  "129": "SUBSCRIBE",
  "130": "(+372) 55 00 00 00",
  "131": "Facebook",
  "132": "Photo gallery of the event",
  "133": "Position display date 09/05/2021",
  "134": "$ 990",
  "135": "User Policy",
  "136": "Country",
  "137": "0, 1",
  "138": "Documentary information",
  "139": "Add a new document",
  "140": "Connected",
  "141": "Streaming",
  "142": "Number of selected items",
  "143": "Contacts",
  "144": "Last Name",
  "145": "Click to change photo",
  "146": "Maria Mets",
  "147": "Twitter",
  "148": "Video design",
  "149": "Full name of We the document*",
  "150": "$ 0",
  "151": "Profile information",
  "152": "Belaya",
  "153": "logo",
  "154": "Position: administrator",
  "155": "Instagram",
  "156": "Position display date 10/05/2021",
  "157": "Hathor Production - year program",
  "158": "Start time",
  "159": "Nika Belaya",
  "160": "Date of Birth",
  "161": "**This background image will be used for the website design.",
  "162": "maria.mets@gmail.com",
  "163": "Messenger",
  "164": "Merchandise",
  "165": "Click to upload",
  "166": "44632",
  "167": "nika.belay@gmail.com",
  "168": "Phone Number",
  "169": "Information organization",
  "170": "Whatsapp",
  "171": "Logo of the sponsor on the event’s clothes and attributes...",
  "172": "19",
  "173": "Name",
  "174": "Email",
  "175": "Password",
  "176": "Having trouble signing in",
  "177": "Remember this device",
  "178": "By signing up, you agree to our Terms & Privacy Policy.",
  "179": "Sign up with Google",
  "180": "Don’t you have an account?",
  "181": "Sign in",
  "182": "Sign up",
  "183": "OR",
  "184": "Enter your name",
  "185": "Enter your email address",
  "186": "Enter your password",
  "187": "Verification email address",
  "188":
    "An email with a verification code has already been sent to your inbox.",
  "189": "Please type OTP code that we give you",
  "190": "Resend",
  "191": "OK",
  "192": "Add team members.",
  "193": "President",
  "194": "Available social network",
  "195": "Available your organization's social network?",
  "196": "You have done the work",
  "197": "or drag and drop a file (max. size 12MB)",
  "198": "Theme of event",
  "199": "You added for position sponsors:",
  "200": "Menu",
  "201": "For organisers",
  "202": "Marketplace",
  "203": "Sign In",
  "204": "Log In",
  "205": "EN",
  "206": "RU",
  "207": "About",
  "208": "Sports events",
  "209": "Video",
  "210": "Platform information",
  "211": "Contact us",
  "212": "Linkedin",
  "213": "Organize events with ease",
  "214": "Create a media plan",
  "215": "Promote your event",
  "216": "Attract sponsors with a few simple steps.",
  "217": "Get Start",
  "218": "More Info",
  "219":
    "50,000 sports organizations have created over a million sports events with Monetiseur.",
  "220": "Events coming up",
  "221": "Browse all events",
  "222": "What you can do?",
  "223":
    "Our platform works on all devices, so you only need to Register it once and get great results forever.",
  "224": "Get start",
  "225": "Work",
  "226": "Perform other natural operations",
  "227": "Get started",
  "228": "Highlights of past events",
  "229":
    "Running short on ideas? Surf through our selection of past events for inspiration.",
  "230": "Take the first step",
  "231": "Registration is easy and takes less than 5 minutes",
  "232": "Organisations have already joined",
  "233": "Upcoming club events",
  "234": "Canada",
  "235": "Dong an Lake Sports Park Stadium",
  "236":
    "The winners of all games in 2022 will meet in the Australian Open womens singles semi-finals.",
  "237": "In the collection popular events",
  "238": "December",
  "239": "SPORTS CENTER FORUS TONDA",
  "240": "Eesti GP",
  "241": "Estonia",
  "242": "listeners",
  "243": "show",
  "244": "photos",
  "245": "On a mission to empower teams",
  "246":
    "We sponsor sports and create opportunities for physical education, support the new generation, and highly value culture and artistic heritage.",
  "247": "Learn more about how we`re changing the world",
  "248": "Founded",
  "249": "Number of events",
  "250": "Club members",
  "251": "Raised",
  "252": "Positions for the sponsor from the organizer",
  "253": "Become a sponsor of a sports organization for the proposed positions",
  "254": "Product selection categories",
  "255": "position",
  "256": "Choose",
  "257": "Help the project",
  "258":
    "Support the project financially by donating any amount for the development of the project:",
  "259": "Support",
  "260": "Organizer",
  "261": "Choose the right sports organization",
  "262": "Choice",
  "263": "Choose the right sponsorship template",
  "264": "Sponsor",
  "265": "Become a sponsor of the selected template",
  "266": "Result",
  "267": "Expect the result after the event and a report from the organizer",
  "268": "Process followed by sponsors",
  "269": "The platform was created for organizations and sponsors",
  "270": "Discover the world of sports activities",
  "271":
    "Will allow solving the problems of forming a unified calendar plan for sports competitions",
  "272": "Subscribe",
  "273": "Popular sports events",
  "274": "More filters",
  "275": "Available languages",
  "276": "Sports event countries",
  "277": "See more",
  "278": "Show all",
  "279": "Hide optional",
  "280": "Apply",
  "281": "Price range",
  "282": "Average price of a template -$120",
  "283": "min. price",
  "284": "max. price",
  "285": "Maximum number of participants",
  "286": "min. people",
  "287": "more than",
  "288": "All eSports events",
  "289": "All judo sports events",
  "290": "Show",
  "291": "Top - level streamers",
  "292": "Streams from outstanding world-class esportsmen",
  "293": "Load more",
  "294": "Become a sports event organizer with a platform!",
  "295": "If you still want to become an event manager, register",
  "296": "Constructor",
  "297": "Group positions",
  "298": "Event group",
  "299":
    "Simply choose your accommodation, enter the price and quantity. It really is that simple.",
  "300": "Description",
  "301": "Enter text…",
  "302": "Enter Quantity",
  "303": "Specify the cost",
  "304": "USD",
  "305": "Save",
  "306": "Next",
};
