import { AnimatePresence } from "framer-motion";
import React, { FC, useEffect, useState } from "react";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";

import { IDataId } from "@types";

import styles from "./LandingSlider.module.scss";

export interface LandingSliderProps<D = IDataId> {
  visibleItems: number;
  data: D[];
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  Element: React.FC<any>;
}

export const next = (callback: () => void): (() => void) => {
  return callback;
};

const LandingSlider: FC<LandingSliderProps> = ({
  visibleItems,
  data,
  Element,
}) => {
  const [slides, setSlides] = useState<typeof data>([]);
  const [shiftIndex, setShiftIndex] = useState<number>(0);

  useEffect(() => {
    setSlides(data.slice(0, visibleItems));
  }, [data, visibleItems]);

  useEffect(() => {
    setSlides(data.slice(shiftIndex, shiftIndex + visibleItems));
  }, [shiftIndex, setShiftIndex, visibleItems, data]);

  const onNext = (): void => {
    if (shiftIndex < data.length - visibleItems)
      setShiftIndex((prev) => prev + 1);
  };

  const onPrev = (): void => {
    if (shiftIndex > 0) setShiftIndex((prev) => prev - 1);
  };

  return (
    <div className={styles.slider}>
      <AnimatePresence>
        <div className={styles.sliderContainer}>
          {slides.map((item) => (
            <Element key={item.id} {...item} />
          ))}
        </div>
      </AnimatePresence>
      <div className={styles.buttons}>
        <button type="button" onClick={onPrev}>
          <HiChevronLeft />
        </button>
        <button type="button" onClick={onNext}>
          <HiChevronRight />
        </button>
      </div>
    </div>
  );
};

export default LandingSlider;
