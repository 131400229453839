import React from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";

import { useAuth } from "@context";
import { RoutePaths } from "@helpers";
import {
  BasketPage,
  Default,
  EventDetails,
  EventLandingPage,
  EventPage,
  EventPositionPage,
  EventsCalendar,
  GlobalSettings,
  Login,
  MainLayout,
  MarketPlaceBasketPage,
  MarketPlacePage,
  NotificationPage,
  OrganizersLanding,
  ProfilePage,
  Recovery,
  Registration,
  ToolboxFirstPage,
  WhitePage,
} from "@pages";

import TemplesMobilView from "@components/EventPageComponents/TemplesMobilView";
import NotificationList from "@components/NotificationPageComponents/NotificationList";
import { isEmpty } from "@lib/lodash";

const PrivateRoute: React.FC = () => {
  const { hasUser, user } = useAuth();
  if (!hasUser()) return <Navigate to={RoutePaths.LANDING} replace />;
  if (isEmpty(user)) return null;
  return <Outlet />;
};

const Navigation: React.FC = () => {
  return (
    <Routes>
      <Route path={RoutePaths.LANDING} element={<OrganizersLanding />} />
      <Route path={RoutePaths.MARKETPLACE} element={<MarketPlacePage />} />
      <Route
        path={RoutePaths.MARKETPLACE_DETAILS}
        element={<EventLandingPage />}
      />
      <Route path={RoutePaths.BASKET} element={<BasketPage />} />
      <Route path={RoutePaths.LOGIN} element={<Login />} />
      <Route path={RoutePaths.REGISTRATION} element={<Registration />} />
      <Route path={RoutePaths.RESET} element={<Recovery />} />
      <Route path={RoutePaths.DEFAULT} element={<Default />} />

      <Route path={RoutePaths.TEMP} element={<MarketPlaceBasketPage />} />
      <Route element={<PrivateRoute />}>
        <Route path={RoutePaths.MONETISEUR} element={<MainLayout />}>
          <Route path={RoutePaths.EVENT} element={<EventPage />} />
          <Route
            path={RoutePaths.EVENT_POSITION}
            element={<EventPositionPage />}
          />

          <Route
            path={RoutePaths.TOOLBOX_FIRST}
            element={<ToolboxFirstPage />}
          />
          <Route path={RoutePaths.NOTIFICATION} element={<NotificationPage />}>
            <Route path="overview" element={<NotificationList />} />
            <Route path="general" element={<NotificationList />} />
          </Route>
          <Route path={RoutePaths.PROFILE} element={<ProfilePage />} />
          <Route path={RoutePaths.EVENT_DETAILS} element={<EventDetails />} />

          <Route path={RoutePaths.PRICING} element={<WhitePage />} />
          <Route path={RoutePaths.HELP} element={<WhitePage />} />
          <Route
            path={RoutePaths.GLOBAL_SETTING}
            element={<GlobalSettings />}
          />
          <Route path={RoutePaths.CONSTRUCTOR} element={<TemplesMobilView />} />
          <Route
            path={RoutePaths.EVENT_CALENDAR}
            element={<EventsCalendar />}
          />
        </Route>
      </Route>
    </Routes>
  );
};

export default React.memo(Navigation);
