import React from "react";

import { LandingNavbar } from "@components";

import { Footer } from "@components/OrganizersLandingComponents";

import Basket from "../../components/MarketPlaceBasket/Basket";

import styles from "./MarketPlaceBasketPage.module.scss";

const MarketPlaceBasketPage: React.FC = () => {
  return (
    <>
      <div className={styles.wrapper}>
        <LandingNavbar />
        <Basket />
      </div>
      <Footer style_={{ marginTop: 0 }} />
    </>
  );
};

export default MarketPlaceBasketPage;
