import React, { useEffect } from "react";
import { useOutletContext } from "react-router-dom";

import {
  ErrorField,
  PageHeader,
  SettingsCurrency,
  SettingsKycUpdate,
  SettingsLanguage,
  SettingsPersonalInformation,
  SettingsSidebar,
} from "@components";
import { IStateHook } from "@types";

import {
  EarthGlobeIcon,
  IonIcon,
  PaperIcon,
  PersonalIcon,
  ThreeUsersIcon,
} from "@assets/icons/kit";
import ProfileWrapper from "@components/ProfileWrapper";

import SettingsPassword from "../../components/GlobalSettingsComponents/SettingsPassword";

import styles from "./GlobalSettings.module.scss";

const GlobalSettings: React.FC = () => {
  const errorTitle =
    "Your subscription is not active. Subscribe and get access to the full platform.";
  // Variables
  const isMobile = window.screen.width > 1050;
  const setRefs = useOutletContext<IStateHook<number[]>>();
  const refs = new Array(6)
    .fill(0)
    .map(() => React.createRef<HTMLDivElement>());

  // Effects
  useEffect(() => {
    setRefs(refs.map((ref) => ref.current?.offsetTop || 0));
    return () => {
      setRefs([]);
    };
  });

  // Functions
  const onClick = (key: number) => {
    return () => {
      refs[key].current?.scrollIntoView({ behavior: "smooth" });
    };
  };

  return (
    <>
      <ErrorField title={errorTitle} isError={false} />
      <div className={styles.container}>
        <PageHeader
          title="Global Settings"
          to="/app/events"
          buttonTitle="Save changes"
        />

        <div className={styles.content}>
          <div className={styles.title}>Global Settings</div>

          <SettingsSidebar onClick={onClick} />
          {isMobile ? (
            <div className={styles.wrapper}>
              <div ref={refs[0]} />
              <SettingsPersonalInformation />
              <div ref={refs[1]} />
              <SettingsLanguage />
              <div ref={refs[2]} />
              <SettingsPassword />
              <div ref={refs[3]} />
              <SettingsCurrency />
              <div ref={refs[4]} />
              <SettingsKycUpdate />
              <div ref={refs[5]} />
            </div>
          ) : (
            <div className={styles.wrapper}>
              <ProfileWrapper title="Personal Settings" Icon={PersonalIcon}>
                <SettingsPersonalInformation />
              </ProfileWrapper>
              <ProfileWrapper title="Language" Icon={IonIcon}>
                <SettingsLanguage />
              </ProfileWrapper>
              <ProfileWrapper title="Password & Security" Icon={PaperIcon}>
                <SettingsPassword />
              </ProfileWrapper>
              <ProfileWrapper title="Currency Preferency" Icon={ThreeUsersIcon}>
                <SettingsCurrency />
              </ProfileWrapper>
              <ProfileWrapper title="KYC Update " Icon={EarthGlobeIcon}>
                <SettingsKycUpdate />
              </ProfileWrapper>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default GlobalSettings;
