import React from "react";

import { useLanguage } from "@context";

// import { DeleteIcon } from "@assets/icons/kit";
import { IToolsCard } from "../MarketingTools.data";

import styles from "./ToolsCard.module.scss";

const ToolsCard: React.FC<IToolsCard> = ({
  type,
  title,
  description,
  cost,
  available,
  image,
}) => {
  const { text } = useLanguage();
  // const { deleteProduct } = useEvent();

  return (
    <div className={styles.card}>
      <figure>
        {/* eslint-disable */}
        <img src={image} alt="tools" />
        <figcaption>
          <button type="button">{type}</button>
          <span className={styles.title}>{title}</span>
          <span className={styles.description}>{description}</span>
        </figcaption>
      </figure>
      <div className={styles.quantity}>
        <div className={styles.quantity_block}>
          <div className={styles.cost}>
            <span>${cost}</span>
            <span>{text[68]}</span>
          </div>
          <div className={styles.line} />
          <div className={styles.available}>
            <span>{available}</span>
            <span>{text[125]}</span>
          </div>
        </div>
        {/* <div className={styles.more_mini} onClick={() => deleteProduct(id)}> */}
        {/*   <DeleteIcon /> */}
        {/*   /!* <FiMoreVertical /> *!/ */}
        {/* </div> */}
      </div>
    </div>
  );
};

export default ToolsCard;
