import classnames from "classnames";
import React, { useState } from "react";

import { IIcon } from "@types";

import { DownOutlineIcon } from "@assets/icons/kit";

import styles from "./ProfileWrapper.module.scss";

interface IProfileWrapper {
  Icon: React.FC<IIcon>;
  title: string;
}

const ProfileWrapper: React.FC<React.PropsWithChildren<IProfileWrapper>> = ({
  Icon,
  title,
  children,
}) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  return (
    <div>
      <div className={styles.wrapper}>
        <div
          className={styles.profile_tab}
          onClick={() => setIsActive((prev) => !prev)}
        >
          <div className={styles.left_block}>
            <span
              className={classnames(styles.icon, {
                [styles.active_icon]: isActive,
              })}
            >
              <Icon />
            </span>
            <span
              className={classnames(styles.title, {
                [styles.active_title]: isActive,
              })}
            >
              {title}
            </span>
          </div>
          <DownOutlineIcon
            className={classnames(styles.down_icon, {
              [styles.active_down_icon]: isActive,
            })}
          />
        </div>
      </div>
      {isActive && children}
    </div>
  );
};

export default ProfileWrapper;
