import React from "react";

import { IIcon } from "@types";

const TiktokIcon: React.FC<IIcon> = ({ className }) => {
  return (
    <svg
      className={className}
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5433 6.85568C19.5258 6.86881 19.5126 10.1676 19.5126 14.1838C19.5126 22.1988 19.5301 21.7219 19.2545 22.4001C19.0183 22.9776 18.5326 23.5551 17.9858 23.9094C17.417 24.2769 16.9926 24.4126 16.2926 24.4432C15.6145 24.4738 15.2339 24.3951 14.6345 24.1063C13.5058 23.5551 12.8101 22.5094 12.7445 21.2626C12.6351 19.2194 14.2714 17.5832 16.3189 17.6882C16.8658 17.7144 17.242 17.8238 17.7539 18.0951L18.1126 18.2876V16.2751V14.2669L17.8851 14.2013C16.892 13.9169 15.4526 13.9257 14.2845 14.2232C11.9001 14.8313 9.90076 16.8394 9.27513 19.2632C9.10013 19.9326 9.04763 20.3832 9.05201 21.1313C9.05638 23.4982 10.2245 25.6551 12.2064 26.9763C14.5908 28.5601 17.6489 28.5601 20.0595 26.9719C21.5514 25.9876 22.6583 24.3688 23.052 22.5969C23.2183 21.8488 23.2314 21.4157 23.2314 17.1544V13.0113L23.5945 13.0638C24.207 13.1601 25.2439 13.1338 25.8258 13.0113L25.9439 12.9851V11.1301V9.27506H25.4014C24.4914 9.27506 23.9008 9.11318 23.5464 8.75881C23.2839 8.49631 23.2314 8.28631 23.2314 7.49443V6.82506H21.4026C20.3964 6.82506 19.5564 6.83818 19.5433 6.85568Z"
        fill="#333333"
      />
    </svg>
  );
};

export default TiktokIcon;
