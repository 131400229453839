import classnames from "classnames";
import { motion } from "framer-motion";
import React, { FC } from "react";

import styles from "./LandingCard.module.scss";

export interface LandingCardProps {
  image: string;
  logo: string;
  title: string;
  date: string;
  city: string;
  isScaling?: boolean;
}

const LandingCard: FC<LandingCardProps> = ({
  image,
  logo,
  city,
  date,
  title,
  isScaling = false,
}) => {
  return (
    <motion.div
      className={classnames(styles.card, { [styles.repeat]: isScaling })}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.5 }}
    >
      <img src={image} alt={title} className={styles.image} />
      <div className={styles.content}>
        <div className={styles.logo}>
          <img src={logo} alt={title} />
        </div>
        <div className={styles.container}>
          <span className={styles.date}>
            {date}• {city}
          </span>
          <span className={styles.title}>{title}</span>
        </div>
      </div>
    </motion.div>
  );
};

export default LandingCard;
