import React, { CSSProperties } from "react";
import DatePicker from "react-datepicker";

import { ReactComponent as DropdownIcon } from "@assets/icons/shared/downdrop.svg";

import styles from "./DatePickerField.module.scss";

interface IDatePickerField {
  label?: string;
  value: Date | null;
  name: string;
  customClassName?: string;
  onChange: (name: string, date: Date | null) => void;
  error?: string;
  customStyles?: CSSProperties;
  placeholder?: string;
}

const DatePickerField: React.FC<IDatePickerField> = ({
  label,
  value,
  name,
  customClassName,
  onChange,
  customStyles,
  error,
  placeholder,
}) => {
  return (
    <div
      className={`${styles.wrapper} ${customClassName || ""} ${
        error ? styles.errorDate : ""
      }`}
      {...(customStyles && { style: customStyles })}
    >
      <label htmlFor="date_picker" className={styles.label}>
        {label || ""}
      </label>
      <div className={styles.block}>
        <DatePicker
          className={styles.input}
          dateFormat="dd MMM yyyy"
          showYearDropdown
          {...(placeholder && { placeholderText: placeholder })}
          showMonthDropdown
          selected={value}
          onChange={(date) => onChange(name, date)}
        />
        <DropdownIcon width="24" height="24" className={styles.icon} />
      </div>
      {!!error && <div className={styles.errorText}>{error}</div>}
    </div>
  );
};

export default DatePickerField;
