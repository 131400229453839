import React, { FC } from "react";

import { capitalChar } from "@helpers";

import ProgressBar from "../ProgressBar";

import styles from "./HeaderAccount.module.scss";

export interface HeaderAccountProps {
  icon: string;
  firstname: string;
  secondname: string;
  progress: number;
  isProgress: boolean;
}

const HeaderAccount: FC<HeaderAccountProps> = ({
  icon,
  firstname,
  secondname,
  progress,
  isProgress,
}) => {
  return (
    <div className={styles.account}>
      <img src={icon} alt={firstname} className={styles.icon} />
      <div className={styles.content}>
        <div className={styles.info}>
          {capitalChar(firstname)} {capitalChar(secondname)}
        </div>
        {isProgress && (
          <div className={styles.statistics}>
            <ProgressBar percents={progress} />
            <span className={styles.progress}>{progress}%</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default HeaderAccount;
