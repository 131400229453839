import React from "react";

import styles from "./BasketPrice.module.scss";

interface IBasketPrice {
  items: number;
  total: number;
  subTotal?: number;
  onContinue?: () => void;
}

const BasketPrice: React.FC<IBasketPrice> = ({
  items,
  total,
  subTotal,
  onContinue,
}) => {
  return (
    <div>
      <div className={styles.sidebar}>
        <h3 className={styles.title}>Price summary</h3>
        <p className={styles.text}>
          <span className={styles.info}>Number of positions</span>
          <span className={styles.info}>{items}</span>
        </p>
        <p className={styles.text}>
          <span className={styles.info}>Subtotal</span>
          <span className={styles.info}>${subTotal}</span>
        </p>
        <div className={styles.line} />
        <p className={styles.total}>
          <span className={styles.totalInfo}>Total</span>
          <span className={styles.totalInfo}>${total}</span>
        </p>
      </div>
      <button type="button" className={styles.btn} onClick={onContinue}>
        Continue
      </button>
    </div>
  );
};

export default BasketPrice;
