import React, { useState } from "react";

import { useLanguage } from "@context";
import { useHover } from "@hooks";

import SliderElement from "@components/MarketPlaceComponents/MarketPlaceStreamers/StreamersSlider/SliderElement/SliderElement";

import CircularIndicator from "../../../CircularIndicator";
import { blockWidth, streamers } from "../MarketPlaceStreamers.data";

import styles from "./StreamersSlider.module.scss";

const StreamersSlider: React.FC = () => {
  const { text } = useLanguage();
  const isMobil: boolean = window.screen.width < 500;
  const fullWidth = isMobil
    ? streamers.length * blockWidth - 16
    : streamers.length * blockWidth - 35;
  const screenWidth = isMobil ? window.screen.width : window.screen.width - 484;

  const prev = useHover();
  const next = useHover();

  const [shiftWidth, setShiftWidth] = useState<number>(0);

  const onNext = (): void => {
    if (fullWidth + shiftWidth >= screenWidth)
      setShiftWidth((pre) => pre - blockWidth);
  };

  const onPrev = (): void => {
    // setSeconds(1);
    if (shiftWidth < 0) setShiftWidth((pre) => pre + blockWidth);
  };
  return (
    <>
      <div
        className={styles.content}
        style={{ left: shiftWidth, transition: `all 1s ease-out` }}
      >
        {streamers.map((item) => (
          <SliderElement key={item.id} {...item} />
        ))}
      </div>
      <div className={styles.button}>
        <span>
          {text[290]} ({streamers.length})
        </span>
        <button
          type="button"
          className={styles.arrow}
          onClick={onPrev}
          onMouseEnter={prev.onEnter}
          onMouseLeave={prev.onLeave}
        >
          <CircularIndicator
            className={styles.svg_cicl}
            percentage={0}
            isLeft
            iHover={prev.isHovered}
          />
        </button>
        <button
          type="button"
          className={styles.arrow}
          onClick={onNext}
          onMouseEnter={next.onEnter}
          onMouseLeave={next.onLeave}
        >
          <CircularIndicator
            className={styles.svg_cicl}
            percentage={0}
            isLeft={false}
            iHover={next.isHovered}
          />
        </button>
      </div>
    </>
  );
};

export default StreamersSlider;
