import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { DataApi } from "@api";
import { IText } from "@types";

import { LanguageData } from "./languageContext.data";

interface LanguageContextType {
  text: IText;
  onChangeLang: (lang: string) => void;
}

const LanguageContext = createContext<LanguageContextType>(
  {} as LanguageContextType,
);

const LanguageProvider: React.FC = ({ children }) => {
  const [lang, setLang] = useState<string>("ENG");
  const [text, setText] = useState<IText>({});

  const onChangeLang = (language: string): void => {
    setLang(language);
  };

  const getLanguage = useCallback(() => {
    DataApi.getLabels(lang)
      .then((response) => setText(response))
      .catch(() => setText(LanguageData));
  }, [lang]);

  useEffect(() => getLanguage(), [getLanguage]);
  const value = useMemo(() => ({ text, onChangeLang }), [text]);

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = (): LanguageContextType => {
  return useContext(LanguageContext);
};

export default LanguageProvider;
