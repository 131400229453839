import React, { FC } from "react";

import { useQueryParams } from "@hooks";

import { MailRecovery, PasswordRecovery } from "@components/LoginForm";
import { LoginLayout } from "@components/layouts";

const Recovery: FC = () => {
  const params = useQueryParams();
  return (
    <LoginLayout>
      {!params?.token ? (
        <MailRecovery />
      ) : (
        <PasswordRecovery token={params.token as string} />
      )}
    </LoginLayout>
  );
};

export default React.memo(Recovery);
